import { IExternalIncome } from '../../../../types/workshop';
import { convertStripeAmountWithoutDecimal } from '../../../../utils/common';

const columns = [
  {
    title: 'Received By',
    render: (value: string, record: IExternalIncome) => record?.receivedBy,
  },
  {
    title: 'Amount',
    render: (value: string, record: IExternalIncome) => {
      return `${record?.currency} ${convertStripeAmountWithoutDecimal(
        Number(record?.amount),
        record?.currency
      )}`;
    },
  },
  {
    title: 'Note',
    render: (value: string, record: IExternalIncome) => record?.note,
  },
];

export default columns;
