import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, message, Spin, Typography } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';

import './index.scss';

import AlertComp from '../../alert';
import { WorkshopDetail } from '../../../types/workshop';
import {
  createWorkshop,
  getWorkshopsBySlug,
  updateWorkshop,
} from '../../../services/workshop-service';
import { useAuthState } from '../../../context/auth-state';
import { TechniqueDetail } from '../../../types/technique';
import { WorkshopInstructor } from '../../../types/instructor';
import { convertStripeAmount, handleError } from '../../../utils/common';
import { getTechniques } from '../../../services/technique-service';
import { getInstructors } from '../../../services/instructor-service';
import { activeEbanxCountries } from '../../../constants/stripeCountry';
import { getUser, TUser } from '../../../services/users-service';

import validationSchema from './validation-schema';
import CreateWorkshopForm from './form';

const ebanxTerritoryMessage =
  'All funds will go to the platform as this workshop is taking place in an EBANX-supported territory.';
const stripeNotVerifiedMessage =
  "All funds will go to the platform as workshop owner's account is onboarded to Stripe but not verified.";
const noPaymentStripeMessage =
  "All funds will go to the platform as workshop owner's account is not onboarded to Stripe.";
const stripeSplitMessage =
  "Since workshop owner's account is onboarded and verified with Stripe, funds will be split as described above.";

const CreateWorkshop = () => {
  const location = useLocation();
  const [requesting, setRequesting] = useState(false);

  const { user: loggedinUser } = useAuthState();
  const [workshopOwner, setWorkshopOwner] = useState<TUser | null>(null);

  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);
  const [instructor, setInstructors] = useState<WorkshopInstructor[]>([]);

  const [loading, setLoading] = useState({
    technique: false,
    instructor: false,
  });

  const navigate = useNavigate();
  const slug = location?.pathname?.split('/')?.[2];
  const isAddMode = !slug;

  const form = useForm<WorkshopDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const country = form.watch('country');
  const workshopOwnerId = form.watch('workshopOwner');

  const loadTechniques = useCallback(async () => {
    setLoading((prevState) => ({ ...prevState, technique: true }));
    try {
      const response = await getTechniques();
      setTechniques(response.data.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading((prevState) => ({ ...prevState, technique: false }));
    }
  }, []);

  const loadInstructors = useCallback(async () => {
    setLoading((prevState) => ({ ...prevState, instructor: true }));
    try {
      const response = await getInstructors();
      setInstructors(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading((prevState) => ({ ...prevState, instructor: false }));
    }
  }, []);

  const setWorkshopValues = async () => {
    setRequesting(true);
    try {
      const workshopDataResponse = await getWorkshopsBySlug(slug);
      const workshopData = workshopDataResponse?.data?.data?.workshop;
      const workshopObject: WorkshopDetail = {
        ...workshopData,
        level: workshopData?.level || undefined,
        zoomDetails: workshopData?.zoomDetails ?? '',
        address2: workshopData?.address2 ?? '',
        ticketPrice: convertStripeAmount(
          workshopData?.ticketPrice,
          workshopData?.currency
        ),
        expenseAgreedAmount: convertStripeAmount(
          workshopData?.expenseAgreedAmount,
          workshopData?.expenseAgreedCurrency
        ),
        startDate: moment(workshopData?.startDate),
        endDate: moment(workshopData?.endDate),
        ticketEndDate: moment(workshopData?.ticketEndDate),
        isTeachingSelf:
          workshopData?.workshopInstructor?.id?.toString() ===
          workshopData?.workshopOwner?.toString(),
        isCertificationMultiLevel: techniques?.find(
          (data) => data.slug === workshopData?.techniqueSlug
        )?.isCertificationMultiLevel,
        ...(workshopData?.workshopInstructor?.id?.toString() !==
          workshopData?.workshopOwner?.toString() && {
          instructor: workshopData?.workshopInstructor?.id,
        }),
      };

      form.reset(workshopObject);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const getWorkshopOwner = async (userId: string) => {
    try {
      const response = await getUser({ _id: userId });
      setWorkshopOwner(response?.data?.data as TUser);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitHandler: SubmitHandler<WorkshopDetail> = async (values) => {
    setRequesting(true);
    try {
      if (isAddMode) {
        const res = await createWorkshop(values);
        const workshopSlug = await res?.data?.data?.data?.workshop?.slug;
        message.success('Workshop added successfully');
        form.reset({});
        navigate(`/workshops?workshopSlug=${workshopSlug}`);
      } else {
        await updateWorkshop(values, slug);
        message.success('Workshop updated successfully');
        navigate('/workshops');
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (slug) {
      setWorkshopValues();
    } else {
      form.reset({});
    }
  }, [location?.pathname, slug, techniques]);

  useEffect(() => {
    if (!slug) {
      form.reset({});
    }
  }, [slug, location?.pathname]);

  useEffect(() => {
    if (!isAddMode) {
      if (workshopOwnerId) {
        getWorkshopOwner(workshopOwnerId);
      }
    } else {
      setWorkshopOwner(loggedinUser);
    }
  }, [isAddMode, loggedinUser, workshopOwnerId]);

  const paymentMessage = activeEbanxCountries.includes(country)
    ? ebanxTerritoryMessage
    : workshopOwner?.stripeConenct
    ? workshopOwner?.stripeConnectVerified
      ? stripeSplitMessage
      : stripeNotVerifiedMessage
    : noPaymentStripeMessage;

  useEffect(() => {
    loadTechniques();
    loadInstructors();
  }, []);

  return (
    <div className="">
      <AlertComp />
      <Spin spinning={requesting || loading.technique || loading.instructor}>
        <Typography.Title level={2}>
          {isAddMode ? 'LAUNCH WORKSHOP' : 'UPDATE WORKSHOP'}
        </Typography.Title>
        <CreateWorkshopForm
          form={form}
          techniques={techniques}
          instructor={instructor}
          workshopOwner={workshopOwner}
          isAddMode={isAddMode}
        />
        {!loading.technique && !loading.instructor && (
          <Button type="primary" onClick={form.handleSubmit(onSubmitHandler)}>
            {isAddMode ? 'GO LIVE' : 'UPDATE WORKSHOP'}
          </Button>
        )}
        <br />
        <br />

        {form.watch('country') && (
          <>
            <div className="ant-alert ant-alert-info ">
              <span
                role="img"
                aria-label="info-circle"
                className="anticon anticon-info-circle ant-alert-icon"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="info-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
                </svg>
              </span>
              <div className="ant-alert-content">
                <div className="ant-alert-message">{paymentMessage}</div>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default CreateWorkshop;
