import moment from 'moment';

import { IWorkshopCoupon } from '../../../types/workshop';
import { NOT_AVAILABLE } from '../../../constants/common';

const columns = [
  {
    title: 'SNo',
    render: (value: number, record: IWorkshopCoupon, index: number) =>
      index + 1,
    key: 'slno',
    width: 100,
  },
  {
    title: 'Name',
    render: (value: string, record: IWorkshopCoupon) => record?.code,
    key: 'name',
    width: 200,
  },
  {
    title: '% off',
    render: (value: string, record: IWorkshopCoupon) =>
      record?.discountPercentage + '%',
    key: '%off',
    width: 150,
  },
  {
    title: 'Max Redemptions',
    render: (value: number, record: IWorkshopCoupon) =>
      record?.limit ?? 'Unlimited',
    key: 'limit',
    width: 200,
  },
  {
    title: 'Expiry Date',
    render: (value: string, record: IWorkshopCoupon) =>
      moment(record?.redeemBy * 1000).format('DD MMM YYYY'),
    key: 'expiry',
    width: 200,
  },
  {
    title: 'Total Redemptions',
    render: (value: string, record: IWorkshopCoupon) => record?.redemptions,
    key: 'noOfRedemptions',
    width: 200,
  },
  {
    title: 'Redemptions for Current Workshop',
    render: (
      value: string,
      record: IWorkshopCoupon & { redemptionsOnCurrentWorkshop?: number }
    ) => record?.redemptionsOnCurrentWorkshop || NOT_AVAILABLE,
    key: 'noOfCurrentRedemptions',
    width: 300,
  },
  {
    title: 'Status',
    render: (value: string, record: IWorkshopCoupon) =>
      record?.active ? 'Active' : 'Expired',
    key: 'status',
    width: 150,
  },
];

export default columns;
