export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const CLIENT_FRONTEND_URL = process.env.REACT_APP_FRONT_END_URL;
export const API_BASE_URL_ADMIN = `${API_BASE_URL}/admin`;

export const AUTH = {
  LOGIN: `${API_BASE_URL}/user/admin-login`,
  REFRESH_SESSION: `${API_BASE_URL}/user/session-refresh`,
  FORGOT_PASSWORD: `${API_BASE_URL}/user/forgot-password`,
};

export const QUESTIONS = {
  GET_QUESTIONS: `${API_BASE_URL_ADMIN}/getQuestion`,
  GET_CATEGORIES: `${API_BASE_URL_ADMIN}/getCategory`,
  ADD_UPDATE_QUESTION: `${API_BASE_URL_ADMIN}/addQuestion`,
};

export const EQUIPMENTS = {
  EQUIPMENTS: `${API_BASE_URL}/equipment`,
  ADD_UPDATE_EQUIPMENTS: `${API_BASE_URL_ADMIN}/addEquipments`,
};

export const WORKSHOPS = {
  WORKSHOPS: `${API_BASE_URL}/workshop`,
  NEW_WORKSHOPS: `${API_BASE_URL}/workshop/new-workshops`,
  GET_FILES: `${API_BASE_URL}/files`,
  ATTENDEES: `${API_BASE_URL}/workshop-attendees`,
  WORKSHOP_PHOTOS: `${API_BASE_URL}/workshop/workshop-photos`,
  WAITLIST: `${API_BASE_URL}/waitlist`,
  SEND_MAIL_ATTENDEE: `${API_BASE_URL}/workshop-attendees/mail-attendees`,
  GET_FILTERED_WORKSHOPS: `${API_BASE_URL}/workshop`,
  WORKSHOPS_COUPONS: `${API_BASE_URL}/workshop/coupons`,
};
export const WORKSHOPATTENDEES = {
  WORKSHOPATTENDEES: `${API_BASE_URL}/workshop-attendees`,
  ATTENDEES: `${API_BASE_URL}/workshop-attendees`,
  MAIL: `${API_BASE_URL}/mail-attendees`,
  SIGN_WORKSHOP_WAIVER: `${API_BASE_URL}/sign-workshop-waiver`,
};
export const USERS = {
  GET_USERS: `${API_BASE_URL}/user/all`,
  UPDATE_USER: `${API_BASE_URL}/user`,
  DELETE_USER: `${API_BASE_URL}/user`,
  PERMANENTLY_DELETE_USER: `${API_BASE_URL}/user/permanently-delete`,
  RESTORE_USER: `${API_BASE_URL}/user/restore-user`,
  GET_URL_TO_UPlOAD_USER_PROFILE_PIC: `${API_BASE_URL}/user/getUrlToUploadUserProfilePic`,
  GET_USER: `${API_BASE_URL}/user`,
  CHANGE_EMAIL: `${API_BASE_URL}/user/change-email`,
  FORGOT_PASSWORD: `${API_BASE_URL}/user/forgot-password`,
  SEND_PASSWORD: `${API_BASE_URL}/user/send-password`,
  ALL_SUBSCRIPTION: `${API_BASE_URL}/user/plans`,
  CHANGE_SUBSCRIPTION: `${API_BASE_URL}/user/change-subscription`,
  REMOVE_PROFILE_PIC: `${API_BASE_URL}/user/deleteprofilepic`,
  LOCAION: `${API_BASE_URL}/user/location`,
  SCHOOL: `${API_BASE_URL}/user/school`,
  GET_FILTERED_USERS: `${API_BASE_URL}/user`,
  UPDATE_MEMBER_TYPE: `${API_BASE_URL}/user/change-member-type`,
  GET_USER_TIMELINE: `${API_BASE_URL}/user/timeline`,
};

export const TEMPLATES = {
  TEMPLATE: `${API_BASE_URL}/template`,
};

export const FAQ = {
  FAQ: `${API_BASE_URL}/faq`,
};
export const PAYMENT = {
  ONBOARDING: `${API_BASE_URL}/payment`,
};

export const INSTRUCTOR = {
  INSTRUCTOR: `${API_BASE_URL}/instructor`,
  CREATE_INSTRUCTOR: `${API_BASE_URL}/register`,
  UPDATE_PAYMENT_PROCESSOR: `${API_BASE_URL}/instructor/payment-processor`,
  UPDATE_PERMISSIONS: `${API_BASE_URL}/instructor/permissions`,
};
export const ADMIN = {
  ADMIN: `${API_BASE_URL}/admin/`,
  UPDATE_ALLOWED_SECTIONS: `${API_BASE_URL}/admin/set-allowed-sections`,
};

export const EXERCISE_EQUIPMENTS = {
  EXERCISE_EQUIPMENT: `${API_BASE_URL}/exercise-equipment`,
};

export const SECTIONS = {
  SECTIONS: `${API_BASE_URL}/video-section`,
};

export const EXERCISE_CATEGORIES = {
  EXERCISE_CATEGORIES: `${API_BASE_URL}/video-category`,
};

export const EXERCISE_CLASSES = {
  EXERCISE_CLASSES: `${API_BASE_URL}/video-classes`,
};

export const MUSCLE = {
  MUSCLE_CATEGORY: `${API_BASE_URL}/muscle/category`,
  MUSCLE: `${API_BASE_URL}/muscle/muscle`,
};
export const TECHNIQUE = {
  TECHNIQUE: `${API_BASE_URL}/technique`,
  GET_TECHNIQUES: `${API_BASE_URL}/technique/all`,
  INSTRUCTOR: `${API_BASE_URL}/technique/instructor/all`,
};

export const STRIPE = {
  ACCOUNT_UPDATE: `${API_BASE_URL}/payment/updatestripe`,
};
export const ASSESSMENT = {
  ASSESSMENT_QUESTION: `${API_BASE_URL}/assesment-question`,
  ASSESSMENT_RESPONSE: `${API_BASE_URL}/assessment-response`,
};
export const CERTIFICATIONS_RENEWAL = {
  QUESTION: `${API_BASE_URL}/certification-renewal-question`,
  RESPONSE: `${API_BASE_URL}/certification-renewal-response`,
};

export const EXERCISES = {
  EXERCISES: `${API_BASE_URL}/exercise-video`,
};
export const CERTIFICATION_CATEGORY = {
  CERTIFICATION_CATEGORY: `${API_BASE_URL}/certification-category`,
};

export const ASSESSMENT_CATEGORIES = {
  ASSESSMENT_CATEGORIES: `${API_BASE_URL}/assessment-categories`,
};

export const S3_URL = {
  PRESIGNED_URL: `${API_BASE_URL}/generate-presigned-url`,
};

export const CERTIFICATE = {
  CREATE_CERTIFICATE: `${API_BASE_URL}/certificate`,
};
export const FILES_DIRECTORY = {
  FILES: `${API_BASE_URL}/files`,
};

export const DRAFT_EXERCISES = {
  DRAFT_EXERCISES: `${API_BASE_URL}/exercise-video-draft`,
};

// GIFT-VOUCHER-PRODUCT
export const GIFT_VOUCHER_PRODUCT = {
  GIFT_VOUCHER_PRODUCT: `${API_BASE_URL}/gift-voucher-product/product`,
};
// GIFT-VOUCHER-ORDERS
export const GIFT_VOUCHER_ORDERS = {
  GIFT_VOUCHER_ORDERS: `${API_BASE_URL}/gift-voucher`,
};

// MARKETING-ASSETS
export const MARKETING_ASSETS = {
  MARKETING_ASSETS: `${API_BASE_URL}/marketing-asset`,
};

export const CERTIFICATE_RESPONSE = {
  UPLOAD: `${API_BASE_URL}/upload-certificates`,
};

export const ENDORSEMENT = `${API_BASE_URL}/endorsement`;

export const TENCENT = {
  GENERATE_UPLOAD_SIGNATURE: `${API_BASE_URL}/tencent/signature`,
  GENERATE_PSIGN: `${API_BASE_URL}/tencent/psign`,
  GET_TENCENT_VIDEO_DATA: `${API_BASE_URL}/tencent/video-data`,
};

export const USER_PAGES = {
  ADD: `${API_BASE_URL}/user-pages`,
  EDIT: `${API_BASE_URL}/user-pages`,
  DELETE: `${API_BASE_URL}/user-pages`,
  GET_ALL: `${API_BASE_URL}/user-pages`,
};

export const BLOG_CATEGORIES = {
  BLOG_CATEGORIES: `${API_BASE_URL}/blog-category`,
};

export const BLOG = `${API_BASE_URL}/blog`;

export const CREDITLIST = {
  GET_ALL: `${API_BASE_URL}/credit-list/all`,
  ADD_TO_WORKSHOP: `${API_BASE_URL}/credit-list/transfer-to-workshop`,
  UPDATE_NOTE: `${API_BASE_URL}/credit-list`,
};

export const EMAIL_ATTACHMENTS = {
  ADD: `${API_BASE_URL}/email-attachments`,
  EDIT: `${API_BASE_URL}/email-attachments`,
  DELETE: `${API_BASE_URL}/email-attachments`,
  GET_ALL: `${API_BASE_URL}/email-attachments`,
};

// export const RECEIPT = {
//   CREATE_RECEIPT: `${API_BASE_URL}/workshop-attendees/download-receipt`,
// };

export const FLAG_LIST = {
  GET: `${API_BASE_URL}/flag-list`,
  GET_BLOCKED_EMAILS: `${API_BASE_URL}/flag-list/blocked-emails`,
  REVIEW_BLOCKED_EMAIL: `${API_BASE_URL}/flag-list/review-blocked-emails`,
};

export const SENT_EMAIL_RECORDS = {
  GET: `${API_BASE_URL}/email-records`,
};

export const DEALS = {
  GET: `${API_BASE_URL}/deals`,
  GET_ALL: `${API_BASE_URL}/deals/all`,
  ADD: `${API_BASE_URL}/deals`,
  DELETE: `${API_BASE_URL}/deals`,
  UPDATE_STATUS: `${API_BASE_URL}/deals/change-status`,
};
