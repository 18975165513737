import { RegionOption, RegionDetail } from '../types/common';

export const REGIONS = [
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035Z' },
    countryCode: 'MO',
    name: 'Macao',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035S' },
    countryCode: 'CN',
    name: 'Macao',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050358' },
    countryCode: 'KE',
    name: 'Kajiado',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035f' },
    countryCode: 'KE',
    name: 'Kisumu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050368' },
    countryCode: 'KE',
    name: 'Meru',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036c' },
    countryCode: 'KE',
    name: 'Nairobi City',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050375' },
    countryCode: 'KE',
    name: 'Taita/Taveta',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050370' },
    countryCode: 'KE',
    name: 'Nyamira',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037a' },
    countryCode: 'KE',
    name: 'Uasin Gishu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036e' },
    countryCode: 'KE',
    name: 'Nandi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050360' },
    countryCode: 'KE',
    name: 'Kitui',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050365' },
    countryCode: 'KE',
    name: 'Makueni',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036f' },
    countryCode: 'KE',
    name: 'Narok',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050374' },
    countryCode: 'KE',
    name: 'Siaya',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035b' },
    countryCode: 'KE',
    name: 'Kiambu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050362' },
    countryCode: 'KE',
    name: 'Laikipia',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050363' },
    countryCode: 'KE',
    name: 'Lamu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050376' },
    countryCode: 'KE',
    name: 'Tana River',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050371' },
    countryCode: 'KE',
    name: 'Nyandarua',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035a' },
    countryCode: 'KE',
    name: 'Kericho',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050378' },
    countryCode: 'KE',
    name: 'Trans Nzoia',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050379' },
    countryCode: 'KE',
    name: 'Turkana',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050364' },
    countryCode: 'KE',
    name: 'Machakos',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036b' },
    countryCode: 'KE',
    name: "Murang'a",
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036d' },
    countryCode: 'KE',
    name: 'Nakuru',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037b' },
    countryCode: 'KE',
    name: 'Vihiga',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037c' },
    countryCode: 'KE',
    name: 'Wajir',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037d' },
    countryCode: 'KE',
    name: 'West Pokot',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035c' },
    countryCode: 'KE',
    name: 'Kilifi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050366' },
    countryCode: 'KE',
    name: 'Mandera',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050359' },
    countryCode: 'KE',
    name: 'Kakamega',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050361' },
    countryCode: 'KE',
    name: 'Kwale',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05036a' },
    countryCode: 'KE',
    name: 'Mombasa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050369' },
    countryCode: 'KE',
    name: 'Migori',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035e' },
    countryCode: 'KE',
    name: 'Kisii',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05035d' },
    countryCode: 'KE',
    name: 'Kirinyaga',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050373' },
    countryCode: 'KE',
    name: 'Samburu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050367' },
    countryCode: 'KE',
    name: 'Marsabit',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050372' },
    countryCode: 'KE',
    name: 'Nyeri',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050377' },
    countryCode: 'KE',
    name: 'Tharaka-Nithi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050387' },
    countryCode: 'KH',
    name: 'Banteay Mean Chey [Bântéay Méanchey]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050388' },
    countryCode: 'KH',
    name: 'Kracheh [Krâchéh]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038c' },
    countryCode: 'KH',
    name: 'Prey Veaeng [Prey Vêng]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038d' },
    countryCode: 'KH',
    name: 'Pousaat [Pouthisat]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038f' },
    countryCode: 'KH',
    name: 'Siem Reab [Siemréab]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038a' },
    countryCode: 'KH',
    name: 'Phnom Penh [Phnum Pénh]',
    type: 'Autonomous municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050386' },
    countryCode: 'KG',
    name: 'Ysyk-Köl',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050381' },
    countryCode: 'KG',
    name: 'Osh',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038e' },
    countryCode: 'KH',
    name: 'Rotanak Kiri [Rôtânôkiri]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050384' },
    countryCode: 'KG',
    name: 'Osh',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05038b' },
    countryCode: 'KH',
    name: 'Preah Vihear [Preah Vihéar]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050383' },
    countryCode: 'KG',
    name: 'Naryn',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037e' },
    countryCode: 'KG',
    name: 'Batken',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050389' },
    countryCode: 'KH',
    name: 'Mondol Kiri [Môndól Kiri]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050380' },
    countryCode: 'KG',
    name: 'Bishkek',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05037f' },
    countryCode: 'KG',
    name: 'Chüy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050382' },
    countryCode: 'KG',
    name: 'Jalal-Abad',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050385' },
    countryCode: 'KG',
    name: 'Talas',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050391' },
    countryCode: 'KH',
    name: 'Stueng Traeng [Stoeng Trêng]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a5' },
    countryCode: 'KM',
    name: 'Mohéli',
    type: 'Island',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a2' },
    countryCode: 'KI',
    name: 'Phoenix Islands',
    type: 'Group of islands',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b6' },
    countryCode: 'KP',
    name: 'Phyeonganpukto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a7' },
    countryCode: 'KN',
    name: 'Saint Anne Sandy Point',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ba' },
    countryCode: 'KP',
    name: 'Kangweonto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b3' },
    countryCode: 'KN',
    name: 'Trinity Palmetto Point',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b9' },
    countryCode: 'KP',
    name: 'Hwanghaipukto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c3' },
    countryCode: 'KR',
    name: "Gwangju Gwang'yeogsi [Kwangju-Kwangyokshi]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c5' },
    countryCode: 'KR',
    name: "Ulsan Gwang'yeogsi [Ulsan-Kwangyokshi]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d4' },
    countryCode: 'KW',
    name: 'Al ‘Asimah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503cd' },
    countryCode: 'KR',
    name: 'Gyeongsangnamdo [Kyongsangnam-do]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050401' },
    countryCode: 'LC',
    name: 'Anse la Raye',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503da' },
    countryCode: 'KZ',
    name: 'Astana',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050413' },
    countryCode: 'LI',
    name: 'Triesen',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503dd' },
    countryCode: 'KZ',
    name: 'Qaraghandy oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042b' },
    countryCode: 'LK',
    name: 'Trincomalee',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040d' },
    countryCode: 'LI',
    name: 'Gamprin',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042d' },
    countryCode: 'LK',
    name: 'Kurunegala',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050436' },
    countryCode: 'LK',
    name: 'Ratnapura',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045c' },
    countryCode: 'LT',
    name: 'Jurbarkas',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050461' },
    countryCode: 'LT',
    name: 'Kazlu Rudos',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050393' },
    countryCode: 'KH',
    name: 'Svaay Rieng [Svay Rieng]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050396' },
    countryCode: 'KH',
    name: 'Krong Kaeb [Krong Kêb]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039a' },
    countryCode: 'KH',
    name: 'Kampong Chhnang [Kâmpóng Chhnang]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c2' },
    countryCode: 'KR',
    name: "Incheon Gwang'yeogsi [Incheon]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503cc' },
    countryCode: 'KR',
    name: 'Gyeongsangbugdo [Kyongsangbuk-do]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d1' },
    countryCode: 'KW',
    name: 'Al Farwaniyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e4' },
    countryCode: 'KZ',
    name: 'Ongtüstik Qazaqstan oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e5' },
    countryCode: 'KZ',
    name: 'Batys Qazaqstan oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ea' },
    countryCode: 'LA',
    name: 'Champasak [Champassak]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ed' },
    countryCode: 'LA',
    name: 'Louang Namtha',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f8' },
    countryCode: 'LA',
    name: 'Xaisômboun',
    type: 'province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503fb' },
    countryCode: 'LB',
    name: 'Beyrouth',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050403' },
    countryCode: 'LC',
    name: 'Choiseul',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050405' },
    countryCode: 'LC',
    name: 'Gros Islet',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050415' },
    countryCode: 'LI',
    name: 'Vaduz',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050425' },
    countryCode: 'LK',
    name: 'Mannar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050428' },
    countryCode: 'LK',
    name: 'Næ?genahira pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050434' },
    countryCode: 'LK',
    name: 'Monaragala',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043a' },
    countryCode: 'LR',
    name: 'Grand Cape Mount',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044c' },
    countryCode: 'LS',
    name: "Mohale's Hoek",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044d' },
    countryCode: 'LS',
    name: 'Quthing',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a6' },
    countryCode: 'KN',
    name: 'Christ Church Nichola Town',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b1' },
    countryCode: 'KN',
    name: 'Saint Thomas Lowland',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503bb' },
    countryCode: 'KP',
    name: 'Hamkyeongnamto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c4' },
    countryCode: 'KR',
    name: "Daejeon Gwang'yeogsi [Taejon-Kwangyokshi]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d9' },
    countryCode: 'KZ',
    name: 'Almaty oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503de' },
    countryCode: 'KZ',
    name: 'Qostanay oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e3' },
    countryCode: 'KZ',
    name: 'Shyghys Qazaqstan oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e7' },
    countryCode: 'LA',
    name: 'Attapu [Attopeu]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e8' },
    countryCode: 'LA',
    name: 'Bokèo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ec' },
    countryCode: 'LA',
    name: 'Khammouan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f0' },
    countryCode: 'LA',
    name: 'Phôngsali [Phong Saly]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ff' },
    countryCode: 'LB',
    name: 'Mont-Liban',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050407' },
    countryCode: 'LC',
    name: 'Micoud',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040b' },
    countryCode: 'LI',
    name: 'Balzers',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040f' },
    countryCode: 'LI',
    name: 'Planken',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050422' },
    countryCode: 'LK',
    name: 'Uturu pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050439' },
    countryCode: 'LR',
    name: 'Bomi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043e' },
    countryCode: 'LR',
    name: 'Gbarpolu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050457' },
    countryCode: 'LT',
    name: 'Druskininkai',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045d' },
    countryCode: 'LT',
    name: 'Kaišiadorys',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050463' },
    countryCode: 'LT',
    name: 'Kelme',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050467' },
    countryCode: 'LT',
    name: 'Kupiškis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039b' },
    countryCode: 'KH',
    name: 'Kampong Spueu [Kâmpóng Spœ]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a8' },
    countryCode: 'KN',
    name: 'Saint George Basseterre',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039e' },
    countryCode: 'KH',
    name: 'Kandaal [Kândal]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c0' },
    countryCode: 'KR',
    name: "Busan Gwang'yeogsi [Pusan-Kwangyokshi]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c7' },
    countryCode: 'KR',
    name: "Gang'weondo [Kang-won-do]",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f4' },
    countryCode: 'LA',
    name: 'Vientiane',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503fc' },
    countryCode: 'LB',
    name: 'Baalbek-Hermel',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050404' },
    countryCode: 'LC',
    name: 'Dennery',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050410' },
    countryCode: 'LI',
    name: 'Ruggell',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050424' },
    countryCode: 'LK',
    name: 'Kilinochchi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050432' },
    countryCode: 'LK',
    name: 'Uva pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050458' },
    countryCode: 'LT',
    name: 'Elektrénai',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045a' },
    countryCode: 'LT',
    name: 'Jonava',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050462' },
    countryCode: 'LT',
    name: 'Kedainiai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ae' },
    countryCode: 'KN',
    name: 'Saint Paul Capisterre',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b0' },
    countryCode: 'KN',
    name: 'Saint Peter Basseterre',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b7' },
    countryCode: 'KP',
    name: 'Jakangto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503db' },
    countryCode: 'KZ',
    name: 'Atyrau oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e6' },
    countryCode: 'KZ',
    name: 'Zhambyl oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f1' },
    countryCode: 'LA',
    name: 'Salavan [Saravane]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050412' },
    countryCode: 'LI',
    name: 'Schellenberg',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050417' },
    countryCode: 'LK',
    name: 'Colombo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050418' },
    countryCode: 'LK',
    name: 'Gampaha',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043d' },
    countryCode: 'LR',
    name: 'Grand Kru',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050444' },
    countryCode: 'LR',
    name: 'River Gee',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050460' },
    countryCode: 'LT',
    name: 'Kaunas',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503fd' },
    countryCode: 'LB',
    name: 'El Béqaa',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050397' },
    countryCode: 'KH',
    name: 'Krong Pailin [Krong Pailin]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040a' },
    countryCode: 'LC',
    name: 'Canaries',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042f' },
    countryCode: 'LK',
    name: 'Uturumæ?da pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050438' },
    countryCode: 'LR',
    name: 'Bong',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050442' },
    countryCode: 'LR',
    name: 'Maryland',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044e' },
    countryCode: 'LS',
    name: "Qacha's Nek",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050459' },
    countryCode: 'LT',
    name: 'Ignalina',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045e' },
    countryCode: 'LT',
    name: 'Kalvarijos',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050466' },
    countryCode: 'LT',
    name: 'Kretinga',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b8' },
    countryCode: 'KP',
    name: 'Hwanghainamto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503bd' },
    countryCode: 'KP',
    name: 'Yanggang-do',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503be' },
    countryCode: 'KP',
    name: 'Nason',
    type: 'Special City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d7' },
    countryCode: 'KZ',
    name: 'Aqtöbe oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e9' },
    countryCode: 'LA',
    name: 'Bolikhamxai [Borikhane]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f5' },
    countryCode: 'LA',
    name: 'Xaignabouli [Sayaboury]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f7' },
    countryCode: 'LA',
    name: 'Xiangkhouang [Xieng Khouang]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503fe' },
    countryCode: 'LB',
    name: 'Liban-Sud',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050411' },
    countryCode: 'LI',
    name: 'Schaan',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050414' },
    countryCode: 'LI',
    name: 'Triesenberg',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050456' },
    countryCode: 'LT',
    name: 'Biržai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045b' },
    countryCode: 'LT',
    name: 'Joniškis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050392' },
    countryCode: 'KH',
    name: 'Baat Dambang [Batdâmbâng]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039f' },
    countryCode: 'KH',
    name: 'Kaoh Kong [Kaôh Kong]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503df' },
    countryCode: 'KZ',
    name: 'Qyzylorda oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f9' },
    countryCode: 'LB',
    name: 'Aakkâr',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050419' },
    countryCode: 'LK',
    name: 'Kalutara',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041d' },
    countryCode: 'LK',
    name: 'Nuwara Eliya',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041f' },
    countryCode: 'LK',
    name: 'Galle',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050446' },
    countryCode: 'LR',
    name: 'Sinoe',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044f' },
    countryCode: 'LS',
    name: 'Mokhotlong',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05045f' },
    countryCode: 'LT',
    name: 'Kauno miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050468' },
    countryCode: 'LT',
    name: 'Lazdijai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c6' },
    countryCode: 'KR',
    name: 'Gyeonggido [Kyonggi-do]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503cf' },
    countryCode: 'KR',
    name: 'Sejong',
    type: 'Special self-governing city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ee' },
    countryCode: 'LA',
    name: 'Louangphabang [Louang Prabang)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050402' },
    countryCode: 'LC',
    name: 'Castries',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040e' },
    countryCode: 'LI',
    name: 'Mauren',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050420' },
    countryCode: 'LK',
    name: 'Matara',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050423' },
    countryCode: 'LK',
    name: 'Jaffna',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050429' },
    countryCode: 'LK',
    name: 'Batticaloa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042e' },
    countryCode: 'LK',
    name: 'Puttalam',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050430' },
    countryCode: 'LK',
    name: 'Anuradhapura',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050433' },
    countryCode: 'LK',
    name: 'Badulla',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050445' },
    countryCode: 'LR',
    name: 'River Cess',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050455' },
    countryCode: 'LT',
    name: 'Birštono',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050390' },
    countryCode: 'KH',
    name: 'Krong Preah Sihanouk [Krong Preah Sihanouk]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050395' },
    countryCode: 'KH',
    name: 'Otdar Mean Chey [Otdâr Méanchey]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050399' },
    countryCode: 'KH',
    name: 'Kampong Chaam [Kâmpóng Cham]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039d' },
    countryCode: 'KH',
    name: 'Kampot [Kâmpôt]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b2' },
    countryCode: 'KN',
    name: 'Saint Thomas Middle Island',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503bf' },
    countryCode: 'KR',
    name: 'Seoul-teukbyeolsi [Seoul]',
    type: 'Special city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e0' },
    countryCode: 'KZ',
    name: 'Mangghystau oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041b' },
    countryCode: 'LK',
    name: 'Kandy',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043b' },
    countryCode: 'LR',
    name: 'Grand Bassa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050441' },
    countryCode: 'LR',
    name: 'Montserrado',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050447' },
    countryCode: 'LS',
    name: 'Maseru',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050465' },
    countryCode: 'LT',
    name: 'Klaipeda',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05039c' },
    countryCode: 'KH',
    name: 'Kampong Thum [Kâmpóng Thum]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503af' },
    countryCode: 'KN',
    name: 'Saint Paul Charlestown',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b4' },
    countryCode: 'KP',
    name: 'Phyeongyang',
    type: 'Capital City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503b5' },
    countryCode: 'KP',
    name: 'Phyeongannamto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503bc' },
    countryCode: 'KP',
    name: 'Hamkyeongpukto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ce' },
    countryCode: 'KR',
    name: 'Jeju-teukbyeoljachido [Jeju]',
    type: 'Special self-governing province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d2' },
    countryCode: 'KW',
    name: '?awalli',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d6' },
    countryCode: 'KZ',
    name: 'Aqmola oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e1' },
    countryCode: 'KZ',
    name: 'Pavlodar oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f3' },
    countryCode: 'LA',
    name: 'Vientiane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041a' },
    countryCode: 'LK',
    name: 'Madhyama pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050427' },
    countryCode: 'LK',
    name: 'Mullaittivu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050431' },
    countryCode: 'LK',
    name: 'Polonnaruwa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050435' },
    countryCode: 'LK',
    name: 'Sabaragamuva pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050443' },
    countryCode: 'LR',
    name: 'Nimba',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050449' },
    countryCode: 'LS',
    name: 'Leribe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a0' },
    countryCode: 'KI',
    name: 'Gilbert Islands',
    type: 'Group of islands',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a1' },
    countryCode: 'KI',
    name: 'Line Islands',
    type: 'Group of islands',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ab' },
    countryCode: 'KN',
    name: 'Saint John Capisterre',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d5' },
    countryCode: 'KW',
    name: 'Mubarak al Kabir',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041c' },
    countryCode: 'LK',
    name: 'Matale',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05041e' },
    countryCode: 'LK',
    name: 'Daku?u pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043f' },
    countryCode: 'LR',
    name: 'Lofa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050451' },
    countryCode: 'LT',
    name: 'Akmene',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050464' },
    countryCode: 'LT',
    name: 'Klaipedos miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a9' },
    countryCode: 'KN',
    name: 'Saint George Gingerland',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050394' },
    countryCode: 'KH',
    name: 'Taakaev [Takêv]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ac' },
    countryCode: 'KN',
    name: 'Saint John Figtree',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ad' },
    countryCode: 'KN',
    name: 'Saint Mary Cayon',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c9' },
    countryCode: 'KR',
    name: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d0' },
    countryCode: 'KW',
    name: 'Al A?madi',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d8' },
    countryCode: 'KZ',
    name: 'Almaty',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503dc' },
    countryCode: 'KZ',
    name: 'Bayqonyr',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503eb' },
    countryCode: 'LA',
    name: 'Houaphan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ef' },
    countryCode: 'LA',
    name: 'Oudômxai [Oudomsai]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503fa' },
    countryCode: 'LB',
    name: 'Liban-Nord',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050400' },
    countryCode: 'LB',
    name: 'Nabatîyé',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050406' },
    countryCode: 'LC',
    name: 'Laborie',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042c' },
    countryCode: 'LK',
    name: 'Vayamba pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050437' },
    countryCode: 'LK',
    name: 'Kegalla',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050440' },
    countryCode: 'LR',
    name: 'Margibi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044a' },
    countryCode: 'LS',
    name: 'Berea',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050450' },
    countryCode: 'LS',
    name: 'Thaba-Tseka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050454' },
    countryCode: 'LT',
    name: 'Anykšciai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a3' },
    countryCode: 'KM',
    name: 'Anjouan',
    type: 'Island',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503a4' },
    countryCode: 'KM',
    name: 'Grande Comore',
    type: 'Island',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503aa' },
    countryCode: 'KN',
    name: 'Saint James Windward',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c8' },
    countryCode: 'KR',
    name: "Chungcheongbugdo [Ch'ungch'ongbuk-do]",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503ca' },
    countryCode: 'KR',
    name: 'Jeonrabugdo [Chollabuk-do]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503cb' },
    countryCode: 'KR',
    name: 'Jeonranamdo [Chollanam-do]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503e2' },
    countryCode: 'KZ',
    name: 'Soltüstik Qazaqstan oblysy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f2' },
    countryCode: 'LA',
    name: 'Savannakhét',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503f6' },
    countryCode: 'LA',
    name: 'Xékong [Sékong]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05040c' },
    countryCode: 'LI',
    name: 'Eschen',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050416' },
    countryCode: 'LK',
    name: 'Basnahira pa?ata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050426' },
    countryCode: 'LK',
    name: 'Vavuniya',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05042a' },
    countryCode: 'LK',
    name: 'Ampara',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05044b' },
    countryCode: 'LS',
    name: 'Mafeteng',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050398' },
    countryCode: 'KH',
    name: 'Tbong Khmum',
    type: 'province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503c1' },
    countryCode: 'KR',
    name: "Daegu Gwang'yeogsi [Taegu-Kwangyokshi]",
    type: 'Metropolitan city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0503d3' },
    countryCode: 'KW',
    name: 'Al Jahra’',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050408' },
    countryCode: 'LC',
    name: 'Soufrière',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050409' },
    countryCode: 'LC',
    name: 'Vieux Fort',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050421' },
    countryCode: 'LK',
    name: 'Hambantota',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05043c' },
    countryCode: 'LR',
    name: 'Grand Gedeh',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050448' },
    countryCode: 'LS',
    name: 'Butha-Buthe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050452' },
    countryCode: 'LT',
    name: 'Alytaus miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050453' },
    countryCode: 'LT',
    name: 'Alytus',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046c' },
    countryCode: 'LT',
    name: 'Neringa',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046e' },
    countryCode: 'LT',
    name: 'Pakruojis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050479' },
    countryCode: 'LT',
    name: 'Šakiai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050473' },
    countryCode: 'LT',
    name: 'Plunge',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047b' },
    countryCode: 'LT',
    name: 'Šiauliu miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050470' },
    countryCode: 'LT',
    name: 'Panevežio miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046f' },
    countryCode: 'LT',
    name: 'Palangos miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050484' },
    countryCode: 'LT',
    name: 'Trakai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050486' },
    countryCode: 'LT',
    name: 'Utena',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050474' },
    countryCode: 'LT',
    name: 'Prienai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050481' },
    countryCode: 'LT',
    name: 'Švencionys',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050469' },
    countryCode: 'LT',
    name: 'Marijampole',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050476' },
    countryCode: 'LT',
    name: 'Raseiniai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046b' },
    countryCode: 'LT',
    name: 'Moletai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047a' },
    countryCode: 'LT',
    name: 'Šalcininkai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047f' },
    countryCode: 'LT',
    name: 'Širvintos',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047e' },
    countryCode: 'LT',
    name: 'Šilute',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046d' },
    countryCode: 'LT',
    name: 'Pagégiai',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05046a' },
    countryCode: 'LT',
    name: 'Mažeikiai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050471' },
    countryCode: 'LT',
    name: 'Panevežys',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050472' },
    countryCode: 'LT',
    name: 'Pasvalys',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050475' },
    countryCode: 'LT',
    name: 'Radviliškis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050480' },
    countryCode: 'LT',
    name: 'Skuodas',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047c' },
    countryCode: 'LT',
    name: 'Šiauliai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050478' },
    countryCode: 'LT',
    name: 'Rokiškis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050485' },
    countryCode: 'LT',
    name: 'Ukmerge',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050483' },
    countryCode: 'LT',
    name: 'Telšiai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050487' },
    countryCode: 'LT',
    name: 'Varena',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050482' },
    countryCode: 'LT',
    name: 'Taurage',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05047d' },
    countryCode: 'LT',
    name: 'Šilale',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050477' },
    countryCode: 'LT',
    name: 'Rietavo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050488' },
    countryCode: 'LT',
    name: 'Vilkaviškis',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050490' },
    countryCode: 'LT',
    name: 'Marijampoles apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048a' },
    countryCode: 'LT',
    name: 'Vilnius',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048b' },
    countryCode: 'LT',
    name: 'Visaginas',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050494' },
    countryCode: 'LT',
    name: 'Telšiu Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b1' },
    countryCode: 'LV',
    name: 'Balvu novads (Balvi)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b4' },
    countryCode: 'LV',
    name: 'Brocenu novads (Broceni)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504bd' },
    countryCode: 'LV',
    name: 'Dundagas novads (Dundaga)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c3' },
    countryCode: 'LV',
    name: 'Gulbenes novads (Gulbene)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504cc' },
    countryCode: 'LV',
    name: 'Jekabpils novads (Jekabpils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ce' },
    countryCode: 'LV',
    name: 'Karsavas novads (Karsava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e0' },
    countryCode: 'LV',
    name: 'Marupes novads (Marupe)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ec' },
    countryCode: 'LV',
    name: 'Priekules novads (Priekule)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504fa' },
    countryCode: 'LV',
    name: 'Saldus novads (Saldus)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504fc' },
    countryCode: 'LV',
    name: 'Sejas novads (Seja)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504fe' },
    countryCode: 'LV',
    name: 'Skriveru novads (Skriveri)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050505' },
    countryCode: 'LV',
    name: 'Tukuma novads (Tukums)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051b' },
    countryCode: 'LY',
    name: 'Al Butnan',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050538' },
    countryCode: 'MA',
    name: 'Chaouia-Ouardigha',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050550' },
    countryCode: 'MA',
    name: 'Fahs-Beni Makada',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050555' },
    countryCode: 'MA',
    name: 'Al Haouz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055a' },
    countryCode: 'MA',
    name: 'Jrada',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050563' },
    countryCode: 'MA',
    name: 'Meknès*',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050565' },
    countryCode: 'MA',
    name: 'Marrakech-Menara',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056a' },
    countryCode: 'MA',
    name: 'Ouarzazate',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056c' },
    countryCode: 'MA',
    name: 'Oujda-Angad',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056e' },
    countryCode: 'MA',
    name: 'Safi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057c' },
    countryCode: 'MA',
    name: 'Tanger-Assilah',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050587' },
    countryCode: 'MC',
    name: 'Moneghetti',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050594' },
    countryCode: 'MD',
    name: 'Basarabeasca',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050597' },
    countryCode: 'MD',
    name: 'Cimislia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b5' },
    countryCode: 'ME',
    name: 'Andrijevica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505bd' },
    countryCode: 'ME',
    name: 'Kolašin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049a' },
    countryCode: 'LU',
    name: 'Echternach',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a8' },
    countryCode: 'LV',
    name: 'Alsungas novads (Alsunga)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504aa' },
    countryCode: 'LV',
    name: 'Amatas novads (Amata)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b5' },
    countryCode: 'LV',
    name: 'Burtnieku novads (Burtnieki)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c2' },
    countryCode: 'LV',
    name: 'Grobinas novads (Grobina)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c4' },
    countryCode: 'LV',
    name: 'Iecavas novads (Iecava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e8' },
    countryCode: 'LV',
    name: 'Pargaujas novads (Pargauja)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050e' },
    countryCode: 'LV',
    name: 'Vilakas novads (Vilaka)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050530' },
    countryCode: 'MA',
    name: 'Tanger-Tétouan',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053a' },
    countryCode: 'MA',
    name: 'Marrakech-Tensift-Al Haouz',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053d' },
    countryCode: 'MA',
    name: 'Guelmim-Es Semara',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050541' },
    countryCode: 'MA',
    name: 'Aousserd',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050554' },
    countryCode: 'MA',
    name: 'El Hajeb',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050571' },
    countryCode: 'MA',
    name: 'Settat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050595' },
    countryCode: 'MD',
    name: 'Cahul',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505bf' },
    countryCode: 'ME',
    name: 'Mojkovac',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c5' },
    countryCode: 'ME',
    name: 'Rožaje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050492' },
    countryCode: 'LT',
    name: 'Šiauliu Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a3' },
    countryCode: 'LV',
    name: 'Aglonas novads (Aglona)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c6' },
    countryCode: 'LV',
    name: 'Ilukstes novads (Ilukste)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504dc' },
    countryCode: 'LV',
    name: 'Ludzas novads (Ludza)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504df' },
    countryCode: 'LV',
    name: 'Malpils novads (Malpils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050500' },
    countryCode: 'LV',
    name: 'Smiltenes novads (Smiltene)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053e' },
    countryCode: 'MA',
    name: 'Laâyoune-Boujdour-Sakia el Hamra',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054a' },
    countryCode: 'MA',
    name: 'Chefchaouene',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505df' },
    countryCode: 'MH',
    name: 'Likiep',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048e' },
    countryCode: 'LT',
    name: 'Klaipedos apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048d' },
    countryCode: 'LT',
    name: 'Alytaus Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b8' },
    countryCode: 'LV',
    name: 'Cesu novads (Cesis)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050b' },
    countryCode: 'LV',
    name: 'Vecumnieku novads (Vecumnieki)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050518' },
    countryCode: 'LV',
    name: 'Ventspils',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052d' },
    countryCode: 'LY',
    name: 'Wadi al Hayat',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055f' },
    countryCode: 'MA',
    name: 'Khouribga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050568' },
    countryCode: 'MA',
    name: 'Nador',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050596' },
    countryCode: 'MD',
    name: 'Calarasi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059c' },
    countryCode: 'MD',
    name: 'Donduseni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059f' },
    countryCode: 'MD',
    name: 'Edinet',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505dc' },
    countryCode: 'MH',
    name: 'Ralik chain',
    type: 'Chains (of islands)',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050496' },
    countryCode: 'LT',
    name: 'Vilniaus Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050499' },
    countryCode: 'LU',
    name: 'Diekirch',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049e' },
    countryCode: 'LU',
    name: 'Mersch',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a2' },
    countryCode: 'LU',
    name: 'Wiltz',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504bf' },
    countryCode: 'LV',
    name: 'Engures novads (Engure)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c1' },
    countryCode: 'LV',
    name: 'Garkalnes novads (Garkalne)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d3' },
    countryCode: 'LV',
    name: 'Krustpils novads (Krustpils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504da' },
    countryCode: 'LV',
    name: 'Livanu novads (Livani)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e3' },
    countryCode: 'LV',
    name: 'Neretas novads (Nereta)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050507' },
    countryCode: 'LV',
    name: 'Valkas novads (Valka)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050513' },
    countryCode: 'LV',
    name: 'Jekabpils',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050517' },
    countryCode: 'LV',
    name: 'Riga',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050525' },
    countryCode: 'LY',
    name: 'Al Marj',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050526' },
    countryCode: 'LY',
    name: 'Murzuq',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052f' },
    countryCode: 'LY',
    name: 'Az Zawiyah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050531' },
    countryCode: 'MA',
    name: 'Gharb-Chrarda-Beni Hssen',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050544' },
    countryCode: 'MA',
    name: 'Beni Mellal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050553' },
    countryCode: 'MA',
    name: 'Guelmim',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055c' },
    countryCode: 'MA',
    name: 'Kelaat Sraghna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056b' },
    countryCode: 'MA',
    name: 'Oued ed Dahab (EH)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058c' },
    countryCode: 'MC',
    name: 'La Source',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ad' },
    countryCode: 'MD',
    name: 'Sîngerei',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ae' },
    countryCode: 'MD',
    name: 'Stînga Nistrului, unitatea teritoriala din',
    type: 'Territorial unit',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ba' },
    countryCode: 'ME',
    name: 'Cetinje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505bc' },
    countryCode: 'ME',
    name: 'Herceg-Novi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d2' },
    countryCode: 'MH',
    name: 'Ailuk',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ad' },
    countryCode: 'LV',
    name: 'Adažu novads (Adaži)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d6' },
    countryCode: 'LV',
    name: 'Kekavas novads (Kekava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ed' },
    countryCode: 'LV',
    name: 'Priekulu novads (Priekuli)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f7' },
    countryCode: 'LV',
    name: 'Salas novads (Sala)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050a' },
    countryCode: 'LV',
    name: 'Vecpiebalgas novads\r\n\r\n(Vecpiebalga)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050c' },
    countryCode: 'LV',
    name: 'Ventspils novads (Ventspils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050527' },
    countryCode: 'LY',
    name: 'Nalut',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050528' },
    countryCode: 'LY',
    name: 'An Nuqat al Khams',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050536' },
    countryCode: 'MA',
    name: 'Rabat-Salé-Zemmour-Zaer',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053f' },
    countryCode: 'MA',
    name: 'Oued ed Dahab-Lagouira',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050545' },
    countryCode: 'MA',
    name: 'Berkane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050559' },
    countryCode: 'MA',
    name: 'El Jadida',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055d' },
    countryCode: 'MA',
    name: 'Khemisset',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050569' },
    countryCode: 'MA',
    name: 'Nouaceur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050575' },
    countryCode: 'MA',
    name: 'Taourirt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050578' },
    countryCode: 'MA',
    name: 'Tata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057a' },
    countryCode: 'MA',
    name: 'Tétouan*',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057e' },
    countryCode: 'MA',
    name: 'Zagora',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050581' },
    countryCode: 'MC',
    name: 'Fontvieille',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058a' },
    countryCode: 'MC',
    name: 'Port-Hercule',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050598' },
    countryCode: 'MD',
    name: 'Criuleni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059b' },
    countryCode: 'MD',
    name: 'Chisinau',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a8' },
    countryCode: 'MD',
    name: 'Ocniþa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505aa' },
    countryCode: 'MD',
    name: 'Rezina',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505af' },
    countryCode: 'MD',
    name: 'Soroca',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b3' },
    countryCode: 'MD',
    name: 'Telenesti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d4' },
    countryCode: 'MH',
    name: 'Arno',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505da' },
    countryCode: 'MH',
    name: 'Bikini and Kili',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050497' },
    countryCode: 'LU',
    name: 'Capellen',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504bc' },
    countryCode: 'LV',
    name: 'Dobeles novads (Dobele)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d9' },
    countryCode: 'LV',
    name: 'Ligatnes novads (Ligatne)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ef' },
    countryCode: 'LV',
    name: 'Rezeknes novads (Rezekne)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050506' },
    countryCode: 'LV',
    name: 'Vainodes novads (Vainode)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051d' },
    countryCode: 'LY',
    name: 'Ghat',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050522' },
    countryCode: 'LY',
    name: 'Al Kufrah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052a' },
    countryCode: 'LY',
    name: 'Surt',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050533' },
    countryCode: 'MA',
    name: "L'Oriental",
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050543' },
    countryCode: 'MA',
    name: 'Azilal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050546' },
    countryCode: 'MA',
    name: 'Ben Slimane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054f' },
    countryCode: 'MA',
    name: 'Es Smara (EH)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056d' },
    countryCode: 'MA',
    name: 'Rabat',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058d' },
    countryCode: 'MC',
    name: 'Spélugues',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058f' },
    countryCode: 'MC',
    name: 'Vallon de la Rousse',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a0' },
    countryCode: 'MD',
    name: 'Falesti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a2' },
    countryCode: 'MD',
    name: 'Gagauzia, Unitatea teritoriala\r\n\r\nautonoma (UTAG)',
    type: 'Autonomous territory unit',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a5' },
    countryCode: 'MD',
    name: 'Ialoveni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ac' },
    countryCode: 'MD',
    name: 'Soldanesti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b1' },
    countryCode: 'MD',
    name: 'Stefan Voda',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505bb' },
    countryCode: 'ME',
    name: 'Danilovgrad',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c2' },
    countryCode: 'ME',
    name: 'Pljevlja',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ca' },
    countryCode: 'ME',
    name: 'Gusinje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e0' },
    countryCode: 'MH',
    name: 'Majuro',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048c' },
    countryCode: 'LT',
    name: 'Zarasai',
    type: 'District municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049c' },
    countryCode: 'LU',
    name: 'Gréivemaacher',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ae' },
    countryCode: 'LV',
    name: 'Babites novads (Babite)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504be' },
    countryCode: 'LV',
    name: 'Durbes novads (Durbe)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c5' },
    countryCode: 'LV',
    name: 'Ikškiles novads (Ikškile)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ca' },
    countryCode: 'LV',
    name: 'Jaunpils novads (Jaunpils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e9' },
    countryCode: 'LV',
    name: 'Pavilostas novads (Pavilosta)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050508' },
    countryCode: 'LV',
    name: 'Varaklanu novads (Varaklani)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050510' },
    countryCode: 'LV',
    name: 'Zilupes novads (Zilupe)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050514' },
    countryCode: 'LV',
    name: 'Jurmala',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050521' },
    countryCode: 'LY',
    name: 'Al Jufrah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050524' },
    countryCode: 'LY',
    name: 'Misratah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050529' },
    countryCode: 'LY',
    name: 'Sabha',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050535' },
    countryCode: 'MA',
    name: 'Meknès-Tafilalet',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050549' },
    countryCode: 'MA',
    name: 'Casablanca [Dar el Beïda]*',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054d' },
    countryCode: 'MA',
    name: 'Errachidia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054e' },
    countryCode: 'MA',
    name: 'Essaouira',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055e' },
    countryCode: 'MA',
    name: 'Khenifra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050572' },
    countryCode: 'MA',
    name: 'Sidi Kacem',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050580' },
    countryCode: 'MC',
    name: 'La Condamine',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050589' },
    countryCode: 'MC',
    name: 'Moulins',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c0' },
    countryCode: 'ME',
    name: 'Nikšic´',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c7' },
    countryCode: 'ME',
    name: 'Tivat',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505cb' },
    countryCode: 'ME',
    name: 'Petnjica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505cf' },
    countryCode: 'MG',
    name: 'Mahajanga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b7' },
    countryCode: 'LV',
    name: 'Cesvaines novads (Cesvaine)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c9' },
    countryCode: 'LV',
    name: 'Jaunpiebalgas novads\r\n\r\n(Jaunpiebalga)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504cf' },
    countryCode: 'LV',
    name: 'Kocenu novads (Koceni)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d1' },
    countryCode: 'LV',
    name: 'Kraslavas novads (Kraslava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504de' },
    countryCode: 'LV',
    name: 'Mazsalacas novads (Mazsalaca)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e5' },
    countryCode: 'LV',
    name: 'Ogres novads (Ogre)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e7' },
    countryCode: 'LV',
    name: 'Ozolnieku novads (Ozolnieki)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050558' },
    countryCode: 'MA',
    name: 'Inezgane-Ait Melloul',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050583' },
    countryCode: 'MC',
    name: 'Jardin Exotique',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c1' },
    countryCode: 'ME',
    name: 'Plav',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c3' },
    countryCode: 'ME',
    name: 'Plužine',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c6' },
    countryCode: 'ME',
    name: 'Šavnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d5' },
    countryCode: 'MH',
    name: 'Aur',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050495' },
    countryCode: 'LT',
    name: 'Utenos Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a0' },
    countryCode: 'LU',
    name: 'Remich',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a1' },
    countryCode: 'LU',
    name: 'Vianden',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ac' },
    countryCode: 'LV',
    name: 'Auces novads (Auce)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c8' },
    countryCode: 'LV',
    name: 'Jaunjelgavas novads\r\n\r\n(Jaunjelgava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504eb' },
    countryCode: 'LV',
    name: 'Preilu novads (Preili)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f8' },
    countryCode: 'LV',
    name: 'Salacgrivas novads (Salacgriva)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050509' },
    countryCode: 'LV',
    name: 'Varkavas novads (Varkava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050532' },
    countryCode: 'MA',
    name: 'Taza-Al Hoceima-Taounate',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050539' },
    countryCode: 'MA',
    name: 'Doukhala-Abda',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050540' },
    countryCode: 'MA',
    name: 'Agadir-Ida-Outanane',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050552' },
    countryCode: 'MA',
    name: 'Figuig',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050560' },
    countryCode: 'MA',
    name: 'Laâyoune',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050561' },
    countryCode: 'MA',
    name: 'Larache',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050564' },
    countryCode: 'MA',
    name: 'Marrakech-Medina',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050573' },
    countryCode: 'MA',
    name: 'Skhirate-Témara',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050576' },
    countryCode: 'MA',
    name: 'Taounate',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057b' },
    countryCode: 'MA',
    name: 'Tiznit',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050585' },
    countryCode: 'MC',
    name: 'Malbousquet',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050586' },
    countryCode: 'MC',
    name: 'Monte-Carlo',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058b' },
    countryCode: 'MC',
    name: 'Sainte-Dévote',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050599' },
    countryCode: 'MD',
    name: 'Causeni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b4' },
    countryCode: 'MD',
    name: 'Ungheni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b9' },
    countryCode: 'ME',
    name: 'Budva',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505be' },
    countryCode: 'ME',
    name: 'Kotor',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505cd' },
    countryCode: 'MG',
    name: 'Antsiranana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d6' },
    countryCode: 'MH',
    name: 'Ebon',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050491' },
    countryCode: 'LT',
    name: 'Panevežio apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c0' },
    countryCode: 'LV',
    name: 'Erglu novads (Ergli)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504cb' },
    countryCode: 'LV',
    name: 'Jelgavas novads (Jelgava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504cd' },
    countryCode: 'LV',
    name: 'Kandavas novads (Kandava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d4' },
    countryCode: 'LV',
    name: 'Kuldigas novads (Kuldiga)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e1' },
    countryCode: 'LV',
    name: 'Mersraga novads (Mersrags)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e2' },
    countryCode: 'LV',
    name: 'Naukšenu novads (Naukšeni)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050501' },
    countryCode: 'LV',
    name: 'Stopinu novads (Stopini)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052c' },
    countryCode: 'LY',
    name: 'Al Wa?at',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050542' },
    countryCode: 'MA',
    name: 'Assa-Zag',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050548' },
    countryCode: 'MA',
    name: 'Boulemane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050577' },
    countryCode: 'MA',
    name: 'Taroudannt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057d' },
    countryCode: 'MA',
    name: 'Tan-Tan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050584' },
    countryCode: 'MC',
    name: 'Larvotto',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059a' },
    countryCode: 'MD',
    name: 'Cantemir',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a4' },
    countryCode: 'MD',
    name: 'Hîncesti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b7' },
    countryCode: 'ME',
    name: 'Berane',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505db' },
    countryCode: 'MH',
    name: 'Kwajalein',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e1' },
    countryCode: 'MH',
    name: 'Maloelap',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050489' },
    countryCode: 'LT',
    name: 'Vilniaus miestas',
    type: 'City municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049f' },
    countryCode: 'LU',
    name: 'Redange',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a9' },
    countryCode: 'LV',
    name: 'Aluksnes novads (Aluksne)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b0' },
    countryCode: 'LV',
    name: 'Baltinavas novads (Baltinava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b6' },
    countryCode: 'LV',
    name: 'Carnikavas novads (Carnikava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b9' },
    countryCode: 'LV',
    name: 'Ciblas novads (Cibla)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d7' },
    countryCode: 'LV',
    name: 'Lielvardes novads (Lielvarde)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e6' },
    countryCode: 'LV',
    name: 'Olaines novads (Olaine)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051a' },
    countryCode: 'LY',
    name: 'Banghazi',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051c' },
    countryCode: 'LY',
    name: 'Darnah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050551' },
    countryCode: 'MA',
    name: 'Fès-Dar-Dbibegh',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050557' },
    countryCode: 'MA',
    name: 'Ifrane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05055b' },
    countryCode: 'MA',
    name: 'Kénitra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050574' },
    countryCode: 'MA',
    name: 'Sidi Youssef Ben Ali',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d3' },
    countryCode: 'MH',
    name: 'Ailinglapalap',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505dd' },
    countryCode: 'MH',
    name: 'Lae',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505de' },
    countryCode: 'MH',
    name: 'Lib',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a6' },
    countryCode: 'LV',
    name: 'Aknistes novads (Akniste)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504af' },
    countryCode: 'LV',
    name: 'Baldones novads (Baldone)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504bb' },
    countryCode: 'LV',
    name: 'Daugavpils novads (Daugavpils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504c7' },
    countryCode: 'LV',
    name: 'Incukalna novads (Incukalns)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d0' },
    countryCode: 'LV',
    name: 'Kokneses novads (Koknese)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504e4' },
    countryCode: 'LV',
    name: 'Nicas novads (Nica)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f2' },
    countryCode: 'LV',
    name: 'Ropažu novads (Ropaži)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f4' },
    countryCode: 'LV',
    name: 'Rugaju novads (Rugaji)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ff' },
    countryCode: 'LV',
    name: 'Skrundas novads (Skrunda)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050502' },
    countryCode: 'LV',
    name: 'Strencu novads (Strenci)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050511' },
    countryCode: 'LV',
    name: 'Daugavpils',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050512' },
    countryCode: 'LV',
    name: 'Jelgava',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050515' },
    countryCode: 'LV',
    name: 'Liepaja',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052b' },
    countryCode: 'LY',
    name: 'Tarabulus',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050562' },
    countryCode: 'MA',
    name: 'Médiouna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05058e' },
    countryCode: 'MC',
    name: 'Saint-Roman',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050592' },
    countryCode: 'MD',
    name: 'Bender [Tighina]',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a1' },
    countryCode: 'MD',
    name: 'Floresti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c4' },
    countryCode: 'ME',
    name: 'Podgorica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d7' },
    countryCode: 'MH',
    name: 'Enewetak and Ujelang',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049b' },
    countryCode: 'LU',
    name: 'Esch-sur-Alzette',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05049d' },
    countryCode: 'LU',
    name: 'Luxembourg',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a4' },
    countryCode: 'LV',
    name: 'Aizkraukles novads (Aizkraukle)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b3' },
    countryCode: 'LV',
    name: 'Beverinas novads (Beverina)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504db' },
    countryCode: 'LV',
    name: 'Lubanas novads (Lubana)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f3' },
    countryCode: 'LV',
    name: 'Rucavas novads (Rucava)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f9' },
    countryCode: 'LV',
    name: 'Salaspils novads (Salaspils)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050519' },
    countryCode: 'LV',
    name: 'Valmiera',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05052e' },
    countryCode: 'LY',
    name: 'Wadi ash Shati?',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050534' },
    countryCode: 'MA',
    name: 'Fès-Boulemane',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054b' },
    countryCode: 'MA',
    name: 'Chichaoua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050567' },
    countryCode: 'MA',
    name: 'Moulay Yacoub',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050579' },
    countryCode: 'MA',
    name: 'Taza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050582' },
    countryCode: 'MC',
    name: 'La Gare',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050593' },
    countryCode: 'MD',
    name: 'Briceni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059e' },
    countryCode: 'MD',
    name: 'Dubasari',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a3' },
    countryCode: 'MD',
    name: 'Glodeni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a7' },
    countryCode: 'MD',
    name: 'Nisporeni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a9' },
    countryCode: 'MD',
    name: 'Orhei',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ab' },
    countryCode: 'MD',
    name: 'Rîscani',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c9' },
    countryCode: 'ME',
    name: 'Žabljak',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d0' },
    countryCode: 'MG',
    name: 'Antananarivo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d1' },
    countryCode: 'MG',
    name: 'Toliara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050493' },
    countryCode: 'LT',
    name: 'Taurages apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ba' },
    countryCode: 'LV',
    name: 'Dagdas novads (Dagda)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d2' },
    countryCode: 'LV',
    name: 'Krimuldas novads (Krimulda)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ee' },
    countryCode: 'LV',
    name: 'Raunas novads (Rauna)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f0' },
    countryCode: 'LV',
    name: 'Riebinu novads (Riebini)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f6' },
    countryCode: 'LV',
    name: 'Rujienas novads (Rujiena)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050516' },
    countryCode: 'LV',
    name: 'Rezekne',
    type: 'Republican city',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051e' },
    countryCode: 'LY',
    name: 'Al Jabal al Akh?ar',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050523' },
    countryCode: 'LY',
    name: 'Al Marqab',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050537' },
    countryCode: 'MA',
    name: 'Grand Casablanca',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053b' },
    countryCode: 'MA',
    name: 'Tadla-Azilal',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05053c' },
    countryCode: 'MA',
    name: 'Sous-Massa-Draa',
    type: 'Economic Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050547' },
    countryCode: 'MA',
    name: 'Boujdour (EH)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05054c' },
    countryCode: 'MA',
    name: 'Chtouka-Ait Baha',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050566' },
    countryCode: 'MA',
    name: 'Mohammadia',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050570' },
    countryCode: 'MA',
    name: 'Sefrou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05057f' },
    countryCode: 'MC',
    name: 'La Colle',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050588' },
    countryCode: 'MC',
    name: 'Monaco-Ville',
    type: 'Quarter',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050591' },
    countryCode: 'MD',
    name: 'Balti',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b0' },
    countryCode: 'MD',
    name: 'Straseni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b6' },
    countryCode: 'ME',
    name: 'Bar',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b8' },
    countryCode: 'ME',
    name: 'Bijelo Polje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505c8' },
    countryCode: 'ME',
    name: 'Ulcinj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ce' },
    countryCode: 'MG',
    name: 'Fianarantsoa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d9' },
    countryCode: 'MH',
    name: 'Jaluit',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05048f' },
    countryCode: 'LT',
    name: 'Kauno Apskritis',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050498' },
    countryCode: 'LU',
    name: 'Clervaux',
    type: 'canton',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a5' },
    countryCode: 'LV',
    name: 'Aizputes novads (Aizpute)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504a7' },
    countryCode: 'LV',
    name: 'Alojas novads (Aloja)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ab' },
    countryCode: 'LV',
    name: 'Apes novads (Ape)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504b2' },
    countryCode: 'LV',
    name: 'Bauskas novads (Bauska)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d5' },
    countryCode: 'LV',
    name: 'Keguma novads (Kegums)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504d8' },
    countryCode: 'LV',
    name: 'Limbažu novads (Limbaži)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504dd' },
    countryCode: 'LV',
    name: 'Madonas novads (Madona)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504ea' },
    countryCode: 'LV',
    name: 'Plavinu novads (Plavinas)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f1' },
    countryCode: 'LV',
    name: 'Rojas novads (Roja)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504f5' },
    countryCode: 'LV',
    name: 'Rundales novads (Rundale)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504fb' },
    countryCode: 'LV',
    name: 'Saulkrastu novads (Saulkrasti)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0504fd' },
    countryCode: 'LV',
    name: 'Siguldas novads (Sigulda)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050503' },
    countryCode: 'LV',
    name: 'Talsu novads (Talsi)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050504' },
    countryCode: 'LV',
    name: 'Tervetes novads (Tervete)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050d' },
    countryCode: 'LV',
    name: 'Viesites novads (Viesite)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05050f' },
    countryCode: 'LV',
    name: 'Vilanu novads (Vilani)',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05051f' },
    countryCode: 'LY',
    name: 'Al Jabal al Gharbi',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050520' },
    countryCode: 'LY',
    name: 'Al Jafarah',
    type: 'Popularate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050556' },
    countryCode: 'MA',
    name: 'Al Hoceïma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05056f' },
    countryCode: 'MA',
    name: 'Salé',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050590' },
    countryCode: 'MD',
    name: 'Anenii Noi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05059d' },
    countryCode: 'MD',
    name: 'Drochia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505a6' },
    countryCode: 'MD',
    name: 'Leova',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505b2' },
    countryCode: 'MD',
    name: 'Taraclia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505cc' },
    countryCode: 'MG',
    name: 'Toamasina',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505d8' },
    countryCode: 'MH',
    name: 'Jabat',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e5' },
    countryCode: 'MH',
    name: 'Namu',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e3' },
    countryCode: 'MH',
    name: 'Mili',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f7' },
    countryCode: 'MK',
    name: 'Vinica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061a' },
    countryCode: 'MK',
    name: 'Novo Selo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050624' },
    countryCode: 'MK',
    name: 'Rosoman',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050627' },
    countryCode: 'MK',
    name: 'Staro Nagoricane',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062c' },
    countryCode: 'MK',
    name: 'Tetovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050665' },
    countryCode: 'MR',
    name: 'Brakna',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067b' },
    countryCode: 'MT',
    name: 'Gzira',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050682' },
    countryCode: 'MT',
    name: 'Iklin',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050698' },
    countryCode: 'MT',
    name: 'Pietà',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a5' },
    countryCode: 'MT',
    name: 'Santa Venera',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a9' },
    countryCode: 'MT',
    name: "Ta' Xbiex",
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b5' },
    countryCode: 'MU',
    name: 'Black River',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506bb' },
    countryCode: 'MU',
    name: 'Moka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e4' },
    countryCode: 'MW',
    name: 'Chikwawa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ef' },
    countryCode: 'MW',
    name: 'Machinga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f8' },
    countryCode: 'MW',
    name: 'Nsanje',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050705' },
    countryCode: 'MX',
    name: 'Chiapas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050728' },
    countryCode: 'MY',
    name: 'Perlis',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072b' },
    countryCode: 'MY',
    name: 'Sabah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ea' },
    countryCode: 'MH',
    name: 'Wotho',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505fe' },
    countryCode: 'MK',
    name: 'Delcevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050602' },
    countryCode: 'MK',
    name: 'Dolneni',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050619' },
    countryCode: 'MK',
    name: 'Novaci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050631' },
    countryCode: 'MK',
    name: 'Štip',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063a' },
    countryCode: 'ML',
    name: 'Kidal',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050648' },
    countryCode: 'MM',
    name: 'Rakhine',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050659' },
    countryCode: 'MN',
    name: 'Dornogovi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050689' },
    countryCode: 'MT',
    name: 'Marsa',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068a' },
    countryCode: 'MT',
    name: 'Marsaskala',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069c' },
    countryCode: 'MT',
    name: 'Rabat Gozo',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069f' },
    countryCode: 'MT',
    name: "Saint Julian's",
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ca' },
    countryCode: 'MV',
    name: 'Hahdhunmathi',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d4' },
    countryCode: 'MV',
    name: 'Noonu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d6' },
    countryCode: 'MV',
    name: 'Gaafu Alifu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d8' },
    countryCode: 'MV',
    name: 'Gnaviyani',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506df' },
    countryCode: 'MV',
    name: 'Mathi-Uthuru',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e8' },
    countryCode: 'MW',
    name: 'Dowa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050708' },
    countryCode: 'MX',
    name: 'Colima',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050711' },
    countryCode: 'MX',
    name: 'Nayarit',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050713' },
    countryCode: 'MX',
    name: 'Oaxaca',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071e' },
    countryCode: 'MX',
    name: 'Yucatán',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e2' },
    countryCode: 'MH',
    name: 'Mejit',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061d' },
    countryCode: 'MK',
    name: 'Pehcevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061f' },
    countryCode: 'MK',
    name: 'Prilep',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050629' },
    countryCode: 'MK',
    name: 'Strumica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062a' },
    countryCode: 'MK',
    name: 'Studenicani',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050633' },
    countryCode: 'ML',
    name: 'Kayes',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050652' },
    countryCode: 'MN',
    name: 'Selenge',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050664' },
    countryCode: 'MR',
    name: 'Gorgol',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066c' },
    countryCode: 'MR',
    name: 'Inchiri',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050679' },
    countryCode: 'MT',
    name: 'Fontana',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069e' },
    countryCode: 'MT',
    name: 'Safi',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c9' },
    countryCode: 'MV',
    name: 'Vaavu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ce' },
    countryCode: 'MV',
    name: 'Raa',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506cf' },
    countryCode: 'MV',
    name: 'Faafu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506dc' },
    countryCode: 'MV',
    name: 'Uthuru',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e2' },
    countryCode: 'MW',
    name: 'Blantyre',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506fb' },
    countryCode: 'MW',
    name: 'Rumphi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050701' },
    countryCode: 'MX',
    name: 'Baja California',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050709' },
    countryCode: 'MX',
    name: 'Durango',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071b' },
    countryCode: 'MX',
    name: 'Tamaulipas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050730' },
    countryCode: 'MZ',
    name: 'Niaosa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ee' },
    countryCode: 'MK',
    name: 'Bitola',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f1' },
    countryCode: 'MK',
    name: 'Bosilovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050605' },
    countryCode: 'MK',
    name: 'Zrnovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050606' },
    countryCode: 'MK',
    name: 'Ilinden',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050614' },
    countryCode: 'MK',
    name: 'Mavrovo-i-Rostuša',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063d' },
    countryCode: 'MM',
    name: 'Bago',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065c' },
    countryCode: 'MN',
    name: 'Bulgan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050674' },
    countryCode: 'MT',
    name: 'Birzebbuga',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a3' },
    countryCode: 'MT',
    name: 'Sannat',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ad' },
    countryCode: 'MT',
    name: 'Xewkija',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b7' },
    countryCode: 'MU',
    name: 'Cargados Carajos Shoals [Saint Brandon Islands]',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c7' },
    countryCode: 'MV',
    name: 'Alifu Alifu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506cd' },
    countryCode: 'MV',
    name: 'Meemu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506fa' },
    countryCode: 'MW',
    name: 'Phalombe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506fc' },
    countryCode: 'MW',
    name: 'Southern',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070f' },
    countryCode: 'MX',
    name: 'Michoacán de Ocampo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050719' },
    countryCode: 'MX',
    name: 'Sonora',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050727' },
    countryCode: 'MY',
    name: 'Perak',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072e' },
    countryCode: 'MY',
    name: 'Wilayah Persekutuan Labuan',
    type: 'Federal territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060d' },
    countryCode: 'MK',
    name: 'Kratovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f5' },
    countryCode: 'MK',
    name: 'Vevcani',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050620' },
    countryCode: 'MK',
    name: 'Probištip',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050622' },
    countryCode: 'MK',
    name: 'Rankovce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050637' },
    countryCode: 'ML',
    name: 'Mopti',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050654' },
    countryCode: 'MN',
    name: 'Ömnögovi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066a' },
    countryCode: 'MR',
    name: 'Guidimaka',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066e' },
    countryCode: 'MR',
    name: 'Nouakchott Nord',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050671' },
    countryCode: 'MT',
    name: 'Balzan',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050688' },
    countryCode: 'MT',
    name: 'Luqa',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050692' },
    countryCode: 'MT',
    name: 'Mtarfa',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c0' },
    countryCode: 'MU',
    name: 'Quatre Bornes',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c3' },
    countryCode: 'MU',
    name: 'Savanne',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c6' },
    countryCode: 'MV',
    name: 'Seenu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d1' },
    countryCode: 'MV',
    name: 'Baa',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070a' },
    countryCode: 'MX',
    name: 'Guerrero',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070c' },
    countryCode: 'MX',
    name: 'Hidalgo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050726' },
    countryCode: 'MY',
    name: 'Pulau Pinang',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072c' },
    countryCode: 'MY',
    name: 'Sarawak',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050610' },
    countryCode: 'MK',
    name: 'Kruševo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050616' },
    countryCode: 'MK',
    name: 'Makedonski Brod',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050628' },
    countryCode: 'MK',
    name: 'Struga',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063f' },
    countryCode: 'MM',
    name: 'Mandalay',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064a' },
    countryCode: 'MM',
    name: 'Nay Pyi Taw',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064b' },
    countryCode: 'MN',
    name: 'Orhon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050650' },
    countryCode: 'MN',
    name: 'Uvs',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050657' },
    countryCode: 'MN',
    name: 'Dundgovi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050685' },
    countryCode: 'MT',
    name: 'Kercem',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069a' },
    countryCode: 'MT',
    name: 'Qormi',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a0' },
    countryCode: 'MT',
    name: 'Saint John',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b9' },
    countryCode: 'MU',
    name: 'Flacq',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d5' },
    countryCode: 'MV',
    name: 'Kaafu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ea' },
    countryCode: 'MW',
    name: 'Kasungu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f0' },
    countryCode: 'MW',
    name: 'Mulanje',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f7' },
    countryCode: 'MW',
    name: 'Nkhotakota',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050714' },
    countryCode: 'MX',
    name: 'Puebla',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071c' },
    countryCode: 'MX',
    name: 'Tlaxcala',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050721' },
    countryCode: 'MY',
    name: 'Kedah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050734' },
    countryCode: 'MZ',
    name: 'Maputo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e6' },
    countryCode: 'MH',
    name: 'Rongelap',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060e' },
    countryCode: 'MK',
    name: 'Kriva Palanka',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ed' },
    countryCode: 'MK',
    name: 'Berovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f9' },
    countryCode: 'MK',
    name: 'Gevgelija',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050615' },
    countryCode: 'MK',
    name: 'Makedonska Kamenica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050626' },
    countryCode: 'MK',
    name: 'Sopište',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062e' },
    countryCode: 'MK',
    name: 'Caška',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064f' },
    countryCode: 'MN',
    name: 'Hovd',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050667' },
    countryCode: 'MR',
    name: 'Adrar',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050670' },
    countryCode: 'MT',
    name: 'Attard',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050675' },
    countryCode: 'MT',
    name: 'Bormla',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050678' },
    countryCode: 'MT',
    name: 'Floriana',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068c' },
    countryCode: 'MT',
    name: 'Mdina',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050691' },
    countryCode: 'MT',
    name: 'Msida',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a7' },
    countryCode: 'MT',
    name: 'Sliema',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ae' },
    countryCode: 'MT',
    name: 'Xghajra',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c8' },
    countryCode: 'MV',
    name: 'Lhaviyani',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506cb' },
    countryCode: 'MV',
    name: 'Haa Alif',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d2' },
    countryCode: 'MV',
    name: 'Haa Dhaalu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506db' },
    countryCode: 'MV',
    name: 'Medhu-Uthuru',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e9' },
    countryCode: 'MW',
    name: 'Karonga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f6' },
    countryCode: 'MW',
    name: 'Ntchisi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050724' },
    countryCode: 'MY',
    name: 'Negeri Sembilan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050729' },
    countryCode: 'MY',
    name: 'Selangor',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050732' },
    countryCode: 'MZ',
    name: 'Gaza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050733' },
    countryCode: 'MZ',
    name: 'Inhambane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060f' },
    countryCode: 'MK',
    name: 'Krivogaštani',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050613' },
    countryCode: 'MK',
    name: 'Lozovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064d' },
    countryCode: 'MN',
    name: 'Hentiy',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065b' },
    countryCode: 'MN',
    name: 'Govi-Altay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050661' },
    countryCode: 'MR',
    name: 'Hodh ech Chargui',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050663' },
    countryCode: 'MR',
    name: 'Assaba',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066d' },
    countryCode: 'MR',
    name: 'Nouakchott Ouest',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050677' },
    countryCode: 'MT',
    name: 'Fgura',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067a' },
    countryCode: 'MT',
    name: 'Gudja',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050683' },
    countryCode: 'MT',
    name: 'Isla',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050693' },
    countryCode: 'MT',
    name: 'Munxar',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c5' },
    countryCode: 'MV',
    name: 'Alifu Dhaalu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d9' },
    countryCode: 'MV',
    name: 'Medhu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f1' },
    countryCode: 'MW',
    name: 'Mwanza',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f3' },
    countryCode: 'MW',
    name: 'Northern',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070b' },
    countryCode: 'MX',
    name: 'Guanajuato',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070e' },
    countryCode: 'MX',
    name: 'México',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050710' },
    countryCode: 'MX',
    name: 'Morelos',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073a' },
    countryCode: 'MZ',
    name: 'Tete',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e8' },
    countryCode: 'MH',
    name: 'Ujae',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e4' },
    countryCode: 'MH',
    name: 'Namdrik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f3' },
    countryCode: 'MK',
    name: 'Valandovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f4' },
    countryCode: 'MK',
    name: 'Vasilevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050600' },
    countryCode: 'MK',
    name: 'Demir Hisar',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050609' },
    countryCode: 'MK',
    name: 'Karbinci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060a' },
    countryCode: 'MK',
    name: 'Kicevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050617' },
    countryCode: 'MK',
    name: 'Mogila',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050632' },
    countryCode: 'MK',
    name: 'Skopje',
    type: 'municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050638' },
    countryCode: 'ML',
    name: 'Tombouctou',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050643' },
    countryCode: 'MM',
    name: 'Kachin',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050658' },
    countryCode: 'MN',
    name: 'Dornod',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050662' },
    countryCode: 'MR',
    name: 'Hodh el Gharbi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067f' },
    countryCode: 'MT',
    name: 'Ghasri',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c4' },
    countryCode: 'MU',
    name: 'Vacoas-Phoenix',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506eb' },
    countryCode: 'MW',
    name: 'Lilongwe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050704' },
    countryCode: 'MX',
    name: 'Chihuahua',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05070d' },
    countryCode: 'MX',
    name: 'Jalisco',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050723' },
    countryCode: 'MY',
    name: 'Melaka',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050735' },
    countryCode: 'MZ',
    name: 'Maputo',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050739' },
    countryCode: 'MZ',
    name: 'Sofala',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ef' },
    countryCode: 'MK',
    name: 'Bogdanci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f8' },
    countryCode: 'MK',
    name: 'Vrapcište',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060c' },
    countryCode: 'MK',
    name: 'Kocani',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061b' },
    countryCode: 'MK',
    name: 'Ohrid',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061c' },
    countryCode: 'MK',
    name: 'Petrovec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05061e' },
    countryCode: 'MK',
    name: 'Plasnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062d' },
    countryCode: 'MK',
    name: 'Centar Župa',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062f' },
    countryCode: 'MK',
    name: 'Cešinovo-Obleševo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050634' },
    countryCode: 'ML',
    name: 'Koulikoro',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063b' },
    countryCode: 'ML',
    name: 'Bamako',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050646' },
    countryCode: 'MM',
    name: 'Chin',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050649' },
    countryCode: 'MM',
    name: 'Shan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065e' },
    countryCode: 'MN',
    name: 'Bayan-Ölgiy',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050681' },
    countryCode: 'MT',
    name: 'Hamrun',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068d' },
    countryCode: 'MT',
    name: 'Mellieha',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c2' },
    countryCode: 'MU',
    name: 'Rivière du Rempart',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d3' },
    countryCode: 'MV',
    name: 'Shaviyani',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e1' },
    countryCode: 'MW',
    name: 'Balaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ec' },
    countryCode: 'MW',
    name: 'Likoma',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ed' },
    countryCode: 'MW',
    name: 'Mchinji',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506fd' },
    countryCode: 'MW',
    name: 'Salima',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050738' },
    countryCode: 'MZ',
    name: 'Zambézia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505eb' },
    countryCode: 'MH',
    name: 'Wotje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505fc' },
    countryCode: 'MK',
    name: 'Debar',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050607' },
    countryCode: 'MK',
    name: 'Jegunovce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050612' },
    countryCode: 'MK',
    name: 'Lipkovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050623' },
    countryCode: 'MK',
    name: 'Resen',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050630' },
    countryCode: 'MK',
    name: 'Cucer Sandevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050642' },
    countryCode: 'MM',
    name: 'Ayeyarwady',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050647' },
    countryCode: 'MM',
    name: 'Mon',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050655' },
    countryCode: 'MN',
    name: 'Övörhangay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050666' },
    countryCode: 'MR',
    name: 'Trarza',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050669' },
    countryCode: 'MR',
    name: 'Tagant',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050673' },
    countryCode: 'MT',
    name: 'Birkirkara',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050697' },
    countryCode: 'MT',
    name: 'Pembroke',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a8' },
    countryCode: 'MT',
    name: 'Swieqi',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ac' },
    countryCode: 'MT',
    name: 'Xaghra',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b2' },
    countryCode: 'MT',
    name: 'Zejtun',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506c1' },
    countryCode: 'MU',
    name: 'Rodrigues Island',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d0' },
    countryCode: 'MV',
    name: 'Dhaalu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506de' },
    countryCode: 'MV',
    name: 'Dhekunu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e0' },
    countryCode: 'MV',
    name: 'Mathi-Dhekunu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e3' },
    countryCode: 'MW',
    name: 'Central',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e6' },
    countryCode: 'MW',
    name: 'Chitipa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e7' },
    countryCode: 'MW',
    name: 'Dedza',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506fe' },
    countryCode: 'MW',
    name: 'Thyolo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050702' },
    countryCode: 'MX',
    name: 'Baja California Sur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050715' },
    countryCode: 'MX',
    name: 'Querétaro',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071a' },
    countryCode: 'MX',
    name: 'Tabasco',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071f' },
    countryCode: 'MX',
    name: 'Zacatecas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072f' },
    countryCode: 'MY',
    name: 'Wilayah Persekutuan Putrajaya',
    type: 'Federal territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073e' },
    countryCode: 'NA',
    name: 'Karas',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505fb' },
    countryCode: 'MK',
    name: 'Gradsko',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505fd' },
    countryCode: 'MK',
    name: 'Debarca',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050603' },
    countryCode: 'MK',
    name: 'Želino',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05060b' },
    countryCode: 'MK',
    name: 'Konce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050645' },
    countryCode: 'MM',
    name: 'Kayin',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050653' },
    countryCode: 'MN',
    name: 'Sühbaatar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065f' },
    countryCode: 'MN',
    name: 'Arhangay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066f' },
    countryCode: 'MR',
    name: 'Nouakchott Sud',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069d' },
    countryCode: 'MT',
    name: 'Rabat Malta',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a4' },
    countryCode: 'MT',
    name: "Saint Lucia's",
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ab' },
    countryCode: 'MT',
    name: 'Valletta',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b8' },
    countryCode: 'MU',
    name: 'Curepipe',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ee' },
    countryCode: 'MW',
    name: 'Mangochi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f4' },
    countryCode: 'MW',
    name: 'Nkhata Bay',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f9' },
    countryCode: 'MW',
    name: 'Ntcheu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050707' },
    countryCode: 'MX',
    name: 'Coahuila de Zaragoza',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050716' },
    countryCode: 'MX',
    name: 'Quintana Roo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050722' },
    countryCode: 'MY',
    name: 'Kelantan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050725' },
    countryCode: 'MY',
    name: 'Pahang',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073f' },
    countryCode: 'NA',
    name: 'Kavango East',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e7' },
    countryCode: 'MH',
    name: 'Ratak chain',
    type: 'Chains (of islands)',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505e9' },
    countryCode: 'MH',
    name: 'Utrik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f0' },
    countryCode: 'MK',
    name: 'Bogovinje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505fa' },
    countryCode: 'MK',
    name: 'Gostivar',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050608' },
    countryCode: 'MK',
    name: 'Kavadarci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050640' },
    countryCode: 'MM',
    name: 'Tanintharyi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050641' },
    countryCode: 'MM',
    name: 'Yangon',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050656' },
    countryCode: 'MN',
    name: 'Dzavhan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050660' },
    countryCode: 'MN',
    name: 'Ulaanbaatar',
    type: 'Capital city',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067d' },
    countryCode: 'MT',
    name: 'Gharb',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067e' },
    countryCode: 'MT',
    name: 'Gharghur',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a6' },
    countryCode: 'MT',
    name: 'Siggiewi',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506af' },
    countryCode: 'MT',
    name: 'Zabbar',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b3' },
    countryCode: 'MT',
    name: 'Zurrieq',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506d7' },
    countryCode: 'MV',
    name: 'Gaafu Dhaalu',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506e5' },
    countryCode: 'MW',
    name: 'Chiradzulu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f5' },
    countryCode: 'MW',
    name: 'Neno',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ff' },
    countryCode: 'MW',
    name: 'Zomba',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050718' },
    countryCode: 'MX',
    name: 'San Luis Potosí',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072a' },
    countryCode: 'MY',
    name: 'Terengganu',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f6' },
    countryCode: 'MK',
    name: 'Veles',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05072d' },
    countryCode: 'MY',
    name: 'Wilayah Persekutuan Kuala Lumpur',
    type: 'Federal territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050737' },
    countryCode: 'MZ',
    name: 'Cabo Delgado',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ff' },
    countryCode: 'MK',
    name: 'Demir Kapija',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050601' },
    countryCode: 'MK',
    name: 'Dojran',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050618' },
    countryCode: 'MK',
    name: 'Negotino',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050636' },
    countryCode: 'ML',
    name: 'Ségou',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050644' },
    countryCode: 'MM',
    name: 'Kayah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050651' },
    countryCode: 'MN',
    name: 'Töv',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065d' },
    countryCode: 'MN',
    name: 'Bayanhongor',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05067c' },
    countryCode: 'MT',
    name: 'Ghajnsielem',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050690' },
    countryCode: 'MT',
    name: 'Mqabba',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050696' },
    countryCode: 'MT',
    name: 'Paola',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050699' },
    countryCode: 'MT',
    name: 'Qala',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a2' },
    countryCode: 'MT',
    name: "Saint Paul's Bay",
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b1' },
    countryCode: 'MT',
    name: 'Zebbug Malta',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506bc' },
    countryCode: 'MU',
    name: 'Pamplemousses',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506be' },
    countryCode: 'MU',
    name: 'Port Louis',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506bf' },
    countryCode: 'MU',
    name: 'Plaines wilhems',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05071d' },
    countryCode: 'MX',
    name: 'Veracruz de Ignacio de la Llave',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050720' },
    countryCode: 'MY',
    name: 'Johor',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050731' },
    countryCode: 'MZ',
    name: 'Manica',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050736' },
    countryCode: 'MZ',
    name: 'Nampula',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073b' },
    countryCode: 'NA',
    name: 'Zambezi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073d' },
    countryCode: 'NA',
    name: 'Hardap',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505ec' },
    countryCode: 'MK',
    name: 'Aracinovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050604' },
    countryCode: 'MK',
    name: 'Zelenikovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0505f2' },
    countryCode: 'MK',
    name: 'Brvenica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050611' },
    countryCode: 'MK',
    name: 'Kumanovo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050621' },
    countryCode: 'MK',
    name: 'Radoviš',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050635' },
    countryCode: 'ML',
    name: 'Sikasso',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064c' },
    countryCode: 'MN',
    name: 'Darhan uul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05064e' },
    countryCode: 'MN',
    name: 'Hövagöl',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05065a' },
    countryCode: 'MN',
    name: 'Govi-Sümber',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050672' },
    countryCode: 'MT',
    name: 'Birgu',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050676' },
    countryCode: 'MT',
    name: 'Dingli',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050680' },
    countryCode: 'MT',
    name: 'Ghaxaq',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050684' },
    countryCode: 'MT',
    name: 'Kalkara',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050686' },
    countryCode: 'MT',
    name: 'Kirkop',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05069b' },
    countryCode: 'MT',
    name: 'Qrendi',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506a1' },
    countryCode: 'MT',
    name: 'Saint Lawrence',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b6' },
    countryCode: 'MU',
    name: 'Beau Bassin-Rose Hill',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506bd' },
    countryCode: 'MU',
    name: 'Port Louis',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506cc' },
    countryCode: 'MV',
    name: 'Thaa',
    type: 'Administrative atoll',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506da' },
    countryCode: 'MV',
    name: 'Male',
    type: 'Capital',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506f2' },
    countryCode: 'MW',
    name: 'Mzimba',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050703' },
    countryCode: 'MX',
    name: 'Campeche',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050706' },
    countryCode: 'MX',
    name: 'Ciudad de Mexico',
    type: 'Federal District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05073c' },
    countryCode: 'NA',
    name: 'Erongo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050625' },
    countryCode: 'MK',
    name: 'Sveti Nikole',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05062b' },
    countryCode: 'MK',
    name: 'Tearce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050639' },
    countryCode: 'ML',
    name: 'Gao',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063c' },
    countryCode: 'MM',
    name: 'Sagaing',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05063e' },
    countryCode: 'MM',
    name: 'Magway',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050668' },
    countryCode: 'MR',
    name: 'Dakhlet Nouâdhibou',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05066b' },
    countryCode: 'MR',
    name: 'Tiris Zemmour',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050687' },
    countryCode: 'MT',
    name: 'Lija',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068b' },
    countryCode: 'MT',
    name: 'Marsaxlokk',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068e' },
    countryCode: 'MT',
    name: 'Mgarr',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05068f' },
    countryCode: 'MT',
    name: 'Mosta',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050694' },
    countryCode: 'MT',
    name: 'Nadur',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050695' },
    countryCode: 'MT',
    name: 'Naxxar',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506aa' },
    countryCode: 'MT',
    name: 'Tarxien',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b0' },
    countryCode: 'MT',
    name: 'Zebbug Gozo',
    type: 'Local council',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506b4' },
    countryCode: 'MU',
    name: 'Agalega Islands',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506ba' },
    countryCode: 'MU',
    name: 'Grand Port',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0506dd' },
    countryCode: 'MV',
    name: 'Medhu-Dhekunu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050700' },
    countryCode: 'MX',
    name: 'Aguascalientes',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050712' },
    countryCode: 'MX',
    name: 'Nuevo León',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050717' },
    countryCode: 'MX',
    name: 'Sinaloa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050740' },
    countryCode: 'NA',
    name: 'Khomas',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050742' },
    countryCode: 'NA',
    name: 'Kavango West',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050748' },
    countryCode: 'NA',
    name: 'Ohangwena',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050767' },
    countryCode: 'NG',
    name: 'Katsina',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050751' },
    countryCode: 'NG',
    name: 'Abia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050759' },
    countryCode: 'NG',
    name: 'Cross River',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075a' },
    countryCode: 'NG',
    name: 'Delta',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075f' },
    countryCode: 'NG',
    name: 'Abuja Capital Territory',
    type: 'Capital territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050741' },
    countryCode: 'NA',
    name: 'Kunene',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074b' },
    countryCode: 'NE',
    name: 'Dosso',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050763' },
    countryCode: 'NG',
    name: 'Kaduna',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050764' },
    countryCode: 'NG',
    name: 'Kebbi',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050757' },
    countryCode: 'NG',
    name: 'Borno',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050749' },
    countryCode: 'NE',
    name: 'Agadez',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050756' },
    countryCode: 'NG',
    name: 'Benue',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050745' },
    countryCode: 'NA',
    name: 'Oshana',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074c' },
    countryCode: 'NE',
    name: 'Maradi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050761' },
    countryCode: 'NG',
    name: 'Imo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050747' },
    countryCode: 'NA',
    name: 'Oshikoto',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074e' },
    countryCode: 'NE',
    name: 'Tillabéri',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050753' },
    countryCode: 'NG',
    name: 'Akwa Ibom',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074a' },
    countryCode: 'NE',
    name: 'Diffa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050755' },
    countryCode: 'NG',
    name: 'Bauchi',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075b' },
    countryCode: 'NG',
    name: 'Ebonyi',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050743' },
    countryCode: 'NA',
    name: 'Otjozondjupa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050758' },
    countryCode: 'NG',
    name: 'Bayelsa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050760' },
    countryCode: 'NG',
    name: 'Gombe',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050765' },
    countryCode: 'NG',
    name: 'Kano',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074f' },
    countryCode: 'NE',
    name: 'Zinder',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075d' },
    countryCode: 'NG',
    name: 'Ekiti',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075e' },
    countryCode: 'NG',
    name: 'Enugu',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050750' },
    countryCode: 'NE',
    name: 'Niamey',
    type: 'Urban community',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050746' },
    countryCode: 'NA',
    name: 'Omusati',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050752' },
    countryCode: 'NG',
    name: 'Adamawa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050754' },
    countryCode: 'NG',
    name: 'Anambra',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050766' },
    countryCode: 'NG',
    name: 'Kogi',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050768' },
    countryCode: 'NG',
    name: 'Kwara',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050744' },
    countryCode: 'NA',
    name: 'Omaheke',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05074d' },
    countryCode: 'NE',
    name: 'Tahoua',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050762' },
    countryCode: 'NG',
    name: 'Jigawa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05075c' },
    countryCode: 'NG',
    name: 'Edo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076a' },
    countryCode: 'NG',
    name: 'Nasarawa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076b' },
    countryCode: 'NG',
    name: 'Niger',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b1' },
    countryCode: 'NP',
    name: 'Mechi',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b9' },
    countryCode: 'NR',
    name: 'Anibare',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050785' },
    countryCode: 'NI',
    name: 'Rivas',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050789' },
    countryCode: 'NL',
    name: 'Fryslân',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a0' },
    countryCode: 'NO',
    name: 'Møre og Romsdal',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ad' },
    countryCode: 'NP',
    name: 'Karnali',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076d' },
    countryCode: 'NG',
    name: 'Ondo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050771' },
    countryCode: 'NG',
    name: 'Rivers',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077b' },
    countryCode: 'NI',
    name: 'Chontales',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050786' },
    countryCode: 'NI',
    name: 'Río San Juan',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a2' },
    countryCode: 'NO',
    name: 'Nord-Trøndelag',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a5' },
    countryCode: 'NO',
    name: 'Finnmark',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507aa' },
    countryCode: 'NP',
    name: 'Dhawalagiri',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050772' },
    countryCode: 'NG',
    name: 'Sokoto',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077d' },
    countryCode: 'NI',
    name: 'Granada',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050780' },
    countryCode: 'NI',
    name: 'Madriz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050799' },
    countryCode: 'NO',
    name: 'Vestfold',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a3' },
    countryCode: 'NO',
    name: 'Nordland',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507bc' },
    countryCode: 'NR',
    name: 'Buada',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050769' },
    countryCode: 'NG',
    name: 'Lagos',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050782' },
    countryCode: 'NI',
    name: 'Masaya',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078c' },
    countryCode: 'NL',
    name: 'Limburg',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a6' },
    countryCode: 'NO',
    name: 'Svalbard (Arctic Region) (See\r\n\r\nalso country code SJ)',
    type: 'Arctic region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b0' },
    countryCode: 'NP',
    name: 'Mahakali',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050779' },
    countryCode: 'NI',
    name: 'Carazo',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077e' },
    countryCode: 'NI',
    name: 'Jinotega',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078f' },
    countryCode: 'NL',
    name: 'Overijssel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050791' },
    countryCode: 'NL',
    name: 'Zeeland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050794' },
    countryCode: 'NO',
    name: 'Akershus',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507af' },
    countryCode: 'NP',
    name: 'Lumbini',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050773' },
    countryCode: 'NG',
    name: 'Taraba',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079a' },
    countryCode: 'NO',
    name: 'Telemark',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079b' },
    countryCode: 'NO',
    name: 'Aust-Agder',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b4' },
    countryCode: 'NP',
    name: 'Sagarmatha',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050770' },
    countryCode: 'NG',
    name: 'Plateau',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050787' },
    countryCode: 'NL',
    name: 'Drenthe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a4' },
    countryCode: 'NO',
    name: 'Troms',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ac' },
    countryCode: 'NP',
    name: 'Janakpur',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076e' },
    countryCode: 'NG',
    name: 'Osun',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078a' },
    countryCode: 'NL',
    name: 'Gelderland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079c' },
    countryCode: 'NO',
    name: 'Vest-Agder',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079d' },
    countryCode: 'NO',
    name: 'Rogaland',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ab' },
    countryCode: 'NP',
    name: 'Gandaki',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077f' },
    countryCode: 'NI',
    name: 'León',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050790' },
    countryCode: 'NL',
    name: 'Utrecht',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079f' },
    countryCode: 'NO',
    name: 'Sogn og Fjordane',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ae' },
    countryCode: 'NP',
    name: 'Kosi [Koshi]',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076f' },
    countryCode: 'NG',
    name: 'Oyo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050774' },
    countryCode: 'NG',
    name: 'Yobe',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050776' },
    countryCode: 'NI',
    name: 'Atlántico Norte',
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050777' },
    countryCode: 'NI',
    name: 'Atlántico Sur',
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077a' },
    countryCode: 'NI',
    name: 'Chinandega',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050793' },
    countryCode: 'NO',
    name: 'Østfold',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050795' },
    countryCode: 'NO',
    name: 'Oslo',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050797' },
    countryCode: 'NO',
    name: 'Oppland',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b6' },
    countryCode: 'NR',
    name: 'Aiwo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b8' },
    countryCode: 'NR',
    name: 'Anetan',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050781' },
    countryCode: 'NI',
    name: 'Managua',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050783' },
    countryCode: 'NI',
    name: 'Matagalpa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a9' },
    countryCode: 'NP',
    name: 'Bheri',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b2' },
    countryCode: 'NP',
    name: 'Narayani',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b5' },
    countryCode: 'NP',
    name: 'Seti',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050775' },
    countryCode: 'NG',
    name: 'Zamfara',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050798' },
    countryCode: 'NO',
    name: 'Buskerud',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a1' },
    countryCode: 'NO',
    name: 'Sør-Trøndelag',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078e' },
    countryCode: 'NL',
    name: 'Noord-Holland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050792' },
    countryCode: 'NL',
    name: 'Zuid-Holland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050796' },
    countryCode: 'NO',
    name: 'Hedmark',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ba' },
    countryCode: 'NR',
    name: 'Baiti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507bb' },
    countryCode: 'NR',
    name: 'Boe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050778' },
    countryCode: 'NI',
    name: 'Boaco',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05077c' },
    countryCode: 'NI',
    name: 'Estelí',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050788' },
    countryCode: 'NL',
    name: 'Flevoland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05079e' },
    countryCode: 'NO',
    name: 'Hordaland',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a7' },
    countryCode: 'NO',
    name: 'Jan Mayen (Arctic Region) (See\r\n\r\nalso country code SJ)',
    type: 'Arctic region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507a8' },
    countryCode: 'NP',
    name: 'Bagmati',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05076c' },
    countryCode: 'NG',
    name: 'Ogun',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050784' },
    countryCode: 'NI',
    name: 'Nueva Segovia',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078b' },
    countryCode: 'NL',
    name: 'Groningen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05078d' },
    countryCode: 'NL',
    name: 'Noord-Brabant',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b3' },
    countryCode: 'NP',
    name: 'Rapti',
    type: 'Zone',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507b7' },
    countryCode: 'NR',
    name: 'Anabar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c1' },
    countryCode: 'NR',
    name: 'Nibok',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c4' },
    countryCode: 'NZ',
    name: 'Auckland',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507dc' },
    countryCode: 'OM',
    name: 'Shamal ash Sharqiyah',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d0' },
    countryCode: 'NZ',
    name: 'Tasman',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d2' },
    countryCode: 'NZ',
    name: 'Wellington',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d8' },
    countryCode: 'OM',
    name: 'Ad Dakhiliyah',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507de' },
    countryCode: 'OM',
    name: 'Az Zahirah',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c3' },
    countryCode: 'NR',
    name: 'Yaren',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507db' },
    countryCode: 'OM',
    name: 'Janub ash Sharqiyah',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507df' },
    countryCode: 'OM',
    name: 'Z¸ufar',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e8' },
    countryCode: 'PA',
    name: 'Panamá',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c5' },
    countryCode: 'NZ',
    name: 'Bay of Plenty',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c7' },
    countryCode: 'NZ',
    name: 'Chatham Islands Territory',
    type: 'Special Island Authorithy',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d1' },
    countryCode: 'NZ',
    name: 'Taranaki',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d7' },
    countryCode: 'OM',
    name: 'Al Buraymi',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c8' },
    countryCode: 'NZ',
    name: 'Gisborne',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507cc' },
    countryCode: 'NZ',
    name: 'Nelson',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ce' },
    countryCode: 'NZ',
    name: 'Otago',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d5' },
    countryCode: 'OM',
    name: 'Janub al Batinah',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ea' },
    countryCode: 'PA',
    name: 'Emberá',
    type: 'indigenous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c9' },
    countryCode: 'NZ',
    name: "Hawkes's Bay",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e0' },
    countryCode: 'PA',
    name: 'Bocas del Toro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c0' },
    countryCode: 'NR',
    name: 'Meneng',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d6' },
    countryCode: 'OM',
    name: 'Shamal al Batinah',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e2' },
    countryCode: 'PA',
    name: 'Coclé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e9' },
    countryCode: 'PA',
    name: 'Veraguas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d9' },
    countryCode: 'OM',
    name: 'Masqat',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507bf' },
    countryCode: 'NR',
    name: 'Ijuw',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c6' },
    countryCode: 'NZ',
    name: 'Canterbury',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507cd' },
    countryCode: 'NZ',
    name: 'Northland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d4' },
    countryCode: 'NZ',
    name: 'West Coast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507da' },
    countryCode: 'OM',
    name: 'Musandam',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507cb' },
    countryCode: 'NZ',
    name: 'Manawatu-Wanganui',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e1' },
    countryCode: 'PA',
    name: 'Panamá Oeste',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e5' },
    countryCode: 'PA',
    name: 'Darién',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e7' },
    countryCode: 'PA',
    name: 'Los Santos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507bd' },
    countryCode: 'NR',
    name: 'Denigomodu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507cf' },
    countryCode: 'NZ',
    name: 'Southland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507eb' },
    countryCode: 'PA',
    name: 'Kuna Yala',
    type: 'indigenous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507c2' },
    countryCode: 'NR',
    name: 'Uaboe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507d3' },
    countryCode: 'NZ',
    name: 'Waikato',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e3' },
    countryCode: 'PA',
    name: 'Colón',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e6' },
    countryCode: 'PA',
    name: 'Herrera',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507be' },
    countryCode: 'NR',
    name: 'Ewa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ca' },
    countryCode: 'NZ',
    name: 'Marlborough',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507dd' },
    countryCode: 'OM',
    name: 'AI Wusta',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507e4' },
    countryCode: 'PA',
    name: 'Chiriquí',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ec' },
    countryCode: 'PA',
    name: 'Ngöbe-Buglé',
    type: 'indigenous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050808' },
    countryCode: 'PG',
    name: 'Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081e' },
    countryCode: 'PH',
    name: 'Abra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050827' },
    countryCode: 'PH',
    name: 'Basilan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082f' },
    countryCode: 'PH',
    name: 'Cagayan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050836' },
    countryCode: 'PH',
    name: 'Cebu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084f' },
    countryCode: 'PH',
    name: 'Mountain Province',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050859' },
    countryCode: 'PH',
    name: 'Pangasinan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050860' },
    countryCode: 'PH',
    name: 'South Cotabato',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050869' },
    countryCode: 'PH',
    name: 'Tawi-Tawi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088b' },
    countryCode: 'PS',
    name: 'Jerusalem',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088c' },
    countryCode: 'PS',
    name: 'Jericho and Al Aghwar',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089d' },
    countryCode: 'PT',
    name: 'Faro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b1' },
    countryCode: 'PW',
    name: 'Ngaraard',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c8' },
    countryCode: 'PY',
    name: 'Itapúa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508df' },
    countryCode: 'RO',
    name: 'Calarasi',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050804' },
    countryCode: 'PE',
    name: 'Tacna',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050821' },
    countryCode: 'PH',
    name: 'Aklan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050872' },
    countryCode: 'PK',
    name: 'Azad Kashmir',
    type: 'Pakistan administrered area',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089e' },
    countryCode: 'PT',
    name: 'Guarda',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b6' },
    countryCode: 'PW',
    name: 'Ngeremlengui',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508be' },
    countryCode: 'PY',
    name: 'Amambay',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c1' },
    countryCode: 'PY',
    name: 'Alto Paraguay',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f7' },
    countryCode: 'RO',
    name: 'Tulcea',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050902' },
    countryCode: 'RS',
    name: 'Zapadnobacki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507fd' },
    countryCode: 'PE',
    name: 'Loreto',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080c' },
    countryCode: 'PG',
    name: 'East Sepik',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081b' },
    countryCode: 'PG',
    name: 'Western Highlands',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081d' },
    countryCode: 'PH',
    name: 'National Capital Région (Manila)',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081f' },
    countryCode: 'PH',
    name: 'Agusan del Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050822' },
    countryCode: 'PH',
    name: 'Albay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082e' },
    countryCode: 'PH',
    name: 'Bulacan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050846' },
    countryCode: 'PH',
    name: 'Lanao del Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050867' },
    countryCode: 'PH',
    name: 'Surigao del Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087e' },
    countryCode: 'PL',
    name: 'Opolskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089c' },
    countryCode: 'PT',
    name: 'Évora',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b3' },
    countryCode: 'PW',
    name: 'Ngardmau',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b7' },
    countryCode: 'PW',
    name: 'Ngiwal',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508dc' },
    countryCode: 'RO',
    name: 'Brasov',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508de' },
    countryCode: 'RO',
    name: 'Cluj',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e7' },
    countryCode: 'RO',
    name: 'Giurgiu',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ef' },
    countryCode: 'RO',
    name: 'Mures',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508fd' },
    countryCode: 'RS',
    name: 'Beograd',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f4' },
    countryCode: 'PE',
    name: 'Cuzco [Cusco]',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507fb' },
    countryCode: 'PE',
    name: 'Lima',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080a' },
    countryCode: 'PG',
    name: 'Eastern Highlands',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080e' },
    countryCode: 'PG',
    name: 'Hela',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050823' },
    countryCode: 'PH',
    name: 'Antique',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083b' },
    countryCode: 'PH',
    name: 'Dinagat Islands',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050854' },
    countryCode: 'PH',
    name: 'Negros oriental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085f' },
    countryCode: 'PH',
    name: 'Sarangani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086d' },
    countryCode: 'PH',
    name: 'Zambales',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050876' },
    countryCode: 'PK',
    name: 'Federally Administered Tribal Areas',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050887' },
    countryCode: 'PS',
    name: 'Bethlehem',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050896' },
    countryCode: 'PT',
    name: 'Aveiro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a4' },
    countryCode: 'PT',
    name: 'Setúbal',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ac' },
    countryCode: 'PW',
    name: 'Angaur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b0' },
    countryCode: 'PW',
    name: 'Melekeok',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d4' },
    countryCode: 'RO',
    name: 'Arges',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e0' },
    countryCode: 'RO',
    name: 'Caras-Severin',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e2' },
    countryCode: 'RO',
    name: 'Covasna',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ee' },
    countryCode: 'RO',
    name: 'Maramures',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f6' },
    countryCode: 'RO',
    name: 'Suceava',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050900' },
    countryCode: 'RS',
    name: 'Severnobanatski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f9' },
    countryCode: 'PE',
    name: 'La Libertad',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050819' },
    countryCode: 'PG',
    name: 'Southern Highlands',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050825' },
    countryCode: 'PH',
    name: 'Aurora',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082a' },
    countryCode: 'PH',
    name: 'Bohol',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050838' },
    countryCode: 'PH',
    name: 'Davao Oriental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083e' },
    countryCode: 'PH',
    name: 'Guimaras',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050845' },
    countryCode: 'PH',
    name: 'Laguna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084e' },
    countryCode: 'PH',
    name: 'Mindoro Oriental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050853' },
    countryCode: 'PH',
    name: 'Negros occidental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086a' },
    countryCode: 'PH',
    name: 'Western Samar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050890' },
    countryCode: 'PS',
    name: 'Qalqilya',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a8' },
    countryCode: 'PT',
    name: 'Região Autónoma dos Açores',
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ad' },
    countryCode: 'PW',
    name: 'Hatohobei',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b4' },
    countryCode: 'PW',
    name: 'Ngatpang',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c2' },
    countryCode: 'PY',
    name: 'Boquerón',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d1' },
    countryCode: 'QA',
    name: 'Al Wakrah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e3' },
    countryCode: 'RO',
    name: 'Dâmbovita',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ec' },
    countryCode: 'RO',
    name: 'Iasi',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050803' },
    countryCode: 'PE',
    name: 'San Martín',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080d' },
    countryCode: 'PG',
    name: 'Gulf',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083f' },
    countryCode: 'PH',
    name: 'Ifugao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050848' },
    countryCode: 'PH',
    name: 'Leyte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050862' },
    countryCode: 'PH',
    name: 'Southern Leyte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050868' },
    countryCode: 'PH',
    name: 'Tarlac',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086f' },
    countryCode: 'PK',
    name: 'Balochistan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050879' },
    countryCode: 'PL',
    name: 'Lubuskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050883' },
    countryCode: 'PL',
    name: 'Slaskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050891' },
    countryCode: 'PS',
    name: 'Ramallah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089b' },
    countryCode: 'PT',
    name: 'Coimbra',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a3' },
    countryCode: 'PT',
    name: 'Santarém',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e6' },
    countryCode: 'RO',
    name: 'Galati',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f8' },
    countryCode: 'RO',
    name: 'Timis',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f2' },
    countryCode: 'PE',
    name: 'Cajamarca',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f8' },
    countryCode: 'PE',
    name: 'Junín',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050800' },
    countryCode: 'PE',
    name: 'Pasco',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050812' },
    countryCode: 'PG',
    name: 'Madang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050817' },
    countryCode: 'PG',
    name: 'Bougainville',
    type: 'autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081c' },
    countryCode: 'PG',
    name: 'Western',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050826' },
    countryCode: 'PH',
    name: 'Bataan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082b' },
    countryCode: 'PH',
    name: 'Batangas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082c' },
    countryCode: 'PH',
    name: 'Batanes',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050830' },
    countryCode: 'PH',
    name: 'Camiguin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050842' },
    countryCode: 'PH',
    name: 'Ilocos Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050857' },
    countryCode: 'PH',
    name: 'Nueva Vizcaya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085d' },
    countryCode: 'PH',
    name: 'Rizal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050861' },
    countryCode: 'PH',
    name: 'Siquijor',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086b' },
    countryCode: 'PH',
    name: 'Zamboanga del Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086c' },
    countryCode: 'PH',
    name: 'Zamboanga del Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050874' },
    countryCode: 'PK',
    name: 'Punjab',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087f' },
    countryCode: 'PL',
    name: 'Podlaskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050881' },
    countryCode: 'PL',
    name: 'Pomorskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088f' },
    countryCode: 'PS',
    name: 'North Gaza',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089f' },
    countryCode: 'PT',
    name: 'Leiria',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b5' },
    countryCode: 'PW',
    name: 'Ngchesar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d5' },
    countryCode: 'RO',
    name: 'Arad',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508fb' },
    countryCode: 'RO',
    name: 'Vrancea',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508fe' },
    countryCode: 'RS',
    name: 'Severnobacki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ee' },
    countryCode: 'PE',
    name: 'Ancash',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ef' },
    countryCode: 'PE',
    name: 'Apurímac',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050818' },
    countryCode: 'PG',
    name: 'West Sepik',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050824' },
    countryCode: 'PH',
    name: 'Apayao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05082d' },
    countryCode: 'PH',
    name: 'Bukidnon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083c' },
    countryCode: 'PH',
    name: 'Kanlurang Dabaw',
    type: 'province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050849' },
    countryCode: 'PH',
    name: 'La Union',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050850' },
    countryCode: 'PH',
    name: 'Misamis Occidental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050851' },
    countryCode: 'PH',
    name: 'Misamis Oriental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050855' },
    countryCode: 'PH',
    name: 'Northern Samar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085c' },
    countryCode: 'PH',
    name: 'Quirino',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050864' },
    countryCode: 'PH',
    name: 'Sorsogon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ba' },
    countryCode: 'PY',
    name: 'Concepción',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508bb' },
    countryCode: 'PY',
    name: 'Alto Paraná',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508cb' },
    countryCode: 'PY',
    name: 'Asunción',
    type: 'Capital',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508cc' },
    countryCode: 'QA',
    name: 'Ad Dawhah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508db' },
    countryCode: 'RO',
    name: 'Botosani',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f4' },
    countryCode: 'RO',
    name: 'Salaj',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f5' },
    countryCode: 'RO',
    name: 'Satu Mare',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ff' },
    countryCode: 'RS',
    name: 'Srednjebanatski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050903' },
    countryCode: 'RS',
    name: 'Južnobacki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f0' },
    countryCode: 'PE',
    name: 'Arequipa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f1' },
    countryCode: 'PE',
    name: 'Ayacucho',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f3' },
    countryCode: 'PE',
    name: 'El Callao',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050835' },
    countryCode: 'PH',
    name: 'Cavite',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050847' },
    countryCode: 'PH',
    name: 'Lanao del Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084c' },
    countryCode: 'PH',
    name: 'Masbate',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084d' },
    countryCode: 'PH',
    name: 'Mindoro Occidental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050885' },
    countryCode: 'PL',
    name: 'Wielkopolskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050895' },
    countryCode: 'PS',
    name: 'Tulkarm',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508aa' },
    countryCode: 'PW',
    name: 'Aimeliik',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ab' },
    countryCode: 'PW',
    name: 'Airai',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b8' },
    countryCode: 'PW',
    name: 'Peleliu',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508bf' },
    countryCode: 'PY',
    name: 'Canindeyú',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c5' },
    countryCode: 'PY',
    name: 'Guairá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d6' },
    countryCode: 'RO',
    name: 'Bucuresti',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e1' },
    countryCode: 'RO',
    name: 'Constarta',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e4' },
    countryCode: 'RO',
    name: 'Dolj',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f1' },
    countryCode: 'RO',
    name: 'Olt',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050901' },
    countryCode: 'RS',
    name: 'Južnobanatski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ed' },
    countryCode: 'PE',
    name: 'Amazonas',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05081a' },
    countryCode: 'PG',
    name: 'West New Britain',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050829' },
    countryCode: 'PH',
    name: 'Biliran',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050834' },
    countryCode: 'PH',
    name: 'Catanduanes',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050858' },
    countryCode: 'PH',
    name: 'Pampanga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085b' },
    countryCode: 'PH',
    name: 'Quezon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087c' },
    countryCode: 'PL',
    name: 'Malopolskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087d' },
    countryCode: 'PL',
    name: 'Mazowieckie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050888' },
    countryCode: 'PS',
    name: 'Deir El Balah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050889' },
    countryCode: 'PS',
    name: 'Gaza',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050892' },
    countryCode: 'PS',
    name: 'Rafah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050894' },
    countryCode: 'PS',
    name: 'Tubas',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a5' },
    countryCode: 'PT',
    name: 'Viana do Castelo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b9' },
    countryCode: 'PW',
    name: 'Sonsorol',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508bc' },
    countryCode: 'PY',
    name: 'Central',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c7' },
    countryCode: 'PY',
    name: 'Caazapá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d2' },
    countryCode: 'QA',
    name: 'Az¸ Z¸a‘ayin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508da' },
    countryCode: 'RO',
    name: 'Braila',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f5' },
    countryCode: 'PE',
    name: 'Huánuco',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050801' },
    countryCode: 'PE',
    name: 'Piura',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050814' },
    countryCode: 'PG',
    name: 'National Capital District (Port Moresby)',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050815' },
    countryCode: 'PG',
    name: 'New Ireland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083a' },
    countryCode: 'PH',
    name: 'Davao del Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05083d' },
    countryCode: 'PH',
    name: 'Eastern Samar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050844' },
    countryCode: 'PH',
    name: 'Kalinga-Apayao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050856' },
    countryCode: 'PH',
    name: 'Nueva Ecija',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050884' },
    countryCode: 'PL',
    name: 'Warminsko-mazurskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088e' },
    countryCode: 'PS',
    name: 'Nablus',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050897' },
    countryCode: 'PT',
    name: 'Beja',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050899' },
    countryCode: 'PT',
    name: 'Bragança',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a2' },
    countryCode: 'PT',
    name: 'Porto',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ae' },
    countryCode: 'PW',
    name: 'Kayangel',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508af' },
    countryCode: 'PW',
    name: 'Koror',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c6' },
    countryCode: 'PY',
    name: 'Caaguazú',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d0' },
    countryCode: 'QA',
    name: 'Umm Salal',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d3' },
    countryCode: 'RO',
    name: 'Alba',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d7' },
    countryCode: 'RO',
    name: 'Bacau',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d9' },
    countryCode: 'RO',
    name: 'Bistrita-Nasaud',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508dd' },
    countryCode: 'RO',
    name: 'Buzau',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e8' },
    countryCode: 'RO',
    name: 'Hunedoara',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f2' },
    countryCode: 'RO',
    name: 'Prahova',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507fe' },
    countryCode: 'PE',
    name: 'Madre de Dios',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507ff' },
    countryCode: 'PE',
    name: 'Moquegua',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050802' },
    countryCode: 'PE',
    name: 'Puno',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050805' },
    countryCode: 'PE',
    name: 'Tumbes',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050806' },
    countryCode: 'PE',
    name: 'Ucayali',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050816' },
    countryCode: 'PG',
    name: 'Northern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050828' },
    countryCode: 'PH',
    name: 'Benguet',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050831' },
    countryCode: 'PH',
    name: 'Camarines Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050833' },
    countryCode: 'PH',
    name: 'Camarines Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050837' },
    countryCode: 'PH',
    name: 'Compostela Valley',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084b' },
    countryCode: 'PH',
    name: 'Maguindanao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050871' },
    countryCode: 'PK',
    name: 'Islamabad',
    type: 'Federal capital territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050873' },
    countryCode: 'PK',
    name: 'Khyber Pakhtunkhwa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050877' },
    countryCode: 'PL',
    name: 'Dolnoslaskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050878' },
    countryCode: 'PL',
    name: 'Kujawsko-pomorskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050893' },
    countryCode: 'PS',
    name: 'Salfit',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a7' },
    countryCode: 'PT',
    name: 'Viseu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508bd' },
    countryCode: 'PY',
    name: 'Ñeembucú',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508d8' },
    countryCode: 'RO',
    name: 'Bihor',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508eb' },
    countryCode: 'RO',
    name: 'Ialomita',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f0' },
    countryCode: 'RO',
    name: 'Neamt',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f6' },
    countryCode: 'PE',
    name: 'Huancavelica',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05084a' },
    countryCode: 'PH',
    name: 'Marinduque',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050852' },
    countryCode: 'PH',
    name: 'Kotabato',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050866' },
    countryCode: 'PH',
    name: 'Surigao del Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050875' },
    countryCode: 'PK',
    name: 'Sindh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050882' },
    countryCode: 'PL',
    name: 'Swietokrzyskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050886' },
    countryCode: 'PL',
    name: 'Zachodniopomorskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088a' },
    countryCode: 'PS',
    name: 'Hebron',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050898' },
    countryCode: 'PT',
    name: 'Braga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05089a' },
    countryCode: 'PT',
    name: 'Castelo Branco',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c3' },
    countryCode: 'PY',
    name: 'San Pedro',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c9' },
    countryCode: 'PY',
    name: 'Misiones',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050832' },
    countryCode: 'PH',
    name: 'Capiz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050841' },
    countryCode: 'PH',
    name: 'Ilocos Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050865' },
    countryCode: 'PH',
    name: 'Sultan Kudarat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050880' },
    countryCode: 'PL',
    name: 'Podkarpackie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05088d' },
    countryCode: 'PS',
    name: 'Khan Yunis',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a1' },
    countryCode: 'PT',
    name: 'Portalegre',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508b2' },
    countryCode: 'PW',
    name: 'Ngarchelong',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ca' },
    countryCode: 'PY',
    name: 'Paraguarí',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508cd' },
    countryCode: 'QA',
    name: 'Al Khawr wa adh Dhakhirah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ce' },
    countryCode: 'QA',
    name: 'Ash Shamal',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e5' },
    countryCode: 'RO',
    name: 'Gorj',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508e9' },
    countryCode: 'RO',
    name: 'Harghita',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f9' },
    countryCode: 'RO',
    name: 'Teleorman',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508fa' },
    countryCode: 'RO',
    name: 'Vâlcea',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050807' },
    countryCode: 'PG',
    name: 'Chimbu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050809' },
    countryCode: 'PG',
    name: 'East New Britain',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080b' },
    countryCode: 'PG',
    name: 'Enga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05080f' },
    countryCode: 'PG',
    name: 'Jiwaka',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050813' },
    countryCode: 'PG',
    name: 'Manus',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050839' },
    countryCode: 'PH',
    name: 'Davao del Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050843' },
    countryCode: 'PH',
    name: 'Isabela',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085a' },
    countryCode: 'PH',
    name: 'Palawan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05085e' },
    countryCode: 'PH',
    name: 'Romblon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050863' },
    countryCode: 'PH',
    name: 'Sulu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05086e' },
    countryCode: 'PH',
    name: 'Zamboanga Sibuguey [Zamboanga Sibugay]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087b' },
    countryCode: 'PL',
    name: 'Lubelskie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a6' },
    countryCode: 'PT',
    name: 'Vila Real',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508cf' },
    countryCode: 'QA',
    name: 'Ar Rayyan',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ea' },
    countryCode: 'RO',
    name: 'Ilfov',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508fc' },
    countryCode: 'RO',
    name: 'Vaslui',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507f7' },
    countryCode: 'PE',
    name: 'Ica',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507fa' },
    countryCode: 'PE',
    name: 'Lambayeque',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0507fc' },
    countryCode: 'PE',
    name: 'Lima hatun llaqta',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050810' },
    countryCode: 'PG',
    name: 'Milne Bay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050811' },
    countryCode: 'PG',
    name: 'Morobe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050820' },
    countryCode: 'PH',
    name: 'Agusan del Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050840' },
    countryCode: 'PH',
    name: 'Iloilo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050870' },
    countryCode: 'PK',
    name: 'Gilgit-Baltistan',
    type: 'Pakistan administrered area',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05087a' },
    countryCode: 'PL',
    name: 'Lódzkie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a0' },
    countryCode: 'PT',
    name: 'Lisboa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508a9' },
    countryCode: 'PT',
    name: 'Região Autónoma da Madeira',
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c0' },
    countryCode: 'PY',
    name: 'Presidente Hayes',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508c4' },
    countryCode: 'PY',
    name: 'Cordillera',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508ed' },
    countryCode: 'RO',
    name: 'Mehedinti',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0508f3' },
    countryCode: 'RO',
    name: 'Sibiu',
    type: 'Departments',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050906' },
    countryCode: 'RS',
    name: 'Kolubarski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090f' },
    countryCode: 'RS',
    name: 'Raški okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092f' },
    countryCode: 'RU',
    name: "Kamchatskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050946' },
    countryCode: 'RU',
    name: "Moskovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050959' },
    countryCode: 'RU',
    name: "Saratovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050972' },
    countryCode: 'RW',
    name: 'Nord',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050976' },
    countryCode: 'SA',
    name: 'Makkah al Mukarramah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050979' },
    countryCode: 'SA',
    name: 'AI Qasim',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098b' },
    countryCode: 'SB',
    name: 'Western',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098e' },
    countryCode: 'SC',
    name: 'Anse Étoile',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050991' },
    countryCode: 'SC',
    name: 'Baie Lazare',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ab' },
    countryCode: 'SD',
    name: 'Gharb Kurdufan',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c2' },
    countryCode: 'SE',
    name: 'Skåne län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050905' },
    countryCode: 'RS',
    name: 'Macvanski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090d' },
    countryCode: 'RS',
    name: 'Zlatiborski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050924' },
    countryCode: 'RU',
    name: "Belgorodskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094b' },
    countryCode: 'RU',
    name: "Nizhegorodskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096a' },
    countryCode: 'RU',
    name: "Vologodskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050977' },
    countryCode: 'SA',
    name: 'Al Madinah al Munawwarah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050980' },
    countryCode: 'SA',
    name: 'AI Jawf',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050999' },
    countryCode: 'SC',
    name: "Grand'Anse Praslin",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099f' },
    countryCode: 'SC',
    name: 'Pointe La Rue',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a3' },
    countryCode: 'SC',
    name: 'Lemamel',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a5' },
    countryCode: 'SD',
    name: 'Wasat Darfur Zalinjay',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ad' },
    countryCode: 'SD',
    name: 'Kassala',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b1' },
    countryCode: 'SD',
    name: 'An Nil al Azraq',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b4' },
    countryCode: 'SD',
    name: 'An Nil al Abya?',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b8' },
    countryCode: 'SE',
    name: 'Västerbottens län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c3' },
    countryCode: 'SE',
    name: 'Hallands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050904' },
    countryCode: 'RS',
    name: 'Sremski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050907' },
    countryCode: 'RS',
    name: 'Podunavski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090a' },
    countryCode: 'RS',
    name: 'Pomoravski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050920' },
    countryCode: 'RU',
    name: "Amurskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050923' },
    countryCode: 'RU',
    name: 'Bashkortostan, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050926' },
    countryCode: 'RU',
    name: 'Buryatiya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092a' },
    countryCode: 'RU',
    name: 'Chuvashskaya Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093c' },
    countryCode: 'RU',
    name: 'Komi, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093e' },
    countryCode: 'RU',
    name: 'Kareliya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050949' },
    countryCode: 'RU',
    name: 'Nenetskiy avtonomnyy okrug',
    type: 'Autonomous district',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050958' },
    countryCode: 'RU',
    name: "Samarskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095a' },
    countryCode: 'RU',
    name: 'Severnaya Osetiya, Respublika Alaniya] [Respublika Severnaya Osetiya-Alaniya]',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050969' },
    countryCode: 'RU',
    name: "Vladimirskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050970' },
    countryCode: 'RW',
    name: 'Ville de Kigali',
    type: 'city',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b5' },
    countryCode: 'SD',
    name: 'Al Ba?r al A?mar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c1' },
    countryCode: 'SE',
    name: 'Blekinge län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090b' },
    countryCode: 'RS',
    name: 'Borski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050941' },
    countryCode: 'RU',
    name: "Leningradskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094e' },
    countryCode: 'RU',
    name: "Orenburgskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050963' },
    countryCode: 'RU',
    name: "Tverskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050993' },
    countryCode: 'SC',
    name: 'Beau Vallon',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099c' },
    countryCode: 'SC',
    name: 'Mont Buxton',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099d' },
    countryCode: 'SC',
    name: 'Mont Fleuri',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ba' },
    countryCode: 'SE',
    name: 'Uppsala län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091b' },
    countryCode: 'RS',
    name: 'Kosovo-Metohija',
    type: 'Autonomous province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050927' },
    countryCode: 'RU',
    name: 'Chechenskaya Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092c' },
    countryCode: 'RU',
    name: 'Ingushskaya Respublika [Respublika Ingushetiya]',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050935' },
    countryCode: 'RU',
    name: "Kurganskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050943' },
    countryCode: 'RU',
    name: "Magadanskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050964' },
    countryCode: 'RU',
    name: 'Tyva, Respublika [Tuva]',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050966' },
    countryCode: 'RU',
    name: 'Udmurtskaya Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096b' },
    countryCode: 'RU',
    name: "Voronezhskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050978' },
    countryCode: 'SA',
    name: 'Ash Sharqiyah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097c' },
    countryCode: 'SA',
    name: 'AI Hudud ash Shamaliyah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c4' },
    countryCode: 'SE',
    name: 'Västra Götalands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092e' },
    countryCode: 'RU',
    name: "Ivanovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050931' },
    countryCode: 'RU',
    name: 'Karachayevo-Cherkesskaya Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093f' },
    countryCode: 'RU',
    name: "Kurskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050961' },
    countryCode: 'RU',
    name: "Tomskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096c' },
    countryCode: 'RU',
    name: 'Yamalo-Nenetskiy avtonomnyy okrug',
    type: 'Autonomous district',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096e' },
    countryCode: 'RU',
    name: "Yevreyskaya avtonomnaya oblast'",
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050971' },
    countryCode: 'RW',
    name: 'Est',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050975' },
    countryCode: 'SA',
    name: 'Ar Riyad',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050981' },
    countryCode: 'SA',
    name: "'Asir",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050983' },
    countryCode: 'SB',
    name: 'Choiseul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098f' },
    countryCode: 'SC',
    name: 'Au Cap',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509bd' },
    countryCode: 'SE',
    name: 'Jönköpings län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050912' },
    countryCode: 'RS',
    name: 'Toplicki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050918' },
    countryCode: 'RS',
    name: 'Prizrenski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091e' },
    countryCode: 'RU',
    name: 'Altay, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092b' },
    countryCode: 'RU',
    name: 'Dagestan, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050930' },
    countryCode: 'RU',
    name: 'Kabardino-Balkarskaya Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050936' },
    countryCode: 'RU',
    name: 'Khabarovskiy kray',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050951' },
    countryCode: 'RU',
    name: "Penzenskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050960' },
    countryCode: 'RU',
    name: "Tambovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096d' },
    countryCode: 'RU',
    name: "Yaroslavskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097e' },
    countryCode: 'SA',
    name: 'Najran',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050984' },
    countryCode: 'SB',
    name: 'Capital Territory (Honiara)',
    type: 'Capital territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a2' },
    countryCode: 'SC',
    name: 'Takamaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a6' },
    countryCode: 'SD',
    name: 'Sharq Darfur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a7' },
    countryCode: 'SD',
    name: 'Shamal Darfur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509bb' },
    countryCode: 'SE',
    name: 'Södermanlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050922' },
    countryCode: 'RU',
    name: "Astrakhanskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050939' },
    countryCode: 'RU',
    name: 'Khakasiya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050947' },
    countryCode: 'RU',
    name: 'Moskva',
    type: 'Autonomous city',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050948' },
    countryCode: 'RU',
    name: "Murmanskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050955' },
    countryCode: 'RU',
    name: "Ryazanskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050988' },
    countryCode: 'SB',
    name: 'Malaita',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050989' },
    countryCode: 'SB',
    name: 'Rennell and Bellona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050990' },
    countryCode: 'SC',
    name: 'Anse Royale',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a1' },
    countryCode: 'SC',
    name: 'Saint Louis',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050910' },
    countryCode: 'RS',
    name: 'Rasinski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050914' },
    countryCode: 'RS',
    name: 'Jablanicki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050915' },
    countryCode: 'RS',
    name: 'Pcinjski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050928' },
    countryCode: 'RU',
    name: "Chelyabinskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05092d' },
    countryCode: 'RU',
    name: "Irkutskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050933' },
    countryCode: 'RU',
    name: "Kemerovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050934' },
    countryCode: 'RU',
    name: 'Kaliningradskaya oblast,',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050945' },
    countryCode: 'RU',
    name: 'Mordoviya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094c' },
    countryCode: 'RU',
    name: "Novosibirskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095f' },
    countryCode: 'RU',
    name: 'Tatarstan, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050995' },
    countryCode: 'SC',
    name: 'Bel Ombre',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b0' },
    countryCode: 'SD',
    name: 'Janub Kurdufan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509bc' },
    countryCode: 'SE',
    name: 'Östergötlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093b' },
    countryCode: 'RU',
    name: "Kaluzhskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050942' },
    countryCode: 'RU',
    name: "Lipetskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050944' },
    countryCode: 'RU',
    name: 'Mariy El, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094d' },
    countryCode: 'RU',
    name: "Omskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094f' },
    countryCode: 'RU',
    name: "Orlovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050965' },
    countryCode: 'RU',
    name: "Tyumenskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097a' },
    countryCode: 'SA',
    name: "Ha'il",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050992' },
    countryCode: 'SC',
    name: 'Baie Sainte Anne',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050998' },
    countryCode: 'SC',
    name: "Grand'Anse Mahé",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099e' },
    countryCode: 'SC',
    name: 'Plaisance',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050908' },
    countryCode: 'RS',
    name: 'Branicevski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050921' },
    countryCode: 'RU',
    name: "Arkhangel'skaya oblast,",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050940' },
    countryCode: 'RU',
    name: 'Krasnoyarskiy kray',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050954' },
    countryCode: 'RU',
    name: "Rostovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050974' },
    countryCode: 'RW',
    name: 'Sud',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050987' },
    countryCode: 'SB',
    name: 'Makira-Ulawa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098c' },
    countryCode: 'SC',
    name: 'Anse aux Pins',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b6' },
    countryCode: 'SD',
    name: 'Sinnar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509bf' },
    countryCode: 'SE',
    name: 'Kalmar län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c0' },
    countryCode: 'SE',
    name: 'Gotlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090c' },
    countryCode: 'RS',
    name: 'Zajecarski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05090e' },
    countryCode: 'RS',
    name: 'Moravicki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050911' },
    countryCode: 'RS',
    name: 'Nišavski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050917' },
    countryCode: 'RS',
    name: 'Pecki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050919' },
    countryCode: 'RS',
    name: 'Kosovsko-Mitrovacki okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050925' },
    countryCode: 'RU',
    name: "Bryanskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050938' },
    countryCode: 'RU',
    name: "Kirovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093a' },
    countryCode: 'RU',
    name: 'Kalmykiya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050952' },
    countryCode: 'RU',
    name: 'Primorskiy kray',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095c' },
    countryCode: 'RU',
    name: 'Sankt-Peterburg',
    type: 'Autonomous city',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050985' },
    countryCode: 'SB',
    name: 'Guadalcanal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098a' },
    countryCode: 'SB',
    name: 'Temotu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050994' },
    countryCode: 'SC',
    name: 'Bel Air',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a9' },
    countryCode: 'SD',
    name: 'Gharb Darfur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b7' },
    countryCode: 'SE',
    name: 'Stockholms län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091f' },
    countryCode: 'RU',
    name: 'Altayskiy kray',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095d' },
    countryCode: 'RU',
    name: "Stavropol'skiy kray",
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050967' },
    countryCode: 'RU',
    name: "Ul'yanovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050986' },
    countryCode: 'SB',
    name: 'Isabel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05098d' },
    countryCode: 'SC',
    name: 'Anse Boileau',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099a' },
    countryCode: 'SC',
    name: 'La Digue',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b2' },
    countryCode: 'SD',
    name: 'Ash Shamaliyah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b9' },
    countryCode: 'SE',
    name: 'Norrbottens län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509be' },
    countryCode: 'SE',
    name: 'Kronoborgs län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050909' },
    countryCode: 'RS',
    name: 'Šumadijski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050913' },
    countryCode: 'RS',
    name: 'Pirotski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05093d' },
    countryCode: 'RU',
    name: "Kostromskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05094a' },
    countryCode: 'RU',
    name: "Novgorodskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050953' },
    countryCode: 'RU',
    name: "Pskovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050957' },
    countryCode: 'RU',
    name: "Sakhalinskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050962' },
    countryCode: 'RU',
    name: "Tul'skaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05096f' },
    countryCode: 'RU',
    name: "Zabaykal'skiy kray",
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050973' },
    countryCode: 'RW',
    name: 'Ouest',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097b' },
    countryCode: 'SA',
    name: 'Tabuk',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097d' },
    countryCode: 'SA',
    name: 'Jazan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050997' },
    countryCode: 'SC',
    name: 'Glacis',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05099b' },
    countryCode: 'SC',
    name: 'La Rivière Anglaise',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a4' },
    countryCode: 'SC',
    name: 'Ros Kaiman',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509aa' },
    countryCode: 'SD',
    name: 'Al Qadarif',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ac' },
    countryCode: 'SD',
    name: 'Al Jazirah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509af' },
    countryCode: 'SD',
    name: 'Shiamal Kurdufan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050916' },
    countryCode: 'RS',
    name: 'Kosovski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050929' },
    countryCode: 'RU',
    name: 'Chukotskiy avtonomnyy okrug',
    type: 'Autonomous district',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050932' },
    countryCode: 'RU',
    name: 'Krasnodarskiy kray',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050937' },
    countryCode: 'RU',
    name: 'Khanty-Mansiyskiy avtonomnyy okrug',
    type: 'Autonomous district',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050956' },
    countryCode: 'RU',
    name: 'Sakha, Respublika [Yakutiya]',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050968' },
    countryCode: 'RU',
    name: "Volgogradskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05097f' },
    countryCode: 'SA',
    name: 'AI Bahah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050996' },
    countryCode: 'SC',
    name: 'Cascade',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ae' },
    countryCode: 'SD',
    name: 'Al Khartum',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509b3' },
    countryCode: 'SD',
    name: 'Nahr an Nil',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091a' },
    countryCode: 'RS',
    name: 'Kosovsko-Pomoravski okrug',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091c' },
    countryCode: 'RS',
    name: 'Vojvodina',
    type: 'Autonomous province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05091d' },
    countryCode: 'RU',
    name: 'Adygeya, Respublika',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050950' },
    countryCode: 'RU',
    name: 'Perm',
    type: 'Administrative territory',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095b' },
    countryCode: 'RU',
    name: "Smolenskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc05095e' },
    countryCode: 'RU',
    name: "Sverdlovskaya oblast'",
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050982' },
    countryCode: 'SB',
    name: 'Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a0' },
    countryCode: 'SC',
    name: 'Port Glaud',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509a8' },
    countryCode: 'SD',
    name: 'Janub Darfur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a07' },
    countryCode: 'SI',
    name: 'Kranj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a13' },
    countryCode: 'SI',
    name: 'Logatec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a21' },
    countryCode: 'SI',
    name: 'Moravske Toplice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a49' },
    countryCode: 'SI',
    name: 'Šentilj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4e' },
    countryCode: 'SI',
    name: 'Škofljica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a52' },
    countryCode: 'SI',
    name: 'Štore',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a62' },
    countryCode: 'SI',
    name: 'Zavrc',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a71' },
    countryCode: 'SI',
    name: 'Hajdina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7b' },
    countryCode: 'SI',
    name: 'Miklavž na Dravskem polju',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8b' },
    countryCode: 'SI',
    name: 'Trnovska vas',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a90' },
    countryCode: 'SI',
    name: 'Žalec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a96' },
    countryCode: 'SI',
    name: 'Cirkulane',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa0' },
    countryCode: 'SI',
    name: 'Šmarješke Toplice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aaf' },
    countryCode: 'SK',
    name: 'Žilinský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aed' },
    countryCode: 'SS',
    name: 'Lakes',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af7' },
    countryCode: 'SV',
    name: 'La Libertad',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050afb' },
    countryCode: 'SV',
    name: 'San Miguel',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b03' },
    countryCode: 'SY',
    name: 'Dayr az Zawr',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b09' },
    countryCode: 'SY',
    name: 'AI Ladhiqiyah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c9' },
    countryCode: 'SE',
    name: 'Gävleborgs län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509de' },
    countryCode: 'SI',
    name: 'Celje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a11' },
    countryCode: 'SI',
    name: 'Ljubno',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1a' },
    countryCode: 'SI',
    name: 'Medvode',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a32' },
    countryCode: 'SI',
    name: 'Preddvor',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a48' },
    countryCode: 'SI',
    name: 'Šencur',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4f' },
    countryCode: 'SI',
    name: 'Šmarje pri Jelšah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a61' },
    countryCode: 'SI',
    name: 'Zagorje ob Savi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a66' },
    countryCode: 'SI',
    name: 'Benedikt',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a88' },
    countryCode: 'SI',
    name: 'Sveti Andraž v Slovenskih goricah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8d' },
    countryCode: 'SI',
    name: 'Velika Polana',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9f' },
    countryCode: 'SI',
    name: 'Sveti Tomaž',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa6' },
    countryCode: 'SI',
    name: 'Mirna',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab1' },
    countryCode: 'SL',
    name: 'Northern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050abf' },
    countryCode: 'SN',
    name: 'Fatick',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ace' },
    countryCode: 'SO',
    name: 'Bari',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae0' },
    countryCode: 'SR',
    name: 'Marowijne',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b10' },
    countryCode: 'TD',
    name: 'Ba?r al Ghazal',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b20' },
    countryCode: 'TD',
    name: 'Ouaddaï',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b27' },
    countryCode: 'TG',
    name: 'Kara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e5' },
    countryCode: 'SI',
    name: 'Destrnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f2' },
    countryCode: 'SI',
    name: 'Gornji Petrovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0f' },
    countryCode: 'SI',
    name: 'Litija',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a15' },
    countryCode: 'SI',
    name: 'Loški Potok',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a36' },
    countryCode: 'SI',
    name: 'Radece',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3d' },
    countryCode: 'SI',
    name: 'Rogaška Slatina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a45' },
    countryCode: 'SI',
    name: 'Slovenske Konjice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5b' },
    countryCode: 'SI',
    name: 'Vipava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5c' },
    countryCode: 'SI',
    name: 'Vitanje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5f' },
    countryCode: 'SI',
    name: 'Vrhnika',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a67' },
    countryCode: 'SI',
    name: 'Bistrica ob Sotli',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a68' },
    countryCode: 'SI',
    name: 'Bloke',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9e' },
    countryCode: 'SI',
    name: 'Sveta Trojica v Slovenskih\r\n\r\nGoricah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa1' },
    countryCode: 'SI',
    name: 'Gorje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa8' },
    countryCode: 'SK',
    name: 'Banskobystrický kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aac' },
    countryCode: 'SK',
    name: 'Prešovský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac5' },
    countryCode: 'SN',
    name: 'Matam',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad9' },
    countryCode: 'SO',
    name: 'Shabeellaha Hoose',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050adc' },
    countryCode: 'SO',
    name: 'Woqooyi Galbeed',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af9' },
    countryCode: 'SV',
    name: 'La Paz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b13' },
    countryCode: 'TD',
    name: 'Ennedi-Est',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b24' },
    countryCode: 'TD',
    name: 'Tibasti',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d8' },
    countryCode: 'SI',
    name: 'Borovnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e7' },
    countryCode: 'SI',
    name: 'Dobrepolje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a18' },
    countryCode: 'SI',
    name: 'Majšperk',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2e' },
    countryCode: 'SI',
    name: 'Pivka',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a31' },
    countryCode: 'SI',
    name: 'Postojna',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6a' },
    countryCode: 'SI',
    name: 'Cankova',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6e' },
    countryCode: 'SI',
    name: 'Dobrovnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a84' },
    countryCode: 'SI',
    name: 'Selnica ob Dravi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa2' },
    countryCode: 'SI',
    name: 'Log-Dragomer',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab3' },
    countryCode: 'SL',
    name: 'Western Area (Freetown)',
    type: 'Area',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae8' },
    countryCode: 'SS',
    name: 'Western Bahr el Ghazal',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aee' },
    countryCode: 'SS',
    name: 'Upper Nile',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b18' },
    countryCode: 'TD',
    name: 'Lac',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b23' },
    countryCode: 'TD',
    name: 'Tandjilé',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509cf' },
    countryCode: 'SG',
    name: 'South East',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d9' },
    countryCode: 'SI',
    name: 'Bovec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f1' },
    countryCode: 'SI',
    name: 'Gornji Grad',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509fb' },
    countryCode: 'SI',
    name: 'Izola',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509fc' },
    countryCode: 'SI',
    name: 'Jesenice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a03' },
    countryCode: 'SI',
    name: 'Kocevje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a14' },
    countryCode: 'SI',
    name: 'Loška dolina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4a' },
    countryCode: 'SI',
    name: 'Šentjernej',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a60' },
    countryCode: 'SI',
    name: 'Vuzenica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a72' },
    countryCode: 'SI',
    name: 'Hoce-Slivnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7f' },
    countryCode: 'SI',
    name: 'Polzela',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a81' },
    countryCode: 'SI',
    name: 'Prevalje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a97' },
    countryCode: 'SI',
    name: 'Kosanjevica na Krki',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa5' },
    countryCode: 'SI',
    name: 'Šentrupert',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aad' },
    countryCode: 'SK',
    name: 'Trnavský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae7' },
    countryCode: 'SS',
    name: 'Northern Bahr el Ghazal',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aef' },
    countryCode: 'SS',
    name: 'Unity',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050afe' },
    countryCode: 'SV',
    name: 'San Vicente',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b19' },
    countryCode: 'TD',
    name: 'Logone-Occidental',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b22' },
    countryCode: 'TD',
    name: 'Sila',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d1' },
    countryCode: 'SH',
    name: 'Ascension',
    type: 'Geographical entity',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d6' },
    countryCode: 'SI',
    name: 'Bled',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d7' },
    countryCode: 'SI',
    name: 'Bohinj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ef' },
    countryCode: 'SI',
    name: 'Gorišnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f4' },
    countryCode: 'SI',
    name: 'Šalovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a24' },
    countryCode: 'SI',
    name: 'Muta',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a41' },
    countryCode: 'SI',
    name: 'Sevnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a50' },
    countryCode: 'SI',
    name: 'Šmartno ob Paki',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6f' },
    countryCode: 'SI',
    name: 'Dolenjske Toplice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a77' },
    countryCode: 'SI',
    name: 'Kostel',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a99' },
    countryCode: 'SI',
    name: 'Mokronog-Trebelno',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9c' },
    countryCode: 'SI',
    name: 'Središce ob Dravi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab4' },
    countryCode: 'SM',
    name: 'Acquaviva',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050abb' },
    countryCode: 'SM',
    name: 'Montegiardino',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae2' },
    countryCode: 'SR',
    name: 'Paramaribo',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae5' },
    countryCode: 'SR',
    name: 'Sipaliwini',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af2' },
    countryCode: 'ST',
    name: 'São Tomé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050afa' },
    countryCode: 'SV',
    name: 'Santa Ana',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050afc' },
    countryCode: 'SV',
    name: 'Sonsonate',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b01' },
    countryCode: 'SY',
    name: 'Dimashq',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0e' },
    countryCode: 'SY',
    name: 'Tartus',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b14' },
    countryCode: 'TD',
    name: 'Ennedi-Ouest',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1b' },
    countryCode: 'TD',
    name: 'Mandoul',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b25' },
    countryCode: 'TD',
    name: 'Wadi Fira',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c6' },
    countryCode: 'SE',
    name: 'Örebro län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d4' },
    countryCode: 'SI',
    name: 'Ajdovšcina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509df' },
    countryCode: 'SI',
    name: 'Cerklje na Gorenjskem',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f7' },
    countryCode: 'SI',
    name: 'Idrija',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a09' },
    countryCode: 'SI',
    name: 'Krško',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0a' },
    countryCode: 'SI',
    name: 'Kungota',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a19' },
    countryCode: 'SI',
    name: 'Maribor',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1d' },
    countryCode: 'SI',
    name: 'Mežica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a64' },
    countryCode: 'SI',
    name: 'Železniki',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a69' },
    countryCode: 'SI',
    name: 'Braslovce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6b' },
    countryCode: 'SI',
    name: 'Cerkvenjak',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a79' },
    countryCode: 'SI',
    name: 'Lovrenc na Pohorju',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a80' },
    countryCode: 'SI',
    name: 'Prebold',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa9' },
    countryCode: 'SK',
    name: 'Bratislavský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab9' },
    countryCode: 'SM',
    name: 'Borgo Maggiore',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aba' },
    countryCode: 'SM',
    name: 'San Marino',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad2' },
    countryCode: 'SO',
    name: 'Hiiraan',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad3' },
    countryCode: 'SO',
    name: 'Jubbada Dhexe',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad5' },
    countryCode: 'SO',
    name: 'Mudug',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050adf' },
    countryCode: 'SR',
    name: 'Coronie',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aeb' },
    countryCode: 'SS',
    name: 'Western Equatoria',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c7' },
    countryCode: 'SE',
    name: 'Västmanlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ce' },
    countryCode: 'SG',
    name: 'North West',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e0' },
    countryCode: 'SI',
    name: 'Cerknica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e4' },
    countryCode: 'SI',
    name: 'Crnomelj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509eb' },
    countryCode: 'SI',
    name: 'Dornava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509fe' },
    countryCode: 'SI',
    name: 'Kamnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a05' },
    countryCode: 'SI',
    name: 'Koper',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a10' },
    countryCode: 'SI',
    name: 'Ljubljana',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a16' },
    countryCode: 'SI',
    name: 'Luce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a25' },
    countryCode: 'SI',
    name: 'Naklo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3b' },
    countryCode: 'SI',
    name: 'Ribnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a59' },
    countryCode: 'SI',
    name: 'Velike Lašce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8e' },
    countryCode: 'SI',
    name: 'Veržej',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9a' },
    countryCode: 'SI',
    name: 'Poljcane',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9d' },
    countryCode: 'SI',
    name: 'Straža',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa3' },
    countryCode: 'SI',
    name: 'Recica ob Savinji',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab6' },
    countryCode: 'SM',
    name: 'Domagnano',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab8' },
    countryCode: 'SM',
    name: 'Fiorentino',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac0' },
    countryCode: 'SN',
    name: 'Kaffrine',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac8' },
    countryCode: 'SN',
    name: 'Tambacounda',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad0' },
    countryCode: 'SO',
    name: 'Galguduud',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae4' },
    countryCode: 'SR',
    name: 'Saramacca',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae6' },
    countryCode: 'SR',
    name: 'Wanica',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1c' },
    countryCode: 'TD',
    name: 'Moyen-Chari',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e1' },
    countryCode: 'SI',
    name: 'Cerkno',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e3' },
    countryCode: 'SI',
    name: 'Crna na Koroškem',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ea' },
    countryCode: 'SI',
    name: 'Domžale',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ee' },
    countryCode: 'SI',
    name: 'Gorenja vas-Poljane',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a01' },
    countryCode: 'SI',
    name: 'Kobarid',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a02' },
    countryCode: 'SI',
    name: 'Kobilje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a29' },
    countryCode: 'SI',
    name: 'Odranci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a43' },
    countryCode: 'SI',
    name: 'Slovenj Gradec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a44' },
    countryCode: 'SI',
    name: 'Slovenska Bistrica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a58' },
    countryCode: 'SI',
    name: 'Velenje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a76' },
    countryCode: 'SI',
    name: 'Komenda',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7c' },
    countryCode: 'SI',
    name: 'Mirna Pec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a83' },
    countryCode: 'SI',
    name: 'Ribnica na Pohorju',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a89' },
    countryCode: 'SI',
    name: 'Šempeter-Vrtojba',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aca' },
    countryCode: 'SN',
    name: 'Ziguinchor',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050acc' },
    countryCode: 'SO',
    name: 'Bakool',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050acf' },
    countryCode: 'SO',
    name: 'Bay',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad6' },
    countryCode: 'SO',
    name: 'Nugaal',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050add' },
    countryCode: 'SR',
    name: 'Brokopondo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af4' },
    countryCode: 'SV',
    name: 'Cabañas',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0d' },
    countryCode: 'SY',
    name: "As Suwayda'",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d2' },
    countryCode: 'SH',
    name: 'Saint Helena',
    type: 'Geographical entity',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509da' },
    countryCode: 'SI',
    name: 'Brda',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ff' },
    countryCode: 'SI',
    name: 'Kanal',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0e' },
    countryCode: 'SI',
    name: 'Lendava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1f' },
    countryCode: 'SI',
    name: 'Mislinja',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a28' },
    countryCode: 'SI',
    name: 'Novo mesto',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2c' },
    countryCode: 'SI',
    name: 'Pesnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3e' },
    countryCode: 'SI',
    name: 'Rogatec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a40' },
    countryCode: 'SI',
    name: 'Semic',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a42' },
    countryCode: 'SI',
    name: 'Sežana',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a46' },
    countryCode: 'SI',
    name: 'Starše',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4c' },
    countryCode: 'SI',
    name: 'Škocjan',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a55' },
    countryCode: 'SI',
    name: 'Trebnje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a63' },
    countryCode: 'SI',
    name: 'Zrece',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7a' },
    countryCode: 'SI',
    name: 'Markovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8f' },
    countryCode: 'SI',
    name: 'Vransko',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a92' },
    countryCode: 'SI',
    name: 'Žirovnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a98' },
    countryCode: 'SI',
    name: 'Makole',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aae' },
    countryCode: 'SK',
    name: 'Trenciansky kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad7' },
    countryCode: 'SO',
    name: 'Sanaag',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b21' },
    countryCode: 'TD',
    name: 'Salamat',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b26' },
    countryCode: 'TG',
    name: 'Centrale',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ca' },
    countryCode: 'SE',
    name: 'Västernorrlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509db' },
    countryCode: 'SI',
    name: 'Brezovica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509dd' },
    countryCode: 'SI',
    name: 'Tišina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f9' },
    countryCode: 'SI',
    name: 'Ilirska Bistrica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a04' },
    countryCode: 'SI',
    name: 'Komen',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a06' },
    countryCode: 'SI',
    name: 'Kozje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a17' },
    countryCode: 'SI',
    name: 'Lukovica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a20' },
    countryCode: 'SI',
    name: 'Moravce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a39' },
    countryCode: 'SI',
    name: 'Radovljica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5d' },
    countryCode: 'SI',
    name: 'Vodice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a75' },
    countryCode: 'SI',
    name: 'Jezersko',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a86' },
    countryCode: 'SI',
    name: 'Solcava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8a' },
    countryCode: 'SI',
    name: 'Tabor',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a94' },
    countryCode: 'SI',
    name: 'Šmartno pri Litiji',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac9' },
    countryCode: 'SN',
    name: 'Thiès',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050acb' },
    countryCode: 'SO',
    name: 'Awdal',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad8' },
    countryCode: 'SO',
    name: 'Shabeellaha Dhexe',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae3' },
    countryCode: 'SR',
    name: 'Para',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af1' },
    countryCode: 'ST',
    name: 'Príncipe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af5' },
    countryCode: 'SV',
    name: 'Chalatenango',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af6' },
    countryCode: 'SV',
    name: 'Cuscatlán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b05' },
    countryCode: 'SY',
    name: 'Hims',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b15' },
    countryCode: 'TD',
    name: 'Guéra',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c8' },
    countryCode: 'SE',
    name: 'Dalarnes län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e2' },
    countryCode: 'SI',
    name: 'Crenšovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f3' },
    countryCode: 'SI',
    name: 'Grosuplje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a23' },
    countryCode: 'SI',
    name: 'Murska Sobota',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2f' },
    countryCode: 'SI',
    name: 'Podcetrtek',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a38' },
    countryCode: 'SI',
    name: 'Radlje ob Dravi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4d' },
    countryCode: 'SI',
    name: 'Škofja Loka',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7d' },
    countryCode: 'SI',
    name: 'Oplotnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a82' },
    countryCode: 'SI',
    name: 'Razkrižje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a95' },
    countryCode: 'SI',
    name: 'Apace',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab2' },
    countryCode: 'SL',
    name: 'Southern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050abc' },
    countryCode: 'SM',
    name: 'Serravalle',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac3' },
    countryCode: 'SN',
    name: 'Kaolack',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad1' },
    countryCode: 'SO',
    name: 'Gedo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aea' },
    countryCode: 'SS',
    name: 'Eastern Equatoria',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0a' },
    countryCode: 'SY',
    name: 'AI Qunaytirah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b16' },
    countryCode: 'TD',
    name: 'Hadjer Lamis',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1a' },
    countryCode: 'TD',
    name: 'Logone-Oriental',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1d' },
    countryCode: 'TD',
    name: 'Mayo-Kebbi-Est',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1f' },
    countryCode: 'TD',
    name: 'Ville de Ndjamena',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509c5' },
    countryCode: 'SE',
    name: 'Värmlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509cd' },
    countryCode: 'SG',
    name: 'North East',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509dc' },
    countryCode: 'SI',
    name: 'Brežice',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ec' },
    countryCode: 'SI',
    name: 'Dravograd',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509ed' },
    countryCode: 'SI',
    name: 'Duplek',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f5' },
    countryCode: 'SI',
    name: 'Hrastnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f6' },
    countryCode: 'SI',
    name: 'Hrpelje-Kozina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f8' },
    countryCode: 'SI',
    name: 'Ig',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509fa' },
    countryCode: 'SI',
    name: 'Ivancna Gorica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a27' },
    countryCode: 'SI',
    name: 'Nova Gorica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2a' },
    countryCode: 'SI',
    name: 'Ormož',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a53' },
    countryCode: 'SI',
    name: 'Tolmin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5a' },
    countryCode: 'SI',
    name: 'Videm',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a5e' },
    countryCode: 'SI',
    name: 'Vojnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a65' },
    countryCode: 'SI',
    name: 'Žiri',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6c' },
    countryCode: 'SI',
    name: 'Dobje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a6d' },
    countryCode: 'SI',
    name: 'Dobrna',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a74' },
    countryCode: 'SI',
    name: 'Horjul',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a7e' },
    countryCode: 'SI',
    name: 'Podlehnik',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a87' },
    countryCode: 'SI',
    name: 'Sveta Ana',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa7' },
    countryCode: 'SI',
    name: 'Ankaran',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac1' },
    countryCode: 'SN',
    name: 'Kolda',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050acd' },
    countryCode: 'SO',
    name: 'Banaadir',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ad4' },
    countryCode: 'SO',
    name: 'Jubbada Hoose',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aec' },
    countryCode: 'SS',
    name: 'Jonglei',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af0' },
    countryCode: 'SS',
    name: 'Warrap',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af3' },
    countryCode: 'SV',
    name: 'Ahuachapán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b02' },
    countryCode: 'SY',
    name: "Dar'a",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b11' },
    countryCode: 'TD',
    name: 'Burku',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509cb' },
    countryCode: 'SE',
    name: 'Jämtlands län',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d0' },
    countryCode: 'SG',
    name: 'South West',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e8' },
    countryCode: 'SI',
    name: 'Dobrova-Polhov Gradec',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509f0' },
    countryCode: 'SI',
    name: 'Gornja Radgona',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0c' },
    countryCode: 'SI',
    name: 'Laško',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a26' },
    countryCode: 'SI',
    name: 'Nazarje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a37' },
    countryCode: 'SI',
    name: 'Radenci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3c' },
    countryCode: 'SI',
    name: 'Rogašovci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a51' },
    countryCode: 'SI',
    name: 'Šoštanj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a54' },
    countryCode: 'SI',
    name: 'Trbovlje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a56' },
    countryCode: 'SI',
    name: 'Tržic',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a57' },
    countryCode: 'SI',
    name: 'Turnišce',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a70' },
    countryCode: 'SI',
    name: 'Grad',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a73' },
    countryCode: 'SI',
    name: 'Hodoš',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a78' },
    countryCode: 'SI',
    name: 'Križevci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aa4' },
    countryCode: 'SI',
    name: 'Sveti Jurij v Slovenskih Goricah',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab0' },
    countryCode: 'SL',
    name: 'Eastern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050abe' },
    countryCode: 'SN',
    name: 'Dakar',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ade' },
    countryCode: 'SR',
    name: 'Commewijne',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050afd' },
    countryCode: 'SV',
    name: 'San Salvador',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b04' },
    countryCode: 'SY',
    name: 'AI Hasakah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b17' },
    countryCode: 'TD',
    name: 'Kanem',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509cc' },
    countryCode: 'SG',
    name: 'Central Singapore',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d3' },
    countryCode: 'SH',
    name: 'Tristan da Cunha',
    type: 'Geographical entity',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509d5' },
    countryCode: 'SI',
    name: 'Beltinci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e6' },
    countryCode: 'SI',
    name: 'Divaca',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509fd' },
    countryCode: 'SI',
    name: 'Juršinci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a00' },
    countryCode: 'SI',
    name: 'Kidricevo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0b' },
    countryCode: 'SI',
    name: 'Kuzma',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1b' },
    countryCode: 'SI',
    name: 'Mengeš',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1e' },
    countryCode: 'SI',
    name: 'Miren-Kostanjevica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2b' },
    countryCode: 'SI',
    name: 'Osilnica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a2d' },
    countryCode: 'SI',
    name: 'Piran',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a34' },
    countryCode: 'SI',
    name: 'Puconci',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3a' },
    countryCode: 'SI',
    name: 'Ravne na Koroškem',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a4b' },
    countryCode: 'SI',
    name: 'Šentjur',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a85' },
    countryCode: 'SI',
    name: 'Sodražica',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a91' },
    countryCode: 'SI',
    name: 'Žetale',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a93' },
    countryCode: 'SI',
    name: 'Žužemberk',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab5' },
    countryCode: 'SM',
    name: 'Chiesanuova',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ab7' },
    countryCode: 'SM',
    name: 'Faetano',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050abd' },
    countryCode: 'SN',
    name: 'Diourbel',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac2' },
    countryCode: 'SN',
    name: 'Kédougou',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac7' },
    countryCode: 'SN',
    name: 'Saint-Louis',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ada' },
    countryCode: 'SO',
    name: 'Sool',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050adb' },
    countryCode: 'SO',
    name: 'Togdheer',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050af8' },
    countryCode: 'SV',
    name: 'Morazán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0b' },
    countryCode: 'SY',
    name: 'Ar Raqqah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc0509e9' },
    countryCode: 'SI',
    name: 'Dol pri Ljubljani',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a08' },
    countryCode: 'SI',
    name: 'Kranjska Gora',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a0d' },
    countryCode: 'SI',
    name: 'Lenart',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a12' },
    countryCode: 'SI',
    name: 'Ljutomer',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a1c' },
    countryCode: 'SI',
    name: 'Metlika',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a22' },
    countryCode: 'SI',
    name: 'Mozirje',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a30' },
    countryCode: 'SI',
    name: 'Podvelka',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a33' },
    countryCode: 'SI',
    name: 'Ptuj',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a35' },
    countryCode: 'SI',
    name: 'Race-Fram',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a3f' },
    countryCode: 'SI',
    name: 'Ruše',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a47' },
    countryCode: 'SI',
    name: 'Sveti Jurij',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a8c' },
    countryCode: 'SI',
    name: 'Trzin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050a9b' },
    countryCode: 'SI',
    name: 'Renèe-Vogrsko',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aaa' },
    countryCode: 'SK',
    name: 'Košický kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aab' },
    countryCode: 'SK',
    name: 'Nitriansky kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac4' },
    countryCode: 'SN',
    name: 'Louga',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ac6' },
    countryCode: 'SN',
    name: 'Sédhiou',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae1' },
    countryCode: 'SR',
    name: 'Nickerie',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050ae9' },
    countryCode: 'SS',
    name: 'Central Equatoria',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050aff' },
    countryCode: 'SV',
    name: 'La Unión',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b00' },
    countryCode: 'SV',
    name: 'Usulután',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b06' },
    countryCode: 'SY',
    name: 'Halab',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b07' },
    countryCode: 'SY',
    name: 'Hamah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b08' },
    countryCode: 'SY',
    name: 'Idlib',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0c' },
    countryCode: 'SY',
    name: 'Rif Dimashq',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b0f' },
    countryCode: 'TD',
    name: 'Batha',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b12' },
    countryCode: 'TD',
    name: 'Chari-Baguirmi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b1e' },
    countryCode: 'TD',
    name: 'Mayo-Kebbi-Ouest',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff70' },
    countryCode: 'ES',
    name: 'Alicante / Alacant',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff71' },
    countryCode: 'ES',
    name: 'Albacete',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff79' },
    countryCode: 'ES',
    name: 'Cádiz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff73' },
    countryCode: 'ES',
    name: 'Ávila',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff75' },
    countryCode: 'ES',
    name: 'Badajoz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff78' },
    countryCode: 'ES',
    name: 'A Coruña  [La Coruña]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff76' },
    countryCode: 'ES',
    name: 'Biskaia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff77' },
    countryCode: 'ES',
    name: 'Burgos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff72' },
    countryCode: 'ES',
    name: 'Almería',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7a' },
    countryCode: 'ES',
    name: 'Cáceres',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff74' },
    countryCode: 'ES',
    name: 'Barcelona  [Barcelona]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff82' },
    countryCode: 'ES',
    name: 'Granada',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff95' },
    countryCode: 'ES',
    name: 'Cantabria',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa1' },
    countryCode: 'ES',
    name: 'Álava / Araba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa3' },
    countryCode: 'ES',
    name: 'Zamora',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb0' },
    countryCode: 'FI',
    name: 'Etelä-Karjala',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffbd' },
    countryCode: 'FI',
    name: 'Pohjois-Savo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff92' },
    countryCode: 'ES',
    name: 'Palencia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb6' },
    countryCode: 'FI',
    name: 'Keski-Suomi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb9' },
    countryCode: 'FI',
    name: 'Pirkanmaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffbe' },
    countryCode: 'FI',
    name: 'Päijät-Häme',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff83' },
    countryCode: 'ES',
    name: 'Guadalajara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7f' },
    countryCode: 'ES',
    name: 'Cuenca',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa8' },
    countryCode: 'ET',
    name: 'Dire Dawa',
    type: 'Administration',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffad' },
    countryCode: 'ET',
    name: 'Sumale',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff89' },
    countryCode: 'ES',
    name: 'La Rioja',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8b' },
    countryCode: 'ES',
    name: 'Madrid',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8d' },
    countryCode: 'ES',
    name: 'Melilla',
    type: 'Autonomous city in North Africa',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff98' },
    countryCode: 'ES',
    name: 'Segovia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9a' },
    countryCode: 'ES',
    name: 'Gipuzkoa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffaa' },
    countryCode: 'ET',
    name: 'Hareri Hizb',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb3' },
    countryCode: 'FI',
    name: 'Kainuu',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffbf' },
    countryCode: 'FI',
    name: 'Satakunta',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff80' },
    countryCode: 'ES',
    name: 'Las Palmas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff93' },
    countryCode: 'ES',
    name: 'Balears  [Baleares]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff94' },
    countryCode: 'ES',
    name: 'Pontevedra  [Pontevedra]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9d' },
    countryCode: 'ES',
    name: 'Santa Cruz de Tenerife',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa6' },
    countryCode: 'ET',
    name: 'Amara',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffbc' },
    countryCode: 'FI',
    name: 'Pohjois-Pohjanmaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff99' },
    countryCode: 'ES',
    name: 'Soria',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9e' },
    countryCode: 'ES',
    name: 'Toledo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffac' },
    countryCode: 'ET',
    name: 'YeDebub Biheroch Bihereseboch na Hizboch',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff91' },
    countryCode: 'ES',
    name: 'Ourense  [Orense]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff81' },
    countryCode: 'ES',
    name: 'Girona  [Gerona]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffaf' },
    countryCode: 'FI',
    name: 'Ahvenanmaan maakunta',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb2' },
    countryCode: 'FI',
    name: 'Etelä-Savo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff86' },
    countryCode: 'ES',
    name: 'Jaén',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8c' },
    countryCode: 'ES',
    name: 'Málaga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9f' },
    countryCode: 'ES',
    name: 'Valencia / València',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9c' },
    countryCode: 'ES',
    name: 'Teruel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7e' },
    countryCode: 'ES',
    name: 'Castellón / Castelló',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffbb' },
    countryCode: 'FI',
    name: 'Pohjois-Karjala',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc1' },
    countryCode: 'FI',
    name: 'Varsinais-Suomi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff96' },
    countryCode: 'ES',
    name: 'Salamanca',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa2' },
    countryCode: 'ES',
    name: 'Zaragoza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa4' },
    countryCode: 'ET',
    name: 'Adis Abeba',
    type: 'Administration',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa5' },
    countryCode: 'ET',
    name: 'Afar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7c' },
    countryCode: 'ES',
    name: 'Córdoba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7b' },
    countryCode: 'ES',
    name: 'Ceuta',
    type: 'Autonomous city in North Africa',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff97' },
    countryCode: 'ES',
    name: 'Sevilla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb8' },
    countryCode: 'FI',
    name: 'Lappi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff7d' },
    countryCode: 'ES',
    name: 'Ciudad Real',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff85' },
    countryCode: 'ES',
    name: 'Huesca',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa0' },
    countryCode: 'ES',
    name: 'Valladolid',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff88' },
    countryCode: 'ES',
    name: 'León',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff84' },
    countryCode: 'ES',
    name: 'Huelva',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8f' },
    countryCode: 'ES',
    name: 'Navarra / Nafarroa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff9b' },
    countryCode: 'ES',
    name: 'Tarragona  [Tarragona]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa7' },
    countryCode: 'ET',
    name: 'Binshangul Gumuz',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb5' },
    countryCode: 'FI',
    name: 'Keski-Pohjanmaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffba' },
    countryCode: 'FI',
    name: 'Pohjanmaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8e' },
    countryCode: 'ES',
    name: 'Murcia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff90' },
    countryCode: 'ES',
    name: 'Asturias',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb4' },
    countryCode: 'FI',
    name: 'Kanta-Häme',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc0' },
    countryCode: 'FI',
    name: 'Uusimaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffab' },
    countryCode: 'ET',
    name: 'Oromiya',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff87' },
    countryCode: 'ES',
    name: 'Lleida  [Lérida]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffae' },
    countryCode: 'ET',
    name: 'Tigray',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff8a' },
    countryCode: 'ES',
    name: 'Lugo  [Lugo]',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffa9' },
    countryCode: 'ET',
    name: 'Gambela Hizboch',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb1' },
    countryCode: 'FI',
    name: 'Etelä-Pohjanmaa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffb7' },
    countryCode: 'FI',
    name: 'Kymenlaakso',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe9' },
    countryCode: 'FR',
    name: "Côtes-d'Armor",
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffef' },
    countryCode: 'FR',
    name: 'Eure-et-Loir',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050007' },
    countryCode: 'FR',
    name: 'Manche',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002f' },
    countryCode: 'FR',
    name: 'Territoire de Belfort',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050001' },
    countryCode: 'FR',
    name: 'Loire-Atlantique',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001b' },
    countryCode: 'FR',
    name: 'Haute-Saône',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001c' },
    countryCode: 'FR',
    name: 'Saône-et-Loire',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050035' },
    countryCode: 'GA',
    name: 'Estuaire',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003a' },
    countryCode: 'GA',
    name: 'Ogooué-Ivindo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050054' },
    countryCode: 'GB',
    name: 'Brighton and Hove',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050058' },
    countryCode: 'GB',
    name: 'Bracknell Forest',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050067' },
    countryCode: 'GB',
    name: 'Camden',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050068' },
    countryCode: 'GB',
    name: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd6' },
    countryCode: 'FR',
    name: 'Aisne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe2' },
    countryCode: 'FR',
    name: 'Calvados',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe3' },
    countryCode: 'FR',
    name: 'Cantal',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe6' },
    countryCode: 'FR',
    name: 'Cher',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffeb' },
    countryCode: 'FR',
    name: 'Dordogne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff5' },
    countryCode: 'FR',
    name: 'Gers',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050023' },
    countryCode: 'FR',
    name: 'Yvelines',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050046' },
    countryCode: 'GB',
    name: 'Armagh',
    type: 'District council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffcd' },
    countryCode: 'FJ',
    name: 'Rewa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fffd' },
    countryCode: 'FR',
    name: 'Landes',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050024' },
    countryCode: 'FR',
    name: 'Deux-Sèvres',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050039' },
    countryCode: 'GA',
    name: 'Nyanga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050045' },
    countryCode: 'GB',
    name: 'Angus',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004c' },
    countryCode: 'GB',
    name: 'Bexley',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050062' },
    countryCode: 'GB',
    name: 'Cheshire East',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050063' },
    countryCode: 'GB',
    name: 'Cheshire West and Chester',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc8' },
    countryCode: 'FJ',
    name: 'Macuata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe0' },
    countryCode: 'FR',
    name: 'Aveyron',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe7' },
    countryCode: 'FR',
    name: 'Corrèze',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffec' },
    countryCode: 'FR',
    name: 'Doubs',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffff' },
    countryCode: 'FR',
    name: 'Loire',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050003' },
    countryCode: 'FR',
    name: 'Lot',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050008' },
    countryCode: 'FR',
    name: 'Marne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050012' },
    countryCode: 'FR',
    name: 'Orne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002d' },
    countryCode: 'FR',
    name: 'Vosges',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050030' },
    countryCode: 'FR',
    name: 'Essonne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050050' },
    countryCode: 'GB',
    name: 'Birmingham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005e' },
    countryCode: 'GB',
    name: 'Caerphilly [Caerffili GB-CAF]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffce' },
    countryCode: 'FJ',
    name: 'Serua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff2' },
    countryCode: 'FR',
    name: 'Haute-Corse',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050009' },
    countryCode: 'FR',
    name: 'Haute-Marne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000f' },
    countryCode: 'FR',
    name: 'Nièvre',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050015' },
    countryCode: 'FR',
    name: 'Pyrénées-Atlantiques',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050017' },
    countryCode: 'FR',
    name: 'Pyrénées-Orientales',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050022' },
    countryCode: 'FR',
    name: 'Seine-et-Marne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050041' },
    countryCode: 'GB',
    name: 'Argyll and Bute',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004d' },
    countryCode: 'GB',
    name: 'Belfast',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004e' },
    countryCode: 'GB',
    name: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005d' },
    countryCode: 'GB',
    name: 'Cambridgeshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050066' },
    countryCode: 'GB',
    name: 'Cumbria',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc4' },
    countryCode: 'FJ',
    name: 'Cakaudrove',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc7' },
    countryCode: 'FJ',
    name: 'Lomaiviti',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe4' },
    countryCode: 'FR',
    name: 'Charente',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff0' },
    countryCode: 'FR',
    name: 'Finistère',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff8' },
    countryCode: 'FR',
    name: 'Ille-et-Vilaine',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050006' },
    countryCode: 'FR',
    name: 'Maine-et-Loire',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050016' },
    countryCode: 'FR',
    name: 'Hautes-Pyrénées',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001d' },
    countryCode: 'FR',
    name: 'Sarthe',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001f' },
    countryCode: 'FR',
    name: 'Haute-Savoie',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050021' },
    countryCode: 'FR',
    name: 'Seine-Maritime',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002e' },
    countryCode: 'FR',
    name: 'Yonne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050037' },
    countryCode: 'GA',
    name: 'Moyen-Ogooué',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050044' },
    countryCode: 'GB',
    name: 'Antrim and Newtownabbey',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050047' },
    countryCode: 'GB',
    name: 'Bath and North East Somerset',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050048' },
    countryCode: 'GB',
    name: 'Blackburn with Darwen',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050049' },
    countryCode: 'GB',
    name: 'Bedford',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050061' },
    countryCode: 'GB',
    name: 'Ceredigion [Sir Ceredigion]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc5' },
    countryCode: 'FJ',
    name: 'Kadavu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc9' },
    countryCode: 'FJ',
    name: 'Nadroga and Navosa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd5' },
    countryCode: 'FR',
    name: 'Ain',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd9' },
    countryCode: 'FR',
    name: 'Hautes-Alpes',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff6' },
    countryCode: 'FR',
    name: 'Gironde',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fffc' },
    countryCode: 'FR',
    name: 'Jura',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fffe' },
    countryCode: 'FR',
    name: 'Loir-et-Cher',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050010' },
    countryCode: 'FR',
    name: 'Nord',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050019' },
    countryCode: 'FR',
    name: 'Haut-Rhin',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050020' },
    countryCode: 'FR',
    name: 'Paris',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050027' },
    countryCode: 'FR',
    name: 'Tarn-et-Garonne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002b' },
    countryCode: 'FR',
    name: 'Vienne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050038' },
    countryCode: 'GA',
    name: 'Ngounié',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003c' },
    countryCode: 'GA',
    name: 'Ogooué-Maritime',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003d' },
    countryCode: 'GA',
    name: 'Woleu-Ntem',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004f' },
    countryCode: 'GB',
    name: 'Blaenau Gwent',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd4' },
    countryCode: 'FM',
    name: 'Yap',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffdd' },
    countryCode: 'FR',
    name: 'Ariège',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050013' },
    countryCode: 'FR',
    name: 'Pas-de-Calais',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050014' },
    countryCode: 'FR',
    name: 'Puy-de-Dôme',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050029' },
    countryCode: 'FR',
    name: 'Vaucluse',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050032' },
    countryCode: 'FR',
    name: 'Seine-Saint-Denis',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050069' },
    countryCode: 'GB',
    name: 'Cornwall',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006a' },
    countryCode: 'GB',
    name: 'Coventry',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006b' },
    countryCode: 'GB',
    name: 'Cardiff [Caerdydd GB-CRD]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc2' },
    countryCode: 'FJ',
    name: 'Ba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd2' },
    countryCode: 'FM',
    name: 'Pohnpei',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc3' },
    countryCode: 'FJ',
    name: 'Bua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffca' },
    countryCode: 'FJ',
    name: 'Naitasiri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffda' },
    countryCode: 'FR',
    name: 'Alpes-Maritimes',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050005' },
    countryCode: 'FR',
    name: 'Lozère',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002c' },
    countryCode: 'FR',
    name: 'Haute-Vienne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050064' },
    countryCode: 'GB',
    name: 'Calderdale',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050002' },
    countryCode: 'FR',
    name: 'Loiret',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000b' },
    countryCode: 'FR',
    name: 'Meurthe-et-Moselle',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001a' },
    countryCode: 'FR',
    name: 'Rhône',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050026' },
    countryCode: 'FR',
    name: 'Tarn',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05002a' },
    countryCode: 'FR',
    name: 'Vendée',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003f' },
    countryCode: 'GB',
    name: 'Aberdeenshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050052' },
    countryCode: 'GB',
    name: 'Bournemouth',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050057' },
    countryCode: 'GB',
    name: 'Blackpool',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050065' },
    countryCode: 'GB',
    name: 'Clackmannanshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffc6' },
    countryCode: 'FJ',
    name: 'Lau',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe1' },
    countryCode: 'FR',
    name: 'Bouches-du-Rhône',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050000' },
    countryCode: 'FR',
    name: 'Haute-Loire',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050025' },
    countryCode: 'FR',
    name: 'Somme',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050033' },
    countryCode: 'FR',
    name: 'Val-de-Marne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003b' },
    countryCode: 'GA',
    name: 'Ogooué-Lolo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050040' },
    countryCode: 'GB',
    name: 'Aberdeen City',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffcf' },
    countryCode: 'FJ',
    name: 'Tailevu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd8' },
    countryCode: 'FR',
    name: 'Alpes-de-Haute-Provence',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffde' },
    countryCode: 'FR',
    name: 'Aube',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffea' },
    countryCode: 'FR',
    name: 'Creuse',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff3' },
    countryCode: 'FR',
    name: 'Gard',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050011' },
    countryCode: 'FR',
    name: 'Oise',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050018' },
    countryCode: 'FR',
    name: 'Bas-Rhin',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050036' },
    countryCode: 'GA',
    name: 'Haut-Ogooué',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004a' },
    countryCode: 'GB',
    name: 'Barking and Dagenham',
    type: 'London borough',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050051' },
    countryCode: 'GB',
    name: 'Buckinghamshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050053' },
    countryCode: 'GB',
    name: 'Barnet',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050055' },
    countryCode: 'GB',
    name: 'Barnsley',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005a' },
    countryCode: 'GB',
    name: 'Bromley',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005c' },
    countryCode: 'GB',
    name: 'Bury',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffcc' },
    countryCode: 'FJ',
    name: 'Ra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff4' },
    countryCode: 'FR',
    name: 'Haute-Garonne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff9' },
    countryCode: 'FR',
    name: 'Indre',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050004' },
    countryCode: 'FR',
    name: 'Lot-et-Garonne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000c' },
    countryCode: 'FR',
    name: 'Meuse',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000d' },
    countryCode: 'FR',
    name: 'Morbihan',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000e' },
    countryCode: 'FR',
    name: 'Moselle',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050034' },
    countryCode: 'FR',
    name: "Val-d'Oise",
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05003e' },
    countryCode: 'GB',
    name: 'Armagh, Banbridge and Craigavon',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05004b' },
    countryCode: 'GB',
    name: 'Brent',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050059' },
    countryCode: 'GB',
    name: 'Bradford',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050060' },
    countryCode: 'GB',
    name: 'Causeway Coast and Glens',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffdb' },
    countryCode: 'FR',
    name: 'Ardèche',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffdc' },
    countryCode: 'FR',
    name: 'Ardennes',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffdf' },
    countryCode: 'FR',
    name: 'Aude',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe5' },
    countryCode: 'FR',
    name: 'Charente-Maritime',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffe8' },
    countryCode: 'FR',
    name: "Côte-d'Or",
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffee' },
    countryCode: 'FR',
    name: 'Eure',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff7' },
    countryCode: 'FR',
    name: 'Hérault',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05000a' },
    countryCode: 'FR',
    name: 'Mayenne',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050028' },
    countryCode: 'FR',
    name: 'Var',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050056' },
    countryCode: 'GB',
    name: 'Bolton',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005f' },
    countryCode: 'GB',
    name: 'Central Bedfordshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffcb' },
    countryCode: 'FJ',
    name: 'Namosi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd0' },
    countryCode: 'FJ',
    name: 'Rotuma',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd1' },
    countryCode: 'FM',
    name: 'Kosrae',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd3' },
    countryCode: 'FM',
    name: 'Chuuk',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffd7' },
    countryCode: 'FR',
    name: 'Allier',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ffed' },
    countryCode: 'FR',
    name: 'Drôme',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fff1' },
    countryCode: 'FR',
    name: 'Corse-du-Sud',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fffa' },
    countryCode: 'FR',
    name: 'Indre-et-Loire',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fffb' },
    countryCode: 'FR',
    name: 'Isère',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05001e' },
    countryCode: 'FR',
    name: 'Savoie',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050031' },
    countryCode: 'FR',
    name: 'Hauts-de-Seine',
    type: 'Metropolitan department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050042' },
    countryCode: 'GB',
    name: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050043' },
    countryCode: 'GB',
    name: 'Ards and North Down',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05005b' },
    countryCode: 'GB',
    name: 'Bristol, City of',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050083' },
    countryCode: 'GB',
    name: 'Essex',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050073' },
    countryCode: 'GB',
    name: 'Dumfries and Galloway',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008f' },
    countryCode: 'GB',
    name: 'Hampshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050077' },
    countryCode: 'GB',
    name: 'Derry and Strabane',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050070' },
    countryCode: 'GB',
    name: 'Denbighshire [Sir Ddinbych GB-DDB]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050071' },
    countryCode: 'GB',
    name: 'Derby',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050075' },
    countryCode: 'GB',
    name: 'Dundee City',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050080' },
    countryCode: 'GB',
    name: 'Enfield',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008a' },
    countryCode: 'GB',
    name: 'Glasgow City',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006e' },
    countryCode: 'GB',
    name: 'Darlington',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050074' },
    countryCode: 'GB',
    name: 'Doncaster',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007d' },
    countryCode: 'GB',
    name: 'East Dunbartonshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050072' },
    countryCode: 'GB',
    name: 'Devon',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008d' },
    countryCode: 'GB',
    name: 'Gwynedd',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007f' },
    countryCode: 'GB',
    name: 'Eilean Siar',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050078' },
    countryCode: 'GB',
    name: 'Dudley',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007a' },
    countryCode: 'GB',
    name: 'Ealing',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050087' },
    countryCode: 'GB',
    name: 'Flintshire [Sir y Fflint GB-FFL]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006d' },
    countryCode: 'GB',
    name: 'Conwy',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050076' },
    countryCode: 'GB',
    name: 'Dorset',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006f' },
    countryCode: 'GB',
    name: 'Derbyshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007e' },
    countryCode: 'GB',
    name: 'East Lothian',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050084' },
    countryCode: 'GB',
    name: 'East Sussex',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050085' },
    countryCode: 'GB',
    name: 'Falkirk',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008b' },
    countryCode: 'GB',
    name: 'Gloucestershire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008e' },
    countryCode: 'GB',
    name: 'Halton',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007b' },
    countryCode: 'GB',
    name: 'East Ayrshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05006c' },
    countryCode: 'GB',
    name: 'Croydon',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050082' },
    countryCode: 'GB',
    name: 'East Riding of Yorkshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050081' },
    countryCode: 'GB',
    name: 'East Renfrewshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050088' },
    countryCode: 'GB',
    name: 'Fermanagh and Omagh',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050089' },
    countryCode: 'GB',
    name: 'Gateshead',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050086' },
    countryCode: 'GB',
    name: 'Fife',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050079' },
    countryCode: 'GB',
    name: 'Durham',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05008c' },
    countryCode: 'GB',
    name: 'Greenwich',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05007c' },
    countryCode: 'GB',
    name: 'Edinburgh, City of',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500aa' },
    countryCode: 'GB',
    name: 'Leicestershire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009b' },
    countryCode: 'GB',
    name: 'Isles of Scilly',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a0' },
    countryCode: 'GB',
    name: 'Kent',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ad' },
    countryCode: 'GB',
    name: 'Liverpool',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050093' },
    countryCode: 'GB',
    name: 'Hillingdon',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ac' },
    countryCode: 'GB',
    name: 'Lincolnshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500bd' },
    countryCode: 'GB',
    name: 'North East Lincolnshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c5' },
    countryCode: 'GB',
    name: 'Northamptonshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009f' },
    countryCode: 'GB',
    name: 'Kensington and Chelsea',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a7' },
    countryCode: 'GB',
    name: 'Lambeth',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ab' },
    countryCode: 'GB',
    name: 'Lewisham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500bc' },
    countryCode: 'GB',
    name: 'Northumberland',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050090' },
    countryCode: 'GB',
    name: 'Havering',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500af' },
    countryCode: 'GB',
    name: 'Luton',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050096' },
    countryCode: 'GB',
    name: 'Hounslow',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a8' },
    countryCode: 'GB',
    name: 'Leicester',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b6' },
    countryCode: 'GB',
    name: 'Monmouthshire [Sir Fynwy GB-FYN]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a1' },
    countryCode: 'GB',
    name: 'Kingston upon Hull',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c0' },
    countryCode: 'GB',
    name: 'Nottingham',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b1' },
    countryCode: 'GB',
    name: 'Middlesbrough',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b5' },
    countryCode: 'GB',
    name: 'Midlothian',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c4' },
    countryCode: 'GB',
    name: 'North Somerset',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050095' },
    countryCode: 'GB',
    name: 'Hammersmith and Fulham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a4' },
    countryCode: 'GB',
    name: 'Knowsley',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050099' },
    countryCode: 'GB',
    name: 'Harrow',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b7' },
    countryCode: 'GB',
    name: 'Merton',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050098' },
    countryCode: 'GB',
    name: 'Hertfordshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009d' },
    countryCode: 'GB',
    name: 'Islington',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009a' },
    countryCode: 'GB',
    name: 'Haringey',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b2' },
    countryCode: 'GB',
    name: 'Medway',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b3' },
    countryCode: 'GB',
    name: 'Mid and East Antrim',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b0' },
    countryCode: 'GB',
    name: 'Manchester',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c1' },
    countryCode: 'GB',
    name: 'North Lanarkshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050092' },
    countryCode: 'GB',
    name: 'Herefordshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050094' },
    countryCode: 'GB',
    name: 'Highland',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050097' },
    countryCode: 'GB',
    name: 'Hartlepool',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a2' },
    countryCode: 'GB',
    name: 'Kirklees',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a6' },
    countryCode: 'GB',
    name: 'Lisburn and Castlereagh',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ae' },
    countryCode: 'GB',
    name: 'London, City of',
    type: 'City Corporation',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500bb' },
    countryCode: 'GB',
    name: 'North Ayrshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009e' },
    countryCode: 'GB',
    name: 'Inverclyde',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a3' },
    countryCode: 'GB',
    name: 'Kingston upon Thames',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b8' },
    countryCode: 'GB',
    name: 'Moray',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b9' },
    countryCode: 'GB',
    name: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c3' },
    countryCode: 'GB',
    name: 'Newry, Mourne and Down',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050091' },
    countryCode: 'GB',
    name: 'Hackney',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc05009c' },
    countryCode: 'GB',
    name: 'Isle of Wight',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a5' },
    countryCode: 'GB',
    name: 'Lancashire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500a9' },
    countryCode: 'GB',
    name: 'Leeds',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500b4' },
    countryCode: 'GB',
    name: 'Milton Keynes',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c2' },
    countryCode: 'GB',
    name: 'North Lincolnshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c6' },
    countryCode: 'GB',
    name: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ba' },
    countryCode: 'GB',
    name: 'Mid Ulster',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500be' },
    countryCode: 'GB',
    name: 'Newcastle upon Tyne',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500bf' },
    countryCode: 'GB',
    name: 'Norfolk',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d9' },
    countryCode: 'GB',
    name: 'Redbridge',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f7' },
    countryCode: 'GB',
    name: 'South Tyneside',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f0' },
    countryCode: 'GB',
    name: 'Surrey',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c8' },
    countryCode: 'GB',
    name: 'North Tyneside',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e3' },
    countryCode: 'GB',
    name: 'Sefton',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e4' },
    countryCode: 'GB',
    name: 'South Gloucestershire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e9' },
    countryCode: 'GB',
    name: 'Salford',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500cd' },
    countryCode: 'GB',
    name: 'Orkney Islands',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d1' },
    countryCode: 'GB',
    name: 'Plymouth',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d7' },
    countryCode: 'GB',
    name: 'Rochdale',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ea' },
    countryCode: 'GB',
    name: 'Slough',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500eb' },
    countryCode: 'GB',
    name: 'South Lanarkshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f4' },
    countryCode: 'GB',
    name: 'Sutton',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f6' },
    countryCode: 'GB',
    name: 'Stockton-on-Tees',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d5' },
    countryCode: 'GB',
    name: 'Peterborough',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e8' },
    countryCode: 'GB',
    name: 'Stockport',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ed' },
    countryCode: 'GB',
    name: 'Solihull',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f2' },
    countryCode: 'GB',
    name: 'Stirling',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c7' },
    countryCode: 'GB',
    name: 'Nottinghamshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ce' },
    countryCode: 'GB',
    name: 'Oxfordshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d6' },
    countryCode: 'GB',
    name: 'Redcar and Cleveland',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e1' },
    countryCode: 'GB',
    name: 'Scottish Borders, The',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f8' },
    countryCode: 'GB',
    name: 'Swansea [Abertawe GB-ATA]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500cc' },
    countryCode: 'GB',
    name: 'Oldham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d3' },
    countryCode: 'GB',
    name: 'Portsmouth',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500db' },
    countryCode: 'GB',
    name: 'Renfrewshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500df' },
    countryCode: 'GB',
    name: 'Sandwell',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ee' },
    countryCode: 'GB',
    name: 'Somerset',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f5' },
    countryCode: 'GB',
    name: 'Staffordshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500cf' },
    countryCode: 'GB',
    name: 'Pembrokeshire [Sir Benfro GB-BNF]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e0' },
    countryCode: 'GB',
    name: 'South Ayrshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e7' },
    countryCode: 'GB',
    name: 'Shropshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d2' },
    countryCode: 'GB',
    name: 'Poole',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500dc' },
    countryCode: 'GB',
    name: 'Richmond upon Thames',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e6' },
    countryCode: 'GB',
    name: 'St. Helens',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f1' },
    countryCode: 'GB',
    name: 'Stoke-on-Trent',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f3' },
    countryCode: 'GB',
    name: 'Southampton',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500c9' },
    countryCode: 'GB',
    name: 'Newham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e2' },
    countryCode: 'GB',
    name: 'Suffolk',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ec' },
    countryCode: 'GB',
    name: 'Sunderland',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ca' },
    countryCode: 'GB',
    name: 'Newport [Casnewydd GB-CNW]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500de' },
    countryCode: 'GB',
    name: 'Rutland',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500e5' },
    countryCode: 'GB',
    name: 'Sheffield',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ef' },
    countryCode: 'GB',
    name: 'Southend-on-Sea',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d0' },
    countryCode: 'GB',
    name: 'Perth and Kinross',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500dd' },
    countryCode: 'GB',
    name: 'Rotherham',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d4' },
    countryCode: 'GB',
    name: 'Powys',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500cb' },
    countryCode: 'GB',
    name: 'North Yorkshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500d8' },
    countryCode: 'GB',
    name: 'Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500da' },
    countryCode: 'GB',
    name: 'Reading',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500fd' },
    countryCode: 'GB',
    name: 'Thurrock',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500fa' },
    countryCode: 'GB',
    name: 'Southwark',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050100' },
    countryCode: 'GB',
    name: 'Trafford',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500fe' },
    countryCode: 'GB',
    name: 'Torbay',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050104' },
    countryCode: 'GB',
    name: 'West Berkshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500fc' },
    countryCode: 'GB',
    name: 'Telford and Wrekin',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500f9' },
    countryCode: 'GB',
    name: 'Swindon',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500ff' },
    countryCode: 'GB',
    name: 'Torfaen [Tor-faen]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050102' },
    countryCode: 'GB',
    name: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050101' },
    countryCode: 'GB',
    name: 'Tower Hamlets',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc050103' },
    countryCode: 'GB',
    name: 'Warwickshire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc0500fb' },
    countryCode: 'GB',
    name: 'Tameside',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050108' },
    countryCode: 'GB',
    name: 'Wiltshire',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050106' },
    countryCode: 'GB',
    name: 'Waltham Forest',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012a' },
    countryCode: 'GE',
    name: "T'bilisi",
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012b' },
    countryCode: 'GH',
    name: 'Greater Accra',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012d' },
    countryCode: 'GH',
    name: 'Brong-Ahafo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050148' },
    countryCode: 'GN',
    name: 'Forécariah',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050186' },
    countryCode: 'GT',
    name: 'Santa Rosa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050189' },
    countryCode: 'GT',
    name: 'Zacapa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050196' },
    countryCode: 'GY',
    name: 'East Berbice-Corentyne',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050198' },
    countryCode: 'GY',
    name: 'Mahaica-Berbice',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a7' },
    countryCode: 'HN',
    name: 'Intibucá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501af' },
    countryCode: 'HR',
    name: 'Zagrebacka županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010c' },
    countryCode: 'GB',
    name: 'Wolverhampton',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050112' },
    countryCode: 'GB',
    name: 'Warrington',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050149' },
    countryCode: 'GN',
    name: 'Fria',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050159' },
    countryCode: 'GN',
    name: 'Mamou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016f' },
    countryCode: 'GR',
    name: 'Attikí',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050173' },
    countryCode: 'GR',
    name: 'Kríti',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017e' },
    countryCode: 'GT',
    name: 'Petén',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018d' },
    countryCode: 'GW',
    name: 'Bissau',
    type: 'Autonomous sector',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050194' },
    countryCode: 'GY',
    name: 'Cuyuni-Mazaruni',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050199' },
    countryCode: 'GY',
    name: 'Pomeroon-Supenaam',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a5' },
    countryCode: 'HN',
    name: 'Gracias a Dios',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b1' },
    countryCode: 'HR',
    name: 'Sisacko-moslavacka županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b7' },
    countryCode: 'HR',
    name: 'Licko-senjska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c5' },
    countryCode: 'HT',
    name: 'Centre',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010d' },
    countryCode: 'GB',
    name: 'Wandsworth',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050131' },
    countryCode: 'GH',
    name: 'Volta',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050132' },
    countryCode: 'GH',
    name: 'Upper East',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050133' },
    countryCode: 'GH',
    name: 'Upper West',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013f' },
    countryCode: 'GN',
    name: 'Beyla',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014a' },
    countryCode: 'GN',
    name: 'Gaoual',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015e' },
    countryCode: 'GN',
    name: 'Tougué',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050175' },
    countryCode: 'GT',
    name: 'Baja Verapaz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050178' },
    countryCode: 'GT',
    name: 'Escuintla',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050192' },
    countryCode: 'GW',
    name: 'Tombali',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b3' },
    countryCode: 'HR',
    name: 'Varaždinska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c4' },
    countryCode: 'HT',
    name: 'Artibonite',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ca' },
    countryCode: 'HT',
    name: 'Nord-Ouest',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010a' },
    countryCode: 'GB',
    name: 'Walsall',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050118' },
    countryCode: 'GD',
    name: 'Saint Andrew',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013e' },
    countryCode: 'GM',
    name: 'Western',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050152' },
    countryCode: 'GN',
    name: 'Kissidougou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015a' },
    countryCode: 'GN',
    name: 'Nzérékoré',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050179' },
    countryCode: 'GT',
    name: 'Guatemala',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017b' },
    countryCode: 'GT',
    name: 'Izabal',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019e' },
    countryCode: 'HN',
    name: 'Choluteca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a6' },
    countryCode: 'HN',
    name: 'Islas de la Bahía',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ba' },
    countryCode: 'HR',
    name: 'Brodsko-posavska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501bb' },
    countryCode: 'HR',
    name: 'Zadarska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c9' },
    countryCode: 'HT',
    name: 'Nippes',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050111' },
    countryCode: 'GB',
    name: 'Wirral',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050122' },
    countryCode: 'GE',
    name: "Imeret'i",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050114' },
    countryCode: 'GB',
    name: 'Westminster',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050125' },
    countryCode: 'GE',
    name: "Mts'khet'a-Mt'ianet'i",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050134' },
    countryCode: 'GH',
    name: 'Western',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050135' },
    countryCode: 'GL',
    name: 'Kommune Kujalleq',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050136' },
    countryCode: 'GL',
    name: 'Qaasuitsup Kommunia',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013d' },
    countryCode: 'GM',
    name: 'Upper River',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050156' },
    countryCode: 'GN',
    name: 'Macenta',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a3' },
    countryCode: 'HN',
    name: 'El Paraíso',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b2' },
    countryCode: 'HR',
    name: 'Karlovacka županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b9' },
    countryCode: 'HR',
    name: 'Požeško-slavonska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c6' },
    countryCode: 'HT',
    name: 'Grande’Anse',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050126' },
    countryCode: 'GE',
    name: 'Racha-Lech’khumi-K’vemo Svanet’i',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050146' },
    countryCode: 'GN',
    name: 'Dubréka',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014c' },
    countryCode: 'GN',
    name: 'Kankan',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050158' },
    countryCode: 'GN',
    name: 'Mali',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b6' },
    countryCode: 'HR',
    name: 'Primorsko-goranska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c1' },
    countryCode: 'HR',
    name: 'Dubrovacko-neretvanska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010b' },
    countryCode: 'GB',
    name: 'West Lothian',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050109' },
    countryCode: 'GB',
    name: 'Wakefield',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010f' },
    countryCode: 'GB',
    name: 'Wokingham',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011c' },
    countryCode: 'GD',
    name: 'Saint Mark',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012c' },
    countryCode: 'GH',
    name: 'Ashanti',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050137' },
    countryCode: 'GL',
    name: 'Qeqqata Kommunia',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050147' },
    countryCode: 'GN',
    name: 'Faranah',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014d' },
    countryCode: 'GN',
    name: 'Koubia',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050162' },
    countryCode: 'GQ',
    name: 'Bioko Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050168' },
    countryCode: 'GR',
    name: 'Kentrikí Makedonía',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050184' },
    countryCode: 'GT',
    name: 'San Marcos',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ad' },
    countryCode: 'HN',
    name: 'Valle',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c0' },
    countryCode: 'HR',
    name: 'Istarska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c8' },
    countryCode: 'HT',
    name: 'Nord-Est',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050116' },
    countryCode: 'GB',
    name: 'York',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050120' },
    countryCode: 'GE',
    name: 'Ajaria',
    type: 'Autonomous republic',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050123' },
    countryCode: 'GE',
    name: "Kakhet'i",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050145' },
    countryCode: 'GN',
    name: 'Dalaba',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050165' },
    countryCode: 'GQ',
    name: 'Litoral',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050167' },
    countryCode: 'GR',
    name: 'Anatolikí Makedonía kai Thráki',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050169' },
    countryCode: 'GR',
    name: 'Dytiki Makedonia',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050174' },
    countryCode: 'GT',
    name: 'Alta Verapaz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018b' },
    countryCode: 'GW',
    name: 'Bolama',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ab' },
    countryCode: 'HN',
    name: 'Olancho',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050107' },
    countryCode: 'GB',
    name: 'Wigan',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013c' },
    countryCode: 'GM',
    name: 'North Bank',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050130' },
    countryCode: 'GH',
    name: 'Northern',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013a' },
    countryCode: 'GM',
    name: 'Lower River',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050143' },
    countryCode: 'GN',
    name: 'Dabola',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014e' },
    countryCode: 'GN',
    name: 'Kindia',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015d' },
    countryCode: 'GN',
    name: 'Télimélé',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016d' },
    countryCode: 'GR',
    name: 'Dytikí Elláda',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016e' },
    countryCode: 'GR',
    name: 'Stereá Elláda',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017d' },
    countryCode: 'GT',
    name: 'Jutiapa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050182' },
    countryCode: 'GT',
    name: 'Retalhuleu',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018e' },
    countryCode: 'GW',
    name: 'Cacheu',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019c' },
    countryCode: 'GY',
    name: 'Upper Takutu-Upper Essequibo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a2' },
    countryCode: 'HN',
    name: 'Cortés',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a9' },
    countryCode: 'HN',
    name: 'La Paz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501bf' },
    countryCode: 'HR',
    name: 'Splitsko-dalmatinska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050166' },
    countryCode: 'GQ',
    name: 'Wele-Nzas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050172' },
    countryCode: 'GR',
    name: 'Notío Aigaío',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050180' },
    countryCode: 'GT',
    name: 'Quiché',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019f' },
    countryCode: 'HN',
    name: 'Colón',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501aa' },
    countryCode: 'HN',
    name: 'Ocotepeque',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050110' },
    countryCode: 'GB',
    name: 'Worcestershire',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050124' },
    countryCode: 'GE',
    name: "K'vemo K'art'li",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050150' },
    countryCode: 'GN',
    name: 'Koundara',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050157' },
    countryCode: 'GN',
    name: 'Mandiana',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050171' },
    countryCode: 'GR',
    name: 'Voreío Aigaío',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050176' },
    countryCode: 'GT',
    name: 'Chimaltenango',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050187' },
    countryCode: 'GT',
    name: 'Suchitepéquez',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019a' },
    countryCode: 'GY',
    name: 'Potaro-Siparuni',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a8' },
    countryCode: 'HN',
    name: 'Lempira',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b8' },
    countryCode: 'HR',
    name: 'Viroviticko-podravska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050105' },
    countryCode: 'GB',
    name: 'West Dunbartonshire',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050115' },
    countryCode: 'GB',
    name: 'West Sussex',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050117' },
    countryCode: 'GB',
    name: 'Shetland Islands',
    type: 'Council area',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050119' },
    countryCode: 'GD',
    name: 'Saint David',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011b' },
    countryCode: 'GD',
    name: 'Saint John',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050128' },
    countryCode: 'GE',
    name: "Shida K'art'li",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050142' },
    countryCode: 'GN',
    name: 'Coyah',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050153' },
    countryCode: 'GN',
    name: 'Labé',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015c' },
    countryCode: 'GN',
    name: 'Siguiri',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016b' },
    countryCode: 'GR',
    name: 'Thessalía',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017a' },
    countryCode: 'GT',
    name: 'Huehuetenango',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017c' },
    countryCode: 'GT',
    name: 'Jalapa',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018f' },
    countryCode: 'GW',
    name: 'Gabú',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019b' },
    countryCode: 'GY',
    name: 'Upper Demerara-Berbice',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05019d' },
    countryCode: 'HN',
    name: 'Atlántida',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b5' },
    countryCode: 'HR',
    name: 'Bjelovarsko-bilogorska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501bd' },
    countryCode: 'HR',
    name: 'Šibensko-kninska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05010e' },
    countryCode: 'GB',
    name: 'Windsor and Maidenhead',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050127' },
    countryCode: 'GE',
    name: "Samts'khe-Javakhet'i",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050129' },
    countryCode: 'GE',
    name: "Samegrelo-Zemo Svanet'i",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050151' },
    countryCode: 'GN',
    name: 'Kouroussa',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015b' },
    countryCode: 'GN',
    name: 'Pita',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050160' },
    countryCode: 'GQ',
    name: 'Annobón',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050163' },
    countryCode: 'GQ',
    name: 'Centro Sur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016a' },
    countryCode: 'GR',
    name: 'Ípeiros',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05016c' },
    countryCode: 'GR',
    name: 'Ionía Nísia',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050170' },
    countryCode: 'GR',
    name: 'Peloponnísos',
    type: 'Administrative region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050181' },
    countryCode: 'GT',
    name: 'Quetzaltenango',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050183' },
    countryCode: 'GT',
    name: 'Sacatepéquez',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050190' },
    countryCode: 'GW',
    name: 'Oio',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a1' },
    countryCode: 'HN',
    name: 'Copán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a4' },
    countryCode: 'HN',
    name: 'Francisco Morazán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ae' },
    countryCode: 'HN',
    name: 'Yoro',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501be' },
    countryCode: 'HR',
    name: 'Vukovarsko-srijemska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c3' },
    countryCode: 'HR',
    name: 'Grad Zagreb',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050113' },
    countryCode: 'GB',
    name: 'Wrexham [Wrecsam GB-WRC]',
    type: 'Unitary authority',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011d' },
    countryCode: 'GD',
    name: 'Saint Patrick',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011e' },
    countryCode: 'GD',
    name: 'Southern Grenadine Islands',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050121' },
    countryCode: 'GE',
    name: 'Guria',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012f' },
    countryCode: 'GH',
    name: 'Eastern',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050139' },
    countryCode: 'GM',
    name: 'Banjul',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050141' },
    countryCode: 'GN',
    name: 'Boké',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014f' },
    countryCode: 'GN',
    name: 'Kérouané',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050155' },
    countryCode: 'GN',
    name: 'Lola',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050195' },
    countryCode: 'GY',
    name: 'Demerara-Mahaica',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011a' },
    countryCode: 'GD',
    name: 'Saint George',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05013b' },
    countryCode: 'GM',
    name: 'Central River',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05011f' },
    countryCode: 'GE',
    name: 'Abkhazia',
    type: 'Autonomous republic',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05012e' },
    countryCode: 'GH',
    name: 'Central',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050138' },
    countryCode: 'GL',
    name: 'Kommuneqarfik Sermersooq',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050140' },
    countryCode: 'GN',
    name: 'Boffa',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050144' },
    countryCode: 'GN',
    name: 'Dinguiraye',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050154' },
    countryCode: 'GN',
    name: 'Lélouma',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05015f' },
    countryCode: 'GN',
    name: 'Yomou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050161' },
    countryCode: 'GQ',
    name: 'Bioko Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050164' },
    countryCode: 'GQ',
    name: 'Kié-Ntem',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05017f' },
    countryCode: 'GT',
    name: 'El Progreso',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050188' },
    countryCode: 'GT',
    name: 'Totonicapán',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018c' },
    countryCode: 'GW',
    name: 'Biombo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050197' },
    countryCode: 'GY',
    name: 'Essequibo Islands-West Demerara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501a0' },
    countryCode: 'HN',
    name: 'Comayagua',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ac' },
    countryCode: 'HN',
    name: 'Santa Bárbara',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b0' },
    countryCode: 'HR',
    name: 'Krapinsko-zagorska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501b4' },
    countryCode: 'HR',
    name: 'Koprivnicko-križevacka županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c2' },
    countryCode: 'HR',
    name: 'Medimurska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501c7' },
    countryCode: 'HT',
    name: 'Nord',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05014b' },
    countryCode: 'GN',
    name: 'Guékédou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050177' },
    countryCode: 'GT',
    name: 'Chiquimula',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050185' },
    countryCode: 'GT',
    name: 'Sololá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05018a' },
    countryCode: 'GW',
    name: 'Bafatá',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050191' },
    countryCode: 'GW',
    name: 'Quinara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050193' },
    countryCode: 'GY',
    name: 'Barima-Waini',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501bc' },
    countryCode: 'HR',
    name: 'Osjecko-baranjska županija',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501dc' },
    countryCode: 'HU',
    name: 'Hajdú-Bihar',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050200' },
    countryCode: 'ID',
    name: 'Jawa Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e9' },
    countryCode: 'HU',
    name: 'Szeged',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f9' },
    countryCode: 'ID',
    name: 'Aceh',
    type: 'Autononous province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050206' },
    countryCode: 'ID',
    name: 'Kepulauan Riau',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020b' },
    countryCode: 'ID',
    name: 'Maluku',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020d' },
    countryCode: 'ID',
    name: 'Nusa Tenggara Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050210' },
    countryCode: 'ID',
    name: 'Papua Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021d' },
    countryCode: 'IE',
    name: 'Cork',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021f' },
    countryCode: 'IE',
    name: 'Dublin',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023d' },
    countryCode: 'IN',
    name: 'Arunachal Pradesh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025b' },
    countryCode: 'IN',
    name: 'Tripura',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029a' },
    countryCode: 'IT',
    name: 'Ancona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029f' },
    countryCode: 'IT',
    name: 'Asti',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b9' },
    countryCode: 'IT',
    name: 'Ferrara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ce' },
    countryCode: 'IT',
    name: 'Mantova',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e4' },
    countryCode: 'IT',
    name: 'Potenza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ed' },
    countryCode: 'IT',
    name: 'Salerno',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f7' },
    countryCode: 'IT',
    name: 'Torino',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050315' },
    countryCode: 'JO',
    name: 'Al ‘A¯simah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050316' },
    countryCode: 'JO',
    name: 'Al ‘Aqabah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050328' },
    countryCode: 'JP',
    name: 'Totigi [Tochigi]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032d' },
    countryCode: 'JP',
    name: 'Kanagawa',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050331' },
    countryCode: 'JP',
    name: 'Hukui [Fukui]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050345' },
    countryCode: 'JP',
    name: 'Ehime',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034d' },
    countryCode: 'JP',
    name: 'Kagosima [Kagoshima]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050351' },
    countryCode: 'KE',
    name: 'Bungoma',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050356' },
    countryCode: 'KE',
    name: 'Homa Bay',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050223' },
    countryCode: 'IE',
    name: 'Kilkenny',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050237' },
    countryCode: 'IL',
    name: 'Yerushalayim',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050245' },
    countryCode: 'IN',
    name: 'Goa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025f' },
    countryCode: 'IQ',
    name: 'AI Anbar',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050269' },
    countryCode: 'IQ',
    name: 'Maysan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027c' },
    countryCode: 'IR',
    name: 'Sistan va Baluchestan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a0' },
    countryCode: 'IT',
    name: 'Avellino',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a3' },
    countryCode: 'IT',
    name: 'Biella',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b6' },
    countryCode: 'IT',
    name: 'Catanzaro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d3' },
    countryCode: 'IT',
    name: 'Novara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e0' },
    countryCode: 'IT',
    name: 'Parma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f0' },
    countryCode: 'IT',
    name: 'La Spezia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050308' },
    countryCode: 'JM',
    name: 'Saint Thomas',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031a' },
    countryCode: 'JO',
    name: 'Irbid',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031d' },
    countryCode: 'JO',
    name: 'AI Mafraq',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050322' },
    countryCode: 'JP',
    name: 'Iwate',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032a' },
    countryCode: 'JP',
    name: 'Saitama',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032b' },
    countryCode: 'JP',
    name: 'Tiba [Chiba]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050337' },
    countryCode: 'JP',
    name: 'Mie',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050348' },
    countryCode: 'JP',
    name: 'Saga',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050353' },
    countryCode: 'KE',
    name: 'Elgeyo/Marakwet',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050355' },
    countryCode: 'KE',
    name: 'Garissa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d1' },
    countryCode: 'HU',
    name: 'Bács-Kiskun',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d2' },
    countryCode: 'HU',
    name: 'Budapest',
    type: 'Capital City',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e5' },
    countryCode: 'HU',
    name: 'Nógrád',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ef' },
    countryCode: 'HU',
    name: 'Szekszárd',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ff' },
    countryCode: 'ID',
    name: 'Jambi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050217' },
    countryCode: 'ID',
    name: 'Sumatera Selatan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050253' },
    countryCode: 'IN',
    name: 'Nagaland',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050265' },
    countryCode: 'IQ',
    name: 'Diyalá',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026f' },
    countryCode: 'IQ',
    name: 'As Sulaymaniyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050270' },
    countryCode: 'IQ',
    name: 'Wasit',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050272' },
    countryCode: 'IR',
    name: 'AZarbayjan-e Gharbi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050275' },
    countryCode: 'IR',
    name: 'Ilam',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050277' },
    countryCode: 'IR',
    name: 'Tehran',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027e' },
    countryCode: 'IR',
    name: 'Kerman',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050297' },
    countryCode: 'IS',
    name: 'Suðurland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b0' },
    countryCode: 'IT',
    name: 'Caltanissetta',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ba' },
    countryCode: 'IT',
    name: 'Foggia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c0' },
    countryCode: 'IT',
    name: 'Grosseto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502dd' },
    countryCode: 'IT',
    name: 'Pisa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e7' },
    countryCode: 'IT',
    name: 'Reggio Emilia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502eb' },
    countryCode: 'IT',
    name: 'Rimini',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ec' },
    countryCode: 'IT',
    name: 'Rovigo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f4' },
    countryCode: 'IT',
    name: 'Taranto',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050306' },
    countryCode: 'JM',
    name: 'Kingston',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030a' },
    countryCode: 'JM',
    name: 'Saint Mary',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d4' },
    countryCode: 'HU',
    name: 'Csongrád',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d5' },
    countryCode: 'HU',
    name: 'Debrecen',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501db' },
    countryCode: 'HU',
    name: 'Gyor',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e0' },
    countryCode: 'HU',
    name: 'Komárom-Esztergom',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050201' },
    countryCode: 'ID',
    name: 'Jawa Timur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050203' },
    countryCode: 'ID',
    name: 'Jawa Tengah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050215' },
    countryCode: 'ID',
    name: 'Sulawesi Selatan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022c' },
    countryCode: 'IE',
    name: 'Mayo',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050236' },
    countryCode: 'IL',
    name: 'H_efa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050248' },
    countryCode: 'IN',
    name: 'Haryana',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024e' },
    countryCode: 'IN',
    name: 'Maharashtra',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050251' },
    countryCode: 'IN',
    name: 'Madhya Pradesh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050260' },
    countryCode: 'IQ',
    name: 'Arbil',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026c' },
    countryCode: 'IQ',
    name: 'Ninawá',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026d' },
    countryCode: 'IQ',
    name: 'Al Qadisiyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027d' },
    countryCode: 'IR',
    name: 'Fars',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028f' },
    countryCode: 'IR',
    name: 'Alborz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050296' },
    countryCode: 'IS',
    name: 'Austurland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029d' },
    countryCode: 'IT',
    name: "L'Aquila",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c5' },
    countryCode: 'IT',
    name: 'Lecce',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502dc' },
    countryCode: 'IT',
    name: 'Perugia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e2' },
    countryCode: 'IT',
    name: 'Pesaro e Urbino',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ff' },
    countryCode: 'IT',
    name: 'Vercelli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050303' },
    countryCode: 'IT',
    name: 'Medio Campidano',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050304' },
    countryCode: 'IT',
    name: 'Viterbo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050307' },
    countryCode: 'JM',
    name: 'Saint Andrew',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050309' },
    countryCode: 'JM',
    name: 'Portland',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050319' },
    countryCode: 'JO',
    name: 'Al Balqa’',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031c' },
    countryCode: 'JO',
    name: 'AI Karak',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050321' },
    countryCode: 'JP',
    name: 'Aomori',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050324' },
    countryCode: 'JP',
    name: 'Akita',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050334' },
    countryCode: 'JP',
    name: 'Gihu [Gifu]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050336' },
    countryCode: 'JP',
    name: 'Aiti [Aichi]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050338' },
    countryCode: 'JP',
    name: 'Siga [Shiga]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050339' },
    countryCode: 'JP',
    name: 'Hyôgo [Kyoto]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050357' },
    countryCode: 'KE',
    name: 'Isiolo',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501dd' },
    countryCode: 'HU',
    name: 'Heves',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050205' },
    countryCode: 'ID',
    name: 'Kalimantan Timur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020e' },
    countryCode: 'ID',
    name: 'Nusa Tenggara Timur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050212' },
    countryCode: 'ID',
    name: 'Sulawesi Utara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050218' },
    countryCode: 'ID',
    name: 'Sulawesi Tengah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050231' },
    countryCode: 'IE',
    name: 'Waterford',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050238' },
    countryCode: 'IL',
    name: 'Al Awsat',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050244' },
    countryCode: 'IN',
    name: 'Dadra and Nagar Haveli',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026a' },
    countryCode: 'IQ',
    name: 'AI Muthanná',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050283' },
    countryCode: 'IR',
    name: 'Lorestan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050290' },
    countryCode: 'IS',
    name: 'Höfuðborgarsvæði utan Reykjavíkur',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a7' },
    countryCode: 'IT',
    name: 'Brindisi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a9' },
    countryCode: 'IT',
    name: 'Barletta-Andria-Trani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b1' },
    countryCode: 'IT',
    name: 'Cuneo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502bf' },
    countryCode: 'IT',
    name: 'Gorizia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d2' },
    countryCode: 'IT',
    name: 'Napoli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e8' },
    countryCode: 'IT',
    name: 'Ragusa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030b' },
    countryCode: 'JM',
    name: 'Saint Ann',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050314' },
    countryCode: 'JO',
    name: '‘Ajlun',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050317' },
    countryCode: 'JO',
    name: 'At Tafilah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050349' },
    countryCode: 'JP',
    name: 'Nagasaki',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501cf' },
    countryCode: 'HU',
    name: 'Békéscsaba',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e8' },
    countryCode: 'HU',
    name: 'Pécs',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ec' },
    countryCode: 'HU',
    name: 'Szolnok',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ee' },
    countryCode: 'HU',
    name: 'Somogy',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050202' },
    countryCode: 'ID',
    name: 'Jakarta Raya',
    type: 'Special district',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050258' },
    countryCode: 'IN',
    name: 'Sikkim',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050259' },
    countryCode: 'IN',
    name: 'Telangana',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026b' },
    countryCode: 'IQ',
    name: 'An Najaf',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a6' },
    countryCode: 'IT',
    name: 'Bologna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b5' },
    countryCode: 'IT',
    name: 'Catania',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502bd' },
    countryCode: 'IT',
    name: 'Frosinone',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c4' },
    countryCode: 'IT',
    name: 'Lecco',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502db' },
    countryCode: 'IT',
    name: 'Pescara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e6' },
    countryCode: 'IT',
    name: 'Reggio Calabria',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f2' },
    countryCode: 'IT',
    name: 'Sassari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f5' },
    countryCode: 'IT',
    name: 'Teramo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050313' },
    countryCode: 'JM',
    name: 'Saint Catherine',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050330' },
    countryCode: 'JP',
    name: 'Isikawa [Ishikawa]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501cb' },
    countryCode: 'HT',
    name: 'Ouest',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501cc' },
    countryCode: 'HT',
    name: 'Sud',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e2' },
    countryCode: 'HU',
    name: 'Kaposvár',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e3' },
    countryCode: 'HU',
    name: 'Miskolc',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e6' },
    countryCode: 'HU',
    name: 'Nyíregyháza',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f1' },
    countryCode: 'HU',
    name: 'Szabolcs-Szatmár-Bereg',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f4' },
    countryCode: 'HU',
    name: 'Vas',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050209' },
    countryCode: 'ID',
    name: 'Kalimantan Utara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021e' },
    countryCode: 'IE',
    name: 'Carlow',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050224' },
    countryCode: 'IE',
    name: 'Kerry',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050232' },
    countryCode: 'IE',
    name: 'Westmeath',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050242' },
    countryCode: 'IN',
    name: 'Daman and Diu',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024c' },
    countryCode: 'IN',
    name: 'Kerala',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050278' },
    countryCode: 'IR',
    name: 'Chahar Ma?al va Bakhtiari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050289' },
    countryCode: 'IR',
    name: 'Qom',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050298' },
    countryCode: 'IT',
    name: 'Agrigento',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050299' },
    countryCode: 'IT',
    name: 'Alessandria',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a1' },
    countryCode: 'IT',
    name: 'Bari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a5' },
    countryCode: 'IT',
    name: 'Benevento',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ac' },
    countryCode: 'IT',
    name: 'Campobasso',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b4' },
    countryCode: 'IT',
    name: 'Cosenza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c3' },
    countryCode: 'IT',
    name: 'Crotone',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f6' },
    countryCode: 'IT',
    name: 'Trento',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f9' },
    countryCode: 'IT',
    name: 'Terni',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050302' },
    countryCode: 'IT',
    name: 'Verona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050305' },
    countryCode: 'IT',
    name: 'Vibo Valentia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030c' },
    countryCode: 'JM',
    name: 'Trelawny',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050310' },
    countryCode: 'JM',
    name: 'Saint Elizabeth',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050312' },
    countryCode: 'JM',
    name: 'Clarendon',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050320' },
    countryCode: 'JP',
    name: 'Hokkaidô [Hokkaido]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050335' },
    countryCode: 'JP',
    name: 'Sizuoka [Shizuoka]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050341' },
    countryCode: 'JP',
    name: 'Hirosima [Hiroshima]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050342' },
    countryCode: 'JP',
    name: 'Yamaguti [Yamaguchi]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050352' },
    countryCode: 'KE',
    name: 'Busia',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501df' },
    countryCode: 'HU',
    name: 'Jász-Nagykun-Szolnok',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e4' },
    countryCode: 'HU',
    name: 'Nagykanizsa',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ea' },
    countryCode: 'HU',
    name: 'Székesfehérvár',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f3' },
    countryCode: 'HU',
    name: 'Tolna',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f8' },
    countryCode: 'HU',
    name: 'Zalaegerszeg',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501fb' },
    countryCode: 'ID',
    name: 'Kepulauan Bangka Belitung',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050204' },
    countryCode: 'ID',
    name: 'Kalimantan Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020c' },
    countryCode: 'ID',
    name: 'Maluku Utara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050225' },
    countryCode: 'IE',
    name: 'Longford',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050235' },
    countryCode: 'IL',
    name: 'Al Janubi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023e' },
    countryCode: 'IN',
    name: 'Assam',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05026e' },
    countryCode: 'IQ',
    name: 'Salah ad Din',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050285' },
    countryCode: 'IR',
    name: 'Markazi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029b' },
    countryCode: 'IT',
    name: 'Aosta / Aoste (fr)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029e' },
    countryCode: 'IT',
    name: 'Arezzo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ad' },
    countryCode: 'IT',
    name: 'Caserta',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502bb' },
    countryCode: 'IT',
    name: 'Firenze',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c9' },
    countryCode: 'IT',
    name: 'Lucca',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502cc' },
    countryCode: 'IT',
    name: 'Messina',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031b' },
    countryCode: 'JO',
    name: 'Jarash',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031e' },
    countryCode: 'JO',
    name: 'Madaba',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050333' },
    countryCode: 'JP',
    name: 'Nagano',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050344' },
    countryCode: 'JP',
    name: 'Kagawa',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021a' },
    countryCode: 'ID',
    name: 'Yogyakarta',
    type: 'Special region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ce' },
    countryCode: 'HU',
    name: 'Baranya',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050229' },
    countryCode: 'IE',
    name: 'Laois',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050233' },
    countryCode: 'IE',
    name: 'Wicklow',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024a' },
    countryCode: 'IN',
    name: 'Jammu and Kashmir',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050254' },
    countryCode: 'IN',
    name: 'Odisha',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050261' },
    countryCode: 'IQ',
    name: 'Al Basrah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050262' },
    countryCode: 'IQ',
    name: 'Babil',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050271' },
    countryCode: 'IR',
    name: 'AZarbayjan-e Sharqi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050279' },
    countryCode: 'IR',
    name: 'Khuzestan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027a' },
    countryCode: 'IR',
    name: 'Zanjan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027b' },
    countryCode: 'IR',
    name: 'Semnan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028a' },
    countryCode: 'IR',
    name: 'Golestan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028d' },
    countryCode: 'IR',
    name: 'Khorasan-e Ra?avi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050291' },
    countryCode: 'IS',
    name: 'Suðurnes',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050294' },
    countryCode: 'IS',
    name: 'Norðurland vestra',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a4' },
    countryCode: 'IT',
    name: 'Belluno',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a8' },
    countryCode: 'IT',
    name: 'Brescia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ab' },
    countryCode: 'IT',
    name: 'Cagliari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d5' },
    countryCode: 'IT',
    name: 'Ogliastra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e9' },
    countryCode: 'IT',
    name: 'Rieti',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ee' },
    countryCode: 'IT',
    name: 'Siena',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050323' },
    countryCode: 'JP',
    name: 'Miyagi',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033d' },
    countryCode: 'JP',
    name: 'Wakayama',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034a' },
    countryCode: 'JP',
    name: 'Kumamoto',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d0' },
    countryCode: 'HU',
    name: 'Békés',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501da' },
    countryCode: 'HU',
    name: 'Gyor-Moson-Sopron',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501ed' },
    countryCode: 'HU',
    name: 'Sopron',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f0' },
    countryCode: 'HU',
    name: 'Salgótarján',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f2' },
    countryCode: 'HU',
    name: 'Tatabánya',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501fc' },
    countryCode: 'ID',
    name: 'Bengkulu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020f' },
    countryCode: 'ID',
    name: 'Papua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050211' },
    countryCode: 'ID',
    name: 'Riau',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021b' },
    countryCode: 'IE',
    name: 'Clare',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050241' },
    countryCode: 'IN',
    name: 'Chhattisgarh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050246' },
    countryCode: 'IN',
    name: 'Gujarat',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024b' },
    countryCode: 'IN',
    name: 'Karnataka',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050250' },
    countryCode: 'IN',
    name: 'Manipur',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050257' },
    countryCode: 'IN',
    name: 'Rajasthan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050264' },
    countryCode: 'IQ',
    name: 'Dahuk',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050276' },
    countryCode: 'IR',
    name: 'Bushehr',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05027f' },
    countryCode: 'IR',
    name: 'Kordestan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028b' },
    countryCode: 'IR',
    name: 'Qazvin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050292' },
    countryCode: 'IS',
    name: 'Vesturland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050293' },
    countryCode: 'IS',
    name: 'Vestfirðir',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050295' },
    countryCode: 'IS',
    name: 'Norðurland eystra',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502aa' },
    countryCode: 'IT',
    name: 'Bolzano / Bozen (de)',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c2' },
    countryCode: 'IT',
    name: 'Isernia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ca' },
    countryCode: 'IT',
    name: 'Monza e Brianza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502cf' },
    countryCode: 'IT',
    name: 'Modena',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d0' },
    countryCode: 'IT',
    name: 'Massa-Carrara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502da' },
    countryCode: 'IT',
    name: 'Padova',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502fe' },
    countryCode: 'IT',
    name: 'Verbano-Cusio-Ossola',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e7' },
    countryCode: 'HU',
    name: 'Pest',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050213' },
    countryCode: 'ID',
    name: 'Sumatera Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050220' },
    countryCode: 'IE',
    name: 'Donegal',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050221' },
    countryCode: 'IE',
    name: 'Galway',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022d' },
    countryCode: 'IE',
    name: 'Offaly',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022f' },
    countryCode: 'IE',
    name: 'Sligo',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050234' },
    countryCode: 'IE',
    name: 'Wexford',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023f' },
    countryCode: 'IN',
    name: 'Bihar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050263' },
    countryCode: 'IQ',
    name: 'Baghdad',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050266' },
    countryCode: 'IQ',
    name: 'Dhi Qar',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050284' },
    countryCode: 'IR',
    name: 'Mazandaran',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05029c' },
    countryCode: 'IT',
    name: 'Ascoli Piceno',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502a2' },
    countryCode: 'IT',
    name: 'Bergamo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c7' },
    countryCode: 'IT',
    name: 'Lodi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d6' },
    countryCode: 'IT',
    name: 'Oristano',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e1' },
    countryCode: 'IT',
    name: 'Pistoia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e3' },
    countryCode: 'IT',
    name: 'Pavia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030f' },
    countryCode: 'JM',
    name: 'Westmoreland',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032c' },
    countryCode: 'JP',
    name: 'Tôkyô [Tokyo]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033f' },
    countryCode: 'JP',
    name: 'Simane [Shimane]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050340' },
    countryCode: 'JP',
    name: 'Okayama',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034b' },
    countryCode: 'JP',
    name: 'Ôita [Oita]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050350' },
    countryCode: 'KE',
    name: 'Bomet',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050354' },
    countryCode: 'KE',
    name: 'Embu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501cd' },
    countryCode: 'HT',
    name: 'Sud-Est',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501fd' },
    countryCode: 'ID',
    name: 'Banten',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050222' },
    countryCode: 'IE',
    name: 'Kildare',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050228' },
    countryCode: 'IE',
    name: 'Leitrim',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024d' },
    countryCode: 'IN',
    name: 'Lakshadweep',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05024f' },
    countryCode: 'IN',
    name: 'Meghalaya',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050252' },
    countryCode: 'IN',
    name: 'Mizoram',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050256' },
    countryCode: 'IN',
    name: 'Puducherry',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050267' },
    countryCode: 'IQ',
    name: "Karbala'",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050268' },
    countryCode: 'IQ',
    name: 'Kirkuk',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050280' },
    countryCode: 'IR',
    name: 'Kermanshah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b7' },
    countryCode: 'IT',
    name: 'Enna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d8' },
    countryCode: 'IT',
    name: 'Palermo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d9' },
    countryCode: 'IT',
    name: 'Piacenza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ea' },
    countryCode: 'IT',
    name: 'Roma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f3' },
    countryCode: 'IT',
    name: 'Savona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050325' },
    countryCode: 'JP',
    name: 'Yamagata',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033a' },
    countryCode: 'JP',
    name: 'Ôsaka [Osaka]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034e' },
    countryCode: 'JP',
    name: 'Okinawa',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d3' },
    countryCode: 'HU',
    name: 'Borsod-Abaúj-Zemplén',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d7' },
    countryCode: 'HU',
    name: 'Eger',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d9' },
    countryCode: 'HU',
    name: 'Fejér',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050208' },
    countryCode: 'ID',
    name: 'Kalimantan Tengah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050226' },
    countryCode: 'IE',
    name: 'Louth',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022e' },
    countryCode: 'IE',
    name: 'Roscommon',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050240' },
    countryCode: 'IN',
    name: 'Chandigarh',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050247' },
    countryCode: 'IN',
    name: 'Himachal Pradesh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025d' },
    countryCode: 'IN',
    name: 'Uttarakhand',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050273' },
    countryCode: 'IR',
    name: 'Ardabil',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050287' },
    countryCode: 'IR',
    name: 'Hamadan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050288' },
    countryCode: 'IR',
    name: 'Yazd',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c8' },
    countryCode: 'IT',
    name: 'Latina',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502cb' },
    countryCode: 'IT',
    name: 'Macerata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d7' },
    countryCode: 'IT',
    name: 'Olbia-Tempio',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ef' },
    countryCode: 'IT',
    name: 'Sondrio',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f1' },
    countryCode: 'IT',
    name: 'Siracusa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502fc' },
    countryCode: 'IT',
    name: 'Udine',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050311' },
    countryCode: 'JM',
    name: 'Manchester',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050327' },
    countryCode: 'JP',
    name: 'Ibaraki',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032e' },
    countryCode: 'JP',
    name: 'Niigata',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033e' },
    countryCode: 'JP',
    name: 'Tottori',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050346' },
    countryCode: 'JP',
    name: 'Kôti [Kochi]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034f' },
    countryCode: 'KE',
    name: 'Baringo',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501eb' },
    countryCode: 'HU',
    name: 'Szombathely',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f5' },
    countryCode: 'HU',
    name: 'Veszprém',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f7' },
    countryCode: 'HU',
    name: 'Zala',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501fa' },
    countryCode: 'ID',
    name: 'Bali',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501fe' },
    countryCode: 'ID',
    name: 'Gorontalo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050207' },
    countryCode: 'ID',
    name: 'Kalimantan Selatan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050216' },
    countryCode: 'ID',
    name: 'Sulawesi Barat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022b' },
    countryCode: 'IE',
    name: 'Monaghan',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050255' },
    countryCode: 'IN',
    name: 'Punjab',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050282' },
    countryCode: 'IR',
    name: 'Gilan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028c' },
    countryCode: 'IR',
    name: 'Khorasan-e Jonubi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502ae' },
    countryCode: 'IT',
    name: 'Chieti',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b2' },
    countryCode: 'IT',
    name: 'Como',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c6' },
    countryCode: 'IT',
    name: 'Livorno',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d1' },
    countryCode: 'IT',
    name: 'Matera',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050300' },
    countryCode: 'IT',
    name: 'Venezia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050332' },
    countryCode: 'JP',
    name: 'Yamanasi [Yamanashi]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033b' },
    countryCode: 'JP',
    name: 'Hyôgo[Hyogo]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05033c' },
    countryCode: 'JP',
    name: 'Nara',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050343' },
    countryCode: 'JP',
    name: 'Tokusima [Tokushima]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d8' },
    countryCode: 'HU',
    name: 'Érd',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501d6' },
    countryCode: 'HU',
    name: 'Dunaújváros',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501de' },
    countryCode: 'HU',
    name: 'Hódmezovásárhely',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501e1' },
    countryCode: 'HU',
    name: 'Kecskemét',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05020a' },
    countryCode: 'ID',
    name: 'Lampung',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050214' },
    countryCode: 'ID',
    name: 'Sulawesi Tenggara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023a' },
    countryCode: 'IL',
    name: 'Ash Shamali',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050249' },
    countryCode: 'IN',
    name: 'Jharkhand',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025a' },
    countryCode: 'IN',
    name: 'Tamil Nadu',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025e' },
    countryCode: 'IN',
    name: 'West Bengal',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05028e' },
    countryCode: 'IR',
    name: 'Khorasan-e Shomali',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502af' },
    countryCode: 'IT',
    name: 'Carbonia-Iglesias',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b3' },
    countryCode: 'IT',
    name: 'Cremona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502b8' },
    countryCode: 'IT',
    name: 'Forlì-Cesena',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502c1' },
    countryCode: 'IT',
    name: 'Imperia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502cd' },
    countryCode: 'IT',
    name: 'Milano',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502d4' },
    countryCode: 'IT',
    name: 'Nuoro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502fa' },
    countryCode: 'IT',
    name: 'Trieste',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502fd' },
    countryCode: 'IT',
    name: 'Varese',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050301' },
    countryCode: 'IT',
    name: 'Vicenza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050318' },
    countryCode: 'JO',
    name: 'Az Zarqa’',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05031f' },
    countryCode: 'JO',
    name: 'Ma‘an',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05034c' },
    countryCode: 'JP',
    name: 'Miyazaki',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0501f6' },
    countryCode: 'HU',
    name: 'Veszprém',
    type: 'City of county right',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050219' },
    countryCode: 'ID',
    name: 'Sumatera Utara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05021c' },
    countryCode: 'IE',
    name: 'Cavan',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050227' },
    countryCode: 'IE',
    name: 'Limerick',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05022a' },
    countryCode: 'IE',
    name: 'Meath',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050230' },
    countryCode: 'IE',
    name: 'Tipperary',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050239' },
    countryCode: 'IL',
    name: 'Tel-Aviv',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023b' },
    countryCode: 'IN',
    name: 'Andaman and Nicobar Islands',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05023c' },
    countryCode: 'IN',
    name: 'Andhra Pradesh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050243' },
    countryCode: 'IN',
    name: 'Delhi',
    type: 'Union territory',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05025c' },
    countryCode: 'IN',
    name: 'Uttar Pradesh',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050274' },
    countryCode: 'IR',
    name: 'Esfahan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050281' },
    countryCode: 'IR',
    name: 'Kohgiluyeh va Bowyer A?mad',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050286' },
    countryCode: 'IR',
    name: 'Hormozgan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502bc' },
    countryCode: 'IT',
    name: 'Fermo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502be' },
    countryCode: 'IT',
    name: 'Genova',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502de' },
    countryCode: 'IT',
    name: 'Pordenone',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502df' },
    countryCode: 'IT',
    name: 'Prato',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502e5' },
    countryCode: 'IT',
    name: 'Ravenna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502f8' },
    countryCode: 'IT',
    name: 'Trapani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc0502fb' },
    countryCode: 'IT',
    name: 'Treviso',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030d' },
    countryCode: 'JM',
    name: 'Saint James',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05030e' },
    countryCode: 'JM',
    name: 'Hanover',
    type: 'Partish',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050326' },
    countryCode: 'JP',
    name: 'Hukusima [Fukushima]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050329' },
    countryCode: 'JP',
    name: 'Gunma',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc05032f' },
    countryCode: 'JP',
    name: 'Toyama',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8d07cb0a71dbc050347' },
    countryCode: 'JP',
    name: 'Hukuoka [Fukuoka]',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8b' },
    countryCode: 'AD',
    name: 'Ordino',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8c' },
    countryCode: 'AD',
    name: 'Sant Julià de Lòria',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc9' },
    countryCode: 'AL',
    name: 'Shkodër',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd2' },
    countryCode: 'AM',
    name: 'Lo?i',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd5' },
    countryCode: 'AM',
    name: 'Tavuš',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd7' },
    countryCode: 'AO',
    name: 'Bengo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe8' },
    countryCode: 'AO',
    name: 'Zaire',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc14' },
    countryCode: 'AZ',
    name: 'Agcab?di',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc15' },
    countryCode: 'AZ',
    name: 'Agdam',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc16' },
    countryCode: 'AZ',
    name: 'Agdas',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc22' },
    countryCode: 'AZ',
    name: 'Dask?s?n',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc29' },
    countryCode: 'AZ',
    name: 'Haciqabul',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb96' },
    countryCode: 'AF',
    name: 'Balkh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb97' },
    countryCode: 'AF',
    name: 'Bamyan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba8' },
    countryCode: 'AF',
    name: 'Kuna?',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbae' },
    countryCode: 'AF',
    name: 'Panjshayr',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbbc' },
    countryCode: 'AG',
    name: 'Saint Peter',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc07' },
    countryCode: 'AT',
    name: 'Tirol',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1a' },
    countryCode: 'AZ',
    name: 'Bab?k',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9a' },
    countryCode: 'AF',
    name: 'Baghlan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb93' },
    countryCode: 'AE',
    name: 'Ra’s al Khaymah',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba6' },
    countryCode: 'AF',
    name: 'Kunduz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbbd' },
    countryCode: 'AG',
    name: 'Saint Philip',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbcd' },
    countryCode: 'AM',
    name: 'Ararat',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe4' },
    countryCode: 'AO',
    name: 'Malange',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbee' },
    countryCode: 'AR',
    name: 'La Rioja',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc02' },
    countryCode: 'AT',
    name: 'Kärnten',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba1' },
    countryCode: 'AF',
    name: 'Herat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc7' },
    countryCode: 'AL',
    name: 'Lezhë',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd6' },
    countryCode: 'AM',
    name: 'Vayoc Jor',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe1' },
    countryCode: 'AO',
    name: 'Lunda Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe3' },
    countryCode: 'AO',
    name: 'Luanda',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbeb' },
    countryCode: 'AR',
    name: 'Ciudad Autónoma de Buenos Aires',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf7' },
    countryCode: 'AR',
    name: 'Neuquén',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf8' },
    countryCode: 'AR',
    name: 'Río Negro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2a' },
    countryCode: 'AZ',
    name: 'Imisli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8d' },
    countryCode: 'AD',
    name: 'Andorra la Vella',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb89' },
    countryCode: 'AD',
    name: 'Encamp',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba4' },
    countryCode: 'AF',
    name: 'Kandahar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbab' },
    countryCode: 'AF',
    name: 'Nangarhar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc8' },
    countryCode: 'AL',
    name: 'Dibër',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd4' },
    countryCode: 'AM',
    name: "Syunik'",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0d' },
    countryCode: 'AU',
    name: 'Queensland',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc12' },
    countryCode: 'AZ',
    name: 'Abseron',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc17' },
    countryCode: 'AZ',
    name: 'Agsu',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc28' },
    countryCode: 'AZ',
    name: 'Göygöl',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb91' },
    countryCode: 'AE',
    name: 'Dubayy [Dubai]',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf3' },
    countryCode: 'AR',
    name: 'La Pampa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc01' },
    countryCode: 'AT',
    name: 'Burgenland',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc04' },
    countryCode: 'AT',
    name: 'Oberösterreich',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0a' },
    countryCode: 'AU',
    name: 'Australian Capital Territory',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc21' },
    countryCode: 'AZ',
    name: 'Culfa',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc26' },
    countryCode: 'AZ',
    name: 'Goranboy',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb90' },
    countryCode: 'AE',
    name: 'Abu Z¸aby [Abu Dhabi]',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9b' },
    countryCode: 'AF',
    name: 'Daykundi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9f' },
    countryCode: 'AF',
    name: 'Ghor',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba2' },
    countryCode: 'AF',
    name: 'Jowzjan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbac' },
    countryCode: 'AF',
    name: 'Nimroz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd0' },
    countryCode: 'AM',
    name: "Gegark'unik'",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd9' },
    countryCode: 'AO',
    name: 'Bié',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf9' },
    countryCode: 'AR',
    name: 'Santa Fe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc03' },
    countryCode: 'AT',
    name: 'Niederösterreich',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0b' },
    countryCode: 'AU',
    name: 'New South Wales',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc10' },
    countryCode: 'AU',
    name: 'Victoria',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc11' },
    countryCode: 'AU',
    name: 'Western Australia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb98' },
    countryCode: 'AF',
    name: 'Badghis',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9e' },
    countryCode: 'AF',
    name: 'Ghazni',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba3' },
    countryCode: 'AF',
    name: 'Kabul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb4' },
    countryCode: 'AF',
    name: 'Takhar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb5' },
    countryCode: 'AF',
    name: 'Uruzgan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb9' },
    countryCode: 'AG',
    name: 'Saint John',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe9' },
    countryCode: 'AR',
    name: 'Salta',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf4' },
    countryCode: 'AR',
    name: 'Mendoza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbfd' },
    countryCode: 'AR',
    name: 'Corrientes',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1b' },
    countryCode: 'AZ',
    name: 'Balak?n',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1d' },
    countryCode: 'AZ',
    name: 'Beyl?qan',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb88' },
    countryCode: 'AD',
    name: 'Canillo',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9d' },
    countryCode: 'AF',
    name: 'Faryab',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb95' },
    countryCode: 'AE',
    name: 'Umm al Qaywayn',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb99' },
    countryCode: 'AF',
    name: 'Badakhshan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba9' },
    countryCode: 'AF',
    name: 'Laghman',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbbb' },
    countryCode: 'AG',
    name: 'Saint Paul',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc4' },
    countryCode: 'AL',
    name: 'Gjirokastër',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbcf' },
    countryCode: 'AM',
    name: 'Erevan',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbda' },
    countryCode: 'AO',
    name: 'Cabinda',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbec' },
    countryCode: 'AR',
    name: 'San Luis',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf0' },
    countryCode: 'AR',
    name: 'Chaco',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc06' },
    countryCode: 'AT',
    name: 'Steiermark',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc08' },
    countryCode: 'AT',
    name: 'Vorarlberg',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc27' },
    countryCode: 'AZ',
    name: 'Göyçay',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbba' },
    countryCode: 'AG',
    name: 'Saint Mary',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc0' },
    countryCode: 'AL',
    name: 'Berat',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc2' },
    countryCode: 'AL',
    name: 'Elbasan',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbcc' },
    countryCode: 'AM',
    name: 'Aragac?otn',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbdc' },
    countryCode: 'AO',
    name: 'Cunene',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbed' },
    countryCode: 'AR',
    name: 'Entre Ríos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbfa' },
    countryCode: 'AR',
    name: 'Tucumán',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc00' },
    countryCode: 'AR',
    name: 'Santa Cruz',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb92' },
    countryCode: 'AE',
    name: 'Al Fujayrah',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb9c' },
    countryCode: 'AF',
    name: 'Farah',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb94' },
    countryCode: 'AE',
    name: 'Ash Shariqah [Sharjah]',
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba7' },
    countryCode: 'AF',
    name: 'Khost',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb0' },
    countryCode: 'AF',
    name: 'Paktiya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbce' },
    countryCode: 'AM',
    name: 'Armavir',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd1' },
    countryCode: 'AM',
    name: "Kotayk'",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe5' },
    countryCode: 'AO',
    name: 'Moxico',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe7' },
    countryCode: 'AO',
    name: 'Uíge',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf2' },
    countryCode: 'AR',
    name: 'Catamarca',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbfe' },
    countryCode: 'AR',
    name: 'Córdoba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0e' },
    countryCode: 'AU',
    name: 'South Australia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc13' },
    countryCode: 'AZ',
    name: 'Agstafa',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba0' },
    countryCode: 'AF',
    name: 'Helmand',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc1' },
    countryCode: 'AL',
    name: 'Durrës',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc5' },
    countryCode: 'AL',
    name: 'Korçë',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd3' },
    countryCode: 'AM',
    name: 'Širak',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbdd' },
    countryCode: 'AO',
    name: 'Kwanza Norte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbef' },
    countryCode: 'AR',
    name: 'Santiago del Estero',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc05' },
    countryCode: 'AT',
    name: 'Salzburg',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1f' },
    countryCode: 'AZ',
    name: 'C?brayil',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc25' },
    countryCode: 'AZ',
    name: 'G?d?b?y',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8a' },
    countryCode: 'AD',
    name: 'La Massana',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbaf' },
    countryCode: 'AF',
    name: 'Parwan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb2' },
    countryCode: 'AF',
    name: 'Samangan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbdb' },
    countryCode: 'AO',
    name: 'Kuando Kubango',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbde' },
    countryCode: 'AO',
    name: 'Kwanza Sul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbff' },
    countryCode: 'AR',
    name: 'Jujuy',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0c' },
    countryCode: 'AU',
    name: 'Northern Territory',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc0f' },
    countryCode: 'AU',
    name: 'Tasmania',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc18' },
    countryCode: 'AZ',
    name: 'Astara',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb1' },
    countryCode: 'AF',
    name: 'Paktika',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb3' },
    countryCode: 'AF',
    name: 'Sar-e Pul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb6' },
    countryCode: 'AF',
    name: 'Wardak',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc3' },
    countryCode: 'AL',
    name: 'Fier',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbca' },
    countryCode: 'AL',
    name: 'Tiranë',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbd8' },
    countryCode: 'AO',
    name: 'Benguela',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe0' },
    countryCode: 'AO',
    name: 'Huíla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf1' },
    countryCode: 'AR',
    name: 'San Juan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf5' },
    countryCode: 'AR',
    name: 'Misiones',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbfc' },
    countryCode: 'AR',
    name: 'Tierra del Fuego',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc19' },
    countryCode: 'AZ',
    name: 'Baki',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc23' },
    countryCode: 'AZ',
    name: 'Füzuli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8e' },
    countryCode: 'AD',
    name: 'Escaldes-Engordany',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fb8f' },
    countryCode: 'AE',
    name: "'Ajman",
    type: 'Emirate',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fba5' },
    countryCode: 'AF',
    name: 'Kapisa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbaa' },
    countryCode: 'AF',
    name: 'Logar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb8' },
    countryCode: 'AG',
    name: 'Saint George',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbbf' },
    countryCode: 'AG',
    name: 'Redonda',
    type: null,
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbc6' },
    countryCode: 'AL',
    name: 'Kukës',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbcb' },
    countryCode: 'AL',
    name: 'Vlorë',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe2' },
    countryCode: 'AO',
    name: 'Lunda Sul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbf6' },
    countryCode: 'AR',
    name: 'Formosa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbfb' },
    countryCode: 'AR',
    name: 'Chubut',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc09' },
    countryCode: 'AT',
    name: 'Wien',
    type: 'state',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1c' },
    countryCode: 'AZ',
    name: 'B?rd?',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc1e' },
    countryCode: 'AZ',
    name: 'Bil?suvar',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbad' },
    countryCode: 'AF',
    name: 'Nuristan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbb7' },
    countryCode: 'AF',
    name: 'Zabul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbbe' },
    countryCode: 'AG',
    name: 'Barbuda',
    type: 'Dependency',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbdf' },
    countryCode: 'AO',
    name: 'Huambo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbe6' },
    countryCode: 'AO',
    name: 'Namibe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fbea' },
    countryCode: 'AR',
    name: 'Buenos Aires',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8ce7cb0a71dbc04fc20' },
    countryCode: 'AZ',
    name: 'C?lilabad',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc24' },
    countryCode: 'AZ',
    name: 'G?nc?',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc34' },
    countryCode: 'AZ',
    name: 'Ming?çevir',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2c' },
    countryCode: 'AZ',
    name: 'K?lb?c?r',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc55' },
    countryCode: 'AZ',
    name: 'Xank?ndi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6f' },
    countryCode: 'BD',
    name: 'Barguna',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca8' },
    countryCode: 'BD',
    name: 'Sirajganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb7' },
    countryCode: 'BE',
    name: 'Luxembourg',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fccd' },
    countryCode: 'BF',
    name: 'Kourwéogo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcdf' },
    countryCode: 'BF',
    name: 'Tapoa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce0' },
    countryCode: 'BF',
    name: 'Tuy',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce4' },
    countryCode: 'BF',
    name: 'Zondoma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce9' },
    countryCode: 'BG',
    name: 'Veliko Tarnovo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcea' },
    countryCode: 'BG',
    name: 'Vidin',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcfd' },
    countryCode: 'BG',
    name: 'Stara Zagora',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd18' },
    countryCode: 'BJ',
    name: 'Atacora',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd23' },
    countryCode: 'BJ',
    name: 'Zou',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd24' },
    countryCode: 'BN',
    name: 'Belait',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4f' },
    countryCode: 'BS',
    name: 'Acklins',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd55' },
    countryCode: 'BS',
    name: 'Crooked Island and Long Cay',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd61' },
    countryCode: 'BS',
    name: 'Mayaguana',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6a' },
    countryCode: 'BS',
    name: 'South Abaco',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6b' },
    countryCode: 'BS',
    name: 'San Salvador',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd82' },
    countryCode: 'BW',
    name: 'Central',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8a' },
    countryCode: 'BW',
    name: 'Kweneng',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc89' },
    countryCode: 'BD',
    name: 'Kurigram',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc90' },
    countryCode: 'BD',
    name: 'Munshiganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc96' },
    countryCode: 'BD',
    name: 'Netrakona',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb6' },
    countryCode: 'BE',
    name: 'Liège',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc3' },
    countryCode: 'BF',
    name: 'Gourma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd6' },
    countryCode: 'BF',
    name: 'Oudalan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fced' },
    countryCode: 'BG',
    name: 'Dobrich',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf2' },
    countryCode: 'BG',
    name: 'Pazardzik',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf8' },
    countryCode: 'BG',
    name: 'Silistra',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd08' },
    countryCode: 'BI',
    name: 'Bujumbura Mairie',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0a' },
    countryCode: 'BI',
    name: 'Cankuzo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2a' },
    countryCode: 'BO',
    name: 'Chuquisaca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd30' },
    countryCode: 'BO',
    name: 'Tarija',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd64' },
    countryCode: 'BS',
    name: 'North Abaco',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7f' },
    countryCode: 'BT',
    name: 'Samdrup Jongkhar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd90' },
    countryCode: 'BW',
    name: 'Selibe Phikwe',
    type: 'Town',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2d' },
    countryCode: 'AZ',
    name: 'K?ng?rli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc47' },
    countryCode: 'AZ',
    name: 'Salyan',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc31' },
    countryCode: 'AZ',
    name: 'L?nk?ran',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc43' },
    countryCode: 'AZ',
    name: 'Sabirabad',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc46' },
    countryCode: 'AZ',
    name: 'S?ki',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc49' },
    countryCode: 'AZ',
    name: 'Saatli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc58' },
    countryCode: 'AZ',
    name: 'Xizi',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc70' },
    countryCode: 'BD',
    name: 'Bogra',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc84' },
    countryCode: 'BD',
    name: 'Jhenaidah',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc88' },
    countryCode: 'BD',
    name: 'Khulna',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9e' },
    countryCode: 'BD',
    name: 'Pabna',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc7' },
    countryCode: 'BF',
    name: 'Kénédougou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcdc' },
    countryCode: 'BF',
    name: 'Sanguié',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd04' },
    countryCode: 'BH',
    name: 'Al Mu?arraq',
    type: 'Governorat',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd49' },
    countryCode: 'BR',
    name: 'Roraima',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4e' },
    countryCode: 'BR',
    name: 'Tocantins',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7a' },
    countryCode: 'BT',
    name: 'Zhemgang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd87' },
    countryCode: 'BW',
    name: 'Jwaneng',
    type: 'Town',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd88' },
    countryCode: 'BW',
    name: 'Kgalagadi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd99' },
    countryCode: 'BZ',
    name: 'Belize',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda0' },
    countryCode: 'CA',
    name: 'British Columbia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc74' },
    countryCode: 'BD',
    name: 'Bhola',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8d' },
    countryCode: 'BD',
    name: 'Lalmonirhat',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc97' },
    countryCode: 'BD',
    name: 'Narsingdi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9b' },
    countryCode: 'BD',
    name: 'Nilphamari',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9d' },
    countryCode: 'BD',
    name: 'Naogaon',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcbd' },
    countryCode: 'BF',
    name: 'Bougouriba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc1' },
    countryCode: 'BF',
    name: 'Ganzourgou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fccc' },
    countryCode: 'BF',
    name: 'Kouritenga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd0' },
    countryCode: 'BF',
    name: 'Mouhoun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf0' },
    countryCode: 'BG',
    name: 'Lovech',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf7' },
    countryCode: 'BG',
    name: 'Ruse',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcfc' },
    countryCode: 'BG',
    name: 'Sofia',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0c' },
    countryCode: 'BI',
    name: 'Gitega',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1d' },
    countryCode: 'BJ',
    name: 'Donga',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd25' },
    countryCode: 'BN',
    name: 'Brunei-Muara',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2b' },
    countryCode: 'BO',
    name: 'La Paz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2d' },
    countryCode: 'BO',
    name: 'Oruro',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd47' },
    countryCode: 'BR',
    name: 'Rio Grande do Norte',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd63' },
    countryCode: 'BS',
    name: 'North Eleuthera',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd78' },
    countryCode: 'BT',
    name: 'Trongsa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7e' },
    countryCode: 'BT',
    name: 'Lhuentse',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8f' },
    countryCode: 'BW',
    name: 'Southern',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc38' },
    countryCode: 'AZ',
    name: 'Naxçivan',
    type: 'Autonomous republic',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2b' },
    countryCode: 'AZ',
    name: 'Ismayilli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3d' },
    countryCode: 'AZ',
    name: 'Qazax',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc36' },
    countryCode: 'AZ',
    name: 'Neftçala',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3f' },
    countryCode: 'AZ',
    name: 'Qubadli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5b' },
    countryCode: 'AZ',
    name: 'Yevlax',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4d' },
    countryCode: 'AZ',
    name: 'Sumqayit',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5d' },
    countryCode: 'AZ',
    name: 'Z?ngilan',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5c' },
    countryCode: 'AZ',
    name: 'Yevlax',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6a' },
    countryCode: 'BB',
    name: 'Saint Michael',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc76' },
    countryCode: 'BD',
    name: 'Chandpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc92' },
    countryCode: 'BD',
    name: 'Magura',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc86' },
    countryCode: 'BD',
    name: 'Jhalakathi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca2' },
    countryCode: 'BD',
    name: 'Rajbari',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8e' },
    countryCode: 'BD',
    name: 'Manikganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcac' },
    countryCode: 'BD',
    name: 'Tangail',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc4' },
    countryCode: 'BF',
    name: 'Houet',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb5' },
    countryCode: 'BE',
    name: 'Hainaut',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce7' },
    countryCode: 'BG',
    name: 'Burgas',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcba' },
    countryCode: 'BF',
    name: 'Bam',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf1' },
    countryCode: 'BG',
    name: 'Montana',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc0' },
    countryCode: 'BF',
    name: 'Comoé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcfb' },
    countryCode: 'BG',
    name: 'Sofia (stolitsa)',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc2' },
    countryCode: 'BF',
    name: 'Gnagna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd09' },
    countryCode: 'BI',
    name: 'Bururi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc6' },
    countryCode: 'BF',
    name: 'Kadiogo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0e' },
    countryCode: 'BI',
    name: 'Karuzi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcca' },
    countryCode: 'BF',
    name: 'Koulpélogo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcdd' },
    countryCode: 'BF',
    name: 'Soum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd38' },
    countryCode: 'BR',
    name: 'Bahia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce2' },
    countryCode: 'BF',
    name: 'Yatenga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3d' },
    countryCode: 'BR',
    name: 'Maranhão',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fceb' },
    countryCode: 'BG',
    name: 'Vratsa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf6' },
    countryCode: 'BG',
    name: 'Razgrad',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd46' },
    countryCode: 'BR',
    name: 'Rio de Janeiro',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcfe' },
    countryCode: 'BG',
    name: 'Targovishte',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd52' },
    countryCode: 'BS',
    name: 'Berry Islands',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd06' },
    countryCode: 'BI',
    name: 'Bubanza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd57' },
    countryCode: 'BS',
    name: 'Central Andros',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd29' },
    countryCode: 'BO',
    name: 'Cochabamba',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd72' },
    countryCode: 'BT',
    name: 'Thimphu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3a' },
    countryCode: 'BR',
    name: 'Distrito Federal',
    type: 'Federal district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3f' },
    countryCode: 'BR',
    name: 'Mato Grosso do Sul',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7d' },
    countryCode: 'BT',
    name: 'Pemagatshel',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd84' },
    countryCode: 'BW',
    name: 'Francistown',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd45' },
    countryCode: 'BR',
    name: 'Paraná',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd94' },
    countryCode: 'BY',
    name: "Homyel'skaya voblasts' (be) - Gomel'skaya oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8d' },
    countryCode: 'BW',
    name: 'North West',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9d' },
    countryCode: 'BZ',
    name: 'Stann Creek',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd92' },
    countryCode: 'BY',
    name: "Bresckaja voblasc (be) - Brestskaja oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd97' },
    countryCode: 'BY',
    name: "Minskaya voblasts' (be) - Minskaya oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda1' },
    countryCode: 'CA',
    name: 'Manitoba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc44' },
    countryCode: 'AZ',
    name: 'S?d?r?k',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4e' },
    countryCode: 'AZ',
    name: 'Samaxi',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8b' },
    countryCode: 'BD',
    name: 'Kushtia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc91' },
    countryCode: 'BD',
    name: 'Madaripur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc98' },
    countryCode: 'BD',
    name: 'Narail',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc99' },
    countryCode: 'BD',
    name: 'Natore',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9a' },
    countryCode: 'BD',
    name: 'Chapai Nawabganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca0' },
    countryCode: 'BD',
    name: 'Patuakhali',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcad' },
    countryCode: 'BD',
    name: 'Thakurgaon',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb0' },
    countryCode: 'BE',
    name: 'Vlaams Brabant',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce6' },
    countryCode: 'BG',
    name: 'Blagoevgrad',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd22' },
    countryCode: 'BJ',
    name: 'Plateau',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2c' },
    countryCode: 'BO',
    name: 'Pando',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4a' },
    countryCode: 'BR',
    name: 'Rio Grande do Sul',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5e' },
    countryCode: 'BS',
    name: 'Inagua',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd71' },
    countryCode: 'BT',
    name: 'Samtse',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd81' },
    countryCode: 'BT',
    name: 'Trashi Yangtse',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd86' },
    countryCode: 'BW',
    name: 'Ghanzi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd93' },
    countryCode: 'BY',
    name: 'Horad Minsk',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd98' },
    countryCode: 'BY',
    name: "Vitsyebskaya voblasts' (be) - Vitebskaya oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9a' },
    countryCode: 'BZ',
    name: 'Cayo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5f' },
    countryCode: 'AZ',
    name: 'Z?rdab',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6d' },
    countryCode: 'BB',
    name: 'Saint Thomas',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc72' },
    countryCode: 'BD',
    name: 'Bagerhat',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc73' },
    countryCode: 'BD',
    name: 'Barisal',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc75' },
    countryCode: 'BD',
    name: 'Comilla',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7d' },
    countryCode: 'BD',
    name: 'Feni',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc80' },
    countryCode: 'BD',
    name: 'Gaibandha',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9c' },
    countryCode: 'BD',
    name: 'Noakhali',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb3' },
    countryCode: 'BE',
    name: 'West-Vlaanderen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcbb' },
    countryCode: 'BF',
    name: 'Banwa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce1' },
    countryCode: 'BF',
    name: 'Yagha',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd15' },
    countryCode: 'BI',
    name: 'Rumonge',
    type: 'province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1c' },
    countryCode: 'BJ',
    name: 'Collines',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1f' },
    countryCode: 'BJ',
    name: 'Littoral',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd35' },
    countryCode: 'BR',
    name: 'Alagoas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd37' },
    countryCode: 'BR',
    name: 'Amapá',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3b' },
    countryCode: 'BR',
    name: 'Espírito Santo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd73' },
    countryCode: 'BT',
    name: 'Tsirang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9e' },
    countryCode: 'BZ',
    name: 'Toledo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc30' },
    countryCode: 'AZ',
    name: 'Laçin',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc50' },
    countryCode: 'AZ',
    name: 'Sirvan',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5a' },
    countryCode: 'AZ',
    name: 'Yardimli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc78' },
    countryCode: 'BD',
    name: "Cox's Bazar",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7a' },
    countryCode: 'BD',
    name: 'Dhaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8a' },
    countryCode: 'BD',
    name: 'Khagrachhari',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc93' },
    countryCode: 'BD',
    name: 'Moulvibazar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcaa' },
    countryCode: 'BD',
    name: 'Sunamganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb2' },
    countryCode: 'BE',
    name: 'Oost-Vlaanderen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd1' },
    countryCode: 'BF',
    name: 'Namentenga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcdb' },
    countryCode: 'BF',
    name: 'Sanmatenga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcde' },
    countryCode: 'BF',
    name: 'Sourou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf4' },
    countryCode: 'BG',
    name: 'Pleven',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd03' },
    countryCode: 'BH',
    name: 'Al Janubiyah',
    type: 'Governorat',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd20' },
    countryCode: 'BJ',
    name: 'Mono',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd59' },
    countryCode: 'BS',
    name: 'Exuma',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5b' },
    countryCode: 'BS',
    name: 'Grand Cay',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd60' },
    countryCode: 'BS',
    name: 'Mangrove Cay',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd67' },
    countryCode: 'BS',
    name: 'Ragged Island',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc33' },
    countryCode: 'AZ',
    name: 'Masalli',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3c' },
    countryCode: 'AZ',
    name: 'Qax',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3e' },
    countryCode: 'AZ',
    name: 'Quba',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc40' },
    countryCode: 'AZ',
    name: 'Qobustan',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc53' },
    countryCode: 'AZ',
    name: 'Tovuz',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc61' },
    countryCode: 'BA',
    name: 'Brcko distrikt',
    type: 'District with special\r\n\r\nstatus',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc62' },
    countryCode: 'BA',
    name: 'Republika Srpska',
    type: 'Entity',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc68' },
    countryCode: 'BB',
    name: 'Saint Joseph',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc77' },
    countryCode: 'BD',
    name: 'Chittagong',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc82' },
    countryCode: 'BD',
    name: 'Jamalpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc95' },
    countryCode: 'BD',
    name: 'Narayanganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca1' },
    countryCode: 'BD',
    name: 'Panchagarh',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca9' },
    countryCode: 'BD',
    name: 'Sylhet',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcab' },
    countryCode: 'BD',
    name: 'Shariatpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd3' },
    countryCode: 'BF',
    name: 'Nayala',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd5' },
    countryCode: 'BF',
    name: 'Oubritenga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce8' },
    countryCode: 'BG',
    name: 'Varna',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf5' },
    countryCode: 'BG',
    name: 'Plovdiv',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd00' },
    countryCode: 'BG',
    name: 'Shumen',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0f' },
    countryCode: 'BI',
    name: 'Kayanza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd14' },
    countryCode: 'BI',
    name: 'Ngozi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd17' },
    countryCode: 'BI',
    name: 'Ruyigi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2f' },
    countryCode: 'BO',
    name: 'Santa Cruz',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd31' },
    countryCode: 'BQ',
    name: 'Bonaire',
    type: 'Special Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4c' },
    countryCode: 'BR',
    name: 'Sergipe',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd56' },
    countryCode: 'BS',
    name: 'Central Abaco',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd70' },
    countryCode: 'BT',
    name: 'Haa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd79' },
    countryCode: 'BT',
    name: 'Bumthang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7c' },
    countryCode: 'BT',
    name: 'Monggar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2f' },
    countryCode: 'AZ',
    name: 'L?nk?ran',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc45' },
    countryCode: 'AZ',
    name: 'Sahbuz',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc56' },
    countryCode: 'AZ',
    name: 'Xaçmaz',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc63' },
    countryCode: 'BB',
    name: 'Christ Church',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc65' },
    countryCode: 'BB',
    name: 'Saint George',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc66' },
    countryCode: 'BB',
    name: 'Saint James',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca7' },
    countryCode: 'BD',
    name: 'Satkhira',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcae' },
    countryCode: 'BE',
    name: 'Bruxelles-Capitale, Région de',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc5' },
    countryCode: 'BF',
    name: 'Ioba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd7' },
    countryCode: 'BF',
    name: 'Passoré',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd02' },
    countryCode: 'BH',
    name: 'Al ‘Asimah',
    type: 'Governorat',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0b' },
    countryCode: 'BI',
    name: 'Cibitoke',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd10' },
    countryCode: 'BI',
    name: 'Makamba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1e' },
    countryCode: 'BJ',
    name: 'Couffo',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd26' },
    countryCode: 'BN',
    name: 'Temburong',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd28' },
    countryCode: 'BO',
    name: 'El Beni',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd2e' },
    countryCode: 'BO',
    name: 'Potosí',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd32' },
    countryCode: 'BQ',
    name: 'Saba',
    type: 'Special Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd40' },
    countryCode: 'BR',
    name: 'Mato Grosso',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5f' },
    countryCode: 'BS',
    name: 'Long Island',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd69' },
    countryCode: 'BS',
    name: 'South Eleuthera',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd80' },
    countryCode: 'BT',
    name: 'Gasa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd89' },
    countryCode: 'BW',
    name: 'Kgatleng',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd95' },
    countryCode: 'BY',
    name: "Hrodzenskaya voblasts' (be) - Grodnenskaya oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9c' },
    countryCode: 'BZ',
    name: 'Orange Walk',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc39' },
    countryCode: 'AZ',
    name: 'Oguz',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4a' },
    countryCode: 'AZ',
    name: 'Sabran',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4b' },
    countryCode: 'AZ',
    name: 'Siy?z?n',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4c' },
    countryCode: 'AZ',
    name: 'S?mkir',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc59' },
    countryCode: 'AZ',
    name: 'Xocav?nd',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6c' },
    countryCode: 'BB',
    name: 'Saint Philip',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7c' },
    countryCode: 'BD',
    name: 'Faridpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcbe' },
    countryCode: 'BF',
    name: 'Boulgou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc8' },
    countryCode: 'BF',
    name: 'Komondjari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fccb' },
    countryCode: 'BF',
    name: 'Kossi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fccf' },
    countryCode: 'BF',
    name: 'Loroum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd9' },
    countryCode: 'BF',
    name: 'Séno',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcec' },
    countryCode: 'BG',
    name: 'Gabrovo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd01' },
    countryCode: 'BG',
    name: 'Yambol',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd05' },
    countryCode: 'BH',
    name: 'Ash Shamaliyah',
    type: 'Governorat',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd0d' },
    countryCode: 'BI',
    name: 'Kirundo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1a' },
    countryCode: 'BJ',
    name: 'Atlantique',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd21' },
    countryCode: 'BJ',
    name: 'Ouémé',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd27' },
    countryCode: 'BN',
    name: 'Tutong',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd39' },
    countryCode: 'BR',
    name: 'Ceará',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4b' },
    countryCode: 'BR',
    name: 'Santa Catarina',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd53' },
    countryCode: 'BS',
    name: 'Central Eleuthera',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5a' },
    countryCode: 'BS',
    name: 'City of Freeport',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd65' },
    countryCode: 'BS',
    name: 'North Andros',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd66' },
    countryCode: 'BS',
    name: 'Rum Cay',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6c' },
    countryCode: 'BS',
    name: 'Spanish Wells',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6f' },
    countryCode: 'BT',
    name: 'Chhukha',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd77' },
    countryCode: 'BT',
    name: 'Sarpang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd7b' },
    countryCode: 'BT',
    name: 'Trashigang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8c' },
    countryCode: 'BW',
    name: 'North East',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9b' },
    countryCode: 'BZ',
    name: 'Corozal',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3b' },
    countryCode: 'AZ',
    name: 'Q?b?l?',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc48' },
    countryCode: 'AZ',
    name: 'S?rur',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc57' },
    countryCode: 'AZ',
    name: 'Xocali',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc5e' },
    countryCode: 'AZ',
    name: 'Zaqatala',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc85' },
    countryCode: 'BD',
    name: 'Joypurhat',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca3' },
    countryCode: 'BD',
    name: 'Rajshahi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb9' },
    countryCode: 'BF',
    name: 'Balé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcda' },
    countryCode: 'BF',
    name: 'Sissili',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf9' },
    countryCode: 'BG',
    name: 'Sliven',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcff' },
    countryCode: 'BG',
    name: 'Haskovo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd13' },
    countryCode: 'BI',
    name: 'Muyinga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd44' },
    countryCode: 'BR',
    name: 'Piauí',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd48' },
    countryCode: 'BR',
    name: 'Rondônia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd50' },
    countryCode: 'BS',
    name: 'Bimini',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd54' },
    countryCode: 'BS',
    name: 'Cat Island',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd58' },
    countryCode: 'BS',
    name: 'East Grand Bahama',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5c' },
    countryCode: 'BS',
    name: 'Harbour Island',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd68' },
    countryCode: 'BS',
    name: 'South Andros',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6d' },
    countryCode: 'BS',
    name: 'West Grand Bahama',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd75' },
    countryCode: 'BT',
    name: 'Punakha',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd85' },
    countryCode: 'BW',
    name: 'Gaborone',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc3a' },
    countryCode: 'AZ',
    name: 'Ordubad',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc41' },
    countryCode: 'AZ',
    name: 'Qusar',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc4f' },
    countryCode: 'AZ',
    name: 'Samux',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc67' },
    countryCode: 'BB',
    name: 'Saint John',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc69' },
    countryCode: 'BB',
    name: 'Saint Lucy',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6b' },
    countryCode: 'BB',
    name: 'Saint Peter',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc6e' },
    countryCode: 'BD',
    name: 'Bandarban',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc71' },
    countryCode: 'BD',
    name: 'Brahmanbaria',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7e' },
    countryCode: 'BD',
    name: 'Gopalganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc87' },
    countryCode: 'BD',
    name: 'Kishoreganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb1' },
    countryCode: 'BE',
    name: 'Limburg',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb8' },
    countryCode: 'BE',
    name: 'Namur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcc9' },
    countryCode: 'BF',
    name: 'Kompienga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd2' },
    countryCode: 'BF',
    name: 'Nahouri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd8' },
    countryCode: 'BF',
    name: 'Poni',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce3' },
    countryCode: 'BF',
    name: 'Ziro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fce5' },
    countryCode: 'BF',
    name: 'Zoundwéogo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcef' },
    countryCode: 'BG',
    name: 'Kjustendil',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd07' },
    countryCode: 'BI',
    name: 'Bujumbura Rural',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd11' },
    countryCode: 'BI',
    name: 'Muramvya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd12' },
    countryCode: 'BI',
    name: 'Mwaro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd16' },
    countryCode: 'BI',
    name: 'Rutana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd19' },
    countryCode: 'BJ',
    name: 'Alibori',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd36' },
    countryCode: 'BR',
    name: 'Amazonas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3c' },
    countryCode: 'BR',
    name: 'Goiás',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd4d' },
    countryCode: 'BR',
    name: 'São Paulo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd96' },
    countryCode: 'BY',
    name: "Mahilyowskaya voblasts' (be) - Mogilevskaya oblast' (ru)",
    type: 'Oblast',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc2e' },
    countryCode: 'AZ',
    name: 'Kürd?mir',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc32' },
    countryCode: 'AZ',
    name: 'Lerik',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc35' },
    countryCode: 'AZ',
    name: 'Naftalan',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc42' },
    countryCode: 'AZ',
    name: 'S?ki',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc51' },
    countryCode: 'AZ',
    name: 'Susa',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc60' },
    countryCode: 'BA',
    name: 'Federacija Bosne i Hercegovine',
    type: 'Entity',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc64' },
    countryCode: 'BB',
    name: 'Saint Andrew',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc79' },
    countryCode: 'BD',
    name: 'Chuadanga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7f' },
    countryCode: 'BD',
    name: 'Gazipur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8f' },
    countryCode: 'BD',
    name: 'Mymensingh',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc9f' },
    countryCode: 'BD',
    name: 'Pirojpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca4' },
    countryCode: 'BD',
    name: 'Rangpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca6' },
    countryCode: 'BD',
    name: 'Sherpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcbc' },
    countryCode: 'BF',
    name: 'Bazèga\r\n\r\nga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcbf' },
    countryCode: 'BF',
    name: 'Boulkiemdé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcce' },
    countryCode: 'BF',
    name: 'Léraba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcd4' },
    countryCode: 'BF',
    name: 'Noumbiel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcee' },
    countryCode: 'BG',
    name: 'Kardzhali',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcf3' },
    countryCode: 'BG',
    name: 'Pernik',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcfa' },
    countryCode: 'BG',
    name: 'Smolyan',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd33' },
    countryCode: 'BQ',
    name: 'Sint Eustatius',
    type: 'Special Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd34' },
    countryCode: 'BR',
    name: 'Acre',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd43' },
    countryCode: 'BR',
    name: 'Pernambuco',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd51' },
    countryCode: 'BS',
    name: 'Black Point',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd62' },
    countryCode: 'BS',
    name: "Moore's Island",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd6e' },
    countryCode: 'BT',
    name: 'Paro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd74' },
    countryCode: 'BT',
    name: 'Dagana',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd83' },
    countryCode: 'BW',
    name: 'Chobe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8b' },
    countryCode: 'BW',
    name: 'Lobatse',
    type: 'Town',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd9f' },
    countryCode: 'CA',
    name: 'Alberta',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc37' },
    countryCode: 'AZ',
    name: 'Naxçivan',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc52' },
    countryCode: 'AZ',
    name: 'T?rt?r',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc54' },
    countryCode: 'AZ',
    name: 'Ucar',
    type: 'Rayon',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc7b' },
    countryCode: 'BD',
    name: 'Dinajpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc81' },
    countryCode: 'BD',
    name: 'Habiganj',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc83' },
    countryCode: 'BD',
    name: 'Jessore',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc8c' },
    countryCode: 'BD',
    name: 'Lakshmipur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fc94' },
    countryCode: 'BD',
    name: 'Meherpur',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fca5' },
    countryCode: 'BD',
    name: 'Rangamati',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcaf' },
    countryCode: 'BE',
    name: 'Antwerpen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fcb4' },
    countryCode: 'BE',
    name: 'Brabant wallon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd1b' },
    countryCode: 'BJ',
    name: 'Borgou',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd3e' },
    countryCode: 'BR',
    name: 'Minas Gerais',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd41' },
    countryCode: 'BR',
    name: 'Pará',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd42' },
    countryCode: 'BR',
    name: 'Paraíba',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd5d' },
    countryCode: 'BS',
    name: 'Hope Town',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd76' },
    countryCode: 'BT',
    name: 'Wangdue Phodrang',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd8e' },
    countryCode: 'BW',
    name: 'South East',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fd91' },
    countryCode: 'BW',
    name: 'Sowa Town',
    type: 'Town',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda2' },
    countryCode: 'CA',
    name: 'New Brunswick',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb1' },
    countryCode: 'CD',
    name: 'Haut-Uélé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdbb' },
    countryCode: 'CD',
    name: 'Maniema',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf0' },
    countryCode: 'CH',
    name: 'Nidwalden (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe03' },
    countryCode: 'CI',
    name: 'Lagunes',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe04' },
    countryCode: 'CI',
    name: 'Montagnes',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe07' },
    countryCode: 'CI',
    name: 'Vallée du Bandama',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe09' },
    countryCode: 'CI',
    name: 'Yamoussoukro',
    type: 'autonomous district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0e' },
    countryCode: 'CL',
    name: 'La Araucanía',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2c' },
    countryCode: 'CN',
    name: 'Shanghai',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe49' },
    countryCode: 'CO',
    name: 'Atlántico',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe51' },
    countryCode: 'CO',
    name: 'Chocó',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe52' },
    countryCode: 'CO',
    name: 'Córdoba',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5f' },
    countryCode: 'CO',
    name: 'Risaralda',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe63' },
    countryCode: 'CO',
    name: 'Tolima',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe87' },
    countryCode: 'CV',
    name: 'Porto Novo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdac' },
    countryCode: 'CD',
    name: 'Kongo Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdee' },
    countryCode: 'CH',
    name: 'Luzern (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf8' },
    countryCode: 'CH',
    name: 'Uri (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe21' },
    countryCode: 'CM',
    name: 'West',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7a' },
    countryCode: 'CU',
    name: 'Santiago de Cuba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe86' },
    countryCode: 'CV',
    name: 'Paul',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe88' },
    countryCode: 'CV',
    name: 'Praia',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9c' },
    countryCode: 'CZ',
    name: 'Stredoceský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea8' },
    countryCode: 'CZ',
    name: 'Moravskoslezský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fead' },
    countryCode: 'DE',
    name: 'Bremen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc5' },
    countryCode: 'CD',
    name: 'Tshuapa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdce' },
    countryCode: 'CF',
    name: 'Kémo-Gribingui',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd4' },
    countryCode: 'CF',
    name: 'Sangha',
    type: 'economic prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdde' },
    countryCode: 'CG',
    name: 'Kouilou',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde5' },
    countryCode: 'CH',
    name: 'Appenzell Ausserrhoden (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde6' },
    countryCode: 'CH',
    name: 'Bern (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde9' },
    countryCode: 'CH',
    name: 'Fribourg (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdec' },
    countryCode: 'CH',
    name: 'Graubünden (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdfd' },
    countryCode: 'CI',
    name: 'Abidjan',
    type: 'autonomous district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0f' },
    countryCode: 'CL',
    name: 'Atacama',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe14' },
    countryCode: 'CL',
    name: 'Los Ríos',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe47' },
    countryCode: 'CO',
    name: 'Antioquia',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe91' },
    countryCode: 'CV',
    name: 'São Salvador do Mundo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb1' },
    countryCode: 'DE',
    name: 'Niedersachsen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb2' },
    countryCode: 'DE',
    name: 'Nordrhein-Westfalen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdbf' },
    countryCode: 'CD',
    name: 'Nord-Ubangi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd2' },
    countryCode: 'CF',
    name: 'Nana-Mambéré',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdef' },
    countryCode: 'CH',
    name: 'Neuchâtel (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe13' },
    countryCode: 'CL',
    name: 'Los Lagos',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe18' },
    countryCode: 'CL',
    name: 'Tarapacá',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1d' },
    countryCode: 'CM',
    name: 'East',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe37' },
    countryCode: 'CN',
    name: 'Guangxi',
    type: 'Autonomous Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe39' },
    countryCode: 'CN',
    name: 'Chongqing',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe40' },
    countryCode: 'CN',
    name: 'Qinghai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4e' },
    countryCode: 'CO',
    name: 'Casanare',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe72' },
    countryCode: 'CU',
    name: 'Villa Clara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe77' },
    countryCode: 'CU',
    name: 'Las Tunas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7c' },
    countryCode: 'CU',
    name: 'Artemisa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8d' },
    countryCode: 'CV',
    name: 'São Filipe',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8f' },
    countryCode: 'CV',
    name: 'São Miguel',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea1' },
    countryCode: 'CZ',
    name: 'Liberecký kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea3' },
    countryCode: 'CZ',
    name: 'Pardubický kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdaf' },
    countryCode: 'CD',
    name: 'Haut-Katanga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdbc' },
    countryCode: 'CD',
    name: 'Mai-Ndombe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdbe' },
    countryCode: 'CD',
    name: 'Nord-Kivu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd1' },
    countryCode: 'CF',
    name: 'Ombella-Mpoko',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde0' },
    countryCode: 'CG',
    name: 'Cuvette',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde7' },
    countryCode: 'CH',
    name: 'Basel-Landschaft (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf9' },
    countryCode: 'CH',
    name: 'Vaud (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe28' },
    countryCode: 'CN',
    name: 'Nei Mongol (mn)',
    type: 'Autonomous Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe33' },
    countryCode: 'CN',
    name: 'Henan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5c' },
    countryCode: 'CO',
    name: 'Norte de Santander',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe74' },
    countryCode: 'CU',
    name: 'Sancti Spíritus',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7d' },
    countryCode: 'CU',
    name: 'Mayabeque',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe95' },
    countryCode: 'CY',
    name: 'Lefkosia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe99' },
    countryCode: 'CY',
    name: 'Pafos',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea5' },
    countryCode: 'CZ',
    name: 'Jihomoravský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda3' },
    countryCode: 'CA',
    name: 'Newfoundland and Labrador',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb2' },
    countryCode: 'CD',
    name: 'Ituri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb3' },
    countryCode: 'CD',
    name: 'Kasaï Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc1' },
    countryCode: 'CD',
    name: 'Sud-Kivu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdda' },
    countryCode: 'CG',
    name: 'Plateaux',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde8' },
    countryCode: 'CH',
    name: 'Basel-Stadt (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf4' },
    countryCode: 'CH',
    name: 'Solothurn (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe19' },
    countryCode: 'CL',
    name: 'Valparaíso',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe20' },
    countryCode: 'CM',
    name: 'North-West',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4a' },
    countryCode: 'CO',
    name: 'Bolívar',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe56' },
    countryCode: 'CO',
    name: 'Guaviare',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6a' },
    countryCode: 'CR',
    name: 'Heredia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe73' },
    countryCode: 'CU',
    name: 'Cienfuegos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7b' },
    countryCode: 'CU',
    name: 'Guantánamo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7f' },
    countryCode: 'CV',
    name: 'Brava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8a' },
    countryCode: 'CV',
    name: 'Ribeira Grande',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe92' },
    countryCode: 'CV',
    name: 'São Vicente',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe93' },
    countryCode: 'CV',
    name: 'Tarrafal',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe94' },
    countryCode: 'CV',
    name: 'Tarrafal de São Nicolau',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe97' },
    countryCode: 'CY',
    name: 'Larnaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feaa' },
    countryCode: 'DE',
    name: 'Berlin',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda4' },
    countryCode: 'CA',
    name: 'Nova Scotia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdae' },
    countryCode: 'CD',
    name: 'Équateur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd5' },
    countryCode: 'CF',
    name: 'Ouaka',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd9' },
    countryCode: 'CG',
    name: 'Sangha',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf2' },
    countryCode: 'CH',
    name: 'Sankt Gallen (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1b' },
    countryCode: 'CM',
    name: 'Centre',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1f' },
    countryCode: 'CM',
    name: 'North',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2d' },
    countryCode: 'CN',
    name: 'Jiangsu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3b' },
    countryCode: 'CN',
    name: 'Guizhou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe41' },
    countryCode: 'CN',
    name: 'Ningxia',
    type: 'Autonomous Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe43' },
    countryCode: 'CN',
    name: 'Taiwan',
    type: 'Province',
    isDeleted: true,
    updatedAt: { $date: '2018-12-13T12:49:22.437+0000' },
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5b' },
    countryCode: 'CO',
    name: 'Nariño',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe60' },
    countryCode: 'CO',
    name: 'Santander',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe65' },
    countryCode: 'CO',
    name: 'Vaupés',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe79' },
    countryCode: 'CU',
    name: 'Granma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdab' },
    countryCode: 'CA',
    name: 'Yukon',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb0' },
    countryCode: 'CD',
    name: 'Haut-Lomami',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb6' },
    countryCode: 'CD',
    name: 'Kwilu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdcf' },
    countryCode: 'CF',
    name: 'Lobaye',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd6' },
    countryCode: 'CF',
    name: 'Vakaga',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde4' },
    countryCode: 'CH',
    name: 'Appenzell Innerrhoden (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0c' },
    countryCode: 'CL',
    name: 'Antofagasta',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe36' },
    countryCode: 'CN',
    name: 'Guangdong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4b' },
    countryCode: 'CO',
    name: 'Boyacá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe50' },
    countryCode: 'CO',
    name: 'Cesar',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe57' },
    countryCode: 'CO',
    name: 'Huila',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8b' },
    countryCode: 'CV',
    name: 'Ribeira Grande de Santiago',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea0' },
    countryCode: 'CZ',
    name: 'Ústecký kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda5' },
    countryCode: 'CA',
    name: 'Northwest Territories',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda8' },
    countryCode: 'CA',
    name: 'Prince Edward Island',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd3' },
    countryCode: 'CF',
    name: 'Ouham-Pendé',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde2' },
    countryCode: 'CG',
    name: 'Brazzaville',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdeb' },
    countryCode: 'CH',
    name: 'Glarus (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe01' },
    countryCode: 'CI',
    name: 'Gôh-Djiboua',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0b' },
    countryCode: 'CL',
    name: 'Aisén del General Carlos Ibañez del Campo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe16' },
    countryCode: 'CL',
    name: 'Maule',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe17' },
    countryCode: 'CL',
    name: 'Región Metropolitana de Santiago',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1a' },
    countryCode: 'CM',
    name: 'Adamaoua',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1e' },
    countryCode: 'CM',
    name: 'Littoral',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe23' },
    countryCode: 'CM',
    name: 'South-West',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2e' },
    countryCode: 'CN',
    name: 'Zhejiang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe34' },
    countryCode: 'CN',
    name: 'Hubei',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe38' },
    countryCode: 'CN',
    name: 'Hainan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3c' },
    countryCode: 'CN',
    name: 'Yunnan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3e' },
    countryCode: 'CN',
    name: 'Shaanxi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4f' },
    countryCode: 'CO',
    name: 'Cauca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5e' },
    countryCode: 'CO',
    name: 'Quindío',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe71' },
    countryCode: 'CU',
    name: 'Matanzas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe80' },
    countryCode: 'CV',
    name: 'Boa Vista',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe84' },
    countryCode: 'CV',
    name: 'Maio',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe96' },
    countryCode: 'CY',
    name: 'Lemesos',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9a' },
    countryCode: 'CY',
    name: 'Keryneia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea7' },
    countryCode: 'CZ',
    name: 'Zlínský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea9' },
    countryCode: 'DE',
    name: 'Brandenburg',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feab' },
    countryCode: 'DE',
    name: 'Baden-Württemberg',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda9' },
    countryCode: 'CA',
    name: 'Quebec',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb8' },
    countryCode: 'CD',
    name: 'Kasaï',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc2' },
    countryCode: 'CD',
    name: 'Sud-Ubangi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc6' },
    countryCode: 'CF',
    name: 'Ouham',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc8' },
    countryCode: 'CF',
    name: 'Bangui',
    type: 'Commune',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc9' },
    countryCode: 'CF',
    name: 'Basse-Kotto',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde1' },
    countryCode: 'CG',
    name: 'Niari',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf1' },
    countryCode: 'CH',
    name: 'Obwalden (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdfb' },
    countryCode: 'CH',
    name: 'Zug (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdfe' },
    countryCode: 'CI',
    name: 'Bas-Sassandra',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe08' },
    countryCode: 'CI',
    name: 'Woroba',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe22' },
    countryCode: 'CM',
    name: 'South',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe25' },
    countryCode: 'CN',
    name: 'Tianjin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe26' },
    countryCode: 'CN',
    name: 'Hebei',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe32' },
    countryCode: 'CN',
    name: 'Shandong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3f' },
    countryCode: 'CN',
    name: 'Gansu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe62' },
    countryCode: 'CO',
    name: 'Sucre',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe68' },
    countryCode: 'CR',
    name: 'Cartago',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe70' },
    countryCode: 'CU',
    name: 'Ciudad de La Habana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe76' },
    countryCode: 'CU',
    name: 'Camagüey',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe78' },
    countryCode: 'CU',
    name: 'Holguín',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe7e' },
    countryCode: 'CU',
    name: 'Isla de la Juventud',
    type: 'Special municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9b' },
    countryCode: 'CZ',
    name: 'Praha, Hlavní mešto',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feac' },
    countryCode: 'DE',
    name: 'Bayern',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdba' },
    countryCode: 'CD',
    name: 'Lualaba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdbd' },
    countryCode: 'CD',
    name: 'Mongala',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd7' },
    countryCode: 'CG',
    name: 'Bouenza',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fde3' },
    countryCode: 'CH',
    name: 'Aargau (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe06' },
    countryCode: 'CI',
    name: 'Savanes',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0d' },
    countryCode: 'CL',
    name: 'Arica y Parinacota',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2a' },
    countryCode: 'CN',
    name: 'Jilin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2f' },
    countryCode: 'CN',
    name: 'Anhui',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe42' },
    countryCode: 'CN',
    name: 'Xinjiang',
    type: 'Autonomous Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe48' },
    countryCode: 'CO',
    name: 'Arauca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4c' },
    countryCode: 'CO',
    name: 'Caldas',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5a' },
    countryCode: 'CO',
    name: 'Meta',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6f' },
    countryCode: 'CU',
    name: 'La Habana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe85' },
    countryCode: 'CV',
    name: 'Mosteiros',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe90' },
    countryCode: 'CV',
    name: 'São Lourenço dos Órgãos',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda6' },
    countryCode: 'CA',
    name: 'Nunavut',
    type: 'Territory',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdaa' },
    countryCode: 'CA',
    name: 'Saskatchewan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdcc' },
    countryCode: 'CF',
    name: 'Mambéré-Kadéï',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd0' },
    countryCode: 'CF',
    name: 'Mbomou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fddc' },
    countryCode: 'CG',
    name: 'Pointe-Noire',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdff' },
    countryCode: 'CI',
    name: 'Comoé',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe15' },
    countryCode: 'CL',
    name: 'Magallanes',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe2b' },
    countryCode: 'CN',
    name: 'Heilongjiang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe31' },
    countryCode: 'CN',
    name: 'Jiangxi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe55' },
    countryCode: 'CO',
    name: 'Guainía',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe66' },
    countryCode: 'CO',
    name: 'Vichada',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6b' },
    countryCode: 'CR',
    name: 'Limón',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6c' },
    countryCode: 'CR',
    name: 'Puntarenas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe89' },
    countryCode: 'CV',
    name: 'Ribeira Brava',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8c' },
    countryCode: 'CV',
    name: 'São Domingos',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9d' },
    countryCode: 'CZ',
    name: 'Jihoceský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9e' },
    countryCode: 'CZ',
    name: 'Plzenský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdad' },
    countryCode: 'CD',
    name: 'Bas-Uélé',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb7' },
    countryCode: 'CD',
    name: 'Kinshasa',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc7' },
    countryCode: 'CF',
    name: 'Bamingui-Bangoran',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdd8' },
    countryCode: 'CG',
    name: 'Pool',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fded' },
    countryCode: 'CH',
    name: 'Jura (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf6' },
    countryCode: 'CH',
    name: 'Thurgau (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe05' },
    countryCode: 'CI',
    name: 'Sassandra-Marahoué',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe24' },
    countryCode: 'CN',
    name: 'Beijing',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe27' },
    countryCode: 'CN',
    name: 'Shanxi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe46' },
    countryCode: 'CO',
    name: 'Amazonas',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe54' },
    countryCode: 'CO',
    name: 'Distrito Capital de Bogotá',
    type: 'Capital district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe58' },
    countryCode: 'CO',
    name: 'La Guajira',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe61' },
    countryCode: 'CO',
    name: 'San Andrés, Providencia y Santa Catalina',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6e' },
    countryCode: 'CU',
    name: 'Pinar del Río',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe81' },
    countryCode: 'CV',
    name: 'Santa Catarina',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe82' },
    countryCode: 'CV',
    name: 'Santa Catarina do Fogo',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea4' },
    countryCode: 'CZ',
    name: 'Kraj Vysocina',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdcb' },
    countryCode: 'CF',
    name: 'Haut-Mbomou',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdcd' },
    countryCode: 'CF',
    name: 'Gribingui',
    type: 'economic prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fddf' },
    countryCode: 'CG',
    name: 'Likouala',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf5' },
    countryCode: 'CH',
    name: 'Schwyz (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe10' },
    countryCode: 'CL',
    name: 'Biobío',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe1c' },
    countryCode: 'CM',
    name: 'Far North',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe29' },
    countryCode: 'CN',
    name: 'Liaoning',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe44' },
    countryCode: 'CN',
    name: 'Xianggang',
    type: 'Special Administrative Region',
    isDeleted: true,
    updatedAt: { $date: '2018-12-13T12:49:03.374+0000' },
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe45' },
    countryCode: 'CN',
    name: 'Aomen',
    type: 'Special Administrative Region',
    isDeleted: true,
    updatedAt: { $date: '2018-12-13T12:49:16.399+0000' },
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe59' },
    countryCode: 'CO',
    name: 'Magdalena',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe5d' },
    countryCode: 'CO',
    name: 'Putumayo',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe69' },
    countryCode: 'CR',
    name: 'Guanacaste',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe9f' },
    countryCode: 'CZ',
    name: 'Karlovarský kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feae' },
    countryCode: 'DE',
    name: 'Hessen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feaf' },
    countryCode: 'DE',
    name: 'Hamburg',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb0' },
    countryCode: 'DE',
    name: 'Mecklenburg-Vorpommern',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb4' },
    countryCode: 'CD',
    name: 'Kasaï Oriental',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb5' },
    countryCode: 'CD',
    name: 'Kwango',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc4' },
    countryCode: 'CD',
    name: 'Tshopo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fddb' },
    countryCode: 'CG',
    name: 'Cuvette-Ouest',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fddd' },
    countryCode: 'CG',
    name: 'Lékoumou',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf7' },
    countryCode: 'CH',
    name: 'Ticino (it)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdfc' },
    countryCode: 'CH',
    name: 'Zürich (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe00' },
    countryCode: 'CI',
    name: 'Denguélé',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe02' },
    countryCode: 'CI',
    name: 'Lacs',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe0a' },
    countryCode: 'CI',
    name: 'Zanzan',
    type: 'district',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe11' },
    countryCode: 'CL',
    name: 'Coquimbo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3a' },
    countryCode: 'CN',
    name: 'Sichuan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe3d' },
    countryCode: 'CN',
    name: 'Xizang',
    type: 'Autonomous Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe53' },
    countryCode: 'CO',
    name: 'Cundinamarca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe64' },
    countryCode: 'CO',
    name: 'Valle del Cauca',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe75' },
    countryCode: 'CU',
    name: 'Ciego de Ávila',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe83' },
    countryCode: 'CV',
    name: 'Santa Cruz',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe8e' },
    countryCode: 'CV',
    name: 'Sal',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe98' },
    countryCode: 'CY',
    name: 'Ammochostos',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea2' },
    countryCode: 'CZ',
    name: 'Královéhradecký kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fea6' },
    countryCode: 'CZ',
    name: 'Olomoucký kraj',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fda7' },
    countryCode: 'CA',
    name: 'Ontario',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdb9' },
    countryCode: 'CD',
    name: 'Lomami',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc0' },
    countryCode: 'CD',
    name: 'Sankuru',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdc3' },
    countryCode: 'CD',
    name: 'Tanganyika',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdca' },
    countryCode: 'CF',
    name: 'Haute-Kotto',
    type: 'Prefecture',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdea' },
    countryCode: 'CH',
    name: 'Genève (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdf3' },
    countryCode: 'CH',
    name: 'Schaffhausen (de)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fdfa' },
    countryCode: 'CH',
    name: 'Valais (fr)',
    type: 'Canton',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe12' },
    countryCode: 'CL',
    name: "Libertador General Bernardo O'Higgins",
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe30' },
    countryCode: 'CN',
    name: 'Fujian',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe35' },
    countryCode: 'CN',
    name: 'Hunan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe4d' },
    countryCode: 'CO',
    name: 'Caquetá',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe67' },
    countryCode: 'CR',
    name: 'Alajuela',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fe6d' },
    countryCode: 'CR',
    name: 'San José',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb3' },
    countryCode: 'DE',
    name: 'Rheinland-Pfalz',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb6' },
    countryCode: 'DE',
    name: 'Sachsen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec0' },
    countryCode: 'DK',
    name: 'Midtjylland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec4' },
    countryCode: 'DM',
    name: 'Saint Andrew',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed4' },
    countryCode: 'DO',
    name: 'Elías Piña',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fedc' },
    countryCode: 'DO',
    name: 'Monte Cristi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff04' },
    countryCode: 'DZ',
    name: 'Tlemcen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0e' },
    countryCode: 'DZ',
    name: 'Annaba',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff13' },
    countryCode: 'DZ',
    name: "M'sila",
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff19' },
    countryCode: 'DZ',
    name: 'Bordj Bou Arréridj',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff40' },
    countryCode: 'EE',
    name: 'Harjumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed6' },
    countryCode: 'DO',
    name: 'Espaillat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fedb' },
    countryCode: 'DO',
    name: 'María Trinidad Sánchez',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee3' },
    countryCode: 'DO',
    name: 'San Juan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feea' },
    countryCode: 'DO',
    name: 'Monte Plata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef0' },
    countryCode: 'DO',
    name: 'Cibao Norte',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0a' },
    countryCode: 'DZ',
    name: 'Sétif',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff36' },
    countryCode: 'EC',
    name: 'Los Ríos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3a' },
    countryCode: 'EC',
    name: 'Tungurahua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3d' },
    countryCode: 'EC',
    name: 'Cotopaxi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4d' },
    countryCode: 'EE',
    name: 'Viljandimaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5c' },
    countryCode: 'EG',
    name: "Janub Sina'",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec1' },
    countryCode: 'DK',
    name: 'Syddanmark',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04febf' },
    countryCode: 'DK',
    name: 'Nordjylland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec5' },
    countryCode: 'DM',
    name: 'Saint David',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feca' },
    countryCode: 'DM',
    name: 'Saint Mark',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed3' },
    countryCode: 'DO',
    name: 'Duarte',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee7' },
    countryCode: 'DO',
    name: 'Santiago Rodríguez',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff07' },
    countryCode: 'DZ',
    name: 'Alger',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff29' },
    countryCode: 'EC',
    name: 'Bolívar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2b' },
    countryCode: 'EC',
    name: 'Orellana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff56' },
    countryCode: 'EG',
    name: 'Ad Daqahliyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff57' },
    countryCode: 'EG',
    name: 'Dumyat',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff58' },
    countryCode: 'EG',
    name: 'AI Fayyum',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feda' },
    countryCode: 'DO',
    name: 'La Vega',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee2' },
    countryCode: 'DO',
    name: 'San Cristóbal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef1' },
    countryCode: 'DO',
    name: 'Cibao Sur',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0c' },
    countryCode: 'DZ',
    name: 'Skikda',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff17' },
    countryCode: 'DZ',
    name: 'El Bayadh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff21' },
    countryCode: 'DZ',
    name: 'Tipaza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2e' },
    countryCode: 'EC',
    name: 'Guayas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff31' },
    countryCode: 'EC',
    name: 'Loja',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff34' },
    countryCode: 'EC',
    name: 'El Oro',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff48' },
    countryCode: 'EE',
    name: 'Pärnumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff51' },
    countryCode: 'EG',
    name: 'Asyut',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff53' },
    countryCode: 'EG',
    name: 'Al Bu?ayrah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5b' },
    countryCode: 'EG',
    name: "AI Isma 'iliyah",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff60' },
    countryCode: 'EG',
    name: 'Al Uqsur',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff68' },
    countryCode: 'EG',
    name: 'As Suways',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04febb' },
    countryCode: 'DJ',
    name: 'Dikhil',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed7' },
    countryCode: 'DO',
    name: 'Independencia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04febe' },
    countryCode: 'DJ',
    name: 'Tadjourah',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff23' },
    countryCode: 'DZ',
    name: 'Aïn Defla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2a' },
    countryCode: 'EC',
    name: 'Carchi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2f' },
    countryCode: 'EC',
    name: 'Chimborazo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff46' },
    countryCode: 'EE',
    name: 'Lääne-Virumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff52' },
    countryCode: 'EG',
    name: 'Al Ba?r al A?mar',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5d' },
    countryCode: 'EG',
    name: 'AI Qalyubiyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff64' },
    countryCode: 'EG',
    name: "Bur Sa'id",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fedd' },
    countryCode: 'DO',
    name: 'Pedernales',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fede' },
    countryCode: 'DO',
    name: 'Peravia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feeb' },
    countryCode: 'DO',
    name: 'Hato Mayor',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef5' },
    countryCode: 'DO',
    name: 'Ozama',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef7' },
    countryCode: 'DO',
    name: 'Yuma',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feff' },
    countryCode: 'DZ',
    name: 'Béchar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff14' },
    countryCode: 'DZ',
    name: 'Mascara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1f' },
    countryCode: 'DZ',
    name: 'Khenchela',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff32' },
    countryCode: 'EC',
    name: 'Manabí',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff45' },
    countryCode: 'EE',
    name: 'Läänemaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4e' },
    countryCode: 'EE',
    name: 'Võrumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6d' },
    countryCode: 'ER',
    name: 'Gash-Barka',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb5' },
    countryCode: 'DE',
    name: 'Saarland',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed1' },
    countryCode: 'DO',
    name: 'Barahona',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fedf' },
    countryCode: 'DO',
    name: 'Puerto Plata',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff18' },
    countryCode: 'DZ',
    name: 'Illizi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff49' },
    countryCode: 'EE',
    name: 'Raplamaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5f' },
    countryCode: 'EG',
    name: 'Qina',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff62' },
    countryCode: 'EG',
    name: 'AI Minufiyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff69' },
    countryCode: 'EG',
    name: 'AI Wadi al Jadid',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feed' },
    countryCode: 'DO',
    name: 'Santo Domingo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0b' },
    countryCode: 'DZ',
    name: 'Saïda',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff20' },
    countryCode: 'DZ',
    name: 'Souk Ahras',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff24' },
    countryCode: 'DZ',
    name: 'Naama',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff38' },
    countryCode: 'EC',
    name: 'Santo Domingo de los Tsáchilas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3f' },
    countryCode: 'EC',
    name: 'Zamora-Chinchipe',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4b' },
    countryCode: 'EE',
    name: 'Tartumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff50' },
    countryCode: 'EG',
    name: 'Aswan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff55' },
    countryCode: 'EG',
    name: 'AI Qahirah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff63' },
    countryCode: 'EG',
    name: 'Matruh',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6b' },
    countryCode: 'ER',
    name: 'Debubawi K’eyyi? Ba?ri',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04febd' },
    countryCode: 'DJ',
    name: 'Obock',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed5' },
    countryCode: 'DO',
    name: 'El Seibo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef3' },
    countryCode: 'DO',
    name: 'Enriquillo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff01' },
    countryCode: 'DZ',
    name: 'Bouira',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff10' },
    countryCode: 'DZ',
    name: 'Constantine',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff11' },
    countryCode: 'DZ',
    name: 'Médéa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2d' },
    countryCode: 'EC',
    name: 'Cañar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff39' },
    countryCode: 'EC',
    name: 'Santa Elena',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff41' },
    countryCode: 'EE',
    name: 'Hiiumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff43' },
    countryCode: 'EE',
    name: 'Jõgevamaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4f' },
    countryCode: 'EG',
    name: 'AI Iskandariyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff54' },
    countryCode: 'EG',
    name: 'Bani Suwayf',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec7' },
    countryCode: 'DM',
    name: 'Saint John',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed8' },
    countryCode: 'DO',
    name: 'La Altagracia',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed9' },
    countryCode: 'DO',
    name: 'La Romana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee0' },
    countryCode: 'DO',
    name: 'Hermanas Mirabal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff02' },
    countryCode: 'DZ',
    name: 'Tamanrasset',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff08' },
    countryCode: 'DZ',
    name: 'Djelfa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff12' },
    countryCode: 'DZ',
    name: 'Mostaganem',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff35' },
    countryCode: 'EC',
    name: 'Pichincha',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4a' },
    countryCode: 'EE',
    name: 'Saaremaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6a' },
    countryCode: 'ER',
    name: '‘Anseba',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6c' },
    countryCode: 'ER',
    name: 'Debub',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec2' },
    countryCode: 'DK',
    name: 'Hovedstaden',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec8' },
    countryCode: 'DM',
    name: 'Saint Joseph',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff03' },
    countryCode: 'DZ',
    name: 'Tébessa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff05' },
    countryCode: 'DZ',
    name: 'Tiaret',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff37' },
    countryCode: 'EC',
    name: 'Morona-Santiago',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3b' },
    countryCode: 'EC',
    name: 'Sucumbíos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff61' },
    countryCode: 'EG',
    name: 'AI Minya',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff67' },
    countryCode: 'EG',
    name: "Shamal Sina'",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb9' },
    countryCode: 'DJ',
    name: 'Arta',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feba' },
    countryCode: 'DJ',
    name: 'Ali Sabieh',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed2' },
    countryCode: 'DO',
    name: 'Dajabón',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee4' },
    countryCode: 'DO',
    name: 'San Pedro de Macorís',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef8' },
    countryCode: 'DZ',
    name: 'Adrar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff00' },
    countryCode: 'DZ',
    name: 'Blida',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff22' },
    countryCode: 'DZ',
    name: 'Mila',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff33' },
    countryCode: 'EC',
    name: 'Napo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3c' },
    countryCode: 'EC',
    name: 'Galápagos',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff42' },
    countryCode: 'EE',
    name: 'Ida-Virumaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5e' },
    countryCode: 'EG',
    name: 'Kafr ash Shaykh',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6e' },
    countryCode: 'ER',
    name: 'Ma’ikel',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb4' },
    countryCode: 'DE',
    name: 'Schleswig-Holstein',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb7' },
    countryCode: 'DE',
    name: 'Sachsen-Anhalt',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feb8' },
    countryCode: 'DE',
    name: 'Thüringen',
    type: 'Länder',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fecb' },
    countryCode: 'DM',
    name: 'Saint Patrick',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fecc' },
    countryCode: 'DM',
    name: 'Saint Paul',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fecf' },
    countryCode: 'DO',
    name: 'Azua',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee9' },
    countryCode: 'DO',
    name: 'Monseñor Nouel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef2' },
    countryCode: 'DO',
    name: 'El Valle',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff06' },
    countryCode: 'DZ',
    name: 'Tizi Ouzou',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff09' },
    countryCode: 'DZ',
    name: 'Jijel',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff16' },
    countryCode: 'DZ',
    name: 'Oran',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1a' },
    countryCode: 'DZ',
    name: 'Boumerdès',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1b' },
    countryCode: 'DZ',
    name: 'El Tarf',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff25' },
    countryCode: 'DZ',
    name: 'Aïn Témouchent',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff2c' },
    countryCode: 'EC',
    name: 'Esmeraldas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff3e' },
    countryCode: 'EC',
    name: 'Pastaza',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff44' },
    countryCode: 'EE',
    name: 'Järvamaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff4c' },
    countryCode: 'EE',
    name: 'Valgamaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff59' },
    countryCode: 'EG',
    name: 'AI Gharbiyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec3' },
    countryCode: 'DK',
    name: 'Sjælland',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fece' },
    countryCode: 'DO',
    name: 'Distrito Nacional (Santo Domingo)',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee5' },
    countryCode: 'DO',
    name: 'Sánchez Ramírez',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feee' },
    countryCode: 'DO',
    name: 'Cibao Nordeste',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef4' },
    countryCode: 'DO',
    name: 'Higuamo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef6' },
    countryCode: 'DO',
    name: 'Valdesia',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fef9' },
    countryCode: 'DZ',
    name: 'Chlef',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0f' },
    countryCode: 'DZ',
    name: 'Guelma',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff15' },
    countryCode: 'DZ',
    name: 'Ouargla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1d' },
    countryCode: 'DZ',
    name: 'Tissemsilt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff27' },
    countryCode: 'DZ',
    name: 'Relizane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff30' },
    countryCode: 'EC',
    name: 'Imbabura',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff5a' },
    countryCode: 'EG',
    name: 'AI Jizah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec9' },
    countryCode: 'DM',
    name: 'Saint Luke',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04febc' },
    countryCode: 'DJ',
    name: 'Djibouti',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fed0' },
    countryCode: 'DO',
    name: 'Baoruco',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feec' },
    countryCode: 'DO',
    name: 'San José de Ocoa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fefb' },
    countryCode: 'DZ',
    name: 'Oum el Bouaghi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fefc' },
    countryCode: 'DZ',
    name: 'Batna',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fefd' },
    countryCode: 'DZ',
    name: 'Béjaïa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fefe' },
    countryCode: 'DZ',
    name: 'Biskra',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff0d' },
    countryCode: 'DZ',
    name: 'Sidi Bel Abbès',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1c' },
    countryCode: 'DZ',
    name: 'Tindouf',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff47' },
    countryCode: 'EE',
    name: 'Põlvamaa',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff66' },
    countryCode: 'EG',
    name: 'Ash Sharqiyah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff6f' },
    countryCode: 'ER',
    name: 'Semienawi K’eyyi? Ba?ri',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fec6' },
    countryCode: 'DM',
    name: 'Saint George',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fecd' },
    countryCode: 'DM',
    name: 'Saint Peter',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee1' },
    countryCode: 'DO',
    name: 'Samaná',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee6' },
    countryCode: 'DO',
    name: 'Santiago',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fee8' },
    countryCode: 'DO',
    name: 'Valverde',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04feef' },
    countryCode: 'DO',
    name: 'Cibao Noroeste',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04fefa' },
    countryCode: 'DZ',
    name: 'Laghouat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff1e' },
    countryCode: 'DZ',
    name: 'El Oued',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff26' },
    countryCode: 'DZ',
    name: 'Ghardaïa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff28' },
    countryCode: 'EC',
    name: 'Azuay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8cf7cb0a71dbc04ff65' },
    countryCode: 'EG',
    name: 'Suhaj',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2a' },
    countryCode: 'TG',
    name: 'Savannes',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b32' },
    countryCode: 'TH',
    name: 'Sing Buri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b43' },
    countryCode: 'TH',
    name: 'Chaiyaphum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2f' },
    countryCode: 'TH',
    name: 'Phra Nakhon Si Ayutthaya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b30' },
    countryCode: 'TH',
    name: 'Ang Thong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b34' },
    countryCode: 'TH',
    name: 'Saraburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b35' },
    countryCode: 'TH',
    name: 'Chon Buri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b42' },
    countryCode: 'TH',
    name: 'Yasothon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3f' },
    countryCode: 'TH',
    name: 'Surin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b44' },
    countryCode: 'TH',
    name: 'Amnat Charoen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3e' },
    countryCode: 'TH',
    name: 'Buri Ram',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b39' },
    countryCode: 'TH',
    name: 'Chachoengsao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b29' },
    countryCode: 'TG',
    name: 'Plateaux',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3a' },
    countryCode: 'TH',
    name: 'Prachin Buri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2b' },
    countryCode: 'TH',
    name: 'Krung Thep Maha Nakhon [Bangkok]',
    type: 'Metropolitan administration',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b40' },
    countryCode: 'TH',
    name: 'Si Sa Ket',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b41' },
    countryCode: 'TH',
    name: 'Ubon Ratchathani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3b' },
    countryCode: 'TH',
    name: 'Nakhon Nayok',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2e' },
    countryCode: 'TH',
    name: 'Pathum Thani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b33' },
    countryCode: 'TH',
    name: 'Chai Nat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b38' },
    countryCode: 'TH',
    name: 'Trat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3d' },
    countryCode: 'TH',
    name: 'Nakhon Ratchasima',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2c' },
    countryCode: 'TH',
    name: 'Samut Prakan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b2d' },
    countryCode: 'TH',
    name: 'Nonthaburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b28' },
    countryCode: 'TG',
    name: 'Maritime (Région)',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b31' },
    countryCode: 'TH',
    name: 'Lop Buri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b37' },
    countryCode: 'TH',
    name: 'Chanthaburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b3c' },
    countryCode: 'TH',
    name: 'Sa Kaeo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b36' },
    countryCode: 'TH',
    name: 'Rayong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6e' },
    countryCode: 'TH',
    name: 'Surat Thani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b48' },
    countryCode: 'TH',
    name: 'Udon Thani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4e' },
    countryCode: 'TH',
    name: 'Sakon Nakhon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b51' },
    countryCode: 'TH',
    name: 'Chiang Mai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b62' },
    countryCode: 'TH',
    name: 'Ratchaburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b72' },
    countryCode: 'TH',
    name: 'Satun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b69' },
    countryCode: 'TH',
    name: 'Prachuap Khiri Khan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6a' },
    countryCode: 'TH',
    name: 'Nakhon Si Thammarat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b71' },
    countryCode: 'TH',
    name: 'Songkhla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b49' },
    countryCode: 'TH',
    name: 'Loei',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b55' },
    countryCode: 'TH',
    name: 'Phrae',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b52' },
    countryCode: 'TH',
    name: 'Lamphun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b56' },
    countryCode: 'TH',
    name: 'Nan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b64' },
    countryCode: 'TH',
    name: 'Suphan Buri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b68' },
    countryCode: 'TH',
    name: 'Phetchaburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6d' },
    countryCode: 'TH',
    name: 'Phuket',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6f' },
    countryCode: 'TH',
    name: 'Ranong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b7b' },
    countryCode: 'TJ',
    name: 'Khatlon',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b60' },
    countryCode: 'TH',
    name: 'Phichit',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4a' },
    countryCode: 'TH',
    name: 'Nong Khai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b81' },
    countryCode: 'TL',
    name: 'Bobonaro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b65' },
    countryCode: 'TH',
    name: 'Nakhon Pathom',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b74' },
    countryCode: 'TH',
    name: 'Phatthalung',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b89' },
    countryCode: 'TL',
    name: 'Oecussi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b70' },
    countryCode: 'TH',
    name: 'Chumphon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b45' },
    countryCode: 'TH',
    name: 'Bueng Kan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b77' },
    countryCode: 'TH',
    name: 'Narathiwat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b7a' },
    countryCode: 'TJ',
    name: 'Kuhistoni Badakhshon',
    type: 'Autonomous region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b7d' },
    countryCode: 'TJ',
    name: 'Sughd',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b53' },
    countryCode: 'TH',
    name: 'Lampang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b54' },
    countryCode: 'TH',
    name: 'Uttaradit',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5d' },
    countryCode: 'TH',
    name: 'Tak',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b63' },
    countryCode: 'TH',
    name: 'Kanchanaburi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b75' },
    countryCode: 'TH',
    name: 'Pattani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b78' },
    countryCode: 'TH',
    name: 'Phatthaya',
    type: 'special administrative\r\n\r\nSpecial administrative city',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b80' },
    countryCode: 'TL',
    name: 'Baucau',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b82' },
    countryCode: 'TL',
    name: 'Cova Lima',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b83' },
    countryCode: 'TL',
    name: 'Díli',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b87' },
    countryCode: 'TL',
    name: 'Manufahi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b57' },
    countryCode: 'TH',
    name: 'Phayao',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5a' },
    countryCode: 'TH',
    name: 'Nakhon Sawan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b66' },
    countryCode: 'TH',
    name: 'Samut Sakhon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b73' },
    countryCode: 'TH',
    name: 'Trang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b7e' },
    countryCode: 'TL',
    name: 'Aileu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b84' },
    countryCode: 'TL',
    name: 'Ermera',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8a' },
    countryCode: 'TL',
    name: 'Viqueque',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b59' },
    countryCode: 'TH',
    name: 'Mae Hong Son',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4c' },
    countryCode: 'TH',
    name: 'Roi Et',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b79' },
    countryCode: 'TJ',
    name: 'Dushanbe',
    type: 'Capital territory',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b85' },
    countryCode: 'TL',
    name: 'Lautem',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5e' },
    countryCode: 'TH',
    name: 'Sukhothai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4d' },
    countryCode: 'TH',
    name: 'Kalasin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4f' },
    countryCode: 'TH',
    name: 'Nakhon Phanom',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b67' },
    countryCode: 'TH',
    name: 'Samut Songkhram',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6b' },
    countryCode: 'TH',
    name: 'Krabi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b7c' },
    countryCode: 'TJ',
    name: 'Nohiyahoi Tobei Jumhurí',
    type: 'Districts Under Republic Administration',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b7f' },
    countryCode: 'TL',
    name: 'Ainaro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b88' },
    countryCode: 'TL',
    name: 'Manatuto',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b47' },
    countryCode: 'TH',
    name: 'Khon Kaen',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5b' },
    countryCode: 'TH',
    name: 'Uthai Thani',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5c' },
    countryCode: 'TH',
    name: 'Kamphaeng Phet',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b6c' },
    countryCode: 'TH',
    name: 'Phangnga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b86' },
    countryCode: 'TL',
    name: 'Liquiça',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b46' },
    countryCode: 'TH',
    name: 'Nong Bua Lam Phu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b58' },
    countryCode: 'TH',
    name: 'Chiang Rai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b4b' },
    countryCode: 'TH',
    name: 'Maha Sarakham',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b50' },
    countryCode: 'TH',
    name: 'Mukdahan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b5f' },
    countryCode: 'TH',
    name: 'Phitsanulok',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b61' },
    countryCode: 'TH',
    name: 'Phetchabun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d17cb0a71dbc050b76' },
    countryCode: 'TH',
    name: 'Yala',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8b' },
    countryCode: 'TM',
    name: 'Ahal',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b98' },
    countryCode: 'TN',
    name: 'Béja',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b96' },
    countryCode: 'TN',
    name: 'Zaghouan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bad' },
    countryCode: 'TO',
    name: "Vava'u",
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bbc' },
    countryCode: 'TR',
    name: 'Burdur',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c21' },
    countryCode: 'TW',
    name: 'Miaoli',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2b' },
    countryCode: 'TW',
    name: 'Yunlin',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c33' },
    countryCode: 'TZ',
    name: 'Kigoma',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba9' },
    countryCode: 'TO',
    name: "'Eua",
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bae' },
    countryCode: 'TR',
    name: 'Adana',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb2' },
    countryCode: 'TR',
    name: 'Amasya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb4' },
    countryCode: 'TR',
    name: 'Antalya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc6' },
    countryCode: 'TR',
    name: 'Erzurum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc7' },
    countryCode: 'TR',
    name: 'Eskisehir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd5' },
    countryCode: 'TR',
    name: 'Kirsehir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bdc' },
    countryCode: 'TR',
    name: 'Mardin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be3' },
    countryCode: 'TR',
    name: 'Sakarya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be4' },
    countryCode: 'TR',
    name: 'Samsun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bed' },
    countryCode: 'TR',
    name: 'Usak',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf1' },
    countryCode: 'TR',
    name: 'Aksaray',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c18' },
    countryCode: 'TW',
    name: 'Chiayi',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1e' },
    countryCode: 'TW',
    name: 'Kaohsiung',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1f' },
    countryCode: 'TW',
    name: 'Kinmen',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2d' },
    countryCode: 'TZ',
    name: 'Dar es Salaam',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3d' },
    countryCode: 'TZ',
    name: 'Mwanza',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c40' },
    countryCode: 'TZ',
    name: 'Ruvuma',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c46' },
    countryCode: 'TZ',
    name: 'Geita',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050baa' },
    countryCode: 'TO',
    name: "Ha'apai",
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc1' },
    countryCode: 'TR',
    name: 'Denizli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd6' },
    countryCode: 'TR',
    name: 'Kocaeli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be5' },
    countryCode: 'TR',
    name: 'Siirt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bee' },
    countryCode: 'TR',
    name: 'Van',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bef' },
    countryCode: 'TR',
    name: 'Yozgat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bff' },
    countryCode: 'TT',
    name: 'Arima',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c14' },
    countryCode: 'TV',
    name: 'Nanumaga',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c27' },
    countryCode: 'TW',
    name: 'Tainan',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4d' },
    countryCode: 'UA',
    name: 'Dnipropetrovska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba3' },
    countryCode: 'TN',
    name: 'Gafsa',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8f' },
    countryCode: 'TM',
    name: 'Mary',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc9' },
    countryCode: 'TR',
    name: 'Giresun',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd0' },
    countryCode: 'TR',
    name: 'Izmir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be6' },
    countryCode: 'TR',
    name: 'Sinop',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0c' },
    countryCode: 'TT',
    name: 'Tobago',
    type: 'ward',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1a' },
    countryCode: 'TW',
    name: 'Hsinchu',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1b' },
    countryCode: 'TW',
    name: 'Hualien',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c20' },
    countryCode: 'TW',
    name: 'Lienchiang',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2e' },
    countryCode: 'TZ',
    name: 'Dodoma',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c36' },
    countryCode: 'TZ',
    name: 'Kusini Unguja',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3c' },
    countryCode: 'TZ',
    name: 'Mtwara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b90' },
    countryCode: 'TM',
    name: 'Asgabat',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9c' },
    countryCode: 'TN',
    name: 'Kairouan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb5' },
    countryCode: 'TR',
    name: 'Artvin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb7' },
    countryCode: 'TR',
    name: 'Balikesir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd9' },
    countryCode: 'TR',
    name: 'Malatya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be9' },
    countryCode: 'TR',
    name: 'Tokat',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c12' },
    countryCode: 'TV',
    name: 'Nukulaelae',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c16' },
    countryCode: 'TW',
    name: 'Changhua',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c39' },
    countryCode: 'TZ',
    name: 'Mbeya',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3a' },
    countryCode: 'TZ',
    name: 'Mjini Magharibi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3b' },
    countryCode: 'TZ',
    name: 'Morogoro',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c42' },
    countryCode: 'TZ',
    name: 'Singida',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4e' },
    countryCode: 'UA',
    name: 'Donetska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8d' },
    countryCode: 'TM',
    name: 'Dasoguz',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc8' },
    countryCode: 'TR',
    name: 'Gaziantep',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b94' },
    countryCode: 'TN',
    name: 'La Manouba',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b95' },
    countryCode: 'TN',
    name: 'Nabeul',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba0' },
    countryCode: 'TN',
    name: 'Monastir',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba8' },
    countryCode: 'TN',
    name: 'Tataouine',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb6' },
    countryCode: 'TR',
    name: 'Aydin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb9' },
    countryCode: 'TR',
    name: 'Bingöl',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bba' },
    countryCode: 'TR',
    name: 'Bitlis',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc2' },
    countryCode: 'TR',
    name: 'Diyarbakir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bcd' },
    countryCode: 'TR',
    name: 'Isparta',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be8' },
    countryCode: 'TR',
    name: 'Tekirdag',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bec' },
    countryCode: 'TR',
    name: 'Sanliurfa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf2' },
    countryCode: 'TR',
    name: 'Bayburt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf5' },
    countryCode: 'TR',
    name: 'Batman',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c00' },
    countryCode: 'TT',
    name: 'Chaguanas',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c05' },
    countryCode: 'TT',
    name: 'Port of Spain',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c07' },
    countryCode: 'TT',
    name: 'Point Fortin',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0b' },
    countryCode: 'TT',
    name: 'San Juan-Laventille',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c44' },
    countryCode: 'TZ',
    name: 'Tanga',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bdb' },
    countryCode: 'TR',
    name: 'Kahramanmaras',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bdd' },
    countryCode: 'TR',
    name: 'Mugla',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bea' },
    countryCode: 'TR',
    name: 'Trabzon',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c03' },
    countryCode: 'TT',
    name: 'Mayaro-Rio Claro',
    type: 'region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c09' },
    countryCode: 'TT',
    name: 'Sangre Grande',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0d' },
    countryCode: 'TT',
    name: 'Tunapuna-Piarco',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c10' },
    countryCode: 'TV',
    name: 'Nui',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c17' },
    countryCode: 'TW',
    name: 'Chiayi',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c47' },
    countryCode: 'TZ',
    name: 'Katavi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4f' },
    countryCode: 'UA',
    name: 'Zhytomyrska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b91' },
    countryCode: 'TN',
    name: 'Tunis',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b97' },
    countryCode: 'TN',
    name: 'Bizerte',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b99' },
    countryCode: 'TN',
    name: 'Jendouba',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bbe' },
    countryCode: 'TR',
    name: 'Canakkale',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bcf' },
    countryCode: 'TR',
    name: 'Istanbul',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be0' },
    countryCode: 'TR',
    name: 'Nigde',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be2' },
    countryCode: 'TR',
    name: 'Rize',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050beb' },
    countryCode: 'TR',
    name: 'Tunceli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0a' },
    countryCode: 'TT',
    name: 'Siparia',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1d' },
    countryCode: 'TW',
    name: 'Keelung',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9b' },
    countryCode: 'TN',
    name: 'Siliana',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9f' },
    countryCode: 'TN',
    name: 'Sousse',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba6' },
    countryCode: 'TN',
    name: 'Gabès',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb1' },
    countryCode: 'TR',
    name: 'Agri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc5' },
    countryCode: 'TR',
    name: 'Erzincan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd3' },
    countryCode: 'TR',
    name: 'Kayseri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be7' },
    countryCode: 'TR',
    name: 'Sivas',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bfb' },
    countryCode: 'TR',
    name: 'Karabuk',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bfc' },
    countryCode: 'TR',
    name: 'Kilis',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c04' },
    countryCode: 'TT',
    name: 'Penal-Debe',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c08' },
    countryCode: 'TT',
    name: 'San Fernando',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0f' },
    countryCode: 'TV',
    name: 'Niutao',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c19' },
    countryCode: 'TW',
    name: 'Hsinchu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c22' },
    countryCode: 'TW',
    name: 'Nantou',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c25' },
    countryCode: 'TW',
    name: 'Pingtung',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b92' },
    countryCode: 'TN',
    name: "L'Ariana",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb0' },
    countryCode: 'TR',
    name: 'Afyonkarahisar',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bcc' },
    countryCode: 'TR',
    name: 'Hatay',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd2' },
    countryCode: 'TR',
    name: 'Kastamonu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bfe' },
    countryCode: 'TR',
    name: 'Düzce',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c06' },
    countryCode: 'TT',
    name: 'Princes Town',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2c' },
    countryCode: 'TZ',
    name: 'Arusha',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2f' },
    countryCode: 'TZ',
    name: 'Iringa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c48' },
    countryCode: 'TZ',
    name: 'Njombe',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4b' },
    countryCode: 'UA',
    name: 'Volynska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9e' },
    countryCode: 'TN',
    name: 'Sidi Bouzid',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba4' },
    countryCode: 'TN',
    name: 'Tozeur',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bbd' },
    countryCode: 'TR',
    name: 'Bursa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc0' },
    countryCode: 'TR',
    name: 'Corum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bce' },
    countryCode: 'TR',
    name: 'Mersin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd1' },
    countryCode: 'TR',
    name: 'Kars',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c0e' },
    countryCode: 'TV',
    name: 'Funafuti',
    type: 'Town council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c15' },
    countryCode: 'TV',
    name: 'Vaitupu',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c23' },
    countryCode: 'TW',
    name: 'New Taipei',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c28' },
    countryCode: 'TW',
    name: 'Taipei',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba7' },
    countryCode: 'TN',
    name: 'Médenine',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bab' },
    countryCode: 'TO',
    name: 'Niuas',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bbf' },
    countryCode: 'TR',
    name: 'Çankiri',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bca' },
    countryCode: 'TR',
    name: 'Gümüshane',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bdf' },
    countryCode: 'TR',
    name: 'Nevsehir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050be1' },
    countryCode: 'TR',
    name: 'Ordu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf4' },
    countryCode: 'TR',
    name: 'Kirikkale',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bfa' },
    countryCode: 'TR',
    name: 'Yalova',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c11' },
    countryCode: 'TV',
    name: 'Nukufetau',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c13' },
    countryCode: 'TV',
    name: 'Nanumea',
    type: 'Island council',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c35' },
    countryCode: 'TZ',
    name: 'Kusini Pemba',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c37' },
    countryCode: 'TZ',
    name: 'Lindi',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c41' },
    countryCode: 'TZ',
    name: 'Shinyanga',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4a' },
    countryCode: 'UA',
    name: 'Vinnytska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c4c' },
    countryCode: 'UA',
    name: 'Luhanska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9a' },
    countryCode: 'TN',
    name: 'Le Kef',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba2' },
    countryCode: 'TN',
    name: 'Sfax',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb3' },
    countryCode: 'TR',
    name: 'Ankara',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bda' },
    countryCode: 'TR',
    name: 'Manisa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bde' },
    countryCode: 'TR',
    name: 'Mus',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bfd' },
    countryCode: 'TR',
    name: 'Osmaniye',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c29' },
    countryCode: 'TW',
    name: 'Taitung',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c30' },
    countryCode: 'TZ',
    name: 'Kagera',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3e' },
    countryCode: 'TZ',
    name: 'Pwani',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c49' },
    countryCode: 'TZ',
    name: 'Simiyu',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8e' },
    countryCode: 'TM',
    name: 'Lebap',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b93' },
    countryCode: 'TN',
    name: 'Ben Arous',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050baf' },
    countryCode: 'TR',
    name: 'Adiyaman',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc4' },
    countryCode: 'TR',
    name: 'Elazig',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd4' },
    countryCode: 'TR',
    name: 'Kirklareli',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf8' },
    countryCode: 'TR',
    name: 'Ardahan',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c01' },
    countryCode: 'TT',
    name: 'Couva-Tabaquite-Talparo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c02' },
    countryCode: 'TT',
    name: 'Diego Martin',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c1c' },
    countryCode: 'TW',
    name: 'Yilan',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c32' },
    countryCode: 'TZ',
    name: 'Kaskazini Unguja',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c34' },
    countryCode: 'TZ',
    name: 'Kilimanjaro',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c38' },
    countryCode: 'TZ',
    name: 'Mara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c43' },
    countryCode: 'TZ',
    name: 'Tabora',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b8c' },
    countryCode: 'TM',
    name: 'Balkan',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050b9d' },
    countryCode: 'TN',
    name: 'Kasserine',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba1' },
    countryCode: 'TN',
    name: 'Mahdia',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ba5' },
    countryCode: 'TN',
    name: 'Kébili',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bb8' },
    countryCode: 'TR',
    name: 'Bilecik',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd7' },
    countryCode: 'TR',
    name: 'Konya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf0' },
    countryCode: 'TR',
    name: 'Zonguldak',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf6' },
    countryCode: 'TR',
    name: 'Sirnak',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf7' },
    countryCode: 'TR',
    name: 'Bartin',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c24' },
    countryCode: 'TW',
    name: 'Penghu',
    type: 'County',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c26' },
    countryCode: 'TW',
    name: 'Taoyuan',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c2a' },
    countryCode: 'TW',
    name: 'Taichung',
    type: 'special municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c45' },
    countryCode: 'TZ',
    name: 'Manyara',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bac' },
    countryCode: 'TO',
    name: 'Tongatapu',
    type: 'Division',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bbb' },
    countryCode: 'TR',
    name: 'Bolu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bc3' },
    countryCode: 'TR',
    name: 'Edirne',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bcb' },
    countryCode: 'TR',
    name: 'Hakkari',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bd8' },
    countryCode: 'TR',
    name: 'Kütahya',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf3' },
    countryCode: 'TR',
    name: 'Karaman',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050bf9' },
    countryCode: 'TR',
    name: 'Igdir',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c31' },
    countryCode: 'TZ',
    name: 'Kaskazini Pemba',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c3f' },
    countryCode: 'TZ',
    name: 'Rukwa',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c52' },
    countryCode: 'UA',
    name: 'Ivano-Frankivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c59' },
    countryCode: 'UA',
    name: 'Mykolaivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c66' },
    countryCode: 'UG',
    name: 'Kampala',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6e' },
    countryCode: 'UG',
    name: 'Rakai',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6f' },
    countryCode: 'UG',
    name: 'Sembabule',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c86' },
    countryCode: 'UG',
    name: 'Pallisa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb2' },
    countryCode: 'UG',
    name: 'Kole',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb9' },
    countryCode: 'UG',
    name: 'Bushenyi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cbb' },
    countryCode: 'UG',
    name: 'Kabale',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc1' },
    countryCode: 'UG',
    name: 'Mbarara',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c83' },
    countryCode: 'UG',
    name: 'Katakwi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c84' },
    countryCode: 'UG',
    name: 'Kumi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8b' },
    countryCode: 'UG',
    name: 'Amuria',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca5' },
    countryCode: 'UG',
    name: 'Moyo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb3' },
    countryCode: 'UG',
    name: 'Lamwo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb5' },
    countryCode: 'UG',
    name: 'Nwoya',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc6' },
    countryCode: 'UG',
    name: 'Kyenjojo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5a' },
    countryCode: 'UA',
    name: 'Odeska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c51' },
    countryCode: 'UA',
    name: 'Zaporizka oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c63' },
    countryCode: 'UA',
    name: 'Chernihivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8d' },
    countryCode: 'UG',
    name: 'Bududa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c94' },
    countryCode: 'UG',
    name: 'Bulambuli',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cbe' },
    countryCode: 'UG',
    name: 'Kibaale',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5b' },
    countryCode: 'UA',
    name: 'Poltavska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c68' },
    countryCode: 'UG',
    name: 'Luwero',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6d' },
    countryCode: 'UG',
    name: 'Nakasongola',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c74' },
    countryCode: 'UG',
    name: 'Nakaseke',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8a' },
    countryCode: 'UG',
    name: 'Sironko',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c91' },
    countryCode: 'UG',
    name: 'Kaliro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c95' },
    countryCode: 'UG',
    name: 'Buyende',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9f' },
    countryCode: 'UG',
    name: 'Arua',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca3' },
    countryCode: 'UG',
    name: 'Lira',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca4' },
    countryCode: 'UG',
    name: 'Moroto',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c55' },
    countryCode: 'UA',
    name: 'Kirovohradska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c58' },
    countryCode: 'UA',
    name: 'Lvivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c64' },
    countryCode: 'UA',
    name: 'Chernivetska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7d' },
    countryCode: 'UG',
    name: 'Bugiri',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8e' },
    countryCode: 'UG',
    name: 'Bukedea',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb1' },
    countryCode: 'UG',
    name: 'Oyam',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c72' },
    countryCode: 'UG',
    name: 'Lyantonde',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7c' },
    countryCode: 'UG',
    name: 'Lwengo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c82' },
    countryCode: 'UG',
    name: 'Kapchorwa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca8' },
    countryCode: 'UG',
    name: 'Pader',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cae' },
    countryCode: 'UG',
    name: 'Kaabong',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc8' },
    countryCode: 'UG',
    name: 'Ibanda',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6b' },
    countryCode: 'UG',
    name: 'Mubende',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c80' },
    countryCode: 'UG',
    name: 'Jinja',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c87' },
    countryCode: 'UG',
    name: 'Soroti',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cad' },
    countryCode: 'UG',
    name: 'Dokolo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cbd' },
    countryCode: 'UG',
    name: 'Kasese',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc3' },
    countryCode: 'UG',
    name: 'Rukungiri',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c53' },
    countryCode: 'UA',
    name: 'Kyiv',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c67' },
    countryCode: 'UG',
    name: 'Kiboga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c89' },
    countryCode: 'UG',
    name: 'Kaberamaido',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cba' },
    countryCode: 'UG',
    name: 'Hoima',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc5' },
    countryCode: 'UG',
    name: 'Kanungu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c90' },
    countryCode: 'UG',
    name: 'Butaleja',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c99' },
    countryCode: 'UG',
    name: 'Namayingo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca1' },
    countryCode: 'UG',
    name: 'Kitgum',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050caa' },
    countryCode: 'UG',
    name: 'Abim',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5d' },
    countryCode: 'UA',
    name: 'Sumska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6c' },
    countryCode: 'UG',
    name: 'Mukono',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c71' },
    countryCode: 'UG',
    name: 'Wakiso',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c78' },
    countryCode: 'UG',
    name: 'Buvuma',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7a' },
    countryCode: 'UG',
    name: 'Kalungu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8c' },
    countryCode: 'UG',
    name: 'Budaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c8f' },
    countryCode: 'UG',
    name: 'Bukwa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9d' },
    countryCode: 'UG',
    name: 'Adjumani',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cac' },
    countryCode: 'UG',
    name: 'Amuru',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb7' },
    countryCode: 'UG',
    name: 'Zombo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c56' },
    countryCode: 'UA',
    name: 'Sevastopol',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c6a' },
    countryCode: 'UG',
    name: 'Mpigi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c70' },
    countryCode: 'UG',
    name: 'Kayunga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7e' },
    countryCode: 'UG',
    name: 'Busia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c81' },
    countryCode: 'UG',
    name: 'Kamuli',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9b' },
    countryCode: 'UG',
    name: 'Serere',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9c' },
    countryCode: 'UG',
    name: 'Mayuge',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb8' },
    countryCode: 'UG',
    name: 'Bundibugyo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ccb' },
    countryCode: 'UG',
    name: 'Buhweju',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c61' },
    countryCode: 'UA',
    name: 'Khmelnytska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c76' },
    countryCode: 'UG',
    name: 'Bukomansibi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c75' },
    countryCode: 'UG',
    name: 'Buikwe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca2' },
    countryCode: 'UG',
    name: 'Kotido',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc0' },
    countryCode: 'UG',
    name: 'Masindi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc7' },
    countryCode: 'UG',
    name: 'Buliisa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cca' },
    countryCode: 'UG',
    name: 'Kiruhura',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c77' },
    countryCode: 'UG',
    name: 'Butambala',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c92' },
    countryCode: 'UG',
    name: 'Manafwa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c97' },
    countryCode: 'UG',
    name: 'Kween',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9a' },
    countryCode: 'UG',
    name: 'Ngora',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb6' },
    countryCode: 'UG',
    name: 'Otuke',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc2' },
    countryCode: 'UG',
    name: 'Ntungamo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c65' },
    countryCode: 'UG',
    name: 'Kalangala',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c69' },
    countryCode: 'UG',
    name: 'Masaka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c73' },
    countryCode: 'UG',
    name: 'Mityana',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c98' },
    countryCode: 'UG',
    name: 'Luuka',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca0' },
    countryCode: 'UG',
    name: 'Gulu',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050caf' },
    countryCode: 'UG',
    name: 'Koboko',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cbc' },
    countryCode: 'UG',
    name: 'Kabarole',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc9' },
    countryCode: 'UG',
    name: 'Isingiro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c54' },
    countryCode: 'UA',
    name: 'Kyivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5f' },
    countryCode: 'UA',
    name: 'Kharkivska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c62' },
    countryCode: 'UA',
    name: 'Cherkaska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c88' },
    countryCode: 'UG',
    name: 'Tororo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c96' },
    countryCode: 'UG',
    name: 'Kibuku',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c9e' },
    countryCode: 'UG',
    name: 'Apac',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb4' },
    countryCode: 'UG',
    name: 'Napak',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cbf' },
    countryCode: 'UG',
    name: 'Kisoro',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cc4' },
    countryCode: 'UG',
    name: 'Kamwenge',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c50' },
    countryCode: 'UA',
    name: 'Zakarpatska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c57' },
    countryCode: 'UA',
    name: 'Avtonomna Respublika Krym',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5c' },
    countryCode: 'UA',
    name: 'Rivnenska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c5e' },
    countryCode: 'UA',
    name: 'Ternopilska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c60' },
    countryCode: 'UA',
    name: 'Khersonska oblast',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c79' },
    countryCode: 'UG',
    name: 'Gomba',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7b' },
    countryCode: 'UG',
    name: 'Kyankwanzi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c7f' },
    countryCode: 'UG',
    name: 'Iganga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c85' },
    countryCode: 'UG',
    name: 'Mbale',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050c93' },
    countryCode: 'UG',
    name: 'Namutumba',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca6' },
    countryCode: 'UG',
    name: 'Nebbi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca7' },
    countryCode: 'UG',
    name: 'Nakapiripirit',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ca9' },
    countryCode: 'UG',
    name: 'Yumbe',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cab' },
    countryCode: 'UG',
    name: 'Amolatar',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cb0' },
    countryCode: 'UG',
    name: 'Maracha',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce2' },
    countryCode: 'US',
    name: 'Connecticut',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd1' },
    countryCode: 'UG',
    name: 'Sheema',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d01' },
    countryCode: 'US',
    name: 'Ohio',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ced' },
    countryCode: 'US',
    name: 'Kansas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cce' },
    countryCode: 'UG',
    name: 'Mitooma',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cdd' },
    countryCode: 'US',
    name: 'Arkansas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd2' },
    countryCode: 'UM',
    name: 'Johnston Atoll (ISO reserved code = JT)',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cda' },
    countryCode: 'UM',
    name: 'Palmyra Atoll',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ceb' },
    countryCode: 'US',
    name: 'Illinois',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf2' },
    countryCode: 'US',
    name: 'Maine',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf4' },
    countryCode: 'US',
    name: 'Minnesota',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cfb' },
    countryCode: 'US',
    name: 'Nebraska',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d02' },
    countryCode: 'US',
    name: 'Oklahoma',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce1' },
    countryCode: 'US',
    name: 'Colorado',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cdc' },
    countryCode: 'US',
    name: 'Alabama',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce7' },
    countryCode: 'US',
    name: 'Guam (see also separate entry under GU)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:03:54.283+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cfe' },
    countryCode: 'US',
    name: 'New Mexico',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf6' },
    countryCode: 'US',
    name: 'Northern Mariana Islands (see also separate entry MP)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:09:22.801+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ccf' },
    countryCode: 'UG',
    name: 'Ntoroko',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ccd' },
    countryCode: 'UG',
    name: 'Kyegegwa',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce3' },
    countryCode: 'US',
    name: 'District of Columbia',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d03' },
    countryCode: 'US',
    name: 'Oregon',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce9' },
    countryCode: 'US',
    name: 'Iowa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf3' },
    countryCode: 'US',
    name: 'Michigan',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cfc' },
    countryCode: 'US',
    name: 'New Hampshire',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd3' },
    countryCode: 'UM',
    name: 'Midway Islands (ISO reserved code = I)',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd7' },
    countryCode: 'UM',
    name: 'Howland Island',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd6' },
    countryCode: 'UM',
    name: 'Baker Island',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd8' },
    countryCode: 'UM',
    name: 'Jarvis Island',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cdb' },
    countryCode: 'US',
    name: 'Alaska',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce0' },
    countryCode: 'US',
    name: 'California',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce5' },
    countryCode: 'US',
    name: 'Florida',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cfd' },
    countryCode: 'US',
    name: 'New Jersey',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce4' },
    countryCode: 'US',
    name: 'Delaware',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ccc' },
    countryCode: 'UG',
    name: 'Kiryandongo',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cee' },
    countryCode: 'US',
    name: 'Kentucky',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf0' },
    countryCode: 'US',
    name: 'Massachusetts',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf7' },
    countryCode: 'US',
    name: 'Mississippi',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf8' },
    countryCode: 'US',
    name: 'Montana',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cff' },
    countryCode: 'US',
    name: 'Nevada',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cdf' },
    countryCode: 'US',
    name: 'Arizona',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce6' },
    countryCode: 'US',
    name: 'Georgia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cea' },
    countryCode: 'US',
    name: 'Idaho',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cef' },
    countryCode: 'US',
    name: 'Louisiana',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd0' },
    countryCode: 'UG',
    name: 'Rubirizi',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd5' },
    countryCode: 'UM',
    name: 'Wake Island (ISO reserved code = WK)',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cde' },
    countryCode: 'US',
    name: 'American Samoa (see also separate entry under AS)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:03:48.118+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf9' },
    countryCode: 'US',
    name: 'North Carolina',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf1' },
    countryCode: 'US',
    name: 'Maryland',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd9' },
    countryCode: 'UM',
    name: 'Kingman Reef',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cd4' },
    countryCode: 'UM',
    name: 'Navassa Island (ISO reserved code = NV)',
    type: 'Islands/Groups of Islands',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050ce8' },
    countryCode: 'US',
    name: 'Hawaii',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cec' },
    countryCode: 'US',
    name: 'Indiana',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cf5' },
    countryCode: 'US',
    name: 'Missouri',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d00' },
    countryCode: 'US',
    name: 'New York',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050cfa' },
    countryCode: 'US',
    name: 'North Dakota',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d09' },
    countryCode: 'US',
    name: 'Tennessee',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d10' },
    countryCode: 'US',
    name: 'Washington',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d19' },
    countryCode: 'UY',
    name: 'Florida',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1a' },
    countryCode: 'UY',
    name: 'Flores',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d15' },
    countryCode: 'UY',
    name: 'Canelones',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0d' },
    countryCode: 'US',
    name: 'Virginia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d16' },
    countryCode: 'UY',
    name: 'Cerro Largo',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1f' },
    countryCode: 'UY',
    name: 'Río Negro',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d13' },
    countryCode: 'US',
    name: 'Wyoming',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d07' },
    countryCode: 'US',
    name: 'South Carolina',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1d' },
    countryCode: 'UY',
    name: 'Montevideo',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1e' },
    countryCode: 'UY',
    name: 'Paysandú',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d20' },
    countryCode: 'UY',
    name: 'Rocha',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0a' },
    countryCode: 'US',
    name: 'Texas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d14' },
    countryCode: 'UY',
    name: 'Artigas',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0f' },
    countryCode: 'US',
    name: 'Vermont',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1b' },
    countryCode: 'UY',
    name: 'Lavalleja',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d04' },
    countryCode: 'US',
    name: 'Pennsylvania',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d06' },
    countryCode: 'US',
    name: 'Rhode Island',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0b' },
    countryCode: 'US',
    name: 'U.S. Minor Outlying Islands (cf. separate entry UM)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:09:34.041+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d12' },
    countryCode: 'US',
    name: 'West Virginia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d23' },
    countryCode: 'UY',
    name: 'San José',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d08' },
    countryCode: 'US',
    name: 'South Dakota',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0e' },
    countryCode: 'US',
    name: 'Virgin Islands of the U.S. (see also separate entry VI)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:09:39.162+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d11' },
    countryCode: 'US',
    name: 'Wisconsin',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d17' },
    countryCode: 'UY',
    name: 'Colonia',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d18' },
    countryCode: 'UY',
    name: 'Durazno',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d05' },
    countryCode: 'US',
    name: 'Puerto Rico (see also separate entry under PR)',
    type: 'Outlying area',
    isDeleted: true,
    updatedAt: { $date: '2018-12-12T10:09:28.623+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d22' },
    countryCode: 'UY',
    name: 'Salto',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d1c' },
    countryCode: 'UY',
    name: 'Maldonado',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d0c' },
    countryCode: 'US',
    name: 'Utah',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d21' },
    countryCode: 'UY',
    name: 'Rivera',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d38' },
    countryCode: 'VC',
    name: 'Saint David',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2c' },
    countryCode: 'UZ',
    name: 'Namangan',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2e' },
    countryCode: 'UZ',
    name: 'Qashqadaryo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2f' },
    countryCode: 'UZ',
    name: 'Qoraqalpog‘iston Respublikasi',
    type: 'Republic',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d28' },
    countryCode: 'UZ',
    name: 'Bukhoro',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d25' },
    countryCode: 'UY',
    name: 'Tacuarembó',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3e' },
    countryCode: 'VE',
    name: 'Apure',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d31' },
    countryCode: 'UZ',
    name: 'Sirdaryo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d33' },
    countryCode: 'UZ',
    name: 'Toshkent',
    type: 'City',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d35' },
    countryCode: 'UZ',
    name: 'Xorazm',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3b' },
    countryCode: 'VC',
    name: 'Grenadines',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3a' },
    countryCode: 'VC',
    name: 'Saint Patrick',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d27' },
    countryCode: 'UZ',
    name: 'Andijon',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d30' },
    countryCode: 'UZ',
    name: 'Samarqand',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d32' },
    countryCode: 'UZ',
    name: 'Surkhondaryo',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3d' },
    countryCode: 'VE',
    name: 'Anzoátegui',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d40' },
    countryCode: 'VE',
    name: 'Barinas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d36' },
    countryCode: 'VC',
    name: 'Charlotte',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d26' },
    countryCode: 'UY',
    name: 'Treinta y Tres',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d37' },
    countryCode: 'VC',
    name: 'Saint Andrew',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d29' },
    countryCode: 'UZ',
    name: 'Farg‘ona',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2d' },
    countryCode: 'UZ',
    name: 'Nawoiy',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d34' },
    countryCode: 'UZ',
    name: 'Toshkent',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3c' },
    countryCode: 'VE',
    name: 'Distrito Capital',
    type: 'Capital district',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d3f' },
    countryCode: 'VE',
    name: 'Aragua',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d24' },
    countryCode: 'UY',
    name: 'Soriano',
    type: 'Department',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2a' },
    countryCode: 'UZ',
    name: 'Jizzax',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d2b' },
    countryCode: 'UZ',
    name: 'Khorazm',
    type: 'Region',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d39' },
    countryCode: 'VC',
    name: 'Saint George',
    type: 'Parish',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d45' },
    countryCode: 'VE',
    name: 'Guárico',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5f' },
    countryCode: 'VN',
    name: 'Ninh Bình',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6a' },
    countryCode: 'VN',
    name: 'Gia Lai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d66' },
    countryCode: 'VN',
    name: 'Th?a Thiên-Hu?',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d71' },
    countryCode: 'VN',
    name: 'Tây Ninh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d81' },
    countryCode: 'VN',
    name: 'B?c Ninh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d77' },
    countryCode: 'VN',
    name: 'Ð?ng Tháp',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8e' },
    countryCode: 'VN',
    name: 'H?u Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d80' },
    countryCode: 'VN',
    name: 'B?c Liêu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d90' },
    countryCode: 'VN',
    name: 'Da Nang, thanh pho',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d98' },
    countryCode: 'VU',
    name: 'Taféa',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d91' },
    countryCode: 'VN',
    name: 'Ha Noi',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db3' },
    countryCode: 'YE',
    name: 'Al Ma?wit',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db4' },
    countryCode: 'YE',
    name: 'Raymah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc9' },
    countryCode: 'ZM',
    name: 'North-Western',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db6' },
    countryCode: 'YE',
    name: "Sa'dah",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dcc' },
    countryCode: 'ZM',
    name: 'Lusaka',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd3' },
    countryCode: 'ZW',
    name: 'Midlands',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd2' },
    countryCode: 'ZW',
    name: 'Mashonaland East',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4f' },
    countryCode: 'VE',
    name: 'Yaracuy',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d44' },
    countryCode: 'VE',
    name: 'Falcón',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d63' },
    countryCode: 'VN',
    name: 'Hà Tinh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d64' },
    countryCode: 'VN',
    name: 'Qu?ng Bình',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d70' },
    countryCode: 'VN',
    name: 'Ninh Thu?n',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da0' },
    countryCode: 'WS',
    name: 'Palauli',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dbf' },
    countryCode: 'ZA',
    name: 'Mpumalanga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dcd' },
    countryCode: 'ZM',
    name: 'Muchinga',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7f' },
    countryCode: 'VN',
    name: 'B?c Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d88' },
    countryCode: 'VN',
    name: 'Nam Ð?nh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da2' },
    countryCode: 'WS',
    name: 'Tuamasaga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da9' },
    countryCode: 'YE',
    name: 'A? ?ali‘',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dbb' },
    countryCode: 'ZA',
    name: 'Eastern Cape',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd5' },
    countryCode: 'ZW',
    name: 'Matabeleland South',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d42' },
    countryCode: 'VE',
    name: 'Carabobo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d46' },
    countryCode: 'VE',
    name: 'Lara',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4a' },
    countryCode: 'VE',
    name: 'Nueva Esparta',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d84' },
    countryCode: 'VN',
    name: 'Cà Mau',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d89' },
    countryCode: 'VN',
    name: 'Phú Th?',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8a' },
    countryCode: 'VN',
    name: 'Thái Nguyên',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8b' },
    countryCode: 'VN',
    name: 'Vinh Phúc',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050daa' },
    countryCode: 'YE',
    name: 'Dhamar',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dab' },
    countryCode: 'YE',
    name: '?a?ramawt',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dac' },
    countryCode: 'YE',
    name: '?ajjah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc0' },
    countryCode: 'ZA',
    name: 'Northern Cape',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd7' },
    countryCode: 'ZW',
    name: 'Mashonaland West',
    type: 'Province',
  },
  {
    _id: { $oid: '5bacd94093502f2230fd987a' },
    __v: 0,
    countryCode: 'HK',
    createdAt: { $date: '2018-09-27T13:21:04.614+0000' },
    isDeleted: false,
    name: 'Hong Kong',
    type: 'City',
    updatedAt: { $date: '2018-09-27T13:21:04.614+0000' },
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d75' },
    countryCode: 'VN',
    name: 'Bà R?a - Vung Tàu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8c' },
    countryCode: 'VN',
    name: 'Ði?n Biên',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d93' },
    countryCode: 'VN',
    name: 'Ho Chi Minh',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d96' },
    countryCode: 'VU',
    name: 'Sanma',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9d' },
    countryCode: 'WS',
    name: "Fa'asaleleaga",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9e' },
    countryCode: 'WS',
    name: "Gaga'emauga",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db0' },
    countryCode: 'YE',
    name: 'Lahij',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dcf' },
    countryCode: 'ZW',
    name: 'Harare',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd6' },
    countryCode: 'ZW',
    name: 'Masvingo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4b' },
    countryCode: 'VE',
    name: 'Portuguesa',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5b' },
    countryCode: 'VN',
    name: 'Tuyên Quang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d59' },
    countryCode: 'VN',
    name: 'Son La',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6c' },
    countryCode: 'VN',
    name: 'Phú Yên',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7e' },
    countryCode: 'VN',
    name: 'B?c K?n',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8d' },
    countryCode: 'VN',
    name: 'Ð?k Nông',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da5' },
    countryCode: 'YE',
    name: 'Abyan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc8' },
    countryCode: 'ZM',
    name: 'Northern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dce' },
    countryCode: 'ZW',
    name: 'Bulawayo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d85' },
    countryCode: 'VN',
    name: 'H?i Duong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d86' },
    countryCode: 'VN',
    name: 'Hà Nam',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da4' },
    countryCode: 'WS',
    name: 'Vaisigano',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da7' },
    countryCode: 'YE',
    name: '‘Amran',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dae' },
    countryCode: 'YE',
    name: 'Ibb',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db2' },
    countryCode: 'YE',
    name: 'Al Mahrah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dba' },
    countryCode: 'YE',
    name: "Ta'izz",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dbc' },
    countryCode: 'ZA',
    name: 'Free State',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dbe' },
    countryCode: 'ZA',
    name: 'Limpopo',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc7' },
    countryCode: 'ZM',
    name: 'Luapula',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d47' },
    countryCode: 'VE',
    name: 'Mérida',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d43' },
    countryCode: 'VE',
    name: 'Cojedes',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5a' },
    countryCode: 'VN',
    name: 'Yên Bái',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5d' },
    countryCode: 'VN',
    name: 'Qu?ng Ninh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d69' },
    countryCode: 'VN',
    name: 'Qu?ng Ngãi',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d8f' },
    countryCode: 'VN',
    name: 'Can Tho',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9c' },
    countryCode: 'WS',
    name: 'Atua',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da1' },
    countryCode: 'WS',
    name: "Satupa 'itea",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da3' },
    countryCode: 'WS',
    name: "Va'a-o-Fonoti",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dca' },
    countryCode: 'ZM',
    name: 'Southern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d53' },
    countryCode: 'VE',
    name: 'Delta Amacuro',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5c' },
    countryCode: 'VN',
    name: 'L?ng Son',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d74' },
    countryCode: 'VN',
    name: 'Long An',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050daf' },
    countryCode: 'YE',
    name: 'Al Jawf',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc5' },
    countryCode: 'ZM',
    name: 'Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc6' },
    countryCode: 'ZM',
    name: 'Eastern',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd0' },
    countryCode: 'ZW',
    name: 'Manicaland',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d41' },
    countryCode: 'VE',
    name: 'Bolívar',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d51' },
    countryCode: 'VE',
    name: 'Dependencias Federales',
    type: 'Federal dependencies',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4e' },
    countryCode: 'VE',
    name: 'Trujillo',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d55' },
    countryCode: 'VN',
    name: 'Lai Châu',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6e' },
    countryCode: 'VN',
    name: 'Khánh Hòa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6f' },
    countryCode: 'VN',
    name: 'Lâm Ð?ng',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d73' },
    countryCode: 'VN',
    name: 'Bình Thu?n',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d99' },
    countryCode: 'VU',
    name: 'Torba',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9f' },
    countryCode: 'WS',
    name: 'Gagaifomauga',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db5' },
    countryCode: 'YE',
    name: 'Amanat al ‘Asimah [city]',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db7' },
    countryCode: 'YE',
    name: 'Shabwah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc2' },
    countryCode: 'ZA',
    name: 'North-West',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc3' },
    countryCode: 'ZA',
    name: 'Western Cape',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d56' },
    countryCode: 'VN',
    name: 'Lào Cai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d57' },
    countryCode: 'VN',
    name: 'Hà Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d68' },
    countryCode: 'VN',
    name: 'Kon Tum',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6b' },
    countryCode: 'VN',
    name: 'Bình Ð?nh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d6d' },
    countryCode: 'VN',
    name: 'Ð?k L?k',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d72' },
    countryCode: 'VN',
    name: 'Ð?ng Nai',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d78' },
    countryCode: 'VN',
    name: 'Ti?n Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d79' },
    countryCode: 'VN',
    name: 'Ki?n Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d92' },
    countryCode: 'VN',
    name: 'Hai Phong',
    type: 'Municipality',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc4' },
    countryCode: 'ZM',
    name: 'Western',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd1' },
    countryCode: 'ZW',
    name: 'Mashonaland Central',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d58' },
    countryCode: 'VN',
    name: 'Cao B?ng',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d48' },
    countryCode: 'VE',
    name: 'Miranda',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7a' },
    countryCode: 'VN',
    name: 'Vinh Long',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d95' },
    countryCode: 'VU',
    name: 'Pénama',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d97' },
    countryCode: 'VU',
    name: 'Shéfa',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9a' },
    countryCode: 'WS',
    name: "A'ana",
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da8' },
    countryCode: 'YE',
    name: 'Al Bay?a’',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db1' },
    countryCode: 'YE',
    name: 'Ma’rib',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db8' },
    countryCode: 'YE',
    name: "San?a'",
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dbd' },
    countryCode: 'ZA',
    name: 'Gauteng',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dc1' },
    countryCode: 'ZA',
    name: 'Kwazulu-Natal',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4d' },
    countryCode: 'VE',
    name: 'Táchira',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d60' },
    countryCode: 'VN',
    name: 'Thái Bình',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d65' },
    countryCode: 'VN',
    name: 'Qu?ng Tr?',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7d' },
    countryCode: 'VN',
    name: 'Sóc Trang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d83' },
    countryCode: 'VN',
    name: 'Bình Phu?c',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d94' },
    countryCode: 'VU',
    name: 'Malampa',
    type: null,
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d9b' },
    countryCode: 'WS',
    name: 'Aiga-i-le-Tai',
    type: 'District',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d4c' },
    countryCode: 'VE',
    name: 'Sucre',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d49' },
    countryCode: 'VE',
    name: 'Monagas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d52' },
    countryCode: 'VE',
    name: 'vargas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d61' },
    countryCode: 'VN',
    name: 'Thanh Hóa',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d62' },
    countryCode: 'VN',
    name: 'Ngh? An',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7b' },
    countryCode: 'VN',
    name: 'B?n Tre',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d7c' },
    countryCode: 'VN',
    name: 'Trà Vinh',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050da6' },
    countryCode: 'YE',
    name: '‘Adan',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dad' },
    countryCode: 'YE',
    name: 'Al ?udaydah',
    type: 'Governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d50' },
    countryCode: 'VE',
    name: 'Zulia',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d54' },
    countryCode: 'VE',
    name: 'Amazonas',
    type: 'State',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d5e' },
    countryCode: 'VN',
    name: 'Hòa Bình',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d67' },
    countryCode: 'VN',
    name: 'Qu?ng Nam',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d76' },
    countryCode: 'VN',
    name: 'An Giang',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d82' },
    countryCode: 'VN',
    name: 'Bình Duong',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050d87' },
    countryCode: 'VN',
    name: 'Hung Yên',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050db9' },
    countryCode: 'YE',
    name: 'Arkhabil Suqutrá',
    type: 'governorate',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dcb' },
    countryCode: 'ZM',
    name: 'Copperbelt',
    type: 'Province',
  },
  {
    _id: { $oid: '5a69a8d27cb0a71dbc050dd4' },
    countryCode: 'ZW',
    name: 'Matabeleland North',
    type: 'Province',
  },
];

export const getSortedRegions = (regions: RegionDetail[]) => {
  return regions
    .filter((r) => !r?.isDeleted)
    .sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
};

export const getRegionsByCountry = (countryCode: string) => {
  const regions = REGIONS.filter((r) => r.countryCode === countryCode);
  return getSortedRegions(regions);
};

export const getRegionsKeyValue = (countryCode: string) => {
  const regions: RegionOption[] = [];
  getRegionsByCountry(countryCode).map((region) =>
    regions.push({ label: region.name, value: region.name })
  );
  return regions;
};
