import moment from 'moment';

import { getCountryByCode } from '../../constants/countries';
import { Workshop } from '../../types/workshop';

const columns = [
  {
    title: 'SNo.',
    render: (value: string, record: Workshop, index: number) => index + 1,
    width: 200,
  },
  {
    title: 'Title',
    render: (value: string, record: Workshop) => <>{record?.title}</>,
    width: 250,
  },
  {
    title: 'Technique',
    render: (value: string, record: Workshop) => (
      <>{record.techniqueSlug?.toUpperCase()}</>
    ),
    width: 250,
  },
  {
    title: 'Mode',
    render: (value: string, record: Workshop) => <>{record.workshopMode}</>,
    width: 250,
  },

  {
    title: 'Level',
    render: (value: string, record: Workshop) => <>{record.level}</>,
    width: 250,
  },
  {
    title: 'Venue',
    render: (value: string, record: Workshop) => <>{record?.venueName}</>,
    width: 250,
  },
  {
    title: 'State/Region',
    render: (value: string, record: Workshop) => <>{record?.state}</>,
    width: 250,
  },
  {
    title: 'Country',
    render: (value: string, record: Workshop) => (
      <>{getCountryByCode(record.country || '').name || ''}</>
    ),
    width: 250,
  },
  {
    title: 'Start Date',
    render: (value: string, record: Workshop) => (
      <>
        {moment(record.startDate)
          .tz(record.timezone || 'UTC')
          .format('D MMM YYYY, h:mm a')
          ?.toString() || ''}
      </>
    ),
    width: 250,
  },

  {
    title: 'Positions Filled',
    render: (value: string, record: Workshop) => <>{record.closedTickets}</>,
    width: 250,
  },
  {
    title: 'Capacity',
    render: (value: string, record: Workshop) => <>{record.totalTickets}</>,
    width: 250,
  },
  {
    title: 'Occupancy %',
    render: (value: string, record: Workshop) => (
      <>
        {(record.closedTickets === undefined || 0
          ? 0
          : Math.floor((record.closedTickets / record.totalTickets) * 100)) +
          '%'}
      </>
    ),
    width: 250,
  },
  {
    title: 'Tutor',
    render: (value: string, record: Workshop) => (
      <>{record.workshopInstructor?.fullname}</>
    ),
    width: 250,
  },
];

export default columns;
