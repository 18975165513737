import * as yup from 'yup';

import { IncomeReceivedBy } from '../../../../types/workshop';

export default yup.object().shape({
  currency: yup.string().required('Currency is required'),
  note: yup.string().required('Note is required'),
  amount: yup
    .number()
    .positive('Should be positive Number')
    .typeError('Expense amount must be a number')
    .required('Expense amount is required'),
  receivedBy: yup
    .string()
    .oneOf([IncomeReceivedBy.PBT, IncomeReceivedBy.TUTOR])
    .required('ReceivedBy is required'),
});
