import { message, Modal, Button } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
// import { DeleteOutlined } from '@ant-design/icons';

import { handleError } from '../../../utils/common';
import { permanentlyDeleteUser } from '../../../services/users-service';

interface IDeleteUserProps {
  children: FC<{ onClick: () => void }>;
  userId?: string;
  loadUsers: () => void;
}

const PermanentlyDeleteAccount: FC<IDeleteUserProps> = ({
  children,
  userId,
  loadUsers,
}) => {
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  const handleModalVisibility = () => {
    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);
  };

  const handleDeleteAccount = async () => {
    setRequesting(true);
    try {
      await permanentlyDeleteUser({
        _id: userId,
      });
      loadUsers();
      message.success('This account has been permanently deleted.');
      handleModalVisibility();
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <div>
      <Modal
        open={isDeleteAccountModalOpen}
        onCancel={handleModalVisibility}
        confirmLoading={requesting}
        closable={false}
        width={520}
        footer={false}
        destroyOnClose={true}
        title={<p className="modal-title">Permanently Delete Account</p>}
      >
        <p className="modal-desc">
          Are you sure, you want to permanently delete this account? This
          process can not be undone.
        </p>
        <div className="modal-btn-container">
          <Button onClick={handleModalVisibility} block>
            Cancel
          </Button>
          <Button
            loading={requesting}
            onClick={handleDeleteAccount}
            block
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>

      {createElement(refChildren.current, { onClick: handleModalVisibility })}
    </div>
  );
};

export default PermanentlyDeleteAccount;
