import * as yup from 'yup';

export default yup.object().shape({
  currency: yup.string().required('Currency is required'),
  name: yup.string().required('Expense name is required'),
  amount: yup
    .number()
    .positive('Should be positive Number')
    .typeError('Expense amount must be a number')
    .required('Expense amount is required'),
});
