import { Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import moment from 'moment';
import { useEffect } from 'react';

import { InputField, SelectField } from '../../common/form';
import DatePickerField from '../../common/form/date-picker-field';
import { WorkshopAttendeeWithUserDetail } from '../../../types/workshop';
import CKEditorWrapper from '../../ckeditor';

interface EmailAttendeesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  attendees: WorkshopAttendeeWithUserDetail[];
  isEditable: boolean;
  workshopTimeZone?: string;
}
const EmailAttendeesForm = ({
  form,
  isEditable,
  attendees,
  workshopTimeZone,
}: EmailAttendeesProps) => {
  const SELECTED_ATTENDEES = [
    { label: 'Send to all attendees', value: 'all' },
    { label: 'Send to selected attendees', value: 'selected' },
  ];
  const IS_SCHEDULED = [
    { label: 'Send Now', value: false },
    { label: 'Schedule for later', value: true },
  ];

  const selectRecipients = form.watch('selectRecipients');

  const onSelectChange = (v: string) => {
    const allAttendees: string[] = [];
    if (v === 'all') {
      attendees?.forEach((data) => {
        allAttendees.push(data?.attendeesUserDetail?.email || data?.email);
      });
      form.setValue('selectRecipients', 'all');
      form.setValue('recipients', allAttendees);
    } else {
      form.setValue('selectRecipients', 'selected');
      form.setValue('recipients', allAttendees);
    }
  };

  useEffect(() => {
    if (onSelectChange) {
      onSelectChange(selectRecipients);
    }
  }, [selectRecipients]);

  return (
    <>
      <Form layout="vertical">
        <InputField
          label="Subject"
          name="subject"
          required
          inputProps={{
            placeholder: 'subject',
            type: 'text',
          }}
          form={form}
        />
        <CKEditorWrapper name="body" label="Email Body" required form={form} />
        <Row>
          <Col span={12}>
            <SelectField
              label="Send now or Schedule for later"
              required
              name="isScheduled"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: IS_SCHEDULED,
                onChange: (v) => form.setValue('isScheduled', v),
                disabled: isEditable,
              }}
            />
          </Col>
          <Col span={12}>
            {form.watch('isScheduled') && (
              <DatePickerField
                label="Schedule Attendee Mail at"
                name="emailScheduleDate"
                form={form}
                format="YYYY-MM-DD HH:mm"
                datePickerProps={{
                  disabledDate: (current) => {
                    const customDate = moment().format('YYYY-MM-DD HH:mm');
                    return (
                      current &&
                      current < moment(customDate, 'YYYY-MM-DD HH:mm')
                    );
                  },
                  showTime: true,
                }}
                timezone={workshopTimeZone}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <SelectField
              label="Select Recipients"
              required
              name="selectRecipients"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: SELECTED_ATTENDEES,
                // onChange: onSelectChange,
              }}
            />
          </Col>
          <Col span={12}>
            {selectRecipients === 'selected' && (
              <SelectField
                required
                name="recipients"
                label="Recipients"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: attendees?.map((attendee) => ({
                    label:
                      attendee?.attendeesUserDetail?.fullName ||
                      attendee?.fullName,
                    value:
                      attendee?.attendeesUserDetail?.email || attendee?.email,
                  })),
                  mode: 'multiple',
                  placeholder: 'Attendees',
                }}
              />
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default EmailAttendeesForm;
