import { message, Modal, Button } from 'antd';
import { createElement, FC, useRef, useState } from 'react';

import { deleteUser } from '../../../services/users-service';
import { handleError } from '../../../utils/common';

import '../style.scss';

interface IDeleteUserProps {
  children: FC<{ onClick: () => void }>;
  userId?: string;
  loadUsers: () => void;
}

const DeleteAccount: FC<IDeleteUserProps> = ({
  children,
  userId,
  loadUsers,
}) => {
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  const handleModalVisibility = () => {
    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);
  };

  const handleDeleteAccount = async () => {
    setRequesting(true);
    try {
      await deleteUser({
        _id: userId,
      });
      loadUsers();
      message.success(
        'This account has been successfully marked for deletion.'
      );
      handleModalVisibility();
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <div>
      <Modal
        open={isDeleteAccountModalOpen}
        onCancel={handleModalVisibility}
        confirmLoading={requesting}
        closable={false}
        width={520}
        footer={false}
        destroyOnClose={true}
        title={<p className="modal-title">Delete Account</p>}
      >
        <p className="modal-desc">
          Are you sure, you want to delete this account?
        </p>
        <div className="modal-btn-container">
          <Button onClick={handleModalVisibility} block>
            Cancel
          </Button>
          <Button
            loading={requesting}
            onClick={handleDeleteAccount}
            block
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>

      {createElement(refChildren.current, { onClick: handleModalVisibility })}
    </div>
  );
};

export default DeleteAccount;
