import { CalendarOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';

import { getCountryByCode } from '../../constants/countries';

import { WorkshopPnlReportsData } from '.';

const columns = [
  {
    title: 'SNo.',
    render: (value: string, record: WorkshopPnlReportsData, index: number) =>
      index + 1,
    key: 'sno',
    width: 200,
  },
  {
    title: 'Details',
    render: (record: WorkshopPnlReportsData) => (
      <div className="details">
        <div className="workshopTitle">
          <p style={{ margin: 0, textTransform: 'capitalize' }}>
            {record.title}{' '}
            <small>
              L{record?.level} ({record?.techniqueSlug?.toUpperCase()})
            </small>
          </p>
        </div>
        <small>
          {record?.workshopMode === 'virtual' ? 'Online' : record?.city}
          {record.country && `, ${getCountryByCode(record.country)?.name}`}
          <br />
          <Tooltip placement="topLeft" title="Workshop Start Date">
            <CalendarOutlined />{' '}
            {moment(record.startDate)
              .tz(record.timezone || 'UTC')
              ?.format('MMM Do YYYY, h:mm A')}
            ,{record.timezone}
          </Tooltip>
          <br />
          {record?.currency?.toUpperCase()}
        </small>
      </div>
    ),
    width: 320,
    key: 'details',
  },
  {
    title: 'Income and Expenses (Combined)',
    key: 'incomeAndExpenses',
    width: 450,
    render: (value: string, record: WorkshopPnlReportsData, index: number) => {
      const currencies = new Set([
        ...Object.keys(record?.totalIncome || {}),
        ...Object.keys(record?.totalExternalIncome || {}),
        ...Object.keys(record?.totalExpenses || {}),
      ]);

      return (
        <div
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          {Array.from(currencies).map((currency) => {
            const income = Number(record?.totalIncome?.[currency] || 0);
            const externalIncome = Number(
              record?.totalExternalIncome?.[currency] || 0
            );
            const expenses = Number(record?.totalExpenses?.[currency] || 0);
            return (
              <div
                key={currency}
                style={{
                  flexGrow: 1,
                  border: '1px solid #ddd',
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  background: '#f9f9f9',
                }}
              >
                <p style={{ margin: 0, fontSize: '14px' }}>
                  <strong>{currency}</strong>
                </p>
                <p style={{ margin: '4px 0', fontSize: '12px' }}>
                  Income: <span style={{ fontWeight: 'bold' }}>{income}</span>
                </p>
                <p style={{ margin: '4px 0', fontSize: '12px' }}>
                  External Income:{' '}
                  <span style={{ fontWeight: 'bold' }}>{externalIncome}</span>
                </p>
                <p style={{ margin: '4px 0', fontSize: '12px' }}>
                  Expenses:{' '}
                  <span style={{ fontWeight: 'bold' }}>{expenses}</span>
                </p>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    title: 'PNL',
    key: 'pnl',
    render: (value: string, record: WorkshopPnlReportsData, index: number) => {
      const currencies = new Set([
        ...Object.keys(record?.totalIncome || {}),
        ...Object.keys(record?.totalExternalIncome || {}),
        ...Object.keys(record?.totalExpenses || {}),
      ]);

      return Array.from(currencies).map((currency) => {
        const income = Number(record?.totalIncome?.[currency] || 0);
        const externalIncome = Number(
          record?.totalExternalIncome?.[currency] || 0
        );
        const expenses = Number(record?.totalExpenses?.[currency] || 0);
        const pnl = income + externalIncome - expenses;

        return (
          <p key={currency}>
            {currency}:{' '}
            <span
              style={{ fontWeight: 'bold', color: pnl >= 0 ? 'green' : 'red' }}
            >
              {pnl?.toFixed(2)}
            </span>
          </p>
        );
      });
    },
  },
];

export default columns;
