/* eslint-disable @typescript-eslint/no-explicit-any */
import { Moment } from 'moment';

import { Locale } from './common';

type Level = 1 | 2 | 3;

export enum MemberType {
  'student' = 'student',
  'teacher' = 'teacher',
}

export enum EPaymentProcessor {
  'stripe' = 'stripe',
  'ebanx' = 'ebanx',
  'paypal' = 'paypal',
}

export enum BuyingFor {
  SELF = 'SELF',
  OTHERS = 'OTHERS',
}

export type TAttendanceStatus = 'ATTENDED' | 'NOT_ATTENDED' | 'NOT_CERTIFIED';

export interface AttendeeDetail {
  email: string;
  role: string;
  memberType: string;
  fullName: string;
}

export interface Locations {
  address1: string;
  address2?: string;
  country: string;
  region: string;
  city: string;
  zip: number;
}
export interface AttendeeUserDetail {
  _id?: string;
  email: string;
  role: string;
  memberType: string;
  fullName: string;
  dob?: number;
  mobileNumber: string;
  locations: Locations[];
  nameAsOnCertificate: string;
  profession?: string;
  emailVerified?: boolean;
  emergencyContactName: string;
  emergencyContactNumber: string;
  teachingInstituteOrQualification?: string;
  teachingYears?: number;
  teachingDanceType?: string;
  wechatId?: string;
  whyPbtWorkshop?: string;
}

export interface WorkshopAttendee {
  _id: string;
  userId?: string;
  buyerIdentifierId: string;
  fullName: string;
  email: string;
  buyerName: string;
  buyerEmail: string;
  businessName: string;
  businessAddress: string;
  businessCountry: string;
  businessRegistrations: [
    {
      name: string;
      number: string;
    }
  ];
  bulkOrderCount: number;
  ticketPurachasedBy: BuyingFor;
  workshopSlug: string;
  originalWorkshopSlug?: string;
  getWorkshopFrom?: string;
  transferStatus:
    | 'NOT_REQUESTED'
    | 'REQUESTED'
    | 'REQUEST_ACCEPTED_PENDING'
    | 'REQUEST_ACCEPTED_COMPLETED'
    | 'REQUEST_DECLINED';
  refundStatus:
    | 'NOT_REQUESTED'
    | 'REQUESTED'
    | 'REQUEST_ACCEPTED_PENDING'
    | 'REQUEST_ACCEPTED_COMPLETED'
    | 'REQUEST_DECLINED';
  attendanceStatus: TAttendanceStatus;
  isRegistrationCompleted: boolean;
  isUnpaid: boolean;
  isPaymentDone: boolean;
  isCancelled: boolean;
  isAttendanceMarked: boolean;
  isPreviousCertificationVerified?: boolean;
  previousCertificationVerifiedBy: string;
  previousCertificationVerifiedOn: Date;
  note?: string;
  createdBy: string;
  certificateLink: string;
  refundResponse: string;
  paymentGatewayResponse: any;
  paymentInstrument: string;
  paymentProcessor: 'stripe' | 'ebanx' | 'paypal';

  isDeleted: boolean;
  paymentCurrency: string;
  transferCurrency: string;
  HPLShareInPaymentCurrency: number;
  HPLShareInTransferCurrency: number;
  instructorShareInPaymentCurrency: number;
  instructorShareInTransferCurrency: number;
  techniqueOwnerShareInPaymentCurrency: number;
  techniqueOwnerShareInTransferCurrency: number;
  gatewayFeeInTransferCurrency: number;
  gatewayFeeInPaymentCurrency: number;

  createdAt: Date;
  isCertificateReceived: boolean;
  expenseCollected: number;

  transferType?: TransferType;
  transferTo?: {
    email: string;
    name: string;
  };
}

export enum TransferType {
  TRANSFER_TO_ANOTHER_WORKSHOP = 'TRANSFER_TO_ANOTHER_WORKSHOP',
  TRANSFER_TO_ANOTHER_PERSON = 'TRANSFER_TO_ANOTHER_PERSON',
}

export interface WorkshopAttendeeWithUserDetail extends WorkshopAttendee {
  attendeesUserDetail: AttendeeUserDetail;
}

export interface Workshop {
  _id: string;
  slug: string;
  slugHistory?: string[];
  workshopfor: MemberType;
  techniqueSlug?: string;
  level: Level;
  isPhysical: boolean;
  startDate: Moment;
  endDate: Moment;
  ticketEndDate: Moment;
  city: string;
  country: string;
  state: string;
  timezone: string;
  title: string;
  enquiryEmail?: string;
  language: string;
  workshopMode?: string;
  workshopOwner: string;
  workshopInstructor?: {
    id?: string;
    contactEmail?: string | undefined;
    nameAsonCertificate?: string | undefined;
    fullname?: string | undefined;
    designation?: Locale;
    credentials?: Locale;
    signatureUrl?: string | undefined;
  };
  venueName: string;
  address1: string;
  address2: string;
  currency: string;
  ticketPrice: number;
  totalTickets: number;
  closedTickets: number;
  workshopImg: string;
  notesPdf: string;
  quipmentPdf: string;
  altLangNotesPdf?: string;
  altLangEquipPdf?: string;
  bannerUrl: string;
  noteUrl: string;
  equipmentUrl: string;
  ne_noteUrl?: string;
  ne_equipmentUrl?: string;
  description: string;
  workshopTimetable: string;
  additionalNotes?: string;
  createdBy: string;
  stripeProductId?: string;
  stripeProductPriceId?: string;
  expenses: IExpense[];
  externalIncome: IExternalIncome[];
  isHidden?: boolean;
  expenseAgreedAmount: number;
  expenseAgreedCurrency: string;
  isAgreedOnDefaultExpense: boolean;
  isExpensesFinalized?: boolean;
  settledExpenseAmount: {
    currency: string;
    amount: number;
  }[];
  workshopOwnerDetails?: {
    email: string;
    fullName: string;
  };
  listedOnBudgetPage?: boolean;
  isWorkshopConcluded?: boolean;
  eventPageVisitCount?: number;
  soldIn24Hrs?: number;
  soldIn7Days?: number;
}

export interface WorkshopDetail {
  workshopfor: MemberType;
  slug: string;
  level: Level;
  isPhysical: boolean | string;
  startDate: Moment;
  endDate: Moment;
  ticketEndDate: Moment;
  city: string;
  country: string;
  state: string;
  timezone: string;
  title: string;
  language: string;
  venueName: string;
  address1: string;
  address2: string;
  currency: string;
  ticketPrice: number;
  totalTickets: number;
  techniqueSlug: string;
  banner: string;
  note: string;
  equipment: string;
  ne_note?: string;
  ne_equipment?: string;
  bannerUrl: string;
  noteUrl: string;
  equipmentUrl: string;
  ne_noteUrl?: string;
  ne_equipmentUrl?: string;
  description: string;
  descriptionH1: string;
  descriptionH2: string;
  instructorPic: string;
  workshopTimetable: string;
  additionalNotes?: string;
  workshopMode: string;
  isCertificationMultiLevel?: boolean;
  expenseAgreedAmount: number;
  expenseAgreedCurrency: string;
  isAgreedOnDefaultExpense: boolean;
  isHidden?: boolean;
  isWorkshopConcluded?: boolean;
  zoomDetails?: string;
  workshopOwner?: string;
}

export enum FileType {
  'banner' = 'banner',
  'note' = 'note',
  'ne_note' = 'ne_note',
  'equipment' = 'equipment',
  'ne_equipment' = 'ne_equipment',
}

export interface Files {
  _id?: string;
  name: string;
  file: FileType;
  type: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UpdateAttendee {
  fullName: string;
  email: string;
  mobileNumber: string;
  country: string;
  state: string;
  address1: string;
  address2: string;
  city: string;
  zip: number;
  nameAsOnCertificate: string;
  note: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
}
export interface WorkshopAttendeeList extends Workshop, WorkshopPhotos {
  attendees: WorkshopAttendeeWithUserDetail[];
}
export interface EmailAttendees {
  _id: string;
  subject: string;
  body: string;
  recipients: string[];
  workshopSlug?: string;
  isScheduled?: boolean;
  emailScheduleDate?: Date;
  selectRecipients?: 'selected' | 'all';
}

export interface IAttendeesEmail {
  _id: string;
  workshopId: string;
  body: string;
  subject: string;
  recipients: string[];
  emailScheduleDate?: Date;
  isScheduled: boolean;
  isEmailSent?: boolean;
  sender: string;
  replyTo: string;
  createdAt: Date;
}

export type WorkshopPhotos = {
  pic1?: string;
  pic2?: string;
  pic3?: string;
  pic4?: string;
  pic5?: string;
  pic6?: string;
};

export interface AttendeeAttendence {
  _id: string;
  note: string;
  fullName: string;
  email: string;
  isAttendanceMarked?: boolean;
  attendanceStatus?: string;
}

export interface WaitlistAttendee {
  _id: string;
  workshopSlug: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  dob: Date;
  isOptedForNewletter?: boolean;
  mailSentOn?: Date;
  createdAt: Date;
}

export interface WaitListEmail {
  subject: string;
  body: string;
  recipients: React.Key[];
}

export interface AttendeeWorkshops extends WorkshopAttendee {
  workshops: Workshop;
  attendeeDetails: {
    email: string;
    nameAsOnCertificate: string;
  };
}

export interface IFilterWorkshop {
  past: AttendeeWorkshops[];
  upcoming: AttendeeWorkshops[];
}

export interface IExpense {
  _id: string;
  name: string;
  currency: string;
  amount: number;
}

export enum IncomeReceivedBy {
  'PBT' = 'PBT',
  'TUTOR' = 'TUTOR',
}

export interface IExternalIncome {
  _id: string;
  note: string;
  currency: string;
  amount: number;
  receivedBy: IncomeReceivedBy;
}

export interface IAddAttendee {
  firstName: string;
  lastName: string;
  email: string;
  cemail: string;
  note?: string;
}

export type IWorkshopCoupon = {
  couponId: string;
  promotionCodeId: string;
  code?: string;
  name: string;
  discountPercentage: number;
  limit?: number;
  redeemBy: number;
  active?: boolean;
  redemptions?: number;
  workshopsIds?: string[];
};
export type IExpenseFieldValue = Omit<IExpense, '_id'>;
export type IExternalIncomeFieldValue = Omit<IExternalIncome, '_id'>;
