import {
  Button,
  Space,
  Table,
  Tooltip,
  Typography,
  Input,
  Dropdown,
} from 'antd';
import { useEffect, useState, useCallback } from 'react';
import {
  EditOutlined,
  // DeleteOutlined,
  ReloadOutlined,
  EllipsisOutlined,
  UserOutlined,
  DeleteOutlined,
  UndoOutlined,
  // SearchOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { PaginationProps } from 'antd/es/pagination';

import { getUsers, TUser } from '../../services/users-service';
import EditUser from '../../components/users/edit-user';
import DeleteAccount from '../../components/users/delete-account';
import PermanentlyDeleteAccount from '../../components/users/permanently-delete-account';
import RestoreUserAccount from '../../components/users/restore-account';

import columns from './columns';

const Users = () => {
  const [requesting, setRequesting] = useState(true);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [users, setUsers] = useState<{
    users: TUser[];
    totalDocuments: number;
  }>();
  const [searchText, setSearchText] = useState('');
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const [debouncedText, setDebouncedText] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') || '1');
  const limit = parseInt(queryParams.get('limit') || '10');

  const loadUsers = useCallback(async () => {
    setRequesting(true);
    try {
      const response = await getUsers({
        searchText: debouncedText,
        limit,
        page,
      });
      setUsers(response?.data?.data);
    } catch (e) {
      console.error(e);
    } finally {
      setRequesting(false);
      setReloadUsers(false);
    }
  }, [limit, page, debouncedText]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: TUser) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'user-profile',
                label: (
                  <Link to={`/userdetails/${record._id}`}>
                    <UserOutlined /> User Profile
                  </Link>
                ),
              },
              {
                key: 'edit-user',
                label: (
                  <EditUser
                    userId={record._id}
                    user={record}
                    successCallback={() => setReloadUsers(true)}
                  >
                    {({ onClick }) => (
                      <p className="no-margin" onClick={onClick}>
                        <EditOutlined /> Edit User
                      </p>
                    )}
                  </EditUser>
                ),
              },
              ...(record?.isDeleted === false
                ? [
                    {
                      key: 'delete-account',
                      label: (
                        <DeleteAccount
                          userId={record?._id}
                          loadUsers={loadUsers}
                          children={({ onClick }) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={onClick}
                            >
                              <DeleteOutlined style={{ color: 'red' }} /> Delete
                              Account
                            </div>
                          )}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      key: 'restore-account',
                      label: (
                        <RestoreUserAccount
                          userId={record?._id}
                          loadUsers={loadUsers}
                          children={({ onClick }) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={onClick}
                            >
                              <UndoOutlined style={{ color: 'green' }} />{' '}
                              Restore Account
                            </div>
                          )}
                        />
                      ),
                    },
                    {
                      key: 'permanentaly-delete',
                      label: (
                        <PermanentlyDeleteAccount
                          userId={record?._id}
                          loadUsers={loadUsers}
                          children={({ onClick }) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={onClick}
                            >
                              <DeleteOutlined style={{ color: 'red' }} />{' '}
                              Permanently Delete
                            </div>
                          )}
                        />
                      ),
                    },
                  ]),
            ],
          }}
          arrow
          trigger={['click']}
          placement="bottomRight"
          className="workshop-dropdown"
          overlayClassName="workshop-dropdown-overlay"
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  useEffect(() => {
    if (reloadUsers && !isFirstSearch) loadUsers();
  }, [reloadUsers, isFirstSearch]);

  useEffect(() => {
    setReloadUsers(true);
  }, [page, limit]);

  useEffect(() => {
    setReloadUsers(true);
    navigate({
      pathname: location.pathname,
      search: `?page=${1}&limit=${10}`,
    });
  }, [debouncedText]);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedText(searchText);
    }, 200);

    return () => {
      clearTimeout(id);
    };
  }, [searchText]);

  return (
    <>
      {isFirstSearch ? (
        <div className="overlay">
          <div className="overlay-content">
            <Input.Search
              // prefix={<SearchOutlined />}
              className="userSearchField"
              placeholder="Search user by name or email"
              allowClear
              size="large"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={(value) => {
                setSearchText(value);
                setIsFirstSearch(!value);
              }}
            />
          </div>
        </div>
      ) : (
        <Table
          loading={requesting}
          dataSource={users?.users}
          columns={columnsWithActions}
          title={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Users</Typography.Title>
              <Space>
                <Input.Search
                  className="userSearchField"
                  placeholder="Search user by name or email"
                  allowClear
                  size="middle"
                  onSearch={(e) => setSearchText(e)}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ width: 300 }}
                />
                <Tooltip title="Refresh" placement="bottom">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      setReloadUsers(true);
                    }}
                  />
                </Tooltip>
              </Space>
            </div>
          )}
          pagination={{
            position: ['bottomRight'],
            total: users?.totalDocuments,
            onChange: handleTableChange,
            showSizeChanger: true,
            current: page,
            pageSize: limit,
          }}
        />
      )}
    </>
  );
};

export default Users;
