import { Button, Form, Col, Row, Spin, Typography, message, Table } from 'antd';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

import {
  DataFilter,
  TUser,
  getFilteredUsers,
} from '../../services/users-service';
import { countriesKeyValue, getCountryByCode } from '../../constants/countries';
import { SelectField } from '../../components/common/form';
import { handleError } from '../../utils/common';

import columns from './columns';

const UserDataDownload = () => {
  const [requesting, setRequesting] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [usersData, setUsersData] = useState<TUser[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const form = useForm<DataFilter>({
    mode: 'all',
  });

  const loadUsers = async (filter: DataFilter) => {
    setRequesting(true);
    try {
      const response = await getFilteredUsers(filter);
      const data = response?.data?.data;
      setUsersData(data);
      const newData: string[][] = [
        [
          'Name',
          'Role',
          'Member Type',
          'Email',
          'Phone',
          'Country',
          'School Teaching At',
          'Teaching Years',
          'Dance Type',
          'WeChat Id',
          'Why PBT Workshop',
        ],
      ];
      if (data && data.length > 0) {
        data.forEach((user: TUser) => {
          newData.push([
            user.fullName || '',
            user.role || '',
            user.memberType || '',
            user.email || '',
            user.mobileNumber || '',
            user.locations?.[0]?.country
              ? getCountryByCode(user.locations?.[0]?.country).name
              : '',
            user.teachingInstituteOrQualification || '',
            user.teachingYears ? String(user.teachingYears) : '0',
            user.teachingDanceType || '',
            user.wechatId || '',
            user.whyPbtWorkshop || '',
          ]);
        });
        setCsvData(newData);
      } else {
        message.error('No User Found');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
      setCurrentPage(1);
    }
  };

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={usersData}
        columns={columns}
        pagination={{
          position: ['bottomRight'],
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
        rowKey={'_id'}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>User Data</Typography.Title>
              </div>
              <Form layout="vertical" style={{ display: 'grid', gap: '1rem' }}>
                <Row gutter={[24, 10]}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="countries"
                      label="Select Countries"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: countriesKeyValue(),
                        mode: 'multiple',
                        size: 'large',
                        placeholder: 'Country',
                        allowClear: true,
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="subscriptionStatus"
                      label="Select Subscription Status"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: 'active', label: 'Active' },
                          { value: 'canceled', label: 'Canceled' },
                          { value: 'trialing', label: 'Trialing' },
                          { value: 'incomplete', label: 'Incomplete' },
                          { value: 'paused', label: 'Paused' },
                        ],
                        mode: 'multiple',
                        size: 'large',
                        placeholder: 'Subscription Status',
                        allowClear: true,
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="membershipType"
                      label="Select Membership Type"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: 'teacher', label: 'Teacher' },
                          { value: 'student', label: 'Student' },
                        ],
                        size: 'large',
                        placeholder: 'Membership Type',
                        allowClear: true,
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="pbtCertification"
                      label="Select PBT Certification"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: true, label: 'Certified' },
                          { value: false, label: 'Not Certified' },
                        ],
                        size: 'large',
                        placeholder: 'PBT Certification',
                        allowClear: true,
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="pctCertification"
                      label="Select PCT Certification"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: true, label: 'Certified' },
                          { value: false, label: 'Not Certified' },
                        ],
                        size: 'large',
                        placeholder: 'PCT Certification',
                        allowClear: true,
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <p style={{ lineHeight: '1.15', visibility: 'hidden' }}>
                      Get User Data
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginRight: '0.8rem',
                        gap: '1rem',
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={form.handleSubmit(loadUsers)}
                        style={{
                          width: 'fit-content',
                          height: '2.5rem',
                        }}
                      >
                        Search
                      </Button>
                      {usersData.length > 0 && (
                        <CSVLink
                          filename={'usersData.csv'}
                          data={csvData}
                          target="_blank"
                        >
                          <Button
                            style={{
                              width: 'fit-content',
                              height: '2.5rem',
                            }}
                          >
                            Download Data
                          </Button>
                        </CSVLink>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default UserDataDownload;
