/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { FC } from 'react';

import { workshopTechniquesMarketingStrategy } from '../../../config/technique';
import { CountryDetail } from '../../../types';
import { InputField, RadioField } from '../../common/form';

interface MarketingExpenseComponentProps {
  form: UseFormReturn<any>;
  currentCountry?: CountryDetail;
}

const techniqueAllowed = workshopTechniquesMarketingStrategy;

const MarketingExpenseComponent: FC<MarketingExpenseComponentProps> = ({
  form,
  currentCountry,
}) => {
  const isAgreedOnDefaultExpense = form.watch('isAgreedOnDefaultExpense');
  const selectedTechniqueSlug = form.watch('techniqueSlug');
  const selectedLevelInForm = form.watch('level');
  const currentCurrency = form.watch('currency');

  const selectedTechnique = techniqueAllowed.filter(
    (technique) => technique?.name === selectedTechniqueSlug
  )?.[0];

  const selectedLevel =
    selectedTechnique?.workshopAdmin?.expenseConsent?.[
      selectedTechnique.isMultiLevel ? selectedLevelInForm - 1 : 0
    ];

  const renderRadioField = () => {
    const radioLabel = selectedLevel?.question;

    return (
      <RadioField
        label={radioLabel}
        name="isAgreedOnDefaultExpense"
        required
        form={form}
        radioFieldProps={{
          options: [
            { label: 'Yes', value: true },
            {
              label: 'No ( please provide Max Budget below )',
              value: false,
            },
          ],
        }}
      />
    );
  };

  const renderInputField = () => {
    return (
      isAgreedOnDefaultExpense === false && (
        <InputField
          label={'Max budget : '}
          name="expenseAgreedAmount"
          form={form}
          inputProps={{
            placeholder: 'Type here',
            addonBefore:
              selectedLevel?.currency === 'workshopCurrency'
                ? currentCountry?.currencies[0]?.code === currentCurrency
                  ? currentCountry?.currencies[0]?.symbol
                  : '$'
                : '$',
            type: 'number',
          }}
        />
      )
    );
  };

  return (
    <Row className="launch_workshop">
      <Col xs={24} sm={24} md={24} lg={24} xl={15}>
        {selectedTechnique?.workshopAdmin?.expenseConsentRequired && (
          <>
            {renderRadioField()}
            {renderInputField()}
          </>
        )}
      </Col>
    </Row>
  );
};

export default MarketingExpenseComponent;
