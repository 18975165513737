/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().required('School name is required'),
  website: yup
    .string()
    .matches(/^https:\/\/(?:[a-z0-9\-]+\.)+[a-z]{2,}(?:\/[^\/]*)?$/i, {
      message: 'Please enter valid website url starting with https://',
      excludeEmptyString: true,
    }),
  facebook: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?facebook\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Facebook URL starting with https://',
      excludeEmptyString: true,
    }),
  instagram: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?instagram\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Instagram URL starting with https://',
      excludeEmptyString: true,
    }),
  twitter: yup.string().matches(/^https:\/\/(?:www\.)?twitter\.[a-z]+\/?.*$/i, {
    message: 'Please enter a valid Twitter URL starting with https://',
    excludeEmptyString: true,
  }),
});
