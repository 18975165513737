import { IExpense } from '../../../../types/workshop';
import { convertStripeAmountWithoutDecimal } from '../../../../utils/common';

const columns = [
  {
    title: 'Name',
    render: (value: string, record: IExpense) => record.name,
  },
  {
    title: 'Amount',
    render: (value: string, record: IExpense) => {
      return `${record?.currency} ${convertStripeAmountWithoutDecimal(
        Number(record?.amount),
        record?.currency
      )}`;
    },
  },
];

export default columns;
