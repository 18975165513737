import { Spin, Typography, Table } from 'antd';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import moment from 'moment';

import { handleError } from '../../utils/common';
import {
  WorkshopFilter,
  getWorkshopsPnl,
} from '../../services/workshop-service';
import { getCountryByCode } from '../../constants/countries';
import WorkshopFilters from '../../components/workshops/workshop-filters';

import columns from './columns';

export interface WorkshopPnlReportsData {
  title: string;
  techniqueSlug: string;
  workshopMode: string;
  level: string;
  country: string;
  city: string;
  currency: string;
  startDate: string;
  timezone: string;
  totalIncome: Record<string, number>;
  totalExternalIncome: Record<string, number>;
  totalExpenses: Record<string, number>;
}

const getPNL = (record: WorkshopPnlReportsData) => {
  const currencies = new Set([
    ...Object.keys(record?.totalIncome || {}),
    ...Object.keys(record?.totalExternalIncome || {}),
    ...Object.keys(record?.totalExpenses || {}),
  ]);

  const pnlString = Array.from(currencies).reduce((acc, currency) => {
    const income = Number(record?.totalIncome?.[currency] || 0);
    const externalIncome = Number(record?.totalExternalIncome?.[currency] || 0);
    const expenses = Number(record?.totalExpenses?.[currency] || 0);
    const pnl = income + externalIncome - expenses;

    return `${acc ? acc + ', ' : ''}${currency}: ${pnl}`;
  }, '');

  return pnlString;
};

const WorkshopPnlReports = () => {
  const [requesting, setRequesting] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [workshopsPNLData, setWorkshopsPNLData] = useState<
    WorkshopPnlReportsData[]
  >([]);

  const form = useForm<WorkshopFilter>({
    mode: 'all',
  });

  const downloadCsvData = (data: WorkshopPnlReportsData[]) => {
    const newData: string[][] = [
      [
        'Sno',
        'Title',
        'Technique',
        'City',
        'Country',
        'startDate',
        'Event Currency',
        'Total Income',
        'Total External Income',
        'Total Expenses',
        'PNL',
      ],
    ];
    if (data && data.length > 0) {
      data.forEach((workshop: WorkshopPnlReportsData, index: number) => {
        newData.push([
          `${index + 1}`,
          `${workshop.title}, L${workshop?.level}`,
          `${workshop?.techniqueSlug}`,
          `${workshop.city || 'online'}`,
          getCountryByCode(workshop.country || '').name || '',
          moment(workshop?.startDate)?.format('ll'),
          workshop?.currency,
          `${Object.entries(workshop.totalIncome).reduce(
            (acc, [key, value]) => `${acc ? acc + ',' : ''} ${key}: ${value}`,
            ''
          )}`,
          `${Object.entries(workshop.totalExternalIncome).reduce(
            (acc, [key, value]) => `${acc ? acc + ',' : ''} ${key}: ${value}`,
            ''
          )}`,
          `${Object.entries(workshop.totalExpenses).reduce(
            (acc, [key, value]) => `${acc ? acc + ',' : ''} ${key}: ${value}`,
            ''
          )}`,
          `${getPNL(workshop)}`,
        ]);
      });
      setCsvData(newData);
    }
  };

  const loadWorkshops = async (filter: WorkshopFilter) => {
    setRequesting(true);
    try {
      const response = await getWorkshopsPnl(filter);
      const data = response?.data?.data;
      setWorkshopsPNLData(data);
      downloadCsvData(data);
    } catch (error) {
      handleError(error);
      setWorkshopsPNLData([]);
      downloadCsvData([]);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Spin spinning={requesting}>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={workshopsPNLData}
        columns={columns}
        pagination={false}
        rowKey={'_id'}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>
                  Workshops PNL Report
                </Typography.Title>
              </div>

              <WorkshopFilters
                form={form}
                loadWorkshops={loadWorkshops}
                csvData={csvData}
                allowDownload={workshopsPNLData.length > 0 ? true : false}
              />
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default WorkshopPnlReports;
