import { Form, Col, Row, Button, Select } from 'antd';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import DateRangePickerField from '../../common/form/date-range-picker-field';
import { WorkshopFilter } from '../../../services/workshop-service';
import { SelectField } from '../../common/form';
import { countriesKeyValue } from '../../../constants/countries';
import { getRegionsKeyValue } from '../../../constants/regions';

const WorkshopFilters = ({
  form,
  loadWorkshops,
  csvData,
  allowDownload = false,
}: {
  form: UseFormReturn<WorkshopFilter, object>;
  loadWorkshops: SubmitHandler<WorkshopFilter>;
  csvData: string[][];
  allowDownload?: boolean;
}) => {
  const [filterBy, setFilterBy] = useState(undefined);

  const { Option } = Select;

  const watchCountry = form.watch('country');
  const watchMode = form.watch('mode');

  useEffect(() => {
    if (filterBy === 'region') {
      form.setValue('country', undefined);
      form.setValue('state', undefined);
    }
    if (filterBy === 'country') {
      form.setValue('region', undefined);
    }
  }, [filterBy]);

  useEffect(() => {
    form.setValue('state', undefined);
  }, [watchMode]);

  return (
    <Form layout="vertical" style={{ display: 'grid', gap: '1rem', margin: 0 }}>
      <Row gutter={[24, 10]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Select Filter By" labelCol={{ span: 24 }}>
            <Select
              placeholder="Filter By"
              onChange={(e) => setFilterBy(e)}
              size="large"
              allowClear
              value={filterBy}
            >
              <Option value="country">Country</Option>
              <Option value="region">Region</Option>
            </Select>
          </Form.Item>
        </Col>
        {filterBy === 'country' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectField
              name="country"
              label="Select Country"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: countriesKeyValue(),
                size: 'large',
                placeholder: 'Country',
                allowClear: true,
                showSearch: true,
              }}
            />
          </Col>
        )}
        {filterBy === 'region' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectField
              name="region"
              label="Select Region"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: [
                  { label: 'All Regions', value: 'all' },
                  { label: 'UK / Europe', value: 'uk-and-europe' },
                  { label: 'Latin America', value: 'latin-america' },
                  { label: 'USA / Canada', value: 'usa-and-canada' },
                  { label: 'China', value: 'china' },
                  { label: 'Asia', value: 'asia' },
                  {
                    label: 'Australia / New Zealand',
                    value: 'australia-and-new-zealand',
                  },
                  { label: 'Others', value: 'others' },
                ],
                size: 'large',
                placeholder: 'Region',
                allowClear: true,
              }}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <SelectField
            name="mode"
            label="Select Mode"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                { value: 'physical', label: 'Offline' },
                { value: 'virtual', label: 'Online' },
              ],
              size: 'large',
              placeholder: 'Mode',
              allowClear: true,
            }}
          />
        </Col>
        {form.watch('mode') === 'physical' && filterBy === 'country' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectField
              label="Select State"
              name="state"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: watchCountry ? getRegionsKeyValue(watchCountry) : [],
                placeholder: 'State',
                allowClear: true,
                showSearch: true,
                size: 'large',
              }}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <SelectField
            name="workshopStatus"
            label="Select Workshop Status"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' },
                { value: 'upcoming', label: 'Upcoming' },
              ],
              size: 'large',
              placeholder: 'Workshop Status',
              allowClear: true,
            }}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <SelectField
            name="technique"
            label="Select Technique"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                { value: 'pbt', label: 'PBT' },
                { value: 'pct', label: 'PCT' },
              ],
              size: 'large',
              placeholder: 'Technique',
              allowClear: true,
            }}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <DateRangePickerField
            name="dateRange"
            label="Select Date Range"
            form={form}
            format="YYYY-MM-DD"
            size="large"
          />
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '0.7rem',
            }}
          >
            <Button
              type="primary"
              onClick={form.handleSubmit(loadWorkshops)}
              style={{
                width: 'fit-content',
                height: '2.5rem',
              }}
            >
              Search
            </Button>
            {allowDownload && (
              <CSVLink
                filename={'Workshops PNL Report.csv'}
                data={csvData}
                target="_blank"
              >
                <Button
                  style={{
                    width: 'fit-content',
                    height: '2.5rem',
                  }}
                >
                  Download Data
                </Button>
              </CSVLink>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkshopFilters;
