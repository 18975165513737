import { createElement, FC, useRef, useState } from 'react';
import { Button, message, Modal } from 'antd';

import { handleError } from '../../../utils/common';
import { restoreUser } from '../../../services/users-service';

interface IRestoreAccountProps {
  children: FC<{ onClick: () => void }>;
  userId?: string;
  loadUsers: () => void;
}

const RestoreUserAccount: FC<IRestoreAccountProps> = ({
  children,
  userId,
  loadUsers,
}) => {
  const [requesting, setRequesting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const refChildren = useRef(children);

  const handleModalVisibility = () => {
    setIsModalOpen(!isModalOpen);
  };

  const RestoreAccount = async () => {
    setRequesting(true);
    try {
      if (!userId) return message.success('User not found');
      await restoreUser(userId);
      loadUsers();
      message.success('This account has been successfully restored.');
      handleModalVisibility();
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={handleModalVisibility}
        confirmLoading={requesting}
        closable={false}
        width={520}
        footer={false}
        destroyOnClose={true}
        title={<p className="modal-title">Restore Account</p>}
      >
        <p className="modal-desc">
          Are you sure, you want to restore this account?
        </p>
        <div className="modal-btn-container">
          <Button onClick={handleModalVisibility} block>
            Cancel
          </Button>
          <Button
            loading={requesting}
            onClick={RestoreAccount}
            block
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>

      {createElement(refChildren.current, { onClick: handleModalVisibility })}
    </div>
  );
};

export default RestoreUserAccount;
