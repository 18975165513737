import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table } from 'antd';

import {
  IExternalIncomeFieldValue,
  IncomeReceivedBy,
} from '../../../../types/workshop';
import {
  convertStripeAmountWithoutDecimal,
  handleError,
} from '../../../../utils/common';
import { InputField, SelectField } from '../../../common/form';
import { addIncome } from '../../../../services/workshop-service';
import { activeEbanxCountries } from '../../../../constants/stripeCountry';
import { WorkshopWithIncomeData } from '..';

import validationSchema from './validation-schema';
import columns from './columns';

const ExternalIncomeSection = ({
  workshop,
  reloadWorkshop,
}: {
  workshop: WorkshopWithIncomeData | null;
  reloadWorkshop: () => void;
}) => {
  const [showForm, setShowForm] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const totalExternalIncome = useMemo(() => {
    if (!workshop) return 0;

    const totalAmount =
      workshop?.externalIncome?.reduce((acc, cur) => {
        if (cur?.amount) acc += cur.amount;
        return acc;
      }, 0) || 0;

    return convertStripeAmountWithoutDecimal(totalAmount, workshop?.currency);
  }, [workshop]);

  const form = useForm<IExternalIncomeFieldValue>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleShowForm = () => {
    setShowForm((prev) => !prev);
    form.reset({});
  };

  const handleSubmit = async (formData: IExternalIncomeFieldValue) => {
    setRequesting(true);
    try {
      if (workshop) {
        await addIncome({
          payload: formData,
          workshopId: workshop._id,
        });
        message.success('Income added.');
        reloadWorkshop();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
      handleShowForm();
    }
  };

  return (
    <div className="income-table-section">
      <div className="income-heading">
        <p className="no-margin">External Incomes</p>
        <div>
          {!showForm && (
            <Button
              className="action-buttons"
              type="primary"
              onClick={handleShowForm}
              icon={<PlusCircleOutlined />}
            >
              Add Income
            </Button>
          )}
        </div>
      </div>
      {showForm && (
        <>
          <Row>
            <Col span={8}>
              <SelectField
                name="receivedBy"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  placeholder: 'Select received by',
                  options: [
                    {
                      label: IncomeReceivedBy.PBT,
                      value: IncomeReceivedBy.PBT,
                    },
                    {
                      label: IncomeReceivedBy.TUTOR,
                      value: IncomeReceivedBy.TUTOR,
                    },
                  ],
                }}
              />
            </Col>
            <Col span={8}>
              <SelectField
                name="currency"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  placeholder: 'Select Currency',
                  options: [
                    ...(workshop?.hasPbtAllShare
                      ? activeEbanxCountries?.includes(
                          workshop?.country?.toUpperCase()
                        ) || workshop?.currency?.toUpperCase() === 'USD'
                        ? [
                            {
                              label: 'USD',
                              value: 'usd',
                            },
                          ]
                        : [
                            {
                              label: 'AUD',
                              value: 'aud',
                            },
                          ]
                      : [
                          {
                            label: workshop?.currency?.toUpperCase(),
                            value: workshop?.currency,
                          },
                        ]),
                  ],
                }}
              />
            </Col>
            <Col span={8}>
              <InputField
                name="amount"
                form={form}
                inputProps={{ placeholder: 'Amount (e.g. 1000)' }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <InputField
                name="note"
                form={form}
                inputProps={{ placeholder: 'Note (e.g. Sponsorship)' }}
              />
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={form.handleSubmit(handleSubmit)}
                loading={requesting}
                style={{ width: '98%' }}
              >
                Add
              </Button>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={handleShowForm}
                danger
                style={{ width: '98%' }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Table
        dataSource={workshop?.externalIncome || []}
        columns={columns}
        pagination={false}
        className="income-table"
      />
      <p style={{ marginTop: '5px', marginBottom: '5px' }}>
        Total External Income: {totalExternalIncome}
      </p>
    </div>
  );
};

export default ExternalIncomeSection;
