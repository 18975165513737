import { Col, Row, Table } from 'antd';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { InputField, SelectField } from '../../common/form';
import { CountryDetail } from '../../../types';
import { TechniqueDetail } from '../../../types/technique';
import { TUser } from '../../../services/users-service';

import { ITimeZone } from './form';

interface ICurrencyAndPrice {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  currencyOptions: ITimeZone[];
  currentCountry?: CountryDetail;
  techniques: TechniqueDetail[];
  workshopOwner: TUser | null;
}

interface IFirstRecord {
  currency: string;
  amount: string;
}
interface ISecondRecord {
  instructorShare: string;
  hplShare: string;
  ownerShare: string;
}

const CurrencyAndPrice: FC<ICurrencyAndPrice> = ({
  form,
  currencyOptions,
  currentCountry,
  techniques,
  workshopOwner,
}) => {
  const workshopPrimaryCurrency = form.watch('currency');
  const primaryTicketPrice = form.watch('ticketPrice') ?? 0;
  const currentTechniqueSlug = form.watch('techniqueSlug');
  const currentTechnique = techniques.filter(
    (technique) => technique?.slug === currentTechniqueSlug
  )?.[0];

  const defaultInstructorShare = currentTechnique?.instructorShare;
  const instructorShare = workshopOwner?.isUsingDefaultTechniqueShares
    ? defaultInstructorShare
    : workshopOwner?.customPaymentShares?.find(
        (share) => share?.techniqueSlug === currentTechnique?.slug
      )?.instructorShare ?? defaultInstructorShare;

  const defaultHPLShare = currentTechnique?.hplShare;
  const hplShare = workshopOwner?.isUsingDefaultTechniqueShares
    ? defaultHPLShare
    : workshopOwner?.customPaymentShares?.find(
        (share) => share?.techniqueSlug === currentTechnique?.slug
      )?.hplShare ?? defaultHPLShare;

  const defaultOwnerShare = currentTechnique?.ownerShare;
  const ownerShare = workshopOwner?.isUsingDefaultTechniqueShares
    ? defaultOwnerShare
    : workshopOwner?.customPaymentShares?.find(
        (share) => share?.techniqueSlug === currentTechnique?.slug
      )?.ownerShare ?? defaultOwnerShare;

  const primaryColumns = [
    {
      title: 'Currency',
      key: 'currency',
      render: (value: string, record: IFirstRecord) => record?.currency,
    },
    {
      title: workshopPrimaryCurrency,
      key: 'amount',
      render: (value: string, record: IFirstRecord) => record?.amount,
    },
  ];

  const primaryDataSource = [
    { currency: 'Ticket Price', amount: (primaryTicketPrice * 1)?.toFixed(2) },
    {
      currency: 'Payment Gateway Charges',
      amount: (primaryTicketPrice * 1 * (5 / 100))?.toFixed(2),
    },
    {
      currency: 'Ticket Price',
      amount: (
        primaryTicketPrice * 1 +
        primaryTicketPrice * 1 * (5 / 100)
      )?.toFixed(2),
    },
  ];

  const primaryShareColumns = [
    {
      title: `Your's (${instructorShare}%)`,
      key: 'instructorShare',
      render: (value: string, record: ISecondRecord) => record?.instructorShare,
    },
    {
      title: `HPL's (${hplShare}%)`,
      key: 'hplShare',
      render: (value: string, record: ISecondRecord) => record?.hplShare,
    },
    {
      title: `Technique Owner's (${ownerShare}%)`,
      key: 'ownerShare',
      render: (value: string, record: ISecondRecord) => record?.ownerShare,
    },
  ];

  const primaryShareData = [
    {
      instructorShare: (
        primaryTicketPrice *
        1 *
        (instructorShare / 100)
      ).toFixed(2),
      ownerShare: (primaryTicketPrice * 1 * ((ownerShare || 0) / 100)).toFixed(
        2
      ),
      hplShare: (primaryTicketPrice * 1 * (hplShare / 100)).toFixed(2),
    },
  ];

  return (
    <>
      <Row style={{ gap: '20px' }} className="launch_workshop">
        <Col span={11}>
          <SelectField
            label={'Currency'}
            required
            name="currency"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: currencyOptions,
              allowClear: true,
              placeholder: 'Select Currency',
            }}
          />
          <InputField
            label={'Ticket Price'}
            name="ticketPrice"
            required
            inputProps={{
              placeholder: 'Ticket Price',
              addonBefore:
                currentCountry?.currencies?.filter(
                  (currency) => currency?.code === workshopPrimaryCurrency
                )?.[0]?.symbol || '$',
            }}
            form={form}
          />
        </Col>
        <Col span={11}>
          {workshopPrimaryCurrency && (
            <>
              <Table
                dataSource={primaryDataSource}
                columns={primaryColumns}
                pagination={false}
                className="split-structure"
              />

              <br />

              <Table
                dataSource={primaryShareData}
                columns={primaryShareColumns}
                pagination={false}
                className="split-structure"
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CurrencyAndPrice;
