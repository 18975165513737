import {
  Button,
  message,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EditOutlined, DeleteFilled } from '@ant-design/icons';
import type { PaginationProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { IAssignee } from '../../types/assesment-response';
import { IUploadedCertificate } from '../../types/uploaded-certificate-response';
import {
  deleteUploadedCertificate,
  getAssignees,
  getUploadCertificatesResponse,
} from '../../services/uploaded-certifcate-response';
import AddReivew from '../../components/uploaded-certificate/review-uploaded-certificate';

import columns from './columns';
import AssigneeModel from './assignee-model';

interface IUploadedCertficateResponsesProps {
  totalDocuments: number;
  totalPages: number;
  currentPage: string;
  uploadedCertificateResponse: IUploadedCertificate[];
}

const UploadedCertficateResponses = () => {
  const [requesting, setRequesting] = useState(false);
  const [loadingAssignees, setLoadingAssignees] = useState(false);
  const [assignees, setAssignees] = useState<IAssignee[]>();
  const [reloadResponses, setReloadResponses] = useState(true);
  const [userResponses, setUserResponses] =
    useState<IUploadedCertficateResponsesProps>({
      totalDocuments: 0,
      totalPages: 0,
      currentPage: '1',
      uploadedCertificateResponse: [],
    });
  const [activeResponseId, setActiveResponseId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const currentURL = location?.pathname || '';

  const page = queryParams.get('page')
    ? parseInt(queryParams.get('page') || '')
    : 1;

  const limit = queryParams.get('limit')
    ? parseInt(queryParams.get('limit') || '')
    : 10;

  const loadUploadedCertficateResponses = async () => {
    setRequesting(true);
    try {
      const response = await getUploadCertificatesResponse({
        page: page,
        ...(limit && { limit }),
        approvedCertificates: currentURL?.includes('approved'),
      });
      setUserResponses(response?.data?.data);
    } catch (e) {
      message.error('Something went wrong');
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadResponses(false);
    }
  };

  const removeQueryParam = () => {
    setActiveResponseId('');
    navigate({
      pathname: location.pathname,
    });
  };

  const loadAssignees = async () => {
    setLoadingAssignees(true);
    try {
      const response = await getAssignees();
      setAssignees(response.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAssignees(false);
    }
  };

  const deleteCertificate = async (certificateId: string) => {
    try {
      await deleteUploadedCertificate(certificateId);
      setReloadResponses(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!requesting && reloadResponses) {
      loadUploadedCertficateResponses();
    }
  }, [reloadResponses, requesting]);

  useEffect(() => {
    setReloadResponses(true);
  }, [page, limit, location.pathname]);

  useEffect(() => {
    const activeResponseId = queryParams.get('id');
    if (activeResponseId) setActiveResponseId(activeResponseId as string);
  }, []);

  useEffect(() => {
    loadAssignees();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Assignee',
      render: (value: string, record: IUploadedCertificate) => {
        return (
          <AssigneeModel
            assignees={assignees}
            loadingAssignees={loadingAssignees}
            response={record}
            successCallback={() => setReloadResponses(true)}
          />
        );
      },
      width: 200,
    },
    {
      title: 'Action',
      render: (value: string, record: IUploadedCertificate) => {
        return (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <AddReivew
              payload={record}
              activeResponseId={activeResponseId}
              successCallback={() => {
                removeQueryParam();
                setReloadResponses(true);
              }}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </AddReivew>
            <Popconfirm
              title="Are You Sure?"
              onConfirm={() => deleteCertificate(record?._id)}
            >
              <DeleteFilled style={{ color: 'red' }} />
            </Popconfirm>
          </div>
        );
      },
      width: 200,
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    setReloadResponses(true);
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  return (
    <Spin spinning={requesting}>
      <Table
        key={JSON.stringify(userResponses?.uploadedCertificateResponse)}
        dataSource={userResponses?.uploadedCertificateResponse}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={{
          position: ['bottomRight'],
          total: userResponses?.totalDocuments,
          defaultCurrent: page,
          defaultPageSize: limit,
          onChange: handleTableChange,
          showSizeChanger: true,
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                {currentURL?.includes('approved')
                  ? 'Approved Certificate Submissions and Review Status'
                  : 'Certificate Submissions and Review Status'}
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadResponses(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default UploadedCertficateResponses;
