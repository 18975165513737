import { Spin, Typography, message, Table } from 'antd';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import moment from 'moment';

import { getCountryByCode } from '../../constants/countries';
import { handleError } from '../../utils/common';
import {
  WorkshopFilter,
  getFilteredWorkshops,
} from '../../services/workshop-service';
import { Workshop } from '../../types/workshop';
import WorkshopFilters from '../../components/workshops/workshop-filters';

import columns from './columns';

const WorkshopDataDownload = () => {
  const [requesting, setRequesting] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [workshopsData, setWorkshopsData] = useState<Workshop[]>([]);

  const form = useForm<WorkshopFilter>({
    mode: 'all',
  });

  const loadWorkshops = async (filter: WorkshopFilter) => {
    setRequesting(true);
    try {
      const response = await getFilteredWorkshops(filter);
      const data = response?.data?.data;
      setWorkshopsData(data);

      const newData: string[][] = [
        [
          'Title',
          'Technique',
          'Mode',
          'Level',
          'Venue',
          'State/Region',
          'Country',
          'Date',
          'Positions Filled',
          'Capacity',
          'Occupancy %',
          'Tutor',
        ],
      ];
      if (data && data.length > 0) {
        data.forEach((workshop: Workshop) => {
          newData.push([
            workshop.title || '',
            workshop.techniqueSlug?.toUpperCase() || '',
            workshop.workshopMode || '',
            workshop.level?.toString() || '',
            workshop.venueName || '',
            workshop.state || '',
            getCountryByCode(workshop.country || '').name || '',
            moment(workshop.startDate)
              .tz(workshop.timezone || 'UTC')
              .format('D MMM YYYY, h:mm a')
              ?.toString() || '',
            workshop.closedTickets?.toString() || '',
            workshop.totalTickets?.toString() || '',
            (workshop.closedTickets === undefined || 0
              ? 0
              : Math.floor(
                  (workshop.closedTickets / workshop.totalTickets) * 100
                )) + '%',
            workshop.workshopInstructor?.fullname || '',
          ]);
        });
        setCsvData(newData);
      } else {
        message.error('No Workshop Found');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Spin spinning={requesting}>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={workshopsData}
        columns={columns}
        pagination={false}
        rowKey={'_id'}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>Workshop Data</Typography.Title>
              </div>

              <WorkshopFilters
                form={form}
                allowDownload={workshopsData.length > 0 ? true : false}
                csvData={csvData}
                loadWorkshops={loadWorkshops}
              />
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default WorkshopDataDownload;
