import { Timeline, Card } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

import { TUserTimeline } from '../../../types';
import { handleError } from '../../../utils/common';
import { getUserTimeline } from '../../../services/users-service';

const UserTimeline = ({ userId }: { userId?: string }) => {
  const [timelineData, setTimelineData] = useState<TUserTimeline>();
  const [requesting, setRequesting] = useState(false);

  async function loadTimeline() {
    try {
      const response = await getUserTimeline(userId || '');
      setTimelineData(response?.data?.data);

      setRequesting(false);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  }
  useEffect(() => {
    if (userId) {
      setRequesting(true);
      loadTimeline();
    }
  }, [userId]);

  return (
    <>
      <Card
        bordered={false}
        style={{ marginTop: '0.7rem' }}
        title={<p style={{ fontSize: '1.2rem' }}>User Timeline</p>}
        className="info-container"
        loading={requesting}
      >
        <Timeline reverse>
          {timelineData?.events?.length ? (
            timelineData?.events?.map((item, index: number) => (
              <Timeline.Item key={index} dot={<CheckCircleOutlined />}>
                <div style={{ display: 'grid' }}>
                  <p style={{ fontWeight: 'bold', margin: 0 }}>{item.title}</p>
                  <p style={{ fontWeight: '500', margin: 0 }}>
                    {moment(item.firedAt).format('ll | hh:mm a')}
                  </p>
                  <p style={{ color: 'gray', margin: 0 }}>{item.desc}</p>
                </div>
              </Timeline.Item>
            ))
          ) : (
            <p>No data</p>
          )}
        </Timeline>
      </Card>
    </>
  );
};

export default UserTimeline;
