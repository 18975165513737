/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Button, Form, message, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { UploadListType } from 'antd/lib/upload/interface';

import fetchClient from '../../../utils/fetch-client';
import { S3_URL } from '../../../constants/api';

interface FileUploadButtonProps {
  label?: string;
  required?: boolean;
  name: string;
  form: UseFormReturn<any, any>;
  buttonLabel: string;
  disabled?: boolean;
  allowAny?: boolean;
  defaultFileList?: Array<UploadFile>;
  allowedFileTypes?: string[];
  triggerValidate?: () => Promise<boolean>;
  className?: string;
  afterUpload?: (v: string) => void;
  accept?: string;
  buttonLabelClassname?: string;
  listType?: UploadListType;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({
  form: {
    formState: { errors },
    control,
    setValue,
  },
  label,
  required,
  name,
  disabled = false,
  allowAny = false,
  buttonLabel,
  defaultFileList = [],
  allowedFileTypes = [
    'image/png',
    'image/webp',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ],
  triggerValidate,
  className,
  afterUpload,
  accept,
  listType = 'picture',
  buttonLabelClassname = 'w-full',
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);

  const props: UploadProps = {
    beforeUpload: (file) => {
      if (allowAny || allowedFileTypes.includes(file.type)) {
        return true;
      } else {
        message.error(`${file.type} is not allowed`);
        return Upload.LIST_IGNORE;
      }
    },
    ...(accept && { accept }),
  };

  const customRequest = ({ file, onError, onSuccess, onProgress }: any) => {
    const fileType = file.type;
    const fileName = file.name;
    const fileDetails = {
      fileName,
      fileType,
    };

    fetchClient
      .post(S3_URL.PRESIGNED_URL, {
        fileDetails,
      })
      .then((response) => {
        const returnData = response.data.data;
        const signedRequest = returnData.signedUrl;
        const key = returnData.key;

        // Put the fileType in the headers for the upload
        const options = {
          onUploadProgress: (event: ProgressEvent) => {
            const { loaded, total } = event;
            onProgress(
              {
                percent: Math.round((loaded / total) * 100),
              },
              file
            );
          },
          headers: {
            'Content-Type': fileType,
          },
        };

        fetchClient
          .put(signedRequest, file, options)
          .then((result) => {
            setValue(name, key);
            if (afterUpload) {
              afterUpload(fileType);
            }
            if (triggerValidate) {
              triggerValidate();
            }
            setLoading(false);
            onSuccess(result, file);
            message.success(`${fileName} Successfully Uploaded!`);
          })
          .catch((error) => {
            onError(error);
            alert('ERROR ' + JSON.stringify(error));
          });
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleFileRemove = () => {
    setValue(name, '');
    if (triggerValidate) {
      triggerValidate();
    }
  };

  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
      className={className || ''}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Upload
            {...props}
            disabled={loading}
            customRequest={customRequest}
            defaultFileList={defaultFileList}
            listType={listType}
            maxCount={1}
            onRemove={handleFileRemove}
            style={{ width: '100%' }}
          >
            <Button
              loading={loading}
              disabled={disabled}
              style={{ width: '200px' }}
              className={buttonLabelClassname}
            >
              <UploadOutlined /> {buttonLabel}
            </Button>
          </Upload>
        )}
      />
    </Form.Item>
  );
};

export default FileUploadButton;
