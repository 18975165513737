import { yupResolver } from '@hookform/resolvers/yup';
import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Button, Drawer, Popconfirm, message } from 'antd';

import { IUploadedCertificate } from '../../../types/uploaded-certificate-response';
import { addReivewToUploadedCertificate } from '../../../services/uploaded-certifcate-response';
import { getTechniques } from '../../../services/technique-service';
import { TechniqueDetail } from '../../../types/technique';

import validationSchema from './validationSchema';
import ReviewForm from './form';

interface IFormSchema {
  remarks: string;
  isApproved: boolean;
  assignedCertificationCode: string[];
  assignedCertificationLevel: Record<string, number>;
}

interface IAddReivewProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: IUploadedCertificate;
  activeResponseId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddReivew: FC<IAddReivewProps> = ({
  children,
  payload,
  activeResponseId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requestingApprove, setRequestingApprove] = useState(false);
  const [requestingReject, setRequestingReject] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);

  const handleRejectPopConfirm = () => {
    setOpenReject((prev) => !prev);
  };

  const form = useForm<IFormSchema>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
    setOpenReject(false);
  };

  const onSubmitHandler = async (values: IFormSchema) => {
    if (values?.isApproved) {
      setRequestingApprove(true);
    } else setRequestingReject(true);
    try {
      await addReivewToUploadedCertificate({
        ...values,
        responseId: payload?._id,
      });
      setDrawerVisible(false);

      message.success('Assessment Reivew added successfully');

      setOpenReject(false);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequestingApprove(false);
      setRequestingReject(false);
    }
  };

  const loadTechniques = useCallback(async () => {
    try {
      const response = await getTechniques();
      setTechniques(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (
      activeResponseId &&
      activeResponseId === payload?._id &&
      drawerVisible !== true
    ) {
      setDrawerVisible(true);
    }
  }, []);

  useEffect(() => {
    if (drawerVisible === true) loadTechniques();
  }, [drawerVisible]);

  useEffect(() => {
    if (!payload) return;

    const level: { [code: string]: number } = {};
    payload?.assignedCertificationCode?.forEach((item, index) => {
      if (!level[item]) {
        level[item] = payload?.assignedCertificationLevel?.[index] || 1;
      }
    });

    form.reset({
      isApproved: payload.isApproved,
      remarks: payload.remarks || '',
      assignedCertificationCode: payload.assignedCertificationCode || [],
      assignedCertificationLevel: level,
    });
  }, [payload]);

  return (
    <>
      <Drawer
        title="Submit Reivew"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requestingApprove}
              onClick={() => {
                form.setValue('isApproved', true);
                form.handleSubmit(onSubmitHandler)();
              }}
              style={{ marginBottom: '10px' }}
              disabled={payload?.isReviewed}
            >
              Approve
            </Button>
            <Popconfirm
              title="Are You Sure ?"
              open={openReject}
              onConfirm={() => {
                form.setValue('isApproved', false);
                form.handleSubmit(onSubmitHandler)();
              }}
              onCancel={handleRejectPopConfirm}
            >
              <Button
                block
                type="ghost"
                loading={requestingReject}
                onClick={() => setOpenReject(true)}
                disabled={payload?.isReviewed}
              >
                Reject
              </Button>
            </Popconfirm>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <h2>Certificates</h2>
          <div>
            {payload?.certificates?.map((response, index) => {
              return (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <Button
                    type="primary"
                    onClick={() => window.open(response?.url, '_blank')}
                  >
                    Open Certificate {index + 1}
                  </Button>
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: '30px' }}>
            <ReviewForm form={form} techniques={techniques} />
          </div>
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddReivew;
